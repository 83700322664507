import { lazy } from "react";

const Report = lazy(() => import("./Report"));

const ReportConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "reports",
      element: <Report />,
    },
  ],
};

export default ReportConfig;
