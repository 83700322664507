import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

function Error404Page() {
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="w-full max-w-3xl text-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
        >
          <Box
            component="svg"
            width="100%"
            height="100%"
            viewBox="0 0 500 380"
            fill="none"
            preserveAspectRatio="xMidYMax slice"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ color: "secondary.main" }}
          >
            <g id="Background_Complete">
              <g>
                <g>
                  <path
                    fill="#E6E6E6"
                    d="M406.7,124.8c0,0,1.4-9.5-5.5-15.6c-6.9-6.1-6.3-9.7-4.3-10.2c2-0.5,3.8,5.6,6.8,7.3c0,0,0.1-8.7-3-11.9
				c-3.1-3.2-4.7-11.4-1.8-11.6c2.9-0.1,3.2,4.9,3.9,8.5c0.6,3.6,3.9,8.6,3.9,8.6s0.6-7.8,2.6-10.9c2-3.2-3.3-10.5-1.3-13.7
				c2.1-3.2,5.7,2.9,5.4,9.7s-4.9,14.1-3.8,21.3c0,0,2-5,3.8-5.5c1.8-0.5,3.6-3.8,4.8-5c1.2-1.2,2.7,3,0,7c-2.7,4-9.7,14.5-8.7,23.1
				L406.7,124.8z"
                  />
                  <path
                    fill="#E6E6E6"
                    d="M402.6,120.4l-2.3,14.2c-0.7,4.4,2.7,8.4,7.1,8.4h2.4c4.4,0,7.8-4,7.1-8.4l-2.3-14.2H402.6z"
                  />
                  <rect
                    x="276.7"
                    y="137.8"
                    fill="#E0E0E0"
                    width="160.1"
                    height="8.2"
                  />
                  <rect
                    x="284.1"
                    y="107.4"
                    fill="#EBEBEB"
                    width="7.1"
                    height="30.3"
                  />
                  <rect
                    x="318.2"
                    y="107.4"
                    fill="#EBEBEB"
                    width="7.1"
                    height="30.3"
                  />

                  <rect
                    x="359.5"
                    y="107.1"
                    transform="matrix(0.9855 -0.1698 0.1698 0.9855 -15.4848 63.4375)"
                    fill="#EBEBEB"
                    width="7.1"
                    height="30.3"
                  />
                  <rect
                    x="292.8"
                    y="111.4"
                    fill="#EBEBEB"
                    width="7.1"
                    height="26.3"
                  />
                  <rect
                    x="309"
                    y="111.4"
                    fill="#EBEBEB"
                    width="7.1"
                    height="26.3"
                  />
                  <rect
                    x="350"
                    y="111.4"
                    fill="#EBEBEB"
                    width="7.1"
                    height="26.3"
                  />

                  <rect
                    x="330.4"
                    y="111"
                    transform="matrix(0.9762 -0.2169 0.2169 0.9762 -18.9802 75.3832)"
                    fill="#EBEBEB"
                    width="7.1"
                    height="26.3"
                  />
                  <rect
                    x="302.4"
                    y="105.9"
                    fill="#EBEBEB"
                    width="3.5"
                    height="31.8"
                  />
                  <rect
                    x="344.4"
                    y="105.9"
                    fill="#EBEBEB"
                    width="3.5"
                    height="31.8"
                  />
                </g>
                <g>
                  <rect
                    x="276.7"
                    y="199.2"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 713.5235 406.6038)"
                    fill="#E0E0E0"
                    width="160.1"
                    height="8.2"
                  />

                  <rect
                    x="422.4"
                    y="168.9"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 851.8518 368.051)"
                    fill="#EBEBEB"
                    width="7.1"
                    height="30.3"
                  />

                  <rect
                    x="388.3"
                    y="168.9"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 783.6315 368.051)"
                    fill="#EBEBEB"
                    width="7.1"
                    height="30.3"
                  />

                  <rect
                    x="346.9"
                    y="168.5"
                    transform="matrix(-0.9855 -0.1699 0.1699 -0.9855 664.6416 424.1586)"
                    fill="#EBEBEB"
                    width="7.1"
                    height="30.3"
                  />

                  <rect
                    x="413.7"
                    y="172.9"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 834.3697 372.0474)"
                    fill="#EBEBEB"
                    width="7.1"
                    height="26.3"
                  />

                  <rect
                    x="397.4"
                    y="172.9"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 801.9413 372.0474)"
                    fill="#EBEBEB"
                    width="7.1"
                    height="26.3"
                  />

                  <rect
                    x="356.5"
                    y="172.9"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 720.0882 372.0474)"
                    fill="#EBEBEB"
                    width="7.1"
                    height="26.3"
                  />

                  <rect
                    x="376.1"
                    y="172.4"
                    transform="matrix(-0.9762 -0.2169 0.2169 -0.9762 709.933 449.0645)"
                    fill="#EBEBEB"
                    width="7.1"
                    height="26.3"
                  />

                  <rect
                    x="407.6"
                    y="167.4"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 818.6428 366.5453)"
                    fill="#EBEBEB"
                    width="3.5"
                    height="31.8"
                  />

                  <rect
                    x="365.6"
                    y="167.4"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 734.7251 366.5453)"
                    fill="#EBEBEB"
                    width="3.5"
                    height="31.8"
                  />

                  <rect
                    x="292.1"
                    y="175.2"
                    transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 618.5021 374.1024)"
                    fill="#EBEBEB"
                    width="34.4"
                    height="23.7"
                  />
                </g>
              </g>
              <g>
                <path
                  fill="#F0F0F0"
                  d="M131.3,208.5l1.9,12.4l24.2-1.4l0.5-12.6c7.8-2.1,15.2-5.6,21.9-10.5l10.1,7.5l16.2-18.1l-8.5-9.2
			c4.2-7.2,6.8-15,8-22.9l12.4-1.9l-1.4-24.2l-12.6-0.5c-2.1-7.8-5.6-15.2-10.5-21.9l7.5-10.1l-18.1-16.2l-9.2,8.5
			c-7.2-4.2-15-6.8-22.9-8L148.9,67l-24.2,1.4L124.2,81c-7.8,2.1-15.2,5.6-21.9,10.5L92.2,84L76,102.1l8.5,9.2
			c-4.2,7.2-6.8,15-8,22.9l-12.4,1.9l1.4,24.2l12.6,0.5c2.1,7.8,5.6,15.2,10.5,21.9l-7.5,10.1L99.2,209l9.2-8.5
			C115.6,204.6,123.4,207.3,131.3,208.5z M119.2,168.5c-13.5-12.1-14.7-32.9-2.6-46.4c12.1-13.5,32.9-14.7,46.4-2.6
			c13.5,12.1,14.7,32.9,2.6,46.4C153.4,179.4,132.7,180.6,119.2,168.5z"
                />
                <path
                  fill="#F0F0F0"
                  d="M196.8,62.8l4.4-6.3l-3-2.5c1-2.4,1.5-5,1.6-7.5l3.8-1.1l-1.4-7.5l-4,0.3c-1-2.4-2.3-4.5-4.2-6.4l1.9-3.5
			l-6.3-4.4l-2.5,3c-2.4-1-5-1.5-7.5-1.6l-1.1-3.8l-7.5,1.4l0.3,4c-2.4,1-4.5,2.3-6.4,4.2l-3.5-1.9l-4.4,6.3l3,2.5
			c-1,2.4-1.5,5-1.6,7.5l-3.8,1.1l1.4,7.5l4-0.3c1,2.4,2.3,4.5,4.2,6.4l-1.9,3.5l6.3,4.4l2.5-3c2.4,1,5,1.5,7.5,1.6l1.1,3.8l7.5-1.4
			l-0.3-4c2.4-1,4.5-2.3,6.4-4.2L196.8,62.8z M173.2,54.5c-4.7-3.3-5.9-9.7-2.7-14.4c3.3-4.7,9.7-5.9,14.4-2.7
			c4.7,3.3,5.9,9.7,2.7,14.4C184.4,56.6,178,57.8,173.2,54.5z"
                />
                <path
                  fill="#F0F0F0"
                  d="M116.3,246.7l3.9-4.4l-2.1-2.2c1-1.7,1.6-3.6,1.9-5.5l3-0.5l-0.3-5.8l-3-0.1c-0.5-1.9-1.3-3.7-2.5-5.3
			l1.8-2.4l-4.4-3.9l-2.2,2.1c-1.7-1-3.6-1.6-5.5-1.9l-0.5-3l-5.8,0.3l-0.1,3c-1.9,0.5-3.7,1.3-5.3,2.5l-2.4-1.8l-3.9,4.4l2.1,2.2
			c-1,1.7-1.6,3.6-1.9,5.5l-3,0.5l0.3,5.8l3,0.1c0.5,1.9,1.3,3.7,2.5,5.3l-1.8,2.4l4.4,3.9l2.2-2.1c1.7,1,3.6,1.6,5.5,1.9l0.5,3
			l5.8-0.3l0.1-3c1.9-0.5,3.7-1.3,5.3-2.5L116.3,246.7z M99.2,238.2c-3.3-2.9-3.5-7.9-0.6-11.2c2.9-3.3,7.9-3.5,11.2-0.6
			c3.3,2.9,3.5,7.9,0.6,11.2C107.5,240.8,102.5,241.1,99.2,238.2z"
                />
                <path
                  fill="#F0F0F0"
                  d="M88.4,79.1L93,74l-2.4-2.6c1.2-2,1.9-4.2,2.2-6.4l3.5-0.5l-0.4-6.8l-3.5-0.1c-0.6-2.2-1.6-4.3-2.9-6.1
			l2.1-2.8l-5.1-4.5l-2.6,2.4c-2-1.2-4.2-1.9-6.4-2.2l-0.5-3.5l-6.8,0.4L70,44.6c-2.2,0.6-4.3,1.6-6.1,2.9L61,45.5l-4.5,5.1l2.4,2.6
			c-1.2,2-1.9,4.2-2.2,6.4l-3.5,0.5l0.4,6.8l3.5,0.1c0.6,2.2,1.6,4.3,2.9,6.1L57.9,76l5.1,4.5l2.6-2.4c2,1.2,4.2,1.9,6.4,2.2
			l0.5,3.5l6.8-0.4l0.1-3.5c2.2-0.6,4.3-1.6,6.1-2.9L88.4,79.1z M68.6,69.2c-3.8-3.4-4.1-9.2-0.7-13c3.4-3.8,9.2-4.1,13-0.7
			c3.8,3.4,4.1,9.2,0.7,13C78.2,72.2,72.4,72.6,68.6,69.2z"
                />
                <path
                  fill="#F0F0F0"
                  d="M150.4,33.9l2-2.2l-1-1.1c0.5-0.9,0.8-1.8,1-2.8l1.5-0.2l-0.2-2.9l-1.5-0.1c-0.3-0.9-0.7-1.8-1.3-2.7
			l0.9-1.2l-2.2-2l-1.1,1c-0.9-0.5-1.8-0.8-2.8-1l-0.2-1.5l-2.9,0.2l-0.1,1.5c-0.9,0.3-1.8,0.7-2.7,1.3l-1.2-0.9l-2,2.2l1,1.1
			c-0.5,0.9-0.8,1.8-1,2.8l-1.5,0.2l0.2,2.9l1.5,0.1c0.3,0.9,0.7,1.8,1.3,2.7l-0.9,1.2l2.2,2l1.1-1c0.9,0.5,1.8,0.8,2.8,1l0.2,1.5
			l2.9-0.2l0.1-1.5c0.9-0.3,1.8-0.7,2.7-1.3L150.4,33.9z M141.8,29.6c-1.6-1.5-1.8-4-0.3-5.6c1.5-1.6,4-1.8,5.6-0.3
			c1.6,1.5,1.8,4,0.3,5.6C145.9,30.9,143.4,31.1,141.8,29.6z"
                />
                <path
                  fill="#F0F0F0"
                  d="M205.1,90.9c1.6,0.7,3.3,1,5,1.1l0.7,2.6l5.1-0.9l-0.2-2.7c1.6-0.6,3.1-1.6,4.3-2.8l2.3,1.3l2.9-4.2
			l-2-1.7c0.7-1.6,1-3.3,1.1-5l2.6-0.7l-0.9-5.1l-2.7,0.2c-0.6-1.6-1.6-3.1-2.8-4.3l1.3-2.3l-4.2-2.9l-1.7,2c-1.6-0.7-3.3-1-5-1.1
			l-0.7-2.6l-5.1,0.9l0.2,2.7c-1.6,0.6-3.1,1.6-4.3,2.8l-2.3-1.3l-2.9,4.2l2,1.7c-0.7,1.6-1,3.3-1.1,5l-2.6,0.7l0.9,5.1l2.7-0.2
			c0.6,1.6,1.6,3.1,2.8,4.3l-1.3,2.3l4.2,2.9L205.1,90.9z M206.5,83.9c-3.2-2.2-4-6.5-1.8-9.7c2.2-3.2,6.5-4,9.7-1.8
			c3.2,2.2,4,6.5,1.8,9.7C214.1,85.3,209.7,86.1,206.5,83.9z"
                />
              </g>
            </g>
            <g id="Background_Simple" display="none">
              <g display="inline">
                <path
                  fill="#407BFF"
                  d="M205.8,27.6c61.1-33.3,140.6,36,147.1,95.7c6.5,59.7,0.3,90.4,39.6,98.7c39.3,8.3,74.3,39.4,45.3,83.4
			c-28.9,44-100.2,68.8-166,43.4c-65.7-25.4-104.2,27.1-169-36.7c-35.3-34.8-4.8-78.3,26.7-109.6C161.2,171.1,134.9,66.2,205.8,27.6
			z"
                />
                <path
                  opacity="0.9"
                  fill="#FFFFFF"
                  d="M205.8,27.6c61.1-33.3,140.6,36,147.1,95.7c6.5,59.7,0.3,90.4,39.6,98.7
			c39.3,8.3,74.3,39.4,45.3,83.4c-28.9,44-100.2,68.8-166,43.4c-65.7-25.4-104.2,27.1-169-36.7c-35.3-34.8-4.8-78.3,26.7-109.6
			C161.2,171.1,134.9,66.2,205.8,27.6z"
                />
              </g>
            </g>
            <g id="Shadow_1_">
              <ellipse
                id="_x3C_Path_x3E__359_"
                fill="#F5F5F5"
                cx="250"
                cy="369.9"
                rx="193.9"
                ry="11.3"
              />
            </g>
            <g id="Character">
              <g>
                <g>
                  <path
                    fill="#EBB376"
                    d="M220.7,198.6c-0.9-0.2-1.7-0.7-2.3-1.4l-5.1-6.2c-1-1.2-1.2-2.9-0.6-4.3l2.8-6.2
				c2.8-6,6.8-11.3,11.8-15.6l1.7-1.4c2.8-2.4,7-2.1,9.4,0.7c0,0.1,0.1,0.1,0.1,0.2l5.2,6.4c2.1,2.6,2,6.2-0.2,8.7l-6.4,7.3
				C237.2,186.7,229,200.1,220.7,198.6z"
                  />
                  <path
                    fill="#407BFF"
                    d="M277.2,226.5c-3.1-5.9-8-9.3-14.7-9.6c-4.7-0.2-8.6,1.9-12.1,5c-7,6.1-14.2,12.1-22.3,16.9
				c-2.4,1.4-4.9,2.7-7.4,3.8c-0.5,0.2-1,0.5-1.5,0.3c-1.8-0.7-0.7-7.5-0.1-10.6c0.9-4.9,3.2-15.3,10.4-34.8
				c-6-3.2-12.1-6.4-18.1-9.6c-8.9,13.3-13.7,25.3-16.4,33.6c-4.3,13.2-4.7,21.7-3.8,28.5c1.3,9.6,5.3,14.5,6.5,16
				c4.2,4.9,9.6,7.5,15.9,8.4c6,0.9,11.9-0.2,17.6-1.9c11.6-3.5,21.6-9.7,31-17c3.9-3,7.5-6.2,11.1-9.7c2.9-2.8,4.8-6.1,5.4-10.1
				c0-0.2,0.1-0.4,0.2-0.6c0-1.1,0-2.1,0-3.2C278.4,230.2,278.1,228.3,277.2,226.5z"
                  />
                  <path
                    opacity="0.2"
                    d="M252.5,226.2c-1.9,8.1-4.7,17.6-9.1,27.8c-2.9,6.6-5.9,12.5-8.9,17.6c1.7-0.6,4-1.5,6.7-2.7
				c5.9-2.7,10-5.4,13.1-7.4c3.1-2,6.9-4.7,11.1-8.2C261.1,244.1,256.8,235.1,252.5,226.2z"
                  />
                  <path
                    opacity="0.2"
                    d="M223.3,168.8c0.3,2.3,1,5.2,2.3,8.4c2.7,6.4,6.7,10.5,9.3,12.8c0.8-1.1,1.6-2.2,2.4-3.3
				c1.7-2.2,3.5-4.3,5.2-6.1c-4.1-6-8.3-11.9-12.4-17.9c-1.4,1-3,2.3-4.5,3.8C224.7,167.3,224,168.1,223.3,168.8z"
                  />
                </g>
                <g>
                  <path
                    fill="#407BFF"
                    d="M398.4,338.6c-13-32.4-21.1-54.9-30.4-114.9c-2.3-14.8-16-24.4-30.1-23.1c-4,0.4-17.4-1.3-40.6,4.3
				c-16.9,4.1-46.7,17.3-46.7,17.3s12.5,61.7,49.2,116.4H398.4z"
                  />
                  <path
                    opacity="0.7"
                    fill="#FFFFFF"
                    d="M398.4,338.6c-13-32.4-21.1-54.9-30.4-114.9c-2.3-14.8-16-24.4-30.1-23.1
				c-4,0.4-17.4-1.3-40.6,4.3c-16.9,4.1-46.7,17.3-46.7,17.3s12.5,61.7,49.2,116.4H398.4z"
                  />
                </g>
                <path
                  fill="#407BFF"
                  d="M333.8,286.4c3.7,9,9.6,20.2,15.6,30.6c4.6,8,9.1,15.6,12.8,21.6h38.4l-14.7-35.2l-2.1-5.1l-2.7-14.1
			L368.7,221c-2.5-12.8-14.8-22.8-27-22l-16.6,1.1c0,0-0.1,0.2-0.2,0.7c-1.2,5.7-8.1,44.1,8.8,85.3
			C333.7,286.1,333.8,286.3,333.8,286.4z"
                />
                <path
                  fill="#407BFF"
                  d="M285.7,322.6c4.3,9.3,7.1,15.3,7.1,15.3s0,0.3-0.1,0.7h14.8c0-5-0.5-10.5-1.2-16.3
			c-1.3-10.3-3.7-21.5-6.6-31.6c-1-3.6-2.1-7.1-3.3-10.5c-9.7-27.5-14.5-67.3-14.9-70.5c0-0.2,0-0.2,0-0.2s-30.2,8.8-31,12.7
			c-2.1,10.4,10.3,43.1,22.3,71.2C277.5,304.4,282.1,314.7,285.7,322.6z"
                />
                <g>
                  <path
                    fill="#EBB376"
                    d="M281.8,205.4c4.4,4.8,6.2,8.5,6.7,11.1c0.8,3.9-1.1,5.6-1.1,5.6c2.3,9.6,15.2,7.6,15.2,7.6
				c14.5-16.7,13.2-28.1,13.2-28.1c-9.2-0.2-15.5-6.1-19.6-12.1c-4.4-6.5-6.1-13.1-6.1-13.1l-16.4,21.3
				C277.1,200.5,279.7,203.1,281.8,205.4z"
                  />
                  <path
                    opacity="0.2"
                    d="M273.6,197.7c3.4,2.8,6.1,5.3,8.2,7.6c4.4,4.8,6.2,8.5,6.7,11.1c7.1-8.4,8.4-18.5,7.7-26.9
				c-4.4-6.5-6.1-13.1-6.1-13.1L273.6,197.7z"
                  />
                </g>
                <g>
                  <path
                    fill="#263238"
                    d="M278.7,120.6c0-2.3,0.4-8.9,5.2-14.1c6.2-6.8,16.9-8.1,24.1-5c9.1,3.9,7.1,12.5,19.2,31
				c9.5,14.5,13.9,14,17.1,24.2c0.9,2.8,6.2,19.7-3.9,30.8c-5.5,6.1-13,7.5-16.5,7.9c1.7-3,3.4-7.4,2.4-12.1
				c-1.2-5.9-6.1-8.9-9.6-12c-16.3-14.1-8.9-32.7-25.3-44.8C289,124.5,284.9,122.1,278.7,120.6z"
                  />
                  <path
                    fill="#263238"
                    d="M233.6,178.6c-1.4-2-12.7-18.5-6.5-36.8c4.3-12.6,14.5-19.2,18.5-21.8c4-2.6,11.7-7.6,21.1-7.6
				c23.8,0.2,41.9,29.1,40.9,46.6c-0.6,9.6-3,21.5-11.5,28.1c-3.4,2.7-4.8-7.6-4.8-7.6S258.1,195.1,233.6,178.6z"
                  />
                  <path
                    fill="#EBB376"
                    d="M289.7,142c6.6,22.1,9.9,31.3,3.4,46.9c-9.8,23.5-37.6,28.1-50.5,8.7c-11.6-17.4-20.5-50.7-3.2-68.1
				C256.5,112.4,283,119.8,289.7,142z"
                  />
                  <path
                    fill="#263238"
                    d="M264.4,143.2c17.3,9.1,2.4,35.5,24.3,24.3c22-11.2-1.6-38.6-18.2-39.7
				C253.9,126.7,264.4,143.2,264.4,143.2z"
                  />
                  <path
                    fill="#263238"
                    d="M259.3,161.4c0.7,1.8,0.3,3.7-0.8,4.2c-1.1,0.5-2.6-0.5-3.3-2.3c-0.7-1.8-0.3-3.7,0.8-4.2
				C257.1,158.6,258.6,159.6,259.3,161.4z"
                  />
                  <path
                    fill="#263238"
                    d="M238.4,171.5c0.7,1.8,0.3,3.7-0.8,4.2c-1.1,0.5-2.6-0.5-3.3-2.3c-0.7-1.8-0.3-3.7,0.8-4.2
				C236.2,168.6,237.7,169.7,238.4,171.5z"
                  />
                  <path
                    fill="#263238"
                    d="M235.6,169l-4.6,0.6C231,169.7,234.4,172.1,235.6,169z"
                  />
                  <path
                    fill="#D58745"
                    d="M245.2,171.7c0,0-0.2,9.1-2.1,14.3c3.1,1.3,6.6-1.4,6.6-1.4L245.2,171.7z"
                  />
                  <path
                    fill="#263238"
                    d="M264.9,185.3c1-0.3,2-0.6,3.1-0.7c0.3,0,0.6,0.2,0.6,0.5c0,0.3-0.2,0.6-0.5,0.6
				c-8.2,1.1-11.6,7.6-11.6,7.7c-0.1,0.3-0.5,0.4-0.7,0.3c-0.3-0.1-0.4-0.5-0.2-0.8C255.6,192.7,258.4,187.4,264.9,185.3z"
                  />
                  <path
                    fill="#EBB376"
                    d="M301.7,160.4c0.1,5.2-2,9.5-4.7,11.9c-4,3.7-8.9-0.7-10.1-6.8c-1.1-5.6-0.3-14.2,4.9-15.4
				C296.9,148.9,301.6,154.5,301.7,160.4z"
                  />
                  <path
                    fill="#263238"
                    d="M227.3,164.1c0.1-0.3,0.4-0.5,0.7-0.5c4.7-1.1,6-5,6-5.1c0.2-0.6,0.8-0.9,1.4-0.7
				c0.6,0.2,0.9,0.9,0.7,1.5c-0.1,0.2-1.7,5.2-7.5,6.5c-0.6,0.1-1.2-0.3-1.3-0.9C227.1,164.7,227.1,164.4,227.3,164.1z"
                  />
                  <path
                    fill="#263238"
                    d="M258.2,150.9c0.3,0.1,0.5,0.3,0.6,0.5c0.3,0.5,0,1.2-0.6,1.5c-5.8,2.7-10.3,0.2-10.5,0.1
				c-0.5-0.3-0.7-1-0.3-1.5c0.3-0.5,1.1-0.7,1.6-0.4c0.2,0.1,3.7,2,8.3-0.2C257.6,150.8,257.9,150.8,258.2,150.9z"
                  />
                  <path
                    fill="#263238"
                    d="M256.6,159l-4.6,0.6C252,159.6,255.4,162,256.6,159z"
                  />
                  <path
                    fill="#263238"
                    d="M246.4,136.9c12.5,12.2,25.9,17.2,32.9,16.5c5.6-0.6,9.4-2.4,9.4-2.4c2.7-1.3,6.8-3.9,6.8-3.9
				c-0.6-3-1.7-6.9-4.1-10.9c-5.9-9.9-15.1-14-18.7-15.4c-3.1-1.2-12.7-4.3-22.5-1.2c-13.9,4.4-23.9,20.1-24.3,39.1
				c0.8-0.6,1.7-1.3,2.6-1.9c3.1-2,6.2-3,8.6-3.6c0.2-1.8,0.6-4,1.6-6.3C240.9,141.7,244.3,138.5,246.4,136.9z"
                  />
                  <path
                    fill="#407BFF"
                    d="M275.1,154.5c-0.1,1-1,1.8-2,1.8c-0.6,0-1.4-0.1-2.1-0.4c-0.7-0.4-1.2-0.9-1.6-1.4
				c-0.5-0.7-0.5-1.6-0.1-2.3l0.9-1.5c0,0,0.1-0.1,0.1-0.1c2.1-2.9,3.5-4.2,4.4-3.8C275.7,147.1,275.8,149.6,275.1,154.5z"
                  />
                  <path
                    opacity="0.7"
                    fill="#FFFFFF"
                    d="M274.4,153.8c-0.1,0.7-0.7,1.3-1.5,1.3c-0.5,0-1-0.1-1.6-0.3c-0.5-0.3-0.9-0.7-1.2-1
				c-0.4-0.5-0.4-1.2,0-1.7l0.7-1.1c0,0,0-0.1,0.1-0.1c1.5-2.1,2.6-3.1,3.2-2.8C274.9,148.3,275,150.2,274.4,153.8z"
                  />
                </g>
                <path
                  opacity="0.2"
                  d="M273.5,179.5c-0.7-2.8-1.2-6.3-1.3-10.3c-0.1-5.2,0.6-9.7,1.4-13c0.5,2.4,1,4.9,1.4,7.3
			c7.1,1.7,14.2,3.3,21.3,5c1.5-4.2,3-8.4,4.4-12.6c0.1,0.4,0.4,1.1,0.6,2c0.1,0.3,1.5,7.5-3.1,13.1c-0.4,0.5-1,1.1-1.7,1.7
			c0,0.6,0,1.3-0.1,1.9c-0.1,2.1-0.4,4.1-0.6,6C288.4,180.3,280.9,179.9,273.5,179.5z"
                />
                <path
                  fill="#EBB376"
                  d="M278.4,179.4c0.8,0.6,1.8,0.9,2.8,0.9l8.7-0.2c1.7,0,3.2-1,3.9-2.6l3-6.7c2.9-6.5,4.3-13.6,4.3-20.7
			l-0.1-2.4c0-4-3.3-7.2-7.3-7.2c-0.1,0-0.2,0-0.2,0l-8.9,0.3c-3.5,0.1-6.4,2.8-6.9,6.4l-1.4,10.4
			C276.3,157.5,271.2,173.7,278.4,179.4z"
                />
                <path
                  opacity="0.2"
                  d="M355.6,203.2c0.8,0.4,1.9,1,3,2c6.7,5.6,6.4,15,6.4,15.6c-0.4,8.2-6.8,13.6-12.2,18.1
			c-10,8.4-21.2,11.4-28.5,12.5C334.7,235.3,345.2,219.3,355.6,203.2z"
                />
                <path
                  opacity="0.2"
                  d="M289.4,255.6c-2.8-0.6-7.6-1.9-12-5.7c-5.5-4.8-7.3-10.6-8.8-15.6c-2.4-7.8-2.3-14.9-1.6-20.1
			c2.3-0.8,4.5-1.5,6.8-2.3C278.9,226.5,284.1,241,289.4,255.6z"
                />
                <path
                  fill="#407BFF"
                  d="M355.9,203.4c-5-4-10.9-3.5-13-3.4c-8.7,0.7-15.2,6.5-21.9,12.4c-3.3,2.9-6.7,5.6-9.9,8.5
			c-1.9,1.8-5.5,4.2-10.3,5.5c-0.3,0.1-0.7,0-0.7,0c-0.2,0-0.3-0.1-0.5-0.2c-0.4-0.2-0.6-0.5-0.7-0.9c-0.7-2.6-1.4-5.2-1.9-7.8
			c-1.6-8.4-1.6-15.2-1.7-23c0-5.4,0.2-10.9,0.5-16.3l0-0.5l-21.9-2.2l-0.1,0.4c-2.7,13.9-4.2,27.4-4.4,40c-0.1,6.6,0,14.3,3.7,23
			c1.2,2.9,2.5,5.9,5.2,9.1c5.2,6,13,9.3,21.4,9.3c1.4,0,2.7-0.1,4.3-0.3c13.8-1.8,25.7-8.2,30.1-10.7c14.7-8.4,21.2-16.7,23.2-19.3
			c2.7-3.6,4.5-6.9,4.5-11C362,208.5,356.2,203.6,355.9,203.4z"
                />
              </g>
            </g>
            <g id="Speech_Bubble">
              <g>
                <g id="bBaloon">
                  <path
                    fill="#407BFF"
                    d="M408.2,60.9c0-5.2-2.7-9.8-7-13.2c0.1-0.7,0.2-1.4,0.2-2.1c0-6.9-7-12.5-15.7-12.6
				c-2.6-6.7-10.5-11.5-19.8-11.5c-7.4,0-13.9,3.1-17.6,7.7c-0.9-0.1-1.9-0.2-2.8-0.2c-8.9,0-16.1,5.7-16.1,12.6
				c0,1.2,0.2,2.4,0.6,3.5c-5.7,3-9.4,8-9.4,13.8c0,5.5,3.5,10.4,8.8,13.4c0,0.1,0,0.1,0,0.2c0,6.8,6.8,12.3,15.3,12.6
				c4.7,4.6,11.9,7.5,20,7.5c5.7,0,10.9-1.4,15.1-3.9c0.7,0.1,1.4,0.1,2.2,0.1c8.9,0,16.1-5.7,16.1-12.6c0,0,0-0.1,0-0.1
				C404.2,72.8,408.2,67.2,408.2,60.9z"
                  />
                  <path
                    fill="#407BFF"
                    d="M301.9,76.7c0,1.5-1.8,2.8-4,2.8c-2.2,0-4-1.2-4-2.8s1.8-2.8,4-2.8C300.1,73.9,301.9,75.2,301.9,76.7z"
                  />
                  <path
                    fill="#407BFF"
                    d="M315.5,66.6c0,2.3-2.7,4.2-6.1,4.2c-3.3,0-6.1-1.9-6.1-4.2c0-2.3,2.7-4.2,6.1-4.2
				C312.8,62.3,315.5,64.2,315.5,66.6z"
                  />
                  <path
                    opacity="0.6"
                    fill="#FFFFFF"
                    d="M408.2,60.9c0-5.2-2.7-9.8-7-13.2c0.1-0.7,0.2-1.4,0.2-2.1c0-6.9-7-12.5-15.7-12.6
				c-2.6-6.7-10.5-11.5-19.8-11.5c-7.4,0-13.9,3.1-17.6,7.7c-0.9-0.1-1.9-0.2-2.8-0.2c-8.9,0-16.1,5.7-16.1,12.6
				c0,1.2,0.2,2.4,0.6,3.5c-5.7,3-9.4,8-9.4,13.8c0,5.5,3.5,10.4,8.8,13.4c0,0.1,0,0.1,0,0.2c0,6.8,6.8,12.3,15.3,12.6
				c4.7,4.6,11.9,7.5,20,7.5c5.7,0,10.9-1.4,15.1-3.9c0.7,0.1,1.4,0.1,2.2,0.1c8.9,0,16.1-5.7,16.1-12.6c0,0,0-0.1,0-0.1
				C404.2,72.8,408.2,67.2,408.2,60.9z"
                  />
                  <path
                    opacity="0.6"
                    fill="#FFFFFF"
                    d="M301.9,76.7c0,1.5-1.8,2.8-4,2.8c-2.2,0-4-1.2-4-2.8s1.8-2.8,4-2.8
				C300.1,73.9,301.9,75.2,301.9,76.7z"
                  />
                  <path
                    opacity="0.6"
                    fill="#FFFFFF"
                    d="M315.5,66.6c0,2.3-2.7,4.2-6.1,4.2c-3.3,0-6.1-1.9-6.1-4.2c0-2.3,2.7-4.2,6.1-4.2
				C312.8,62.3,315.5,64.2,315.5,66.6z"
                  />
                </g>
                <g id="Block_00000064338532358506309180000007488627355369483936_">
                  <g>
                    <rect
                      x="337.4"
                      y="43.5"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="337.4"
                      y="43.5"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="355.7"
                      y="43.5"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="355.7"
                      y="43.5"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="373.9"
                      y="43.5"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="373.9"
                      y="43.5"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="328.9"
                      y="52.8"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="328.9"
                      y="52.8"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="347.1"
                      y="52.8"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="347.1"
                      y="52.8"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="365.4"
                      y="52.8"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="365.4"
                      y="52.8"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="383.7"
                      y="52.8"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="383.7"
                      y="52.8"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="337.4"
                      y="62.7"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="337.4"
                      y="62.7"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="355.7"
                      y="62.7"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="355.7"
                      y="62.7"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="373.9"
                      y="62.7"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="373.9"
                      y="62.7"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="346.1"
                      y="34.2"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="346.1"
                      y="34.2"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="364.4"
                      y="34.2"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="364.4"
                      y="34.2"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="347.1"
                      y="72"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="347.1"
                      y="72"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                  <g>
                    <rect
                      x="365.4"
                      y="72"
                      fill="#407BFF"
                      width="16.3"
                      height="7.8"
                    />
                    <rect
                      x="365.4"
                      y="72"
                      opacity="0.8"
                      fill="#FFFFFF"
                      width="16.3"
                      height="7.8"
                    />
                  </g>
                </g>
                <g id="_x3F_">
                  <path
                    fill="#407BFF"
                    d="M369.4,49.8c-0.1-3.2-2.8-5.3-7.1-5.2c-4.1,0.1-7,1.7-8.9,4.5l-5.5-3.5c3-4.3,7.9-7,14.8-7.1
				c8.1-0.1,13.7,3.6,13.9,10.1c0.3,9.2-9.2,10.2-9,16.9l-7,0.1C360.4,57.2,369.6,55.5,369.4,49.8z M360,74.7
				c-0.1-2.4,1.7-4.4,4.3-4.4c2.5,0,4.4,1.9,4.5,4.3c0.1,2.4-1.7,4.4-4.3,4.5C361.9,79.1,360.1,77.1,360,74.7z"
                  />
                </g>
              </g>
            </g>
            <g id="Table">
              <g id="Table_00000133503001058431316980000001075791575608379275_">
                <rect
                  x="70.6"
                  y="346.3"
                  fill="#263238"
                  width="360.2"
                  height="23.3"
                />
                <rect
                  x="70.6"
                  y="346.3"
                  opacity="0.3"
                  fill="#FFFFFF"
                  width="360.2"
                  height="23.3"
                />
                <path
                  fill="#263238"
                  d="M432.2,338.5H69.3c-2,0-3.7,1.7-3.7,3.7v8.1c0,2,1.7,3.7,3.7,3.7h362.9c2,0,3.7-1.7,3.7-3.7v-8.1
			C435.9,340.2,434.2,338.5,432.2,338.5z"
                />
                <path
                  opacity="0.4"
                  fill="#FFFFFF"
                  d="M432.2,338.5H69.3c-2,0-3.7,1.7-3.7,3.7v8.1c0,2,1.7,3.7,3.7,3.7h362.9c2,0,3.7-1.7,3.7-3.7
			v-8.1C435.9,340.2,434.2,338.5,432.2,338.5z"
                />
              </g>
            </g>
            <g id="Books">
              <g id="Book_00000050648475491746912640000002418111853263543191_">
                <g>
                  <rect
                    x="89.1"
                    y="321.4"
                    fill="#263238"
                    width="70.8"
                    height="15"
                  />
                  <rect
                    x="89.1"
                    y="321.4"
                    opacity="0.8"
                    fill="#FFFFFF"
                    width="70.8"
                    height="15"
                  />
                  <rect
                    x="89.1"
                    y="324.4"
                    opacity="0.5"
                    fill="#FFFFFF"
                    width="69.8"
                    height="0.7"
                  />
                  <rect
                    x="89.1"
                    y="328.2"
                    opacity="0.5"
                    fill="#FFFFFF"
                    width="69.8"
                    height="0.7"
                  />
                  <rect
                    x="89.1"
                    y="332.3"
                    opacity="0.5"
                    fill="#FFFFFF"
                    width="69.8"
                    height="0.7"
                  />
                  <path
                    fill="#407BFF"
                    d="M159.3,319.4L159.3,319.4h-1.9H87.4c-0.3,0-0.6,0.3-0.6,0.6v1.9c0,0.3,0.3,0.6,0.6,0.6h68.7
				c0.7,0,1.2,0.6,1.2,1.2v10.7c0,0.7-0.6,1.2-1.2,1.2H87.3c-0.3,0-0.5,0.2-0.5,0.5v1.8c0,0.3,0.2,0.5,0.5,0.5h70h2c1.1,0,2-0.9,2-2
				v-0.9v-13.1v-1l0,0C161.4,320.3,160.4,319.4,159.3,319.4z"
                  />
                </g>
                <g>
                  <rect
                    x="89.1"
                    y="302.2"
                    fill="#263238"
                    width="70.8"
                    height="15"
                  />
                  <rect
                    x="89.1"
                    y="302.2"
                    opacity="0.9"
                    fill="#FFFFFF"
                    width="70.8"
                    height="15"
                  />
                  <rect
                    x="89.1"
                    y="304.6"
                    opacity="0.7"
                    fill="#FFFFFF"
                    width="69.8"
                    height="0.7"
                  />
                  <rect
                    x="89.1"
                    y="307.3"
                    opacity="0.7"
                    fill="#FFFFFF"
                    width="69.8"
                    height="0.7"
                  />
                  <rect
                    x="89.1"
                    y="310.4"
                    opacity="0.7"
                    fill="#FFFFFF"
                    width="69.8"
                    height="0.7"
                  />
                  <rect
                    x="89.1"
                    y="313.1"
                    opacity="0.7"
                    fill="#FFFFFF"
                    width="69.8"
                    height="0.7"
                  />
                  <path
                    fill="#407BFF"
                    d="M159.3,300.1L159.3,300.1h-1.9H87.4c-0.3,0-0.6,0.3-0.6,0.6v1.9c0,0.3,0.3,0.6,0.6,0.6h68.7
				c0.7,0,1.2,0.6,1.2,1.2v10.7c0,0.7-0.6,1.2-1.2,1.2H87.3c-0.3,0-0.5,0.2-0.5,0.5v1.8c0,0.3,0.2,0.5,0.5,0.5h70h2c1.1,0,2-0.9,2-2
				v-0.9v-13.1v-1l0,0C161.4,301.1,160.4,300.1,159.3,300.1z"
                  />
                  <path
                    opacity="0.3"
                    fill="#FFFFFF"
                    d="M159.3,300.1L159.3,300.1h-1.9H87.4c-0.3,0-0.6,0.3-0.6,0.6v1.9c0,0.3,0.3,0.6,0.6,0.6
				h68.7c0.7,0,1.2,0.6,1.2,1.2v10.7c0,0.7-0.6,1.2-1.2,1.2H87.3c-0.3,0-0.5,0.2-0.5,0.5v1.8c0,0.3,0.2,0.5,0.5,0.5h70h2
				c1.1,0,2-0.9,2-2v-0.9v-13.1v-1l0,0C161.4,301.1,160.4,300.1,159.3,300.1z"
                  />
                </g>
                <g>
                  <rect
                    x="89.1"
                    y="288.6"
                    fill="#263238"
                    width="70.8"
                    height="9.4"
                  />
                  <rect
                    x="89.1"
                    y="288.6"
                    opacity="0.8"
                    fill="#FFFFFF"
                    width="70.8"
                    height="9.4"
                  />
                  <rect
                    x="89.1"
                    y="290.6"
                    opacity="0.5"
                    fill="#FFFFFF"
                    width="69.8"
                    height="0.7"
                  />
                  <rect
                    x="89.1"
                    y="294.6"
                    opacity="0.5"
                    fill="#FFFFFF"
                    width="69.8"
                    height="0.7"
                  />
                  <path
                    fill="#407BFF"
                    d="M86.8,286.1v2.4c0,0.2,0.2,0.4,0.4,0.4H157c0.2,0,0.4,0.2,0.4,0.4v7.1c0,0.5-0.4,1-1,1H87.2
				c-0.2,0-0.4,0.2-0.4,0.4v2.2c0,0.2,0.2,0.4,0.4,0.4h70.2h2.2c1,0,1.8-0.8,1.8-1.8v-1.1v-11.2c0-0.2-0.2-0.4-0.4-0.4H87.2
				C87,285.7,86.8,285.9,86.8,286.1z"
                  />
                </g>
              </g>
            </g>
            <g id="Paper_balls">
              <g id="Paper_Trash_00000057830542778719310620000008804914498562551229_">
                <g>
                  <path
                    fill="#263238"
                    d="M348.2,325.9c1.4-2.8,2.8-5.7,4.2-8.5c-2.2-0.9-4.9-1.9-7.9-2.6c-2.1-0.5-4-0.8-5.7-1
				c0,1.5-0.1,2.9-0.1,4.4c0.7,0.3,1.3,0.6,2,0.9c-0.2,1.7-0.5,3.4-0.7,5.1c1.4,0,2.8,0,4.3,0C345.5,324.8,346.8,325.3,348.2,325.9z
				"
                  />
                  <path
                    fill="#263238"
                    d="M338.6,318.2c0.6,1,1.1,1.9,1.7,2.9c0.1-0.7,0.2-1.4,0.3-2.1C339.9,318.8,339.2,318.5,338.6,318.2z"
                  />
                  <path
                    fill="#263238"
                    d="M339.8,324.2c2.5,2.8,5,5.6,7.5,8.5c0.3-2.2,0.6-4.5,0.9-6.7c-1.4-0.6-2.8-1.1-4.1-1.7
				C342.7,324.2,341.2,324.2,339.8,324.2z"
                  />
                  <path
                    fill="#263238"
                    d="M347.3,332.6c1.5-0.4,3-0.7,4.4-1.1c-1.2-1.9-2.3-3.8-3.5-5.7C347.9,328.2,347.6,330.4,347.3,332.6z"
                  />
                  <path
                    fill="#263238"
                    d="M352.4,317.4c2.3,1.3,4.6,2.5,6.9,3.8c-1.5,2.2-3.1,4.4-4.6,6.6c-2.1-0.6-4.2-1.3-6.4-1.9
				C349.6,323.1,351,320.3,352.4,317.4z"
                  />
                  <path
                    fill="#263238"
                    d="M347.3,332.6c1.1,1.2,2.2,2.4,3.3,3.7c1.1,0.3,2.1,0.6,3.2,0.9c-0.7-1.9-1.4-3.8-2.1-5.7
				C350.3,331.9,348.8,332.3,347.3,332.6z"
                  />
                  <path
                    fill="#263238"
                    d="M353.8,337.3c0.5-0.7,1.1-1.3,1.6-2c-0.6-1.6-1.1-3.1-1.7-4.7c-0.6,0.3-1.3,0.6-1.9,1
				C352.4,333.5,353.1,335.4,353.8,337.3z"
                  />
                  <path
                    fill="#263238"
                    d="M353.7,330.6c-1.8-1.6-3.6-3.1-5.4-4.7c1.2,1.9,2.3,3.8,3.5,5.7C352.4,331.3,353,330.9,353.7,330.6z"
                  />
                  <path
                    fill="#263238"
                    d="M354.6,327.8c0.7,1.7,1.5,3.4,2.2,5.1c-1.1-0.8-2.1-1.5-3.2-2.3c-1.8-1.6-3.6-3.1-5.4-4.7
				C350.3,326.5,352.5,327.2,354.6,327.8z"
                  />
                  <path
                    fill="#263238"
                    d="M356.8,332.9c2.5-1,5-1.9,7.6-2.9c-0.9-2.2-1.8-4.5-2.7-6.7c0.1-1.1,0.3-2.2,0.4-3.3
				c-0.9,0.4-1.9,0.9-2.8,1.3c-1.5,2.2-3.1,4.4-4.6,6.6C355.3,329.5,356.1,331.2,356.8,332.9z"
                  />
                  <path
                    fill="#263238"
                    d="M345.5,315.1c1-0.8,2-1.5,3-2.3c1.5-0.7,3.1-1.4,4.6-2.2c1.5,1.9,3,3.8,4.5,5.6c-1.8,0.4-3.5,0.8-5.3,1.1
				c-0.9-0.4-1.9-0.8-3-1.2C348,315.8,346.7,315.4,345.5,315.1z"
                  />
                  <path
                    fill="#263238"
                    d="M357.7,316.3c1.5,1.2,2.9,2.5,4.4,3.7c-1,0.4-1.9,0.9-2.9,1.3c-2.3-1.3-4.5-2.5-6.8-3.8
				C354.1,317,355.9,316.7,357.7,316.3z"
                  />
                  <path
                    fill="#263238"
                    d="M355.4,335.3c1.8,1.2,3.6,2.3,5.5,3.5c-0.4-1.3-0.8-2.6-1.2-3.9c-2-1.4-4-2.8-5.9-4.3
				C354.2,332.2,354.8,333.7,355.4,335.3z"
                  />
                  <path
                    fill="#263238"
                    d="M360.8,338.8c1.1-2.2,2.1-4.4,3.2-6.5c0.1-0.8,0.3-1.5,0.4-2.3c-2.5,1-5,1.9-7.6,2.9
				c0.9,0.7,1.8,1.3,2.8,2C360,336.2,360.4,337.5,360.8,338.8z"
                  />
                  <path
                    fill="#263238"
                    d="M339.8,324.2c-0.6,0.3-1.2,0.6-1.8,0.8c0.8,0.8,1.7,1.6,2.5,2.4c1.4,3.5,2.7,7,4.1,10.4
				c0.9-1.7,1.8-3.4,2.7-5.2C344.8,329.8,342.3,327,339.8,324.2z"
                  />
                  <path
                    fill="#263238"
                    d="M344.6,337.8c1.3,0.4,2.5,0.7,3.8,1.1c1.8-0.5,3.6-1.1,5.4-1.6c-1.1-0.3-2.1-0.6-3.2-0.9
				c-1.1-1.2-2.2-2.4-3.3-3.7C346.4,334.4,345.5,336.1,344.6,337.8z"
                  />
                  <path
                    fill="#263238"
                    d="M338,325c-0.4,0.7-1,1.6-1.4,2.8c-0.5,1.4-0.6,2.6-0.6,3.5c2.4,0.9,4.7,1.8,7.1,2.7
				c-0.9-2.2-1.8-4.4-2.6-6.6C339.6,326.6,338.8,325.8,338,325z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M348.2,325.9c1.4-2.8,2.8-5.7,4.2-8.5c-2.2-0.9-4.9-1.9-7.9-2.6c-2.1-0.5-4-0.8-5.7-1
				c0,1.5-0.1,2.9-0.1,4.4c0.7,0.3,1.3,0.6,2,0.9c-0.2,1.7-0.5,3.4-0.7,5.1c1.4,0,2.8,0,4.3,0C345.5,324.8,346.8,325.3,348.2,325.9z
				"
                  />
                  <path
                    opacity="0.8"
                    fill="#FFFFFF"
                    d="M338.6,318.2c0.6,1,1.1,1.9,1.7,2.9c0.1-0.7,0.2-1.4,0.3-2.1
				C339.9,318.8,339.2,318.5,338.6,318.2z"
                  />
                  <path
                    opacity="0.8"
                    fill="#FFFFFF"
                    d="M339.8,324.2c2.5,2.8,5,5.6,7.5,8.5c0.3-2.2,0.6-4.5,0.9-6.7c-1.4-0.6-2.8-1.1-4.1-1.7
				C342.7,324.2,341.2,324.2,339.8,324.2z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M347.3,332.6c1.5-0.4,3-0.7,4.4-1.1c-1.2-1.9-2.3-3.8-3.5-5.7
				C347.9,328.2,347.6,330.4,347.3,332.6z"
                  />
                  <path
                    opacity="0.8"
                    fill="#FFFFFF"
                    d="M352.4,317.4c2.3,1.3,4.6,2.5,6.9,3.8c-1.5,2.2-3.1,4.4-4.6,6.6c-2.1-0.6-4.2-1.3-6.4-1.9
				C349.6,323.1,351,320.3,352.4,317.4z"
                  />
                  <path
                    opacity="0.7"
                    fill="#FFFFFF"
                    d="M347.3,332.6c1.1,1.2,2.2,2.4,3.3,3.7c1.1,0.3,2.1,0.6,3.2,0.9c-0.7-1.9-1.4-3.8-2.1-5.7
				C350.3,331.9,348.8,332.3,347.3,332.6z"
                  />
                  <path
                    opacity="0.5"
                    fill="#FFFFFF"
                    d="M353.8,337.3c0.5-0.7,1.1-1.3,1.6-2c-0.6-1.6-1.1-3.1-1.7-4.7c-0.6,0.3-1.3,0.6-1.9,1
				C352.4,333.5,353.1,335.4,353.8,337.3z"
                  />
                  <path
                    opacity="0.5"
                    fill="#FFFFFF"
                    d="M353.7,330.6c-1.8-1.6-3.6-3.1-5.4-4.7c1.2,1.9,2.3,3.8,3.5,5.7
				C352.4,331.3,353,330.9,353.7,330.6z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M354.6,327.8c0.7,1.7,1.5,3.4,2.2,5.1c-1.1-0.8-2.1-1.5-3.2-2.3c-1.8-1.6-3.6-3.1-5.4-4.7
				C350.3,326.5,352.5,327.2,354.6,327.8z"
                  />
                  <path
                    opacity="0.8"
                    fill="#FFFFFF"
                    d="M356.8,332.9c2.5-1,5-1.9,7.6-2.9c-0.9-2.2-1.8-4.5-2.7-6.7c0.1-1.1,0.3-2.2,0.4-3.3
				c-0.9,0.4-1.9,0.9-2.8,1.3c-1.5,2.2-3.1,4.4-4.6,6.6C355.3,329.5,356.1,331.2,356.8,332.9z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M345.5,315.1c1-0.8,2-1.5,3-2.3c1.5-0.7,3.1-1.4,4.6-2.2c1.5,1.9,3,3.8,4.5,5.6c-1.8,0.4-3.5,0.8-5.3,1.1
				c-0.9-0.4-1.9-0.8-3-1.2C348,315.8,346.7,315.4,345.5,315.1z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M357.7,316.3c1.5,1.2,2.9,2.5,4.4,3.7c-1,0.4-1.9,0.9-2.9,1.3c-2.3-1.3-4.5-2.5-6.8-3.8
				C354.1,317,355.9,316.7,357.7,316.3z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M355.4,335.3c1.8,1.2,3.6,2.3,5.5,3.5c-0.4-1.3-0.8-2.6-1.2-3.9c-2-1.4-4-2.8-5.9-4.3
				C354.2,332.2,354.8,333.7,355.4,335.3z"
                  />
                  <path
                    opacity="0.8"
                    fill="#FFFFFF"
                    d="M360.8,338.8c1.1-2.2,2.1-4.4,3.2-6.5c0.1-0.8,0.3-1.5,0.4-2.3c-2.5,1-5,1.9-7.6,2.9
				c0.9,0.7,1.8,1.3,2.8,2C360,336.2,360.4,337.5,360.8,338.8z"
                  />
                  <path
                    opacity="0.7"
                    fill="#FFFFFF"
                    d="M339.8,324.2c-0.6,0.3-1.2,0.6-1.8,0.8c0.8,0.8,1.7,1.6,2.5,2.4c1.4,3.5,2.7,7,4.1,10.4
				c0.9-1.7,1.8-3.4,2.7-5.2C344.8,329.8,342.3,327,339.8,324.2z"
                  />
                  <path
                    opacity="0.5"
                    fill="#FFFFFF"
                    d="M344.6,337.8c1.3,0.4,2.5,0.7,3.8,1.1c1.8-0.5,3.6-1.1,5.4-1.6c-1.1-0.3-2.1-0.6-3.2-0.9
				c-1.1-1.2-2.2-2.4-3.3-3.7C346.4,334.4,345.5,336.1,344.6,337.8z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M338,325c-0.4,0.7-1,1.6-1.4,2.8c-0.5,1.4-0.6,2.6-0.6,3.5c2.4,0.9,4.7,1.8,7.1,2.7
				c-0.9-2.2-1.8-4.4-2.6-6.6C339.6,326.6,338.8,325.8,338,325z"
                  />
                </g>
                <g>
                  <path
                    fill="#263238"
                    d="M402.3,323.7c2.5,1.9,4.9,3.9,7.4,5.8c1.4-2,2.9-4.4,4.2-7.2c0.9-1.9,1.6-3.7,2.2-5.4
				c-1.4-0.3-2.8-0.7-4.3-1c-0.4,0.6-0.8,1.2-1.2,1.7c-1.6-0.6-3.3-1.2-4.9-1.8c-0.3,1.4-0.6,2.8-0.9,4.2
				C403.9,321.3,403.1,322.5,402.3,323.7z"
                  />
                  <path
                    fill="#263238"
                    d="M411.8,315.9c-1.1,0.3-2.1,0.7-3.2,1c0.7,0.2,1.3,0.5,2,0.7C411,317.1,411.4,316.5,411.8,315.9z"
                  />
                  <path
                    fill="#263238"
                    d="M405.7,315.9c-3.3,1.8-6.6,3.7-9.8,5.5c2.1,0.8,4.3,1.5,6.4,2.3c0.8-1.2,1.7-2.5,2.5-3.7
				C405.1,318.7,405.4,317.3,405.7,315.9z"
                  />
                  <path
                    fill="#263238"
                    d="M395.9,321.4c0,1.5,0.1,3,0.1,4.6c2.1-0.7,4.2-1.5,6.3-2.2C400.1,323,398,322.2,395.9,321.4z"
                  />
                  <path
                    fill="#263238"
                    d="M409.7,329.6c-1.7,2-3.4,3.9-5.2,5.9c-1.8-2-3.6-3.9-5.4-5.9c1.1-1.9,2.1-3.9,3.2-5.8
				C404.7,325.7,407.2,327.6,409.7,329.6z"
                  />
                  <path
                    fill="#263238"
                    d="M395.9,321.4c-1.4,0.8-2.9,1.6-4.3,2.5c-0.5,1-1.1,1.9-1.6,2.9c2-0.3,4-0.5,6-0.8
				C396,324.5,395.9,322.9,395.9,321.4z"
                  />
                  <path
                    fill="#263238"
                    d="M390,326.8c0.5,0.7,1.1,1.3,1.6,2c1.6-0.2,3.3-0.5,4.9-0.7c-0.2-0.7-0.4-1.4-0.5-2.1
				C394,326.3,392,326.5,390,326.8z"
                  />
                  <path
                    fill="#263238"
                    d="M396.5,328.1c1.9-1.4,3.8-2.9,5.7-4.3c-2.1,0.7-4.2,1.5-6.3,2.2C396.2,326.7,396.3,327.4,396.5,328.1z"
                  />
                  <path
                    fill="#263238"
                    d="M399.1,329.6c-1.8,0.4-3.6,0.7-5.4,1.1c1-0.9,1.9-1.7,2.9-2.6c1.9-1.4,3.8-2.9,5.7-4.3
				C401.2,325.7,400.1,327.6,399.1,329.6z"
                  />
                  <path
                    fill="#263238"
                    d="M393.6,330.7c0.4,2.7,0.8,5.3,1.3,8c2.4-0.4,4.8-0.8,7.1-1.3c1,0.4,2.1,0.7,3.1,1.1c-0.2-1-0.4-2-0.7-3
				c-1.8-2-3.6-3.9-5.4-5.9C397.3,329.9,395.5,330.3,393.6,330.7z"
                  />
                  <path
                    fill="#263238"
                    d="M413.4,323.4c0.5,1.1,1.1,2.3,1.6,3.4c0.4,1.6,0.8,3.3,1.1,4.9c-2.2,1.1-4.3,2.2-6.5,3.2
				c0-1.8,0-3.6,0-5.4c0.6-0.8,1.2-1.7,1.8-2.7C412.2,325.7,412.9,324.5,413.4,323.4z"
                  />
                  <path
                    fill="#263238"
                    d="M409.7,335c-1.5,1.2-3,2.3-4.5,3.5c-0.2-1-0.4-2-0.7-3.1c1.7-2,3.4-3.9,5.2-5.9
				C409.7,331.4,409.7,333.2,409.7,335z"
                  />
                  <path
                    fill="#263238"
                    d="M391.6,328.8c-1.5,1.5-3,3.1-4.6,4.6c1.4-0.1,2.7-0.3,4.1-0.4c1.8-1.6,3.6-3.3,5.4-4.9
				C394.9,328.3,393.2,328.5,391.6,328.8z"
                  />
                  <path
                    fill="#263238"
                    d="M387,333.4c1.9,1.5,3.8,3,5.7,4.5c0.7,0.3,1.4,0.6,2.2,0.9c-0.4-2.7-0.8-5.3-1.3-8
				c-0.8,0.8-1.7,1.5-2.5,2.3C389.7,333.1,388.4,333.2,387,333.4z"
                  />
                  <path
                    fill="#263238"
                    d="M405.7,315.9c-0.1-0.7-0.3-1.3-0.4-2c-0.9,0.6-1.9,1.3-2.8,1.9c-3.7,0.6-7.4,1.2-11.1,1.8
				c1.5,1.2,3,2.5,4.5,3.7C399.2,319.6,402.4,317.7,405.7,315.9z"
                  />
                  <path
                    fill="#263238"
                    d="M391.4,317.7c-0.6,1.2-1.2,2.3-1.8,3.5c0.2,1.9,0.3,3.8,0.5,5.6c0.5-1,1.1-1.9,1.6-2.9
				c1.4-0.8,2.9-1.6,4.3-2.5C394.4,320.2,392.9,318.9,391.4,317.7z"
                  />
                  <path
                    fill="#263238"
                    d="M405.3,313.9c-0.6-0.6-1.4-1.3-2.4-1.9c-1.3-0.8-2.4-1.1-3.3-1.4c-1.4,2.1-2.7,4.3-4.1,6.4
				c2.3-0.4,4.7-0.8,7-1.2C403.4,315.2,404.3,314.6,405.3,313.9z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M402.3,323.7c2.5,1.9,4.9,3.9,7.4,5.8c1.4-2,2.9-4.4,4.2-7.2c0.9-1.9,1.6-3.7,2.2-5.4
				c-1.4-0.3-2.8-0.7-4.3-1c-0.4,0.6-0.8,1.2-1.2,1.7c-1.6-0.6-3.3-1.2-4.9-1.8c-0.3,1.4-0.6,2.8-0.9,4.2
				C403.9,321.3,403.1,322.5,402.3,323.7z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M411.8,315.9c-1.1,0.3-2.1,0.7-3.2,1c0.7,0.2,1.3,0.5,2,0.7
				C411,317.1,411.4,316.5,411.8,315.9z"
                  />
                  <path
                    opacity="0.8"
                    fill="#FFFFFF"
                    d="M405.7,315.9c-3.3,1.8-6.6,3.7-9.8,5.5c2.1,0.8,4.3,1.5,6.4,2.3c0.8-1.2,1.7-2.5,2.5-3.7
				C405.1,318.7,405.4,317.3,405.7,315.9z"
                  />
                  <path
                    opacity="0.8"
                    fill="#FFFFFF"
                    d="M395.9,321.4c0,1.5,0.1,3,0.1,4.6c2.1-0.7,4.2-1.5,6.3-2.2
				C400.1,323,398,322.2,395.9,321.4z"
                  />
                  <path
                    opacity="0.8"
                    fill="#FFFFFF"
                    d="M409.7,329.6c-1.7,2-3.4,3.9-5.2,5.9c-1.8-2-3.6-3.9-5.4-5.9c1.1-1.9,2.1-3.9,3.2-5.8
				C404.7,325.7,407.2,327.6,409.7,329.6z"
                  />
                  <path
                    opacity="0.7"
                    fill="#FFFFFF"
                    d="M395.9,321.4c-1.4,0.8-2.9,1.6-4.3,2.5c-0.5,1-1.1,1.9-1.6,2.9c2-0.3,4-0.5,6-0.8
				C396,324.5,395.9,322.9,395.9,321.4z"
                  />
                  <path
                    opacity="0.5"
                    fill="#FFFFFF"
                    d="M390,326.8c0.5,0.7,1.1,1.3,1.6,2c1.6-0.2,3.3-0.5,4.9-0.7c-0.2-0.7-0.4-1.4-0.5-2.1
				C394,326.3,392,326.5,390,326.8z"
                  />
                  <path
                    opacity="0.5"
                    fill="#FFFFFF"
                    d="M396.5,328.1c1.9-1.4,3.8-2.9,5.7-4.3c-2.1,0.7-4.2,1.5-6.3,2.2
				C396.2,326.7,396.3,327.4,396.5,328.1z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M399.1,329.6c-1.8,0.4-3.6,0.7-5.4,1.1c1-0.9,1.9-1.7,2.9-2.6c1.9-1.4,3.8-2.9,5.7-4.3
				C401.2,325.7,400.1,327.6,399.1,329.6z"
                  />
                  <path
                    opacity="0.7"
                    fill="#FFFFFF"
                    d="M393.6,330.7c0.4,2.7,0.8,5.3,1.3,8c2.4-0.4,4.8-0.8,7.1-1.3c1,0.4,2.1,0.7,3.1,1.1
				c-0.2-1-0.4-2-0.7-3c-1.8-2-3.6-3.9-5.4-5.9C397.3,329.9,395.5,330.3,393.6,330.7z"
                  />
                  <path
                    opacity="0.7"
                    fill="#FFFFFF"
                    d="M413.4,323.4c0.5,1.1,1.1,2.3,1.6,3.4c0.4,1.6,0.8,3.3,1.1,4.9c-2.2,1.1-4.3,2.2-6.5,3.2
				c0-1.8,0-3.6,0-5.4c0.6-0.8,1.2-1.7,1.8-2.7C412.2,325.7,412.9,324.5,413.4,323.4z"
                  />
                  <path
                    opacity="0.5"
                    fill="#FFFFFF"
                    d="M409.7,335c-1.5,1.2-3,2.3-4.5,3.5c-0.2-1-0.4-2-0.7-3.1c1.7-2,3.4-3.9,5.2-5.9
				C409.7,331.4,409.7,333.2,409.7,335z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M391.6,328.8c-1.5,1.5-3,3.1-4.6,4.6c1.4-0.1,2.7-0.3,4.1-0.4c1.8-1.6,3.6-3.3,5.4-4.9
				C394.9,328.3,393.2,328.5,391.6,328.8z"
                  />
                  <path
                    opacity="0.7"
                    fill="#FFFFFF"
                    d="M387,333.4c1.9,1.5,3.8,3,5.7,4.5c0.7,0.3,1.4,0.6,2.2,0.9c-0.4-2.7-0.8-5.3-1.3-8
				c-0.8,0.8-1.7,1.5-2.5,2.3C389.7,333.1,388.4,333.2,387,333.4z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M405.7,315.9c-0.1-0.7-0.3-1.3-0.4-2c-0.9,0.6-1.9,1.3-2.8,1.9c-3.7,0.6-7.4,1.2-11.1,1.8
				c1.5,1.2,3,2.5,4.5,3.7C399.2,319.6,402.4,317.7,405.7,315.9z"
                  />
                  <path
                    opacity="0.9"
                    fill="#FFFFFF"
                    d="M391.4,317.7c-0.6,1.2-1.2,2.3-1.8,3.5c0.2,1.9,0.3,3.8,0.5,5.6c0.5-1,1.1-1.9,1.6-2.9
				c1.4-0.8,2.9-1.6,4.3-2.5C394.4,320.2,392.9,318.9,391.4,317.7z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M405.3,313.9c-0.6-0.6-1.4-1.3-2.4-1.9c-1.3-0.8-2.4-1.1-3.3-1.4c-1.4,2.1-2.7,4.3-4.1,6.4
				c2.3-0.4,4.7-0.8,7-1.2C403.4,315.2,404.3,314.6,405.3,313.9z"
                  />
                </g>
              </g>
            </g>
            <g id="Device">
              <g>
                <path
                  fill="#407BFF"
                  d="M234,338.5l44.4,0c0.4,0,0.6-0.3,0.6-0.7c-0.5-2.1-0.9-4.1-1.4-6.2c-0.1-0.3-0.3-0.4-0.6-0.4
			c-15.8,0-31.7,0-47.5,0c-0.5,0-0.7,0.5-0.5,0.9c1.5,2.1,3,4.1,4.4,6.2C233.6,338.5,233.8,338.5,234,338.5z"
                />
                <path
                  opacity="0.2"
                  d="M234,338.5l44.4,0c0.4,0,0.6-0.3,0.5-0.7c-0.5-2.1-0.9-4.2-1.4-6.3c-0.1-0.3-0.3-0.4-0.5-0.4h-47.5
			c-0.5,0-0.7,0.5-0.5,0.9c1.5,2.1,3,4.2,4.5,6.2C233.6,338.5,233.8,338.5,234,338.5z"
                />
                <path
                  fill="#407BFF"
                  d="M214.2,262.5h-83.7c-0.4,0-0.9,0.1-1.3,0.3l-3.6,1.7c6.9,24.6,13.8,49.3,20.6,73.9
			c27.9,0.1,55.9,0.2,83.8,0.2c1.7-0.1,3-0.1,4.7-0.2c-6.5-25-12.7-50-19.2-75C215.4,263,214.9,262.5,214.2,262.5z"
                />
                <path
                  opacity="0.2"
                  d="M230.1,338.7c-27.9-0.1-55.9-0.2-83.8-0.2c-6.9-24.6-13.8-49.3-20.6-73.9h85.5L230.1,338.7z"
                />
                <path
                  opacity="0.2"
                  fill="#FFFFFF"
                  d="M129,263l-3.4,1.6h85.5l3.4-1.6c0.2-0.1,0.1-0.4-0.1-0.4h-83.6
			C130.2,262.5,129.5,262.7,129,263z"
                />
                <path
                  fill="#407BFF"
                  d="M182,298.6c0.4,4.7-3,8.9-7.8,9.4c-4.7,0.4-8.9-3-9.4-7.8c-0.4-4.7,3-8.9,7.8-9.4
			C177.4,290.4,181.6,293.9,182,298.6z"
                />
                <path
                  opacity="0.4"
                  d="M215.6,263.6c6.4,25,12.8,50,19.2,75l-4.7,0.2l-19-74.2l3.3-1.6C214.9,262.8,215.5,263,215.6,263.6z"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="500" height="400" fill="white" />
              </clipPath>
            </defs>
          </Box>
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
      >
        <Typography
          variant="h1"
          className="mt-48 sm:mt-86 text-4xl md:text-7xl font-extrabold tracking-tight leading-tight md:leading-none text-center"
        >
          Sorry, we can't find that page.
        </Typography>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
      >
        <Typography
          variant="h5"
          color="text.secondary"
          className="mt-8 text-lg md:text-xl font-medium tracking-tight text-center"
        >
          The page must have been removed or the url is wrong. Please go back
          and try again.
        </Typography>
      </motion.div>
      <Link
        to={"/"}
        className={`flex flex-row my-14 text-20 items-center justify-start`}
        style={{
          textDecoration: "none",
          color: "#8c8483",
        }}
      >
        <FuseSvgIcon className="text-48 mr-8" size={24}>
          heroicons-outline:arrow-circle-left
        </FuseSvgIcon>
        Go home
      </Link>
    </div>
  );
}

export default Error404Page;
