import FuseUtils from "@fuse/utils/FuseUtils";
import axiosConfig from "./axiosConfig";
import { ClientJS } from "clientjs";

class AxiosService extends FuseUtils.EventEmitter {
  init() {
    this.handleAuthentication();
  }

  handleAuthentication = () => {
    const session_token = this.getSessionToken();
    const brs_tr = this.getBRSTR();
    this.setAccessAs(null);

    if (!session_token || !brs_tr) {
      this.setSessionToken(null);
      this.setSignInToken(null);
      this.setClientAccess(null);
      this.setAdminDebug(null);
      this.setAccessAs(null);
      this.emit("onNoAccessToken");
      return;
    }

    this.isSessionTokenValid(session_token, brs_tr)
      .then((values) => {
        this.setSessionToken(session_token);
        this.setBRSTR(brs_tr);
        this.setClientAccess(null);
        this.setAccessAs(null);
        this.emit("onAutoLogin", values);
      })
      .catch((err) => {
        // this.setSessionToken(null);
        // this.setSignInToken(null);
        // this.setBRSTR(null);
        // this.setClientAccess(null);
        // this.setAdminDebug(null);
        if (err === "invalid_session_token")
          this.emit("onAutoLogout", "invalid_session_token");
        else this.emit("onAutoLogout");
      });
  };

  isSessionTokenValid = (session_token, brs_tr) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post("?menu_id=1", "")
        .then((res) => {
          if (res?.message === "invalid_session_token") {
            return reject("invalid_session_token");
          }

          return resolve(res.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  accessAs = (userData) => {
    if (userData.user_id) {
      this.setAccessAs(userData.session_token);
      this.emit("okAccessAs", userData);
    } else {
      this.emit("noAccessUser");
    }
  };

  removeAccessAs = () => {
    this.handleAuthentication();
  };

  validateSignUp = (name, email, password, lang) => {
    const data = { name, email, password };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/signup?lang=${lang}`, data)
        .then((res) => {
          if (res?.status === "error") {
            return reject(res);
          }

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  validateLogin = (email, password, lang) => {
    const data = { email, password };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/signin?lang=${lang}`, data)
        .then((res) => {
          if (res?.status === "error") {
            return reject(res);
          } else {
            if (Boolean(res.result.confirm_signin)) {
              this.setSignInToken(res?.result?.signin_token);
              this.setSessionToken(null);
              this.setBRSTR(null);
              this.setAdminDebug(null);
              return resolve(res);
            } else {
              this.setSessionToken(res?.result?.session_token);
              this.setBRSTR(res?.result["brs-tr"]);
              this.setSignInToken(null);
              this.emit(
                "onLogin",
                res?.result?.session_token,
                res?.result["brs-tr"]
              );
            }
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  validateSecretCode = (secretcode, signin_token) => {
    //ID fingerPrint
    const client = new ClientJS();
    const browser_track = client.getFingerprint();

    //Window.Navigator
    var browser_track_data = {};
    for (var i in navigator) browser_track_data[i] = navigator[i];

    const data = {
      secretcode,
      signin_token,
      browser_track_data,
      browser_track,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post("/confirm_signin_token", data)
        .then((res) => {
          if (res?.status === "error") {
            return reject(res);
          } else {
            this.setSessionToken(res?.result?.session_token);
            this.setBRSTR(res?.result["brs-tr"]);
            this.setSignInToken(null);
            resolve(res);
            this.emit(
              "onLogin",
              res?.result?.session_token,
              res?.result["brs-tr"]
            );
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  validateTokenSignUp = (request_token, lang) => {
    //ID fingerPrint
    const client = new ClientJS();
    const browser_track = client.getFingerprint();

    //Window.Navigator
    var browser_track_data = {};
    for (var i in navigator) browser_track_data[i] = navigator[i];

    const data = {
      signup_token: request_token,
      browser_track_data,
      browser_track,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/confirm_signup_token?lang=${lang}`, data)
        .then((res) => {
          if (res?.status === "error") {
            return reject(res);
          }
          if (res?.result?.session_token) {
            this.setSessionToken(res?.result?.session_token);
            this.setBRSTR(res.result["brs-tr"]);
            this.setSignInToken(null);
            resolve(res?.session_token);
            this.emit(
              "onLogin",
              res?.result?.session_token,
              res.result["brs-tr"]
            );
            return;
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  validateTokenResetPass = (request_token) => {
    const data = {
      request_token,
      check: 1,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post("/reset_password", data)
        .then((res) => {
          if (res?.status === "error") {
            this.emit("onInvalidTokenPass");
            return reject(res);
          } else {
            return resolve(res);
          }
        })
        .catch((err) => {
          this.emit("onInvalidTokenPass");
          return reject(err);
        });
    });
  };

  requestForgotPassword = (email, lang) => {
    const data = { email };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/reset_password_request?lang=${lang}`, data)
        .then((res) => {
          if (res?.status === "error") {
            return reject(res);
          }

          return resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  resetPassword = (passOne, passTwo, request_token) => {
    const data = {
      request_token,
      password: passOne,
      passTwo,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post("/reset_password", data)
        .then((res) => {
          if (res?.status === "error") {
            return reject(res);
          }

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post("/signout")
        .then((res) => {
          if (res.status === "error") return reject(res);

          this.emit("onLogout", "Logged out");
          return resolve(res);
        })
        .catch((err) => {
          console.error("Error sign out", err.message);
          return reject(err);
        });
    });
  };

  setSessionToken = (session_token) => {
    if (session_token) {
      localStorage.setItem("session_token", session_token);
    } else {
      localStorage.removeItem("session_token");
    }
  };

  setSignInToken = (signin_token) => {
    if (signin_token) {
      localStorage.setItem("signin_token", signin_token);
    } else {
      localStorage.removeItem("signin_token");
    }
  };

  setBRSTR = (brs_tr) => {
    if (brs_tr) {
      localStorage.setItem("brs_tr", brs_tr);
    } else {
      localStorage.removeItem("brs_tr");
    }
  };

  setClientAccess = (data) => {
    if (data) {
      localStorage.setItem("data", data);
    } else {
      localStorage.removeItem("data");
    }
  };

  setAccessAs = (data) => {
    if (data) {
      localStorage.setItem("A.ss__Q564", data);
    } else {
      localStorage.removeItem("A.ss__Q564");
    }
  };

  setAdminDebug = (data) => {
    if (data) {
      localStorage.setItem("__kdm.V", data);
    } else {
      localStorage.removeItem("__kdm.V");
    }
  };

  getClientAccess = () => {
    return window.localStorage.getItem("data");
  };

  getBRSTR = () => {
    return window.localStorage.getItem("brs_tr");
  };

  getSessionToken = () => {
    return window.localStorage.getItem("session_token");
  };

  getSignInToken = () => {
    return window.localStorage.getItem("signin_token");
  };

  getAccessAs = () => {
    return window.localStorage.getItem("A.ss__Q564");
  };

  getAdminDebug = () => {
    return window.localStorage.getItem("__kdm.V");
  };

  setTheme = (data) => {
    if (data) {
      localStorage.setItem("theme.type", data);
    } else {
      localStorage.removeItem("theme.type");
    }
  };

  getTheme = () => {
    return window.localStorage.getItem("theme.type");
  };
}

const axiosService = new AxiosService();
export default axiosService;
