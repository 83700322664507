import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class CronjobsService {
  addCronjob = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/cronjobs/`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  deleteCronjob = (id) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/cronjobs/${id}`)
        .then((res) => {
          if (res.status === "error") return reject(res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  editCronjob = (data, id) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/cronjobs/${id}`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  executeCron = (id) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .get(`/cjroutine/${id}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const cronjobsService = new CronjobsService();
export default cronjobsService;
