import FuseUtils from "@fuse/utils/FuseUtils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import {
  createNavigation,
  createTemplatesGroups,
  mountNavigation,
  mountTemplatesGroups,
  removeNavigationItem,
  resetNavigation,
  updateNavigationItem,
} from "./navigationSectionsSlice";
import sectionsService from "../service/sectionsService";
import SectionModel, {
  SectionNaviModel,
  SectionNewModel,
  SectionNewNaviModel,
} from "../model/SectionModel";

export const getSections = createAsyncThunk(
  "section/sections/getSections",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/posts/sections/?formated=1`);

      if (res.status === "error") return [];

      // dispatch(resetNavigation());
      const items = mountNavigation(res.result);
      // const items = await res.result.map((r) => SectionNewNaviModel(r));
      dispatch(createNavigation(items));

      return items;
    } catch (error) {
      return [];
    }
  }
);

export const getSectionsTemplates = createAsyncThunk(
  "section/sections/getSectionsTemplates",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/posts/templates/`);

      if (res.status === "error") return [];

      dispatch(resetNavigation());
      const items = mountTemplatesGroups(res.result);
      // const items = await res.result.map((r) => SectionNewNaviModel(r));
      dispatch(createTemplatesGroups(items));

      return res.result;
    } catch (error) {
      return [];
    }
  }
);

export const getSection = createAsyncThunk(
  "section/sections/getSection",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/posts/sections/${_}`);

      if (res.status === "error") return SectionNewModel();

      return SectionNewNaviModel(res.result);
    } catch (error) {
      return SectionNewModel();
    }
  }
);

export const addSection = createAsyncThunk(
  "section/sections/addSection",
  async (data, { dispatch, getState }) => {
    try {
      const { language } = getState().i18n;

      const res = await sectionsService.addSection(data);

      const newItem = {
        ...data,
        last_id: res.result.last_id,
        name: data.name_lang[language] || `[${data.name_tag}]`,
        title: data.name_lang[language] || `[${data.name_tag}]`,
      };

      dispatch(createNavigation([SectionNaviModel(newItem)]));

      return SectionNaviModel(newItem);
    } catch (error) {}
  }
);

export const updateSection = createAsyncThunk(
  "section/sections/updateSection",
  async (data, { dispatch, getState }) => {
    try {
      const { language } = getState().i18n;
      const res = await sectionsService.updateSection(data);

      const newItem = {
        ...data,
        name: data.name_lang[language] || `[${data.name_tag}]`,
        title: data.name_lang[language] || `[${data.name_tag}]`,
      };

      await dispatch(updateNavigationItem(newItem.id, newItem));

      return newItem;
    } catch (error) {}
  }
);

export const removeSection = createAsyncThunk(
  "section/sections/removeSection",
  async (data, { dispatch, getState }) => {
    try {
      const res = await sectionsService.deleteSection(data);

      await dispatch(removeNavigationItem(data.id));

      return data;
    } catch (error) {}
  }
);

export const searchNavigation = createAsyncThunk(
  "section/sections/searchNavigation",
  async (text, { dispatch, getState }) => {
    try {
      const { sections } = getState().section.sections;

      dispatch(resetNavigation());

      if (text.length == 0) return dispatch(createNavigation(sections));

      const filterArray = FuseUtils.filterArrayByString([...sections], text);

      return dispatch(createNavigation(filterArray));
    } catch (error) {}
  }
);

const sectionsSlice = createSlice({
  name: "section/sections",
  initialState: {
    sections: [],
    section: SectionNewModel(),
    templates: [],
    templateDefault: {},
    status: "pending",
  },
  reducers: {
    newSection: (state, action) => {
      const { post_template_id } = action.payload || {};
      state.section = SectionNewModel({ post_template_id });
    },
    resetSection: (state) => {
      state.section = SectionNewModel();
    },
    setTemplateDefault: (state, action) => {
      state.templateDefault = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSections.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getSections.fulfilled, (state, action) => {
        state.sections = action.payload;
        state.status = "fulfilled";
      })
      .addCase(getSections.rejected, (state, action) => {
        state.sections = [];
        state.status = "rejected";
      })
      .addCase(addSection.fulfilled, (state, action) => {
        state.sections.push(action.payload);
      })
      .addCase(updateSection.fulfilled, (state, action) => {
        const index = state.sections.findIndex(
          (t) => t.id == action.payload.id
        );

        state.sections[index] = action.payload;
      })
      .addCase(removeSection.fulfilled, (state, action) => {
        const index = state.sections.findIndex(
          (t) => t.id == action.payload.id
        );

        state.sections = state.sections.splice(index, 1);
      })
      .addCase(getSection.fulfilled, (state, action) => {
        state.section = action.payload;
      })
      .addCase(getSectionsTemplates.fulfilled, (state, action) => {
        state.templates = action.payload;
      });
  },
});

export const { newSection, resetSection, setTemplateDefault } =
  sectionsSlice.actions;

export const selectSections = ({ section }) => section.sections;
export const selectSection = ({ section }) => section.sections.section;
export const selectSectionsTemplates = ({ section }) =>
  section.sections.templates;

export const selectTemplateSection = ({ section }) =>
  section.sections.templateDefault;

export default sectionsSlice.reducer;
