import { selectPageTitle } from "app/store/labels/globalLabels";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import { CachedOutlined } from "@mui/icons-material";
import { fetchGateways } from "./store/gatewaysSlice";
import { useDispatch } from "react-redux";
import { selectSubscription } from "../../access_plans/subscription/store/subscriptionSlice";
import SubscriptionAlert from "src/modules/components/SubscriptionAlert";
import { useEffect } from "react";
import history from "@history";
import axiosService from "src/app/auth/services/axios/axiosService";

const GatewaysHeader = (props) => {
  const dispatch = useDispatch();
  const pageTitle = useSelector(selectPageTitle);
  const { leftSidebar } = props;
  const { valid, plan, status } = useSelector(selectSubscription);
  const access = axiosService.getAccessAs();

  const handleRefreshDevices = () => {
    dispatch(fetchGateways());
  };

  useEffect(() => {
    if (status == "fulfilled" && !Boolean(plan?.web_app) && !access)
      return history.push("/access_plans/subscription");
  }, [dispatch, plan, valid]);

  return (
    <>
      {!valid && <SubscriptionAlert />}
      <div className="flex flex-col p-24 w-full sm:py-32 sm:px-40">
        <div className="flex flex-row items-center w-full mt-8 -mx-10">
          {leftSidebar && (
            <div className="flex shrink-0 items-center">
              <IconButton onClick={leftSidebar} aria-label="toggle sidebar">
                <FuseSvgIcon>heroicons-outline:menu</FuseSvgIcon>
              </IconButton>
            </div>
          )}
          <div className="flex flex-col">
            <Typography
              component="h2"
              className="flex-1 text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate mx-10"
            >
              {pageTitle}
            </Typography>
          </div>
          <div className="flex flex-1 justify-end items-end shrink-0">
            <IconButton
              onClick={handleRefreshDevices}
              aria-label="toggle sidebar"
            >
              <CachedOutlined />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default GatewaysHeader;
