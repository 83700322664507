import _ from "@lodash";

const TemplateModel = (data) =>
  _.defaults(data || {}, {
    post_template_id: data.last_id,
  });

export const TemplateNewModel = (data) =>
  _.defaults(data || {}, {
    name: "",
    name_tag: "",
    name_lang: "",
    title: "",
    content: [],
  });

export const TemplateNaviModel = (data) =>
  _.defaults(data || {}, {
    id: `${data.last_id}`,
    post_template_id: data.last_id,
    title: data.name || `[${data.name_tag}]`,
    type: "item",
    hasActions: true,
    url: `/system/templates/${data.last_id}`,
    content: [],
  });

export const TemplateNewNaviModel = (data) =>
  _.mergeWith(
    {
      ...data,
      name_lang: "",
      id: `${data.post_template_id}`,
      title: data.name || `[${data.name_tag}]`,
      name: "",
      type: "item",
      hasActions: true,
      url: `/system/templates/${data.post_template_id}`,
      content: [],
    },
    data,
    (objValue, srcValue, key) => {
      if (key == "name_lang") return JSON.parse(srcValue);

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

// export const TemplateNewNaviModel = (data) =>
//   _.defaults(data || {}, {
//     ...data,
//     name_lang: JSON.parse(data.name_lang),
//     id: `${data.post_template_id}`,
//     title: data.name || `[${data.name_tag}]`,
//     name: "",
//     type: "item",
//     hasActions: true,
//     url: `/system/templates/${data.post_template_id}`,
//     content: [],
//   });

export default TemplateModel;
