import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectSystemLanguages } from "app/store/i18nSlice";
import { t } from "app/store/labels/globalLabels";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "@lodash";
import FuseLoading from "@fuse/core/FuseLoading";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  createFilterOptions,
} from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { useEffect, useState } from "react";
import { selectDrawer } from "../store/drawerSlice";
import { LoadingButton } from "@mui/lab";
import { addLabels, selectLabels, updateLabels } from "../store/tableSlice";
import labelsService from "../service/labelsService";
import { LabelsNewModel } from "../model/LabelsModel";

const filter = createFilterOptions();

const createSchema = (languages) => {
  const schema = {};

  languages.map((lang) => {
    schema[lang.code] = yup.string();
  });

  return schema;
};

const LabelsForm = ({ close }) => {
  const dispatch = useDispatch();
  const systemLangs = useSelector(selectSystemLanguages);
  const newModel = LabelsNewModel(systemLangs);
  const { data } = useSelector(selectDrawer);
  const { tags } = useSelector(selectLabels);
  const [isLoading, setIsLoading] = useState(false);
  const [radioValue, setRadioValue] = useState("close");

  const schemaLangs = createSchema(systemLangs);

  const schema = yup.object().shape({
    label_group_tag: yup
      .string()
      .required(dispatch(t("common.input_required"))),
    label_tag: yup.string().required(dispatch(t("common.input_required"))),
    ...schemaLangs,
  });

  const { control, formState, handleSubmit, reset, watch, getValues } = useForm(
    {
      mode: "onChange",
      resolver: yupResolver(schema),
    }
  );

  const { dirtyFields, isValid, errors } = formState;

  const form = watch();

  useEffect(() => {
    reset({ ...newModel, ...data });
  }, [reset, data]);

  const onSubmit = async (data) => {
    // console.log(data);
    // return;
    try {
      if (data?.label_group_id) {
        //edit
        // const res = await labelsService.updateLabel(data);
        dispatch(updateLabels(data)).then(({ payload }) => {
          if (payload) close();
        });
      } else {
        //add
        // const res = await labelsService.addLabel(data);
        dispatch(addLabels(data)).then(({ payload }) => {
          if (payload) resolveFunction();
        });
      }
    } catch (error) {}
  };

  const handleCancel = () => {
    close();
  };

  const handleChangeRadio = (event) => {
    setRadioValue(event.target.value);
  };

  const resolveFunction = async () => {
    setIsLoading(false);

    if (radioValue === "tag") {
      reset({
        ...data,
        ...newModel,
        label_group_tag: getValues().label_group_tag,
      });
    } else if (radioValue === "form") {
      reset({ ...data, ...newModel });
    } else if (radioValue === "close") {
      reset({});
      close();
    }
  };

  if (_.isEmpty(form)) return <FuseLoading />;

  return (
    <>
      <div className="w-full flex flex-col py-36 md:px-52 xs:px-28">
        <Controller
          control={control}
          name="label_group_tag"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="label_group_tag"
              className="mt-32"
              options={tags}
              freeSolo
              disabled={!!data?.label_group_id}
              value={value}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              onChange={(event, newValue) => {
                return onChange(newValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== "") {
                  const normalizedInput = params.inputValue.toLowerCase();
                  const existingOptions = options.map((option) =>
                    option.toLowerCase()
                  );

                  if (!existingOptions.includes(normalizedInput)) {
                    filtered.push(params.inputValue);
                  }
                }

                return filtered;
              }}
              isOptionEqualToValue={(option, value) => option == value}
              getOptionLabel={(option) => option}
              renderOption={(_props, option) => <li {..._props}>{option}</li>}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={dispatch(t("common.group"))}
                  placeholder={dispatch(t("common.group"))}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="label_tag"
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              disabled={!!data?.label_group_id}
              label={dispatch(t("common.tag"))}
              placeholder={dispatch(t("common.tag"))}
              error={!!errors.label_tag}
              helperText={errors?.label_tag?.message}
              variant="outlined"
              required
              fullWidth
            />
          )}
        />

        {systemLangs.map((s, _index) => (
          <Controller
            control={control}
            name={s.code}
            key={_index}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-32"
                label={s.name}
                placeholder={s.name}
                error={!!errors[s.code]}
                helperText={errors[s.code]?.message}
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
              />
            )}
          />
        ))}

        {!data?.label_group_id && (
          <FormControl className="flex flex-auto justify-center items-center mt-32">
            <FormLabel id="system_tables_drawer">
              {dispatch(t("common.options"))}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="system_tables_drawer"
              value={radioValue}
              onChange={handleChangeRadio}
              name="system_labels"
              className="flex flex-row"
            >
              <FormControlLabel
                value="tag"
                size="small"
                control={<Radio />}
                label={dispatch(t("SYSTEM.labels_radio_group_1_title"))}
              />
              <FormControlLabel
                value="form"
                size="small"
                control={<Radio />}
                label={dispatch(t("SYSTEM.labels_radio_group_2_title"))}
              />
              <FormControlLabel
                value="close"
                size="small"
                control={<Radio />}
                label={dispatch(t("SYSTEM.labels_radio_group_3_title"))}
              />
            </RadioGroup>
          </FormControl>
        )}
      </div>

      <Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t">
        {/* {data?.access_plan_preset_id &&
          (user.data?.access_level?.is_master ||
            user.data?.access_level?.is_admin) && (
            <Button color="error" onClick={() => setOpenDialog(true)}>
              {dispatch(t("common.delete"))}
            </Button>
          )} */}
        <Button className="ml-auto" onClick={handleCancel}>
          {dispatch(t("common.cancel"))}
        </Button>
        <LoadingButton
          className="ml-8"
          variant="contained"
          color="secondary"
          loading={isLoading}
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </LoadingButton>
      </Box>
    </>
  );
};

export default LabelsForm;
