import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import _ from "@lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addGroup,
  addPost,
  createNewPost,
  getGroup,
  getPost,
  getPosts,
  getPostsItems,
  newPost,
  removePost,
  selectItems,
  selectPost,
  selectPostError,
  selectPostStatus,
  selectSchemas,
  selectValues,
  updateGroup,
  updatePost,
} from "../../store/postsSlice";
import { useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import ItemComponent from "../Components";
import { useDeepCompareEffect } from "@fuse/hooks";
import ConfirmationDialog from "src/app/main/components/confirmationDialog/ConfirmationDialog";
import { selectUser } from "app/store/userSlice";
import InputTranslate from "src/app/main/components/textTranslate/InputTranslate";
import { LoadingButton } from "@mui/lab";

const GroupForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const post = useSelector(selectPost);
  const error = useSelector(selectPostError);
  const status = useSelector(selectPostStatus);
  const routeParams = useParams();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (routeParams.post === "new") {
      setTitle(dispatch(t("common.add")));
      Promise.all([dispatch(getPostsItems())])
        .then((r) => {
          dispatch(createNewPost());
        })
        .catch((e) => {
          navigate("/system/sections");
        });
    } else {
      setTitle(dispatch(t("common.edit")));
      Promise.all([dispatch(getPostsItems())])
        .then((r) => {
          dispatch(
            getGroup({ section: routeParams.section, post: routeParams.post })
          );
        })
        .catch((e) => {
          navigate("/system/sections");
        });
    }
  }, [routeParams, dispatch]);

  const [title, setTitle] = useState("");

  const schema = yup.object().shape({
    ...post.schema,
  });

  const {
    control,
    setValue,
    watch,
    reset,
    formState,
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      title_lang: post.title_lang,
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { title_lang, ...rest } = data;
    try {
      if (routeParams.post === "new") {
        dispatch(addGroup(title_lang)).then(({ payload }) => {
          if (payload) {
            Promise.all([dispatch(getPosts(routeParams.section))]).then((r) => {
              setIsLoading(false);
              navigate(`/system/sections/${routeParams.section}`);
            });
          }
        });
      } else {
        dispatch(updateGroup(title_lang)).then(({ payload }) => {
          setIsLoading(false);
          if (payload) navigate(`/system/sections/${routeParams.section}`);
        });
      }
    } catch (error) {}
  };

  const handleRemove = () => {
    try {
      dispatch(removePost()).then(({ payload }) => {
        if (payload) navigate(`/system/sections/${routeParams.section}`);
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (error) {
      routeParams.section
        ? navigate(`/system/sections/${routeParams.section}`)
        : navigate(`/system/sections`);
    }
  }, [error]);

  useEffect(() => {
    if (!_.isEmpty(post)) {
      reset({ title_lang: post.title_lang });
    }
  }, [reset, post]);

  if (_.isEmpty(post) || status !== "fulfilled")
    return (
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex justify-between items-center mt-10 px-24">
          <Typography>{title}</Typography>
          <IconButton
            className=""
            sx={{ color: "background.primary" }}
            component={NavLinkAdapter}
            to={`/system/sections/${routeParams.section}`}
            size="large"
          >
            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>
        </div>
        <FuseLoading />
      </div>
    );

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex justify-between items-center mt-10 px-24">
        <Typography>{title}</Typography>
        <IconButton
          className=""
          sx={{ color: "background.primary" }}
          component={NavLinkAdapter}
          to={`/system/sections/${routeParams.section}`}
          size="large"
        >
          <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
        </IconButton>
      </div>
      <div className="w-full px-24 flex-1">
        <Controller
          control={control}
          name={"title_lang"}
          render={({ field }) => (
            <InputTranslate
              {...field}
              placeholder={dispatch(t("common.title"))}
              className={"mt-32"}
            />
          )}
        />
      </div>
      <Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t">
        {routeParams.post !== "new" &&
          (user.data?.access_level?.is_master ||
            user.data?.access_level?.is_admin) && (
            <Button color="error" onClick={() => setOpen(true)}>
              {dispatch(t("common.delete"))}
            </Button>
          )}
        <Button
          className="ml-auto"
          component={NavLinkAdapter}
          to={`/system/sections/${routeParams.section}`}
        >
          {dispatch(t("common.cancel"))}
        </Button>
        <LoadingButton
          className="ml-8"
          variant="contained"
          color="secondary"
          loading={isLoading}
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </LoadingButton>
      </Box>
      <ConfirmationDialog
        onClose={() => setOpen(false)}
        onSubmit={handleRemove}
        open={open}
        title={"common.delete"}
      />
    </div>
  );
};

export default GroupForm;
