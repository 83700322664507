import Smtp_ServersApp from "./Smtp_ServersApp";

const Smtp_ServersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "system/smtp_servers",
      element: <Smtp_ServersApp />,
    },
  ],
};

export default Smtp_ServersConfig;
