import { forwardRef } from "react";

const components = {};

export const registerComponent = (name, Component) => {
  components[name] = Component;
};

const ItemComponent = forwardRef((props, ref) => {
  const C = components[props.type];
  return C ? <C ref={ref} {...props} /> : null;
});

export default ItemComponent;
