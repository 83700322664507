import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class SectionsService {
  addSection = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/posts/sections`, data)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  updateSection = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/posts/sections/${data.id}`, data)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  deleteSection = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/posts/sections/${data.id}`)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };
}

const sectionsService = new SectionsService();
export default sectionsService;
