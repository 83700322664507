import { combineReducers } from "@reduxjs/toolkit";
import gantt from "./cronjobsSlice";
import drawer from "./drawerSlice";

const reducer = combineReducers({
  gantt,
  drawer,
});

export default reducer;
