import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getSocialMedia = createAsyncThunk(
  "configurations/social/getSocialMedia",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/APIconfig`);

      if (res.status === "error") throw new Error(res);

      return res.result.corporate.social_media;
    } catch (error) {}
  }
);

export const updateSocial = createAsyncThunk(
  "configurations/social/updateSocial",
  async (data, { dispatch, getState }) => {
    try {
      const body = new FormData();

      await data.social_media.map((s, _index) => {
        body.append(`corporate[social_media][${_index}]`, JSON.stringify(s));
      });

      const res = await axiosConfig.post(`/APIconfig`, body);

      if (res.status === "error") throw new Error(res);

      return data;
    } catch (error) {}
  }
);

const socialSlice = createSlice({
  name: "configurations/social",
  initialState: {
    data: [
      {
        short: "",
        name: "",
        url: "",
      },
    ],
    status: "idle",
  },
  reducers: {
    addItem: (state, action) => {
      state.data.push({
        short: "",
        name: "",
        url: "",
      });
    },
    removeItem: (state, action) => {
      state.data.splice(action.payload, 1);
    },
    updateItem: (state, action) => {
      const { index, name, value } = action.payload;

      state.data[index][name] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSocialMedia.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getSocialMedia.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "fulfilled";
      })
      .addCase(getSocialMedia.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const { addItem, removeItem, updateItem } = socialSlice.actions;

export const selectSocial = ({ configurations }) => configurations.social;

export default socialSlice.reducer;
