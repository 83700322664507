const MenuModal = (data) => {
  return _.defaults(data || {}, {
    ...data,
    id: `${data.place_group_id}`,
    title: `${data.name}`,
    subtitle: `${data.address}`,
    type: "item",
    hasActions: true,
    icon: "material-outline:layers",
  });
};

export default MenuModal;
