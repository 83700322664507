import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import _ from "@lodash";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/system/Box";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { getEntity, selectEntity } from "../store/entitySlice";
import PhoneNumberSelector from "./phone-number-selector/PhoneNumberSelector";
import AddressSelector from "./address-selector/AddressSelector";

import EntityEmailSelector from "./email-selector/EntityEmailSelector";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { t } from "app/store/labels/globalLabels";
import EntityDataInput from "./tabs/EntityDataInput";
import EntityEmailInput from "./tabs/EntityEmailInput";
import EntityPhoneInput from "./tabs/EntityPhoneInput";
import EntityAddressInput from "./tabs/EntityAddressInput";
import FileUpdate from "src/app/main/components/fileUpdate/FileUpdate";

const EntityForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const entity = useSelector(selectEntity);
  const routeParams = useParams();

  const [tab, setTab] = useState("1");
  const [files, setFiles] = useState([]);

  const pond = useRef(null);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  useEffect(() => {
    dispatch(getEntity(routeParams.id));
  }, [dispatch, routeParams]);

  useEffect(() => {
    reset({ ...entity });
  }, [entity, reset]);

  useEffect(() => {
    if (entity) {
      setFiles(entity?.picture_src);
    }
  }, [entity]);

  if (_.isEmpty(form) || !entity) {
    return (
      <div className="flex w-full h-full">
        <FuseLoading />
      </div>
    );
  }

  return (
    <>
      <Box
        className="relative w-full h-136 sm:h-120 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.paper",
        }}
      ></Box>

      <div className="w-full">
        <div className="w-full">
          <div className="w-128 h-128 -mt-64 ml-48">
            <Controller
              control={control}
              name="picture_src"
              render={({ field: { onChange, value } }) => (
                <FileUpdate
                  refPond={pond}
                  // Preview
                  imagePreviewMaxHeight={200}
                  imagePreviewHeight={170}
                  // file-validate-type
                  imageCropAspectRatio={"1:1"}
                  acceptedFileTypes={"image/*"}
                  stylePanelLayout={"compact circle"}
                  //core
                  files={files}
                  allowProcess={false}
                  instantUpload={true}
                  maxFileSize={"5MB"}
                  // onupdatefiles={onChange}
                  onupdatefiles={(fileItems) => {
                    const files = fileItems.map((fileItem) => fileItem.file);
                    setValue("picture", files[0]);
                    onChange(files[0]);
                    setFiles(files);
                  }}
                  styleLoadIndicatorPosition={"center bottom"}
                  styleButtonRemoveItemPosition={"center bottom"}
                />
              )}
            />
          </div>
        </div>
        <Box>
          <TabContext value={tab} className="px-5">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                className="relative flex flex-1 flex-col h-44 px-24 sm:px-48"
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label={dispatch(t("common.data"))} value="1" />
                <Tab label={dispatch(t("common.emails"))} value="2" />
                <Tab label={dispatch(t("common.contacts"))} value="3" />
                <Tab label={dispatch(t("common.addresses"))} value="4" />
              </TabList>
            </Box>
            <TabPanel value="1" className="p-0">
              <EntityDataInput
                entity={entity}
                // onSubmit={onSubmit}
                pond={files}
              />
            </TabPanel>
            <TabPanel value="2" className="p-0">
              <EntityEmailInput entity={entity} />
            </TabPanel>
            <TabPanel value="3" className="p-0">
              <EntityPhoneInput entity={entity} />
            </TabPanel>
            <TabPanel value="4" className="p-0">
              <EntityAddressInput entity={entity} />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </>
  );
};

export default EntityForm;
