import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async (id, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/access_plans`);

      if (res.status === "error") return [];

      return {
        plan: res.result.access_plan,
        nextPlan: res.result.next_access_plan,
        used: res.result.used_so_far,
        valid: res.result.is_valid,
      };
    } catch (error) {
      return [];
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    plan: {},
    nextPlan: {},
    used: {},
    valid: true,
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscription.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        const { plan, nextPlan, used, valid } = action.payload;

        state.plan = plan;
        state.nextPlan = nextPlan;
        state.used = used;
        state.valid = valid;
        state.status = "fulfilled";
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.plan = {};
        state.nextPlan = {};
        state.used = {};
        state.status = "rejected";
      });
  },
});

export const selectSubscription = ({ subscription }) => subscription;

export default subscriptionSlice.reducer;
