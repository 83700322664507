import { createSlice, current } from "@reduxjs/toolkit";
import NotificationsModel from "../model/NotificationsModel";

const drawerSlice = createSlice({
  name: "notifications/drawer",
  initialState: {
    drawerState: false,
    editRow: NotificationsModel(),
  },

  reducers: {
    setEmptyRow: (state) => {
      state.editRow = NotificationsModel();
      state.drawerState = true;
    },
    setDrawerState: (state, action) => {
      state.drawerState = action.payload;
    },
    setEditRow: (state, action) => {
      state.editRow = action.payload;
    },
  },
  extraReducers: {},
});

export const selectDrawerState = ({ notifications }) =>
  notifications.drawer.drawerState;
export const selectEditRow = ({ notifications }) =>
  notifications.drawer.editRow;

export const { setDrawerState, setEditRow, setEmptyRow } = drawerSlice.actions;

export default drawerSlice.reducer;
