import { lazy } from "react";
import UserView from "./contact/UserView";
import UserForm from "./contact/UserForm";

const UsersApp = lazy(() => import("./UsersApp"));

const UsersAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "system/users",
      element: <UsersApp />,
      children: [
        {
          path: ":id",
          element: <UserView />,
        },
        {
          path: ":id/edit",
          element: <UserForm />,
        },
      ],
    },
  ],
};

export default UsersAppConfig;
