import {
  createSelector,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import FaqsModel from "../model/FaqsModel";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getFaqs = createAsyncThunk(
  "faqsApp/faqs/getFaqs",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/posts/templates/2?formated=1`);

      if (res.status === "error") throw new Error(res);

      const posts = await sortFaqsByViews(res.result.subitems);
      const items = parsePosts(res.result.subitems);

      return { items, posts };
    } catch (error) {
      throw new Error(res);
    }
  }
);

const parsePosts = (data) => {
  const arrayNew = [];

  for (let i = 0; i < data.length; i++) {
    const element = data[i];

    arrayNew.push(FaqsModel(element));

    if (element.subitems) arrayNew[i].subitems = parsePosts(element.subitems);
  }
  return arrayNew;
};

const sortFaqsByViews = async (data) => {
  const postsArr = [];
  await data?.map((i) => {
    i.subitems?.map((_i) => postsArr.push(FaqsModel(_i)));
    return;
  });

  const posts = postsArr
    .filter((p) => p.content.mostviews && p.content.mostviews.value > 0)
    .sort((a, b) => a.content?.mostviews?.value - b.content?.mostviews?.value);

  return posts;
};

const faqsSlice = createSlice({
  name: "faqsApp/faqs",
  initialState: {
    items: [],
    posts: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFaqs.fulfilled, (state, action) => {
        const { items, posts } = action.payload;

        state.items = items;
        state.posts = posts;
        state.status = "fulfilled";
      })
      .addCase(getFaqs.rejected, (state) => {
        state.items = [];
        state.posts = [];
        state.status = "rejected";
      });
  },
});

export const selectItems = ({ faqsApp }) => faqsApp.faqs.items;
export const selectStatus = ({ faqsApp }) => faqsApp.faqs.status;
export const selectPosts = ({ faqsApp }) => faqsApp.faqs.posts;

export default faqsSlice.reducer;
