import {
  createSelector,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import i18n from "src/i18n";
import { axiosInitial } from "../auth/services/axios/axiosConfig";
import { fetchNavigation } from "./fuse/navigationSlice";
import { fetchGlobalLabels } from "./labels/globalLabels";
import { getDevices } from "src/modules/pages/user/devices/voyager/store/devicesSlice";
import { setSignup } from "./apiConfigSlice";

export const changeLanguage = (languageId) => async (dispatch, getState) => {
  const { user } = getState();

  Promise.all([
    dispatch(fetchGlobalLabels(languageId)),
    dispatch(fetchLanguages(languageId)),
    dispatch(fetchNavigation(languageId)),
    dispatch(tableImportLang(languageId)),
  ]);

  // if (user.data.user_id) {
  //   Promise.all([
  //     dispatch(fetchNavigation(languageId)),
  //     dispatch(tableImportLang(languageId)),
  //   ]);
  //   // dispatch(getDevices(user?.data?.voyager_wifi_user?.userId));
  // }

  // return i18n.changeLanguage(languageId).then(() => {
  return dispatch(i18nSlice.actions.languageChanged(languageId));

  // });
};

export const fetchLanguages = createAsyncThunk(
  "i18n/fetchLanguages",
  async (_, { dispatch, getState }) => {
    const { user } = getState();

    if (user.role?.length === 0) {
      dispatch(fetchGlobalLabels(_));
    }

    try {
      const data = await axiosInitial.get(`${_ ? `?lang=${_}` : ""}`);

      if (data?.status === "error") {
        // dispatch(setSystemLanguages([]));
        return [];
      }

      dispatch(setSignup(data?.result?.user?.can_signup));

      dispatch(fetchSystemLanguages(_));

      // dispatch(setSystemLanguages(data?.result?.system_lang_list));

      const languages = await data?.result?.lang_list?.map((lang) => {
        return {
          id: lang.code,
          title: lang.name,
          flag: lang.flag_src,
          // flag: lang.code.toUpperCase(),
        };
      });

      return languages;
    } catch (error) {}
  }
);

export const fetchSystemLanguages = createAsyncThunk(
  "i18n/fetchSystemLanguages",
  async (_, { dispatch, getState }) => {
    try {
      const data = await axiosInitial.get(
        `?all_lang_list=1${_ ? `&lang=${_}` : ""}`
      );

      return data?.result;
    } catch (error) {
      return [];
    }
  }
);

export const tableImportLang = createAsyncThunk(
  "i18n/tableImportLang",
  async (langId, { dispatch, getState }) => {
    const actualLang = await import(
      `material-react-table/locales/${langId}.js`
    );

    dispatch(
      setTableLang(actualLang[`MRT_Localization_${langId.toUpperCase()}`])
    );
  }
);

const i18nSlice = createSlice({
  name: "i18n",
  initialState: {
    language: navigator.language.substring(0, 2),
    languages: [],
    systemLanguages: [],
    allLanguages: [],
    status: "idle",
    tableLang: null,
    error: null,
  },
  reducers: {
    languageChanged: (state, action) => {
      state.language = action.payload;
    },
    setSystemLanguages: (state, action) => {
      state.systemLanguages = action.payload;
    },
    setTableLang: (state, action) => {
      state.tableLang = action.payload;
    },
    setAllLanguages: (state, action) => {
      state.allLanguages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.languages = action.payload;
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSystemLanguages.fulfilled, (state, action) => {
        state.systemLanguages = action.payload.system_lang_list;

        state.allLanguages = action.payload.all_lang_list;
      });
  },
});

export const { setSystemLanguages, setTableLang } = i18nSlice.actions;

export const selectCurrentLanguageId = ({ i18n: _i18n }) => _i18n.language;

export const selectTableLanguage = ({ i18n: _i18n }) => _i18n.tableLang;

export const selectLanguages = (state) => {
  return state.i18n.languages;
};

export const selectSystemLanguages = (state) => {
  return state.i18n.systemLanguages;
};

export const selectAllLanguages = (state) => {
  return state.i18n.allLanguages;
};

export const selectCurrentLanguageDirection = createSelector(
  [selectCurrentLanguageId],
  (id) => {
    return i18n.dir(id);
  }
);

export const selectCurrentLanguage = createSelector(
  [selectCurrentLanguageId, selectLanguages],
  (id, languages) => {
    return languages?.find((lng) => lng.id === id);
  }
);

export default i18nSlice.reducer;
