import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "gateways/drawer",
  initialState: {
    drawer: false,
    edit: {},
  },
  reducers: {
    setDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
  },
});

export const { setDrawer, setEdit } = drawerSlice.actions;

export const drawerState = ({ gateways }) => gateways.drawer.drawer;
export const selectEdit = ({ gateways }) => gateways.drawer.edit;

export default drawerSlice.reducer;
