import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Box from "@mui/system/Box";
import format from "date-fns/format";
import _ from "@lodash";
import { getUser, selectUser } from "../store/contactSlice";
import { selectCountries } from "../store/countriesSlice";
import { selectTags } from "../store/tagsSlice";
import countries from "src/app/main/components/countriesPhone";
import { t } from "app/store/labels/globalLabels";
import draftToHtml from "draftjs-to-html";
import { getAccess } from "../store/accessSlice";
import { getWorkspace } from "../store/workspaceSlice";
import { useContext } from "react";
import { useAuth } from "src/app/auth/AuthContext";
import { useThemeMediaQuery } from "@fuse/hooks";
import axiosService from "src/app/auth/services/axios/axiosService";
import { selectLanguages } from "app/store/i18nSlice";
import { getLanguageByCode } from "app/shared-components/Functions";
import { useState } from "react";

const UserView = () => {
  const contact = useSelector(selectUser);
  const tags = useSelector(selectTags);
  const languages = useSelector(selectLanguages);
  const routeParams = useParams();
  const dispatch = useDispatch();
  const { setWaitAuthCheck } = useAuth();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [status, setStatus] = useState([
    {
      label: dispatch(t("common.active")),
      value: "OK",
    },
    {
      label: dispatch(t("common.blocked")),
      value: "BLOCKED",
    },
    {
      label: dispatch(t("common.await")),
      value: "AWAIT",
    },
  ]);

  useEffect(() => {
    Promise.all([
      dispatch(getUser(routeParams.id)),
      dispatch(getAccess()),
      dispatch(getWorkspace()),
    ]);
  }, [dispatch, routeParams]);

  const getCountryByIso = (iso) => {
    return countries.find((country) => country.iso === iso);
  };

  if (!contact) {
    return <FuseLoading />;
  }

  const buttonLoading = () => {
    setWaitAuthCheck(true);

    axiosService.accessAs(contact);

    // return <FuseLoading />;
  };

  return (
    <>
      <Box
        className="relative w-full h-136 sm:h-120 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.paper",
        }}
      />
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
        <div className="w-full max-w-3xl">
          <div className="flex flex-auto items-end -mt-64">
            <Avatar
              sx={{
                borderWidth: 4,
                borderStyle: "solid",
                borderColor: "background.default",
                backgroundColor: "background.paper",
                color: "text.secondary",
              }}
              className="w-128 h-128 text-64 font-bold"
              src={contact.picture_src}
              alt={contact.name}
            >
              {contact.name?.charAt(0)}
            </Avatar>
            <div className="flex items-center ml-auto mb-4">
              <Button
                variant="contained"
                color="secondary"
                component={NavLinkAdapter}
                to="edit"
              >
                <FuseSvgIcon size={20}>
                  heroicons-outline:pencil-alt
                </FuseSvgIcon>
                <span className="mx-8">{dispatch(t("common.edit"))}</span>
              </Button>
              <Button className="ml-12" onClick={() => buttonLoading()}>
                <FuseSvgIcon size={20}>material-outline:preview</FuseSvgIcon>
                {!isMobile && (
                  <Typography className="text-15 mx-4 font-bold truncate">
                    {dispatch(t("common.access"))}
                  </Typography>
                )}
              </Button>
            </div>
          </div>

          <Typography className="mt-12 text-4xl font-bold truncate">
            {contact.name}
          </Typography>

          <Divider className="mt-16 mb-24" />

          <div className="flex flex-col space-y-20">
            {contact?.email && (
              <div className="flex items-start">
                <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.email"))}
                  </Typography>
                  <div className="ml-24 leading-6">
                    <Typography className="font-400">
                      <a
                        className="hover:underline text-primary-500"
                        href={`mailto: ${contact.email}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {contact.email}
                      </a>
                    </Typography>
                  </div>
                </div>
              </div>
            )}

            {contact?.phone_number && (
              <div className="flex items-start">
                <FuseSvgIcon>heroicons-outline:phone</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.phone"))}
                  </Typography>
                  <div className="min-w-0 ml-24 space-y-4">
                    <div className="flex items-center leading-6">
                      <Box
                        className="hidden sm:flex w-24 h-16 overflow-hidden"
                        sx={{
                          background:
                            "url('/assets/images/flags/flags.png') no-repeat 0 0",
                          backgroundSize: "24px 3876px",
                          backgroundPosition: getCountryByIso(
                            contact.phone_country_code
                          )?.flagImagePos,
                        }}
                      />

                      <div className="sm:ml-12 font-mono">
                        <Typography className=" font-400">
                          {getCountryByIso(contact.phone_country_code)?.code}
                        </Typography>
                      </div>

                      <div className="ml-10 font-mono">
                        <Typography className=" font-400">
                          {contact.phone_number}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {contact?.lang && (
              <div className="flex items-start">
                <FuseSvgIcon>heroicons-outline:globe</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.language"))}
                  </Typography>
                  <div className="min-w-0 ml-24 space-y-4">
                    <div className="flex items-center leading-6">
                      {getLanguageByCode(contact.lang, languages)?.flag && (
                        <img
                          className="min-w-20 object-contain w-20 h-20"
                          src={getLanguageByCode(contact.lang, languages)?.flag}
                          alt={getLanguageByCode(contact.lang, languages)?.flag}
                        />
                      )}

                      <div className="ml-10 leading-6">
                        <Typography className=" font-400">
                          {getLanguageByCode(contact.lang, languages)?.title}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {contact?.workspace_name && (
              <div className="flex items-start">
                <FuseSvgIcon>material-outline:work_outline</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.workspace"))}
                  </Typography>
                  <div className="ml-24 leading-6">
                    <Typography className=" font-400">
                      {contact.workspace_name}
                    </Typography>
                  </div>
                </div>
              </div>
            )}

            {contact?.status && (
              <div className="flex items-start">
                <FuseSvgIcon>material-outline:build_circle</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.status"))}
                  </Typography>
                  <div className="ml-24 leading-6">
                    <Typography className=" font-400">
                      {status.find((s) => s.value == contact.status).label}
                    </Typography>
                  </div>
                </div>
              </div>
            )}

            {contact.access_level && (
              <div className="flex items-start">
                <FuseSvgIcon>material-outline:account_box</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.access_level"))}
                  </Typography>
                  <div className="ml-24 leading-6">
                    <Typography className=" font-400">
                      {contact.access_level?.name}
                    </Typography>
                  </div>
                </div>
              </div>
            )}

            <div className="flex items-start">
              <FuseSvgIcon>material-outline:security</FuseSvgIcon>
              <div>
                <Typography className="ml-24 leading-6 font-600">
                  {dispatch(t("common.2fa"))}
                </Typography>
                <div className="ml-24 leading-6">
                  <Typography className="font-400">
                    {Boolean(contact.signin_token_required)
                      ? dispatch(t("common.active"))
                      : dispatch(t("common.inactive"))}
                  </Typography>
                </div>
              </div>
            </div>

            {contact.birthdate && (
              <div className="flex items-start">
                <FuseSvgIcon>heroicons-outline:cake</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.birthday"))}
                  </Typography>
                  <div className="ml-24 leading-6">
                    <Typography className=" font-400">
                      {format(new Date(contact.birthdate), "MMMM d, y")}
                    </Typography>
                  </div>
                </div>
              </div>
            )}

            {contact.email_signature && (
              <div className="flex w-full">
                <FuseSvgIcon>heroicons-outline:menu-alt-2</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.signature"))}
                  </Typography>
                  <div className="max-w-none w-full ml-24 prose dark:prose-invert whitespace-pre-wrap">
                    <Typography className=" font-400">
                      {contact.email_signature}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserView;
