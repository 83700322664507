import Webkey from "./Webkey";
import { authRoles } from "src/app/auth";

const WebkeyConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null,
  routes: [
    {
      // path: "user/devices/voyager/webkey",
      path: "webkey",
      element: <Webkey />,
    },
  ],
};

export default WebkeyConfig;
