import FuseUtils from "@fuse/utils/FuseUtils";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import menu from "../config/navigationConfig";
import { t } from "app/store/labels/globalLabels";

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(emptyInitialState, menu);

export const changeNavigationLang = () => (dispatch, getState) => {
  dispatch(
    updateNavigationItem("all_devices", {
      title: dispatch(t("DEVICES.all_devices")),
    })
  );
  dispatch(
    updateNavigationItem("my_devices", {
      title: dispatch(t("DEVICES.menu_myDevices_title")),
    })
  );
  dispatch(
    updateNavigationItem("my_all_devices", {
      title: dispatch(t("DEVICES.all_devices")),
    })
  );

  dispatch(
    updateNavigationItem("shared_devices", {
      title: dispatch(t("DEVICES.menu_shared_devices")),
    })
  );

  dispatch(
    updateNavigationItem("devices_shared_withme", {
      title: dispatch(t("DEVICES.menu_sharedDevices_title")),
    })
  );
  dispatch(
    updateNavigationItem("my_all_shared_devices", {
      title: dispatch(t("DEVICES.all_devices")),
    })
  );
};

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(
    setNavigationDevice(FuseUtils.updateNavItem(navigation, id, item))
  );
};

export const prependNavigationItem =
  (item, parentId) => (dispatch, getState) => {
    const navigation = selectNavigationAll(getState());

    return dispatch(
      setNavigationDevice(FuseUtils.appendNavItem(navigation, item, parentId))
    );
  };

export const removeNavigationItem = (id) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigationDevice(FuseUtils.removeNavItem(navigation, id)));
};

export const {
  selectAll: selectNavigationAll,
  selectIds: selectNavigationIds,
  selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors((state) => state.voyager.navigation);

const navigationDevicesSlice = createSlice({
  name: "voyager/navigation",
  initialState,
  reducers: {
    setNavigationDevice: navigationAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setNavigationDevice, resetNavigation } =
  navigationDevicesSlice.actions;

export const selectNavigation = createSelector(
  [selectNavigationAll],
  (navigation) => {
    return navigation;
  }
);

export const mountNavigation = (data) => async (dispatch, getState) => {
  var myBadge = 0;
  var sharedBadge = 0;
  var mySharedDevices = 0;

  dispatch(
    updateNavigationItem("all_devices", {
      title: dispatch(t("DEVICES.all_devices")),
    })
  );
  dispatch(
    updateNavigationItem("my_devices", {
      title: dispatch(t("DEVICES.menu_myDevices_title")),
    })
  );
  dispatch(
    updateNavigationItem("my_all_devices", {
      title: dispatch(t("DEVICES.all_devices")),
    })
  );

  dispatch(
    updateNavigationItem("shared_devices", {
      title: dispatch(t("DEVICES.menu_shared_devices")),
    })
  );

  dispatch(
    updateNavigationItem("devices_shared_withme", {
      title: dispatch(t("DEVICES.menu_sharedDevices_title")),
    })
  );
  dispatch(
    updateNavigationItem("my_all_shared_devices", {
      title: dispatch(t("DEVICES.all_devices")),
    })
  );

  const allRooms = await data
    ?.map((item) => {
      !Boolean(item.isshared)
        ? (myBadge = myBadge + 1)
        : (sharedBadge = sharedBadge + 1);

      if (item.totals.shared > 0) mySharedDevices = mySharedDevices + 1;

      return {
        shared: item.isshared,
        room: item.room ? item.room : "[N/A]",
        addr: item.addr ? item.addr : "[N/A]",
      };
    })
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.shared === value.shared &&
            t.room === value.room &&
            t.addr === value.addr
        )
    )
    .map((room) => {
      !Boolean(room.shared)
        ? dispatch(
            prependNavigationItem(
              {
                id: `${room.room}.${room.addr}`,
                title: room.room,
                subtitle: room.addr,
                type: "item",
                icon: "heroicons-outline:key",
                shared: 0,
              },
              "my_devices"
            )
          )
        : dispatch(
            prependNavigationItem(
              {
                id: `${room.room}.${room.addr}`,
                title: room.room,
                subtitle: room.addr,
                type: "item",
                icon: "heroicons-outline:share",
                shared: 1,
              },
              "devices_shared_withme"
            )
          );
    });

  data.length > 0 &&
    dispatch(
      updateNavigationItem("all_devices", {
        badge: {
          title: data.length,
          classes: "w-20 h-20 bg-deep-purple text-white rounded-full",
        },
      })
    );

  myBadge > 0 &&
    dispatch(
      updateNavigationItem("my_all_devices", {
        badge: {
          title: myBadge,
          classes: "w-20 h-20 bg-deep-purple text-white rounded-full",
        },
      })
    );

  mySharedDevices > 0 &&
    dispatch(
      updateNavigationItem("shared_devices", {
        badge: {
          title: mySharedDevices,
          classes: "w-20 h-20 bg-deep-purple text-white rounded-full",
        },
      })
    );

  sharedBadge > 0 &&
    dispatch(
      updateNavigationItem("my_all_shared_devices", {
        badge: {
          title: sharedBadge,
          classes: "w-20 h-20 bg-deep-purple text-white rounded-full",
        },
      })
    );
};
export default navigationDevicesSlice.reducer;
