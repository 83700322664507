import { lazy } from "react";
import SidebarRedirect from "./pages/SidebarRedirect";

const Configurations = lazy(() => import("./Configurations"));

const ConfigurationsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "system/configurations",
      element: <Configurations />,
      children: [
        {
          path: ":id",
          element: <SidebarRedirect />,
        },
        // {
        //   path: ":id/edit",
        //   // element: <UserForm />,
        // },
      ],
    },
  ],
};

export default ConfigurationsConfig;
