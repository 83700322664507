import React from "react";
import Typography from "@mui/material/Typography";
import {
  selectGlobalLabels,
  selectPageSubTitle,
  selectPageTitle,
  t,
} from "app/store/labels/globalLabels";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";

const MenuAppHeader = ({ leftSidebarToggle, rightSidebarToggle }) => {
  const dispatch = useDispatch();
  const labels = useSelector(selectGlobalLabels);
  const pageTitle = useSelector(selectPageTitle);
  const pageSubtitle = useSelector(selectPageSubTitle);

  return (
    <div className="flex flex-col p-24 w-full sm:py-32 sm:px-40">
      <div className="flex flex-row items-center w-full mt-8 -mx-10">
        {leftSidebarToggle && (
          <div className="flex shrink-0 items-center">
            <IconButton onClick={leftSidebarToggle} aria-label="toggle sidebar">
              <FuseSvgIcon>material-outline:layers</FuseSvgIcon>
            </IconButton>
          </div>
        )}
        <div className="flex flex-col">
          <Typography className="text-3xl font-semibold tracking-tight leading-8">
            {pageTitle}
          </Typography>
          <Typography
            className="font-medium tracking-tight"
            color="text.secondary"
          >
            {pageSubtitle}
          </Typography>
        </div>
        <div className="flex flex-1 justify-end items-end shrink-0">
          {rightSidebarToggle && (
            <IconButton
              onClick={rightSidebarToggle}
              aria-label="toggle sidebar"
            >
              <FuseSvgIcon>material-outline:groups</FuseSvgIcon>
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

{
  /* <div className="flex flex-col p-24 w-full sm:py-32 sm:px-40">
<div className="columns-2 sm:items-center min-w-0 p-24 md:p-32 pb-0 md:pb-0">
<div >
  {leftSidebarToggle && (
      <IconButton onClick={leftSidebarToggle} aria-label="toggle sidebar">
        <FuseSvgIcon>material-outline:layers</FuseSvgIcon>
      </IconButton>
  )}
  
    <Typography
      component="h2"
      className="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate mx-10"
    >
      {pageTitle}
    </Typography>

    <Typography
      className="font-medium tracking-tight"
      color="text.secondary"
    >
      {pageSubtitle}
    </Typography>
  </div>
  {rightSidebarToggle && (
    <div >
      <IconButton
        onClick={rightSidebarToggle}
        aria-label="toggle sidebar"
      >
        <FuseSvgIcon>material-outline:groups</FuseSvgIcon>
      </IconButton>
    </div>
  )}
</div>
</div> */
}

export default MenuAppHeader;
