import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "cronjobs/drawer",
  initialState: {
    open: false,
    edit: false,
    editRow: {},
  },

  reducers: {
    setOpenDrawer: (state, action) => {
      state.open = action.payload;
    },

    setEditDrawer: (state, action) => {
      state.edit = action.payload;
    },

    setEditRow: (state, action) => {
      state.editRow = action.payload;
    },
  },
  extraReducers: {},
});

export const selectOpenState = ({ cronjobs }) => cronjobs.drawer.open;
export const selectEditState = ({ cronjobs }) => cronjobs.drawer.edit;
export const selectRowInfo = ({ cronjobs }) => cronjobs.drawer.editRow;

export const { setOpenDrawer, setEditDrawer, setEditRow } = drawerSlice.actions;

export default drawerSlice.reducer;
