import BrowserRouter from "@fuse/core/BrowserRouter";
import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import { SnackbarProvider } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import {
  selectCurrentLanguageDirection,
  selectCurrentLanguageId,
} from "app/store/i18nSlice";
import { selectUser } from "app/store/userSlice";
import themeLayouts from "app/theme-layouts/themeLayouts";
import { changeTheme, selectMainTheme } from "app/store/fuse/settingsSlice";
import FuseAuthorization from "@fuse/core/FuseAuthorization";
import settingsConfig from "app/configs/settingsConfig";
import withAppProviders from "./withAppProviders";
import { AuthProvider } from "./auth/AuthContext";
import { useEffect, useLayoutEffect } from "react";
import { fetchLanguages } from "./store/i18nSlice";
import {
  fetchGlobalLabels,
  selectGlobalLabelsStatus,
  // selectGlobalLabelsStatus,
} from "./store/labels/globalLabels";
import FuseLoading from "@fuse/core/FuseLoading";
import {
  fetchLogos,
  selectAssets,
  selectDark,
  selectFavicon,
} from "./store/labels/logosSlice";
import SnackbarTemplate from "./main/components/SnackbarTemplate";
import axiosService from "./auth/services/axios/axiosService";

const emotionCacheOptions = {
  rtl: {
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
  ltr: {
    key: "muiltr",
    stylisPlugins: [],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
};

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const langDefault = useSelector(selectCurrentLanguageId);
  const mainTheme = useSelector(selectMainTheme);
  const status = useSelector(selectGlobalLabelsStatus);
  const dark = useSelector(selectDark);
  const favicon = useSelector(selectFavicon);
  const { page_title } = useSelector(selectAssets);

  useEffect(() => {
    document.title = page_title && page_title;

    const updateFavicon = () => {
      const link = document.querySelector("link[rel='shortcut icon']");
      if (link && favicon.length > 0) {
        link.href = `${process.env.REACT_APP_API_ASSETS_FAVICON}/${favicon}`;
      }
    };

    updateFavicon();
  }, [dark, page_title]);

  useLayoutEffect(() => {
    const timerId = setTimeout(() => {
      dispatch(fetchLanguages(langDefault));
      dispatch(fetchLogos());
      const theme = axiosService.getTheme();
      theme && dispatch(changeTheme(theme));
      const metaTheme = document.querySelector("meta[name='theme-color']");
      if (theme) metaTheme.content = theme == "defaultDark" ? "#000" : "#FFF";
    }, 1500);

    return () => clearTimeout(timerId);
  }, [dispatch]);

  useEffect(() => {
    const root = document.documentElement;
    const newDrawerBackgroundColor = mainTheme.palette.background.default;
    root.style.setProperty(
      "--drawer-background-color",
      newDrawerBackgroundColor
    );

    root.style.setProperty("--drawer-color-text-title", mainTheme.palette.text);
    root.style.setProperty(
      "--filepond-bg-color",
      mainTheme.palette.background.paper
    );
  }, [mainTheme]);

  if (user.role?.length === 0 && status != "fulfilled") return <FuseLoading />;

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <FuseTheme theme={mainTheme} direction={langDirection}>
        <AuthProvider>
          <BrowserRouter>
            <FuseAuthorization
              userRoutes={user.routes}
              userRole={user.role}
              loginRedirectUrl={settingsConfig.loginRedirectUrl}
            >
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                classes={{
                  containerRoot: `top-0 right-0 mr-8 lg:mr-8 z-99999`,
                }}
                Components={{ snackbarTemplate: SnackbarTemplate }}
                preventDuplicate
                disableWindowBlurListener
              >
                <FuseLayout layouts={themeLayouts} />
              </SnackbarProvider>
            </FuseAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
  );
};

export default withAppProviders(App)();
