import _ from "@lodash";

const ProfileModel = (data) =>
  _.defaults(data || {}, {
    user_id: null,
    picture: null,
    picture_src: null,
    background: null,
    name: "",
    email: "",
    lang: "pt",
    phone_number: "",
    phone_country_code: "pt",
    email_signature: "",
    birthdate: null,
    access_level_id: null,
    workspace_id: null,
  });

export const ProfileModelMerge = (data) =>
  _.mergeWith(
    {
      phone_number: "",
      phone_country_code: "pt",
      email_signature: "",
      lang: "pt",
    },
    data,
    (objValue, srcValue, key) => {
      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export default ProfileModel;
