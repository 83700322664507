import React from "react";
import Lottie from "lottie-react";
import NoResultsJson from "src/app/assets/lottie/no_result.json";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { t } from "app/store/labels/globalLabels";

const NoResults = ({ className }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={clsx("flex flex-col items-center justify-center", className)}
    >
      <Lottie
        className="w-full h-full opacity-30"
        animationData={NoResultsJson}
        loop
      />
      <Typography className="mt-32">
        {dispatch(t("common.no_records"))}
      </Typography>
    </div>
  );
};

export default NoResults;
