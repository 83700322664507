import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t } from "app/store/labels/globalLabels";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getGuidesTickets, selectItems } from "./store/guidesTicketsSlice";
import { Autocomplete, Box } from "@mui/material";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import supportService from "./service/supportService";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

const ContactSupport = () => {
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const lang = useSelector(selectCurrentLanguageId);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getGuidesTickets());
  }, [dispatch]);

  const defaultValues = {
    name: "",
    email: "",
    phone_number: "",
    section: "",
    subject: "",
    content: "",
  };

  const schema = yup.object().shape({
    name: yup.string().required(dispatch(t("common.input_required"))),
    email: yup
      .string()
      .email(dispatch(t("common.input_invalid_email")))
      .required(dispatch(t("common.input_required"))),
    phone_number: yup.string(),
    section: yup.number().required(dispatch(t("common.input_required"))),
    subject: yup
      .string()
      .required(dispatch(t("common.input_required")))
      .min(15, dispatch(t("common.min_chars", { chars: "15" }))),
    content: yup
      .string()
      .required(dispatch(t("common.input_required")))
      .min(50, dispatch(t("common.min_chars", { chars: "50" }))),
  });

  const { control, handleSubmit, watch, formState, getValues, reset } = useForm(
    {
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    }
  );
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const onSubmit = async (data) => {
    setIsLoading(true);
    const obj = {
      ...data,
      section: _.find(items, {
        post_section_id: data?.section,
      }).name_lang[lang],
    };

    try {
      const res = await supportService.addTicket(obj);

      reset({});
      navigate("/system/help_center");
    } catch (error) {}
    setIsLoading(false);
  };

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <div className="flex flex-col items-center p-24 sm:p-40 container">
      <div className="flex flex-col w-full max-w-4xl">
        <div className="sm:mt-32">
          <Button
            component={Link}
            to="/system/help_center"
            color="secondary"
            startIcon={
              <FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>
            }
          >
            {dispatch(t("common.back_to"))} {dispatch(t("common.helpcenter"))}
          </Button>
        </div>
        <div className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
          {dispatch(t("common.support"))}
        </div>

        <Paper className="mt-32 sm:mt-48 p-24 pb-28 sm:p-40 sm:pb-28 rounded-2xl">
          <form onSubmit={handleSubmit(onSubmit)} className="px-0 sm:px-24">
            <div className="mb-24">
              <Typography className="text-2xl font-bold tracking-tight">
                {dispatch(t("SYSTEM.submit_request"))}
              </Typography>
              <Typography color="text.secondary">
                {dispatch(t("SYSTEM.your_request_will_processed"))}
              </Typography>
            </div>
            <div className="space-y-32">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    className="w-full"
                    {...field}
                    label={dispatch(t("common.name"))}
                    placeholder={dispatch(t("common.name"))}
                    id="name"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-16 w-full"
                    label={dispatch(t("common.email"))}
                    placeholder={dispatch(t("common.email"))}
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name="phone_number"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-16 w-full"
                    label={dispatch(t("common.phone"))}
                    placeholder={dispatch(t("common.phone"))}
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name="section"
                render={({ field: { onChange, value, ...field } }) => (
                  <Autocomplete
                    {...field}
                    id="section"
                    className="mt-32"
                    options={items}
                    value={
                      value
                        ? _.find(items, {
                            post_section_id: value?.post_section_id || value,
                          })
                        : null
                    }
                    onChange={(event, newValue) => {
                      onChange(newValue.post_section_id || null);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      value && option.post_section_id == value?.post_section_id
                    }
                    getOptionLabel={(option) => option.name_lang[lang]}
                    renderOption={(_props, option) => (
                      <Box
                        {..._props}
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      >
                        {option.name_lang[lang]}
                      </Box>
                    )}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={dispatch(t("common.select"))}
                        placeholder={dispatch(t("common.select"))}
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name="subject"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-16 w-full"
                    label={dispatch(t("common.subject"))}
                    placeholder={dispatch(t("common.subject"))}
                    variant="outlined"
                    fullWidth
                    error={!!errors.subject}
                    helperText={errors?.subject?.message}
                    required
                  />
                )}
              />

              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={dispatch(t("common.message"))}
                    placeholder={dispatch(t("common.message"))}
                    className="mt-16 w-full"
                    margin="normal"
                    multiline
                    minRows={4}
                    variant="outlined"
                    error={!!errors.content}
                    helperText={errors?.content?.message}
                    required
                  />
                )}
              />
            </div>
          </form>
          <div className="flex items-center justify-end mt-32">
            <Button
              onClick={() => navigate("/system/help_center")}
              className="mx-8"
            >
              {dispatch(t("common.cancel"))}{" "}
            </Button>
            <LoadingButton
              className="mx-8"
              variant="contained"
              color="secondary"
              loading={isLoading}
              disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={handleSubmit(onSubmit)}
            >
              {dispatch(t("common.save"))}
            </LoadingButton>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default ContactSupport;
