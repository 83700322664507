import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import _ from "@lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addBlock,
  getBlock,
  newBlock,
  removeBlock,
  selectBlock,
  updateBlock,
} from "../../store/blocksSlice";
import ItemComponent from "../Components";
import { useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import InputTranslate from "src/app/main/components/textTranslate/InputTranslate";
import BlockFormFeatures from "./BlockFormFeatures";
import { selectUser } from "app/store/userSlice";
import ConfirmationDialog from "src/app/main/components/confirmationDialog/ConfirmationDialog";
import { setOpen } from "src/app/main/profile/store/profileSlice";

const BlockForm = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const routeParams = useParams();
  const navigate = useNavigate();
  const block = useSelector(selectBlock);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (routeParams.block === "new") {
      setTitle(dispatch(t("common.add")));
      dispatch(newBlock());
    } else {
      setTitle(dispatch(t("common.edit")));
      dispatch(getBlock({ ...routeParams }));
    }
  }, [dispatch, routeParams]);

  const schema = yup.object().shape({
    name_tag: yup
      .string()
      .matches(/^[a-zA-Z0-9_-]+$/, dispatch(t("common.invalid_chars")))
      .required(dispatch(t("common.input_required"))),
    features: yup.object().shape({
      type: yup.string().required(dispatch(t("common.input_required"))),
    }),
  });

  const {
    control,
    setValue,
    watch,
    reset,
    formState,
    handleSubmit,
    getFieldState,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name_tag: "",
      features: {},
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {}, [block]);

  const form = watch();

  const onSubmit = (data) => {
    try {
      if (routeParams.block === "new") {
        dispatch(addBlock(data))
          .then(({ payload }) => {
            if (payload) navigate(`/system/templates/${routeParams.template}`);
          })
          .catch((e) => {});
      } else {
        dispatch(updateBlock(data))
          .then(({ payload }) => {
            navigate(`/system/templates/${routeParams.template}`);
          })
          .catch((e) => {});
      }
    } catch (error) {}
  };

  const handleRemove = () => {
    try {
      dispatch(removeBlock())
        .then(({ payload }) => {
          if (payload) navigate(`/system/templates/${routeParams.template}`);
        })
        .catch((e) => console.error(e));
    } catch (error) {}
  };

  useEffect(() => {
    reset({ ...block });
  }, [block, reset]);

  if (_.isEmpty(form) || !block) {
    return <FuseLoading />;
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex justify-between items-center mt-10 px-24">
        <Typography>{title}</Typography>
        <IconButton
          className=""
          sx={{ color: "background.primary" }}
          component={NavLinkAdapter}
          to={`/system/templates/${routeParams.template}`}
          size="large"
        >
          <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
        </IconButton>
      </div>
      <div className="w-full px-24 flex-1">
        <Controller
          control={control}
          name={"name_tag"}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              label={dispatch(t("common.tag"))}
              placeholder={dispatch(t("common.tag"))}
              disabled={routeParams.block !== "new"}
              id="name_tag"
              error={!!errors.name_tag}
              helperText={errors?.name_tag?.message}
              variant="outlined"
              required
              fullWidth
            />
          )}
        />

        <Controller
          control={control}
          name={"name_lang"}
          render={({ field }) => (
            <InputTranslate {...field} className={"mt-32"} />
          )}
        />

        <Controller
          control={control}
          name={"features"}
          render={({ field }) => (
            <BlockFormFeatures {...field} className={"mt-32"} />
          )}
        />
      </div>
      <Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t">
        {routeParams.block !== "new" &&
          (user.data?.access_level?.is_master ||
            user.data?.access_level?.is_admin) && (
            <Button color="error" onClick={() => setOpen(true)}>
              {dispatch(t("common.delete"))}
            </Button>
          )}
        <Button
          className="ml-auto"
          component={NavLinkAdapter}
          to={`/system/templates/${routeParams.template}`}
        >
          {dispatch(t("common.cancel"))}
        </Button>
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </Button>
      </Box>
      <ConfirmationDialog
        onClose={() => setOpen(false)}
        onSubmit={handleRemove}
        open={open}
        title={"common.delete"}
      />
    </div>
  );
};

export default BlockForm;
