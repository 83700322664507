import {
  createSelector,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import GuidesModel from "../model/GuidesModel";

export const getGuides = createAsyncThunk(
  "guidesApp/guides/getGuides",
  async (_, { dispatch, getState }) => {
    try {
      const { language } = getState().i18n;

      const res = await axiosConfig.get(`/posts/templates/1?formated=1`);

      if (res.status === "error") throw new Error(res);

      const items = parsePosts(res.result.subitems);

      const guides = items.map((i) => {
        return {
          label: i.name_lang[language],
          value: i.name_lang[language],
        };
      });

      return { items, guides };
    } catch (error) {
      throw new Error(res);
    }
  }
);

const parsePosts = (data) => {
  const arrayNew = [];

  for (let i = 0; i < data.length; i++) {
    const element = data[i];

    arrayNew.push(GuidesModel(element));

    if (element.subitems) arrayNew[i].subitems = parsePosts(element.subitems);
  }
  return arrayNew;
};

const guidesSlice = createSlice({
  name: "guidesApp/guides",
  initialState: {
    items: [],
    guides: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGuides.fulfilled, (state, action) => {
        const { items, guides } = action.payload;

        state.guides = guides;

        state.items = items;
        state.status = "fulfilled";
      })
      .addCase(getGuides.rejected, (state) => {
        state.items = [];
        state.status = "rejected";
      });
  },
});

export const selectItems = ({ guidesApp }) => guidesApp.guides.items;
export const selectGuides = ({ guidesApp }) => guidesApp.guides.guides;
export const selectStatus = ({ guidesApp }) => guidesApp.guides.status;

export default guidesSlice.reducer;
