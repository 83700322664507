const menu = [
  {
    id: "all_devices",
    title: "All Devices",
    type: "item",
    icon: "material-twotone:all_inclusive",
    active: true,
  },
  {
    id: "my_devices",
    title: "My Devices",
    type: "group",
    active: false,
    children: [
      {
        id: "my_all_devices",
        title: "All my devices",
        type: "item",
        icon: "material-outline:house",
        active: false,
      },
      {
        id: "shared_devices",
        title: "Shared Devices",
        type: "item",
        icon: "heroicons-outline:share",
        active: false,
      },
    ],
  },
  {
    id: "devices_shared_withme",
    title: "Devices Shared with Me",
    type: "group",
    active: false,
    children: [
      {
        id: "my_all_shared_devices",
        title: "My Shared Devices",
        type: "item",
        icon: "heroicons-outline:share",
        active: false,
      },
    ],
  },
];

export default menu;
