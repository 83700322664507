import { useDispatch } from "react-redux";
import {
  selectDrawerUserState,
  selectUsers,
  setDrawerUserState,
} from "../store/drawerSlice";
import { t } from "app/store/labels/globalLabels";
import { useSelector } from "react-redux";
import { Drawer } from "rsuite";
import { Card, Typography } from "@mui/material";
import { selectPush } from "../store/pushNotificationsSlice";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const UserList = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectDrawerUserState);
  const { userPushList } = useSelector(selectPush);

  const handleClose = () => {
    dispatch(setDrawerUserState(false));
  };

  return (
    <>
      <Drawer backdrop={true} open={state} onClose={handleClose}>
        <Drawer.Header>
          <Drawer.Title>{dispatch(t("common.users"))}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          {userPushList === null ? (
            <Typography className="flex flex-col items-center">
              {dispatch(t("common.no_results"))}
            </Typography>
          ) : (
            <div className="w-full grid grid-flow-col ">
              {userPushList?.map((item, key) => (
                <div className="w-full flex flex-col mt-2 items-center" key={key}>
                  <Card className="w-full p-20 bg-slate-300	">
                    <div className="flex flex-row">
                      <PersonOutlineOutlinedIcon />
                      <Typography className="font-semibold ml-10">{`${dispatch(
                        t("common.name")
                      )}:`}</Typography>
                      <Typography className="ml-5">{`${item.name}`}</Typography>
                    </div>
                    <div className="flex flex-row">
                      <EmailOutlinedIcon />
                      <Typography className="font-semibold ml-10">{`${dispatch(
                        t("common.email")
                      )}:`}</Typography>
                      <Typography className="ml-5">{`${item.email}`}</Typography>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default UserList;
