import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";
import { lighten } from "@mui/system";
import VoyagerGatewaysMenu from "./VoyagerGatewaysMenu";
import VoyagerGatewaysLocalization from "./VoyagerGatewaysLocalization";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectSubscription } from "src/modules/pages/access_plans/subscription/store/subscriptionSlice";
import axiosService from "src/app/auth/services/axios/axiosService";

const VoyagerGateways = ({ gateways }) => {
  const dispatch = useDispatch();
  const { plan } = useSelector(selectSubscription);
  const access = axiosService.getAccessAs();

  return (
    <>
      <Card className="flex flex-col h-256 shadow">
        <CardHeader
          title={
            <Typography className="text-16 font-medium">
              {gateways.gateway_name}
            </Typography>
          }
          action={<VoyagerGatewaysMenu gateways={gateways} />}
        />
        <CardContent className="flex flex-col flex-auto p-24">
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <CardMedia
              component={"img"}
              sx={{ width: 110 }}
              image={"/assets/images/devices/gateway_bg.png"}
            />

            <Box
              sx={{ display: "flex", flexDirection: "column", marginLeft: 2 }}
            >
              {gateways.place_name && (
                <Typography className="flex items-center space-x-6 text-15">
                  {gateways.place_name}
                </Typography>
              )}
              {gateways.place_address && (
                <Typography className="flex items-center space-x-6 text-14">
                  {gateways.place_address}
                </Typography>
              )}

              {(Boolean(plan?.gateway_status) || access) && (
                <Typography
                  className={`flex items-center space-x-6 text-13 break-words mt-10`}
                >
                  <span
                    className={`flex border-solid border-8 rounded-full ${
                      gateways.isOnline == 1
                        ? "!border-green-500 !bg-green-500"
                        : "!border-red-500 !bg-red-500"
                    } `}
                  />
                  <span className="leading-none">
                    {gateways.isOnline == 1 ? `Online` : `Offline`}
                  </span>
                </Typography>
              )}
            </Box>
          </Box>
        </CardContent>

        <CardActions
          className="items-center justify-between py-16 px-24"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? lighten(theme.palette.background.paper, 0.4)
                : lighten(theme.palette.background.paper, 0.03),
          }}
        >
          <Typography className="flex flex-row items-center justify-center">
            {gateways.timezone}
          </Typography>
          <Typography className="px-16 min-w-128">
            {gateways.deviceId}
          </Typography>
        </CardActions>
      </Card>
      <VoyagerGatewaysLocalization />
    </>
  );
};

export default VoyagerGateways;
