import { amber, blue, green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideMessage,
  selectFuseMessageOptions,
  selectFuseMessageState,
} from "app/store/fuse/messageSlice";
import FuseSvgIcon from "../FuseSvgIcon";

const StyledSnackbar = styled(Snackbar)(({ theme, variant }) => ({
  "& .FuseMessage-content": {
    ...(variant === "success" && {
      backgroundColor: "#43a047",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),

    ...(variant === "error" && {
      backgroundColor: "#d32f2f",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),

    ...(variant === "info" && {
      backgroundColor: "#2196f3",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),

    ...(variant === "warning" && {
      backgroundColor: "#ff9800",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),

    ...((variant === "" || !variant) && {
      backgroundColor: "#000",
      color: "#FFFFFF",
      borderRadius: "25px",
    }),
  },
}));

const variantIcon = {
  success: "check_circle",
  warning: "warning",
  error: "error_outline",
  info: "info",
};

function FuseMessage(props) {
  const dispatch = useDispatch();
  const state = useSelector(selectFuseMessageState);
  const options = useSelector(selectFuseMessageOptions);

  return (
    <StyledSnackbar
      {...options}
      open={state}
      onClose={() => dispatch(hideMessage())}
      className="z-9999999"
      ContentProps={{
        variant: "body2",
        headlineMapping: {
          body1: "div",
          body2: "div",
        },
      }}
    >
      <SnackbarContent
        className="FuseMessage-content"
        message={
          <div className="flex items-center">
            {variantIcon[options.variant] && (
              <FuseSvgIcon color="inherit">
                {variantIcon[options.variant]}
              </FuseSvgIcon>
            )}
            <Typography className="mx-8">{options.message}</Typography>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(hideMessage())}
            size="medium"
          >
            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>,
        ]}
      />
    </StyledSnackbar>
  );
}

export default memo(FuseMessage);
