import { axiosInitial } from "src/app/auth/services/axios/axiosConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchLogos = createAsyncThunk(
  "labelsApp/logos/fetchLogos",
  async (_, { dispatch, getState }) => {
    try {
      const data = await axiosInitial.get("");

      if (data?.status === "error") {
        dispatch(setFavicons([]));
        dispatch(setLogos(null));
        dispatch(setFolderAssets(null));
        return [];
      }

      return data.result;
    } catch (error) {
      dispatch(setFavicons([]));
      dispatch(setLogos(null));
      dispatch(setFolderAssets(null));
    }
  }
);

const logosSlice = createSlice({
  name: "labelsApp/logos",
  initialState: {
    favicon: "",
    logos: null,
    folder: null,
    light: null,
    dark: null,
    page_title: "Loading...",
  },
  reducers: {
    setFavicons: (state, action) => {
      state.favicon = action.payload;
    },
    setLogos: (state, action) => {
      state.logos = action.payload;
    },
    setFolderAssets: (state, action) => {
      state.folder = action.payload;
    },
  },
  extraReducers: {
    [fetchLogos.pending]: (state, action) => {},
    [fetchLogos.fulfilled]: (state, action) => {
      state.favicon = action.payload?.favicon;
      state.logos = action.payload?.logos;
      state.folder = action.payload?.assests_folder;
      state.light = action.payload?.logos.light_theme;
      state.dark = action.payload?.logos.dark_theme;
      state.page_title = action.payload?.page_title;
    },
    [fetchLogos.rejected]: (state, action) => {
      state.favicon = "";
      state.logos = null;
      state.folder = null;
    },
  },
});

export const { setFavicons, setLogos, setFolderAssets } = logosSlice.actions;

export const selectFavicon = ({ labelsApp }) => labelsApp.logos.favicon;
export const selectLogos = ({ labelsApp }) => labelsApp.logos.logos;
export const selectFolder = ({ labelsApp }) => labelsApp.logos.folder;
export const selectLight = ({ labelsApp }) => labelsApp.logos.light;
export const selectDark = ({ labelsApp }) => labelsApp.logos.dark;
export const selectAssets = ({ labelsApp }) => labelsApp.logos;

export default logosSlice.reducer;
