import { createSlice } from "@reduxjs/toolkit";
import NewEndpointsModel, {
  RelatioModel,
} from "../components/model/NewEndpointsModel";
import { RelationModelMerge } from "../components/model/RelationModel";

const drawerSlice = createSlice({
  name: "access_level/drawer",
  initialState: {
    endpointDrawerState: false,
    relationDrawerState: false,
    endpoint: NewEndpointsModel(),
    relation: {},
    index: "",
    board: "",
    permissions: [
      { permission_type: "update", permission: "UPDATE" },
      { permission_type: "insert", permission: "INSERT" },
      { permission_type: "delete", permission: "DELETE" },
    ],
    methods: [
      { method_type: "get", method: "GET" },
      { method_type: "post", method: "POST" },
      { method_type: "delete", method: "DELETE" },
    ],
  },
  reducers: {
    setEmptyEndpoint: (state, action) => {
      state.endpoint = NewEndpointsModel();
      state.endpointDrawerState = true;
    },
    setEndpointDrawerState: (state, action) => {
      state.endpointDrawerState = action.payload;
    },
    setRelationDrawerState: (state, action) => {
      state.relationDrawerState = action.payload;
    },
    setEditEndpoint: (state, action) => {
      state.endpoint = action.payload;
    },
    setEditRelation: (state, action) => {
      state.relation = RelationModelMerge(action.payload);
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setBoard: (state, action) => {
      state.board = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setEmptyEndpoint,
  setEndpointDrawerState,
  setRelationDrawerState,
  setEditEndpoint,
  setEditRelation,
  setIndex,
  setBoard,
} = drawerSlice.actions;

export const selectEndpointDrawerState = ({ access_level }) =>
  access_level.drawer.endpointDrawerState;
export const selectRelationDrawerState = ({ access_level }) =>
  access_level.drawer.relationDrawerState;
export const selectEditEndpoint = ({ access_level }) =>
  access_level.drawer.endpoint;
export const selectEditRelation = ({ access_level }) =>
  access_level.drawer.relation;
export const selectIndex = ({ access_level }) => access_level.drawer.index;
export const selectBoard = ({ access_level }) => access_level.drawer.board;
export const selectPermissions = ({ access_level }) =>
  access_level.drawer.permissions;
export const selectMethods = ({ access_level }) => access_level.drawer.methods;

export default drawerSlice.reducer;
