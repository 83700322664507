import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import { getSubscription } from "src/modules/pages/access_plans/subscription/store/subscriptionSlice";

export const fetchGateways = createAsyncThunk(
  "gateways/cards/fetchGateways",
  async (_, { dispatch, getState }) => {
    try {
      const { user } = getState();
      const { groups } = getState().gateways.menu;

      const res = await axiosConfig.get(
        `/devices/${user.data.user_id}/gateways`
      );

      if (res.status === "errors") return [];

      dispatch(getSubscription());

      return res.result.voyager_wifi;
    } catch (error) {
      return [];
    }
  }
);

const gatewaysSlice = createSlice({
  name: "gateways/cards",
  initialState: {
    gateways: [],
    filterGateways: [],
    status: "",
  },

  reducers: {
    setGateways: (state, action) => {
      state.gateways = action.payload;
    },
    setFilters: (state, action) => {
      const index = action.payload;

      if (index === "all_gateways") {
        state.filterGateways = state.gateways;
      } else {
        state.filterGateways = state.gateways.filter(
          (item) => item.place_group_id == index
        );
      }
    },
    updateGateway: (state, action) => {
      const { index, value } = action.payload;
      state.gateways[index] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGateways.pending, (state) => {
      state.status = "pending";
    }),
      builder.addCase(fetchGateways.fulfilled, (state, action) => {
        state.gateways = action.payload;
        state.filterGateways = action.payload;
        state.status = "success";
      }),
      builder.addCase(fetchGateways.rejected, (state) => {
        state.gateways = [];
        state.filterGateways = [];
        state.status = "reject";
      });
  },
});

export const { setGateways, setFilters, updateGateway } = gatewaysSlice.actions;

export const selectFilterGateways = ({ gateways }) =>
  gateways.cards.filterGateways;
export const selectGateways = ({ gateways }) => gateways.cards.gateways;
export const selectStatus = ({ gateways }) => gateways.cards.status;

export default gatewaysSlice.reducer;
