import MaterialReactTable from "material-react-table";
import {
  Add,
  Close,
  Delete,
  Edit,
  FormatListBulletedOutlined,
  InfoOutlined,
  KeyOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteLabels, getLabels, selectLabels } from "./store/tableSlice";
import {
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  Typography,
  ListItemIcon,
  ListItemText,
  Menu,
  Box,
  Switch,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  selectCurrentLanguageId,
  selectSystemLanguages,
  selectTableLanguage,
} from "../../../store/i18nSlice";
import { t } from "app/store/labels/globalLabels";
import { FormatDate } from "app/shared-components/Functions";
import DrawerLabels from "./components/DrawerLabels";
import { setEdit, setOpen } from "./store/drawerSlice";
import { selectUser } from "app/store/userSlice";
import ConfirmationDialog from "../../components/confirmationDialog/ConfirmationDialog";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import serviceFunctions from "../../components/serviceFunctions/serviceFunctions";

const TableLabels = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { labels, status } = useSelector(selectLabels);
  const systemLangs = useSelector(selectSystemLanguages);
  const lang = useSelector(selectSystemLanguages);
  const langId = useSelector(selectCurrentLanguageId);
  const tableLang = useSelector(selectTableLanguage);

  const [moreMenuEl, setMoreMenuEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowDelete, setRowDelete] = useState(null);

  const handleDeleteRow = async (row) => {
    try {
      setRowDelete(row.original);
      setOpenDelete(true);
    } catch (error) {}
  };

  const handleDelete = () => {
    try {
      dispatch(deleteLabels(rowDelete));
      setOpenDelete(false);
    } catch (error) {}
  };

  const handleMoreMenuClick = async (event) => {
    setMoreMenuEl(event.currentTarget);
  };

  const handleMoreMenuClose = (event) => {
    setMoreMenuEl(null);
  };

  const handleExportTable = async () => {
    console.log(labels, columns);
    return;
    try {
      const res = await serviceFunctions.exportTable(labels, columns);
    } catch (error) {}
  };

  //muiTableHeadCellFilterTextFieldProps

  const columns = useMemo(() => {
    const staticColumns = [
      {
        header: dispatch(t("common.system")),
        accessorFn: (originalRow) =>
          Boolean(originalRow.is_system) ? "true" : "false", //must be strings
        id: "is_system",
        filterVariant: "checkbox",
        Cell: ({ cell }) =>
          cell.getValue() === "true"
            ? dispatch(t("common.system"))
            : dispatch(t("common.module")),
        size: 100,
      },
      {
        accessorKey: "label_group_tag",
        enableClickToCopy: true,
        header: dispatch(t("common.group")),
        size: 150,
        filterFn: "contains",
      },
      {
        accessorKey: "label_tag",
        enableClickToCopy: true,
        header: dispatch(t("common.tag")),
        size: 150,
        filterFn: "contains",
      },
    ];

    const langColumns = systemLangs.map((l) => {
      return {
        accessorKey: l.code,
        header: l.name,
        enableClickToCopy: true,
        filterFn: "contains",
      };
    });

    return [...staticColumns, ...langColumns];
  }, [systemLangs]);

  // console.log(columns);
  // console.log(labels);

  return (
    <>
      <MaterialReactTable
        //COLUMN
        columns={columns}
        enableStickyHeader
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 332px)" } }}
        muiTableHeadCellFilterTextFieldProps={{
          placeholder: "",
        }}
        muiTableHeadCellFilterCheckboxProps={{
          content: "",
          placeholder: "",
          title: dispatch(t("common.system")),
        }}
        // enableColumnFilterModes
        enableColumnOrdering
        enableGrouping
        enablePinning
        enableColumnResizing
        localization={tableLang}
        globalFilterFn="contains"
        //DATA
        data={labels}
        //ROW
        enableRowActions
        renderTopToolbarCustomActions={({ table }) => (
          <Box className={"w-full flex justify-end"}>
            <Tooltip title={dispatch(t("commomn.export"))}>
              <IconButton onClick={handleExportTable}>
                <FuseSvgIcon className="text-48" size={24} color="action">
                  material-outline:cloud_download
                </FuseSvgIcon>
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderRowActionMenuItems={({ row, closeMenu }) => {
          return [
            <MenuItem
              key={1}
              onClick={() => {
                dispatch(setOpen({ open: true, data: row.original }));
                closeMenu();
              }}
            >
              <Edit />
              <Typography className="ml-8">
                {dispatch(t("common.edit"))}
              </Typography>
            </MenuItem>,
            <div key={"info"}>
              <MenuItem
                onClick={handleMoreMenuClick}
                aria-owns={moreMenuEl ? "more-menu-info" : null}
                aria-haspopup="true"
              >
                <ListItemIcon>
                  <InfoOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>{dispatch(t("common.info"))}</ListItemText>
              </MenuItem>
              <Menu
                id="more-menu-info"
                anchorEl={moreMenuEl}
                open={Boolean(moreMenuEl)}
                onClose={handleMoreMenuClose}
                autoFocus={false}
                disableAutoFocus
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {row.original.created && (
                  <MenuItem disabled>
                    <ListItemText>
                      {`${dispatch(t("common.created"))}: ${FormatDate(
                        row.original.created,
                        true
                      )}`}
                    </ListItemText>
                  </MenuItem>
                )}
                {row.original.created_by_name && (
                  <MenuItem disabled>
                    <ListItemText>
                      {`${dispatch(t("common.created"))}: ${
                        row.original.created_by_name
                      }`}
                    </ListItemText>
                  </MenuItem>
                )}
                {row.original.updated && (
                  <MenuItem disabled>
                    <ListItemText>
                      {`${dispatch(t("common.updated"))}: ${FormatDate(
                        row.original.updated,
                        true
                      )}`}
                    </ListItemText>
                  </MenuItem>
                )}
                {row.original.updated_by_name && (
                  <MenuItem disabled>
                    <ListItemText>
                      {`${dispatch(t("common.updated"))}: ${
                        row.original.updated_by_name
                      }`}
                    </ListItemText>
                  </MenuItem>
                )}
              </Menu>
            </div>,
            // Boolean(row.original.is_system) &&
            <MenuItem
              key={2}
              color="error"
              onClick={() => {
                handleDeleteRow(row);
                closeMenu();
              }}
            >
              <Delete />
              <Typography className="ml-8">
                {dispatch(t("common.delete"))}
              </Typography>
            </MenuItem>,
          ];
        }}
        // STATE { INITIAL, ONCHANGE, ERRORS }
        initialState={{ showColumnFilters: false }}
        state={{
          showSkeletons: status == "pending" ? true : false,
        }}
      />

      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle={dispatch(t("common.add"))}
          tooltipOpen
          onClick={() => {
            dispatch(setEdit());
          }}
        />
        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh_table"))}
          tooltipOpen
          onClick={() => {
            dispatch(getLabels());
          }}
        />
      </SpeedDial>
      <DrawerLabels />
      <ConfirmationDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onSubmit={handleDelete}
        title={"common.delete"}
      />
    </>
  );
};

export default TableLabels;
