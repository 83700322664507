import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class BlocksService {
  addBlock = (template, data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/posts/templates/${template.post_template_id}/blocks`, data)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  updateBlock = (template, block, data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/posts/templates/${template.post_template_id}/blocks/${block.post_template_id}`,
          data
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  deleteBlock = (template, block) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(
          `/posts/templates/${template.post_template_id}/blocks/${block.post_template_id}`
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  reorderBlocks = (template, items) => {
    const data = new FormData();

    for (let index = 0; index < items.length; index++)
      data.append("ids[]", items[index].post_template_id);

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/posts/templates/${template.post_template_id}/reorder`, data)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };
}

const blocksService = new BlocksService();
export default blocksService;
