import FusePageSimple from "@fuse/core/FusePageSimple";
import { motion } from "framer-motion";
import _ from "@lodash";
import TableLabels from "./TableLabels";
import TableLabelsAppHeader from "./TableLabelsAppHeader";
import { useLayoutEffect, useMemo } from "react";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import { useDispatch } from "react-redux";
import { getLabels } from "./store/tableSlice";
import { useEffect } from "react";

const TableLabelsApp = (props) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(getLabels());
  }, [dispatch]);

  return (
    <>
      <FusePageSimple
        header={<TableLabelsAppHeader />}
        content={
          <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
            {useMemo(() => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full mt-32">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <TableLabels />
                    </motion.div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        }
      />
    </>
  );
};

export default withReducer("label", reducer)(TableLabelsApp);
