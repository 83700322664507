import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Box from "@mui/system/Box";
import _ from "@lodash";
import { getContact, selectContact } from "../store/contactSlice";

const ContactView = () => {
  const contact = useSelector(selectContact);
  const routeParams = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getContact(routeParams.id));
  }, [dispatch, routeParams]);

  if (!contact) {
    return <FuseLoading />;
  }

  return (
    <>
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.paper",
        }}
      >
        {contact.background && (
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={contact.background}
            alt="user background"
          />
        )}
      </Box>
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
        <div className="w-full max-w-3xl">
          <div className="flex flex-auto items-end -mt-64">
            <Avatar
              sx={{
                borderWidth: 4,
                borderStyle: "solid",
                borderColor: "background.default",
                backgroundColor: "background.paper",
                color: "text.secondary",
              }}
              className="w-128 h-128 text-64 font-bold"
              src={contact.avatar}
              alt={contact.name?.toString()}
            >
              {contact.name?.toString().charAt(0)}
            </Avatar>
            {/* <div className="flex items-center ml-auto mb-4">
              <Button variant="contained" color="secondary" component={NavLinkAdapter} to="edit">
                <FuseSvgIcon size={20}>heroicons-outline:pencil-alt</FuseSvgIcon>
                <span className="mx-8">Edit</span>
              </Button>
            </div> */}
          </div>

          <Typography className="mt-12 text-4xl font-bold truncate">
            {contact.name}
          </Typography>

          <div className="flex flex-wrap items-center mt-8">
            {contact.tags?.map((id) => (
              <Chip
                key={id}
                label={_.find(tags, { id }).title}
                className="mr-12 mb-12"
                size="small"
              />
            ))}
          </div>

          <Divider className="mt-16 mb-24" />

          <div className="flex flex-col space-y-32">
            {contact.email.length && (
              <div className="flex">
                <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
                <div className="min-w-0 ml-24 space-y-4">
                  <div
                    className="flex items-center leading-6"
                    key={contact.email}
                  >
                    <a
                      className="hover:underline text-primary-500"
                      href={`mailto: ${contact.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {contact.email}
                    </a>
                  </div>
                </div>
              </div>
            )}

            {contact.notes && (
              <div className="flex">
                <FuseSvgIcon>heroicons-outline:menu-alt-2</FuseSvgIcon>
                <div
                  className="max-w-none ml-24 prose dark:prose-invert"
                  dangerouslySetInnerHTML={{ __html: contact.notes }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactView;
