import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "@lodash";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { t } from "app/store/labels/globalLabels";
import { removeEntity, updateEntity } from "../../store/entitySlice";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter/NavLinkAdapter";
import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";

const EntityDataInput = ({ pond, entity }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();

  const [open, setOpen] = useState(false);

  const defaultValues = {
    name: "",
    vat: "",
    notes: "",
  };

  const schema = yup.object().shape({
    name: yup.string().required(),
    notes: yup.string(),
  });

  const { control, handleSubmit, formState, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setValue("name", entity.name),
      {
        shouldDirty: true,
        shouldValidate: true,
      };
    setValue("vat", entity.vat),
      {
        shouldDirty: true,
        shouldValidate: true,
      };
    setValue("notes", entity.notes),
      {
        shouldDirty: true,
        shouldValidate: true,
      };
  }, [entity, setValue]);

  const onSubmit = (obj) => {
    const data = {
      entity_id: entity.entity_id,
      name: obj.name,
      vat: obj.vat,
      notes: obj.notes,
      picture_src: "",
      picture: pond[0] || "",
    };

    // props.onSubmit(data);

    dispatch(updateEntity(data)).then(({ payload }) => {
      navigate(`/system/entities/${routeParams.type}/${payload.id}`);
    });
  };

  const handleSubmitDialog = () => {
    setOpen(false);
    dispatch(removeEntity(entity.entity_id)).then(() => {
      navigate(`/system/entities/${routeParams.type}`);
    });
  };

  const handleCancelDialog = () => {
    setOpen(false);
  };

  const handleRemoveEntity = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="relative flex flex-1 flex-col h-full px-24 sm:px-48">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.name"))}
              placeholder={dispatch(t("common.name"))}
              id="name"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="vat"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              value={field.value || ""}
              label={dispatch(t("common.vat"))}
              placeholder={dispatch(t("common.vat"))}
              id="vat"
              error={!!errors.vat}
              helperText={errors?.vat?.message}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-outline:receipt-tax
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="notes"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.notes"))}
              placeholder={dispatch(t("common.notes"))}
              id="notes"
              error={!!errors.notes}
              helperText={errors?.notes?.message}
              variant="outlined"
              fullWidth
              multiline
              minRows={5}
              maxRows={10}
              InputProps={{
                className: "max-h-min h-min items-start",
                startAdornment: (
                  <InputAdornment className="mt-16" position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:menu-alt-2
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>

      <Box
        className="flex flex-1 items-center mt-224 pb-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
        sx={{ height: "82px", backgroundColor: "background.paper" }}
      >
        {routeParams.id !== "new" && (
          <Button color="error" onClick={handleRemoveEntity}>
            {dispatch(t("common.delete"))}
          </Button>
        )}
        <Button component={NavLinkAdapter} to={-1}>
          {dispatch(t("common.cancel"))}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </Button>
      </Box>
      <ConfirmationDialog
        open={open}
        onClose={handleCancelDialog}
        onSubmit={handleSubmitDialog}
      />
    </>
  );
};

const ConfirmationDialog = ({ open, onClose, onSubmit }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {dispatch(t("common.delete"))}
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{dispatch(t("common.confirm_action"))}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onSubmit}>
          {dispatch(t("common.yes"))}
        </Button>
        <Button autoFocus onClick={onClose}>
          {dispatch(t("common.cancel"))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EntityDataInput;
