import FuseUtils from "@fuse/utils/FuseUtils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import {
  changeLanguage,
  createNavigation,
  resetNavigation,
} from "./navigationGroupsSlice";
import { changeWorkspace } from "./menuSlice";

export const fetchGroups = createAsyncThunk(
  "menu/groups/fetchGroups",
  async (_, { dispatch, getState }) => {
    dispatch(resetNavigation());
    dispatch(setActiveGroup(null));
    try {
      const res = await axiosConfig.get(`/menu`);

      if (res.status === "error") return [];

      if (res.result?.length > 0) dispatch(createNavigation(res.result));

      dispatch(changeLanguage());

      return res.result;
    } catch (error) {
      return [];
    }
  }
);

export const appendNavigationItem =
  (item, parentId) => (dispatch, getState) => {
    const { groups } = getState().menu.groups;

    return dispatch(setGroups(FuseUtils.appendNavItem(groups, item, parentId)));
  };

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const { groups } = getState().menu.groups;

  return dispatch(setGroups(FuseUtils.updateNavItem(groups, id, item)));
};

export const filterGroup = (text) => (dispatch, getState) => {
  const { groups } = getState().menu.groups;

  dispatch(resetNavigation());

  if (text.length === 0) return dispatch(createNavigation(groups));

  const filteredGroups = FuseUtils.filterArrayByString([...groups], text);

  dispatch(setFilteredGroups(filteredGroups));
  return dispatch(createNavigation(filteredGroups));
};

const groupsSlice = createSlice({
  name: "menu/groups",
  initialState: {
    groups: [],
    groupsFiltered: [],
    groupInfo: {},
    groupActive: null,
    status: "idle",
  },
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setActiveGroup: (state, action) => {
      state.groupActive = action.payload;
    },
    setFilteredGroups: (state, action) => {
      state.groupsFiltered = action.payload;
    },
    resetGroups: (state, action) => {
      state.groupsFiltered = state.groups;
    },
    setGroupInfo: (state, action) => {
      state.groupInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.groupsFiltered = action.payload;
        state.status = "fulfilled";
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.groups = [];
        state.groupsFiltered = [];
        state.status = "rejected";
      });
  },
});

export const {
  setGroups,
  setActiveGroup,
  setFilteredGroups,
  resetGroups,
  setGroupInfo,
} = groupsSlice.actions;

export const selectGroups = ({ menu }) => menu.groups;

export default groupsSlice.reducer;
