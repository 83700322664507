import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class Smtp_serverService {
  addSmtp_server = (obj) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post("/smtp_servers/", obj)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  deleteSmtp_server = (id) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/smtp_servers/${id}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  editSmtp_server = (id, obj) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/smtp_servers/${id}`, obj)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  executeSmtp_server = (id) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .get(`/smtp_servers/${id}/test`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const smtp_serverService = new Smtp_serverService();
export default smtp_serverService;
