import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    open: false,
  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const selectProfile = ({ profile }) => profile;

export const { setOpen } = profileSlice.actions;

export default profileSlice.reducer;
