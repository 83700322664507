import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { t } from "app/store/labels/globalLabels";

const SelectItem = () => {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col flex-1 items-center justify-center p-24">
      <FuseSvgIcon className="icon-size-128 mb-16" color="disabled" size={24}>
        material-outline:auto_awesome_mosaic
      </FuseSvgIcon>
      <Typography
        className="mt-16 text-2xl font-semibold tracking-tight"
        color="text.secondary"
      >
        {dispatch(
          t("common.select_item", {
            type: dispatch(t("common.item")),
          })
        )}
      </Typography>
    </div>
  );
};

export default SelectItem;
