import _ from "@lodash";

const SectionModel = (data) =>
  _.defaults(data || {}, {
    post_section_id: data.last_id,
  });

export const SectionNewModel = (data) =>
  _.defaults(data || {}, {
    name: "",
    name_tag: "",
    name_lang: "",
    title: "",
    is_group: 0,
    is_public: 0,
    section_order: 0,
    post_template_id: null,
  });

export const SectionNaviModel = (data) =>
  _.defaults(data || {}, {
    is_group: 0,
    post_template_id: null,
    id: `${data.last_id}`,
    post_section_id: data.last_id,
    title: data.name || `[${data.name_tag}]`,
    type: "item",
    hasActions: true,
    url: `/system/sections/${data.last_id}`,
  });

export const SectionNewNaviModel = (data) =>
  _.mergeWith(
    {
      ...data,
      name_lang: "",
      id: `${data.post_section_id}`,
      title: data.name || `[${data.name_tag}]`,
      name: "",
      type: "item",
      hasActions: true,
      url:
        data.subitems && data.subitems.length > 0
          ? null
          : `/system/sections/${data.post_section_id}`,
    },
    data,
    (objValue, srcValue, key) => {
      if (key == "name_lang") return JSON.parse(srcValue);

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export const TemplateNaviModel = (data) =>
  _.mergeWith(
    {
      ...data,
      name_lang: "",
      id: `temp_${data.post_template_id}`,
      title: data.name || `[${data.name_tag}]`,
      name: "",
      type: "group",
      hasActions: true,
      children: [],
    },
    data,
    (objValue, srcValue, key) => {
      if (key == "name_lang") return JSON.parse(srcValue);

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export default SectionModel;
