import { useDispatch, useSelector } from "react-redux";
import { t, selectGlobalLabels } from "app/store/labels/globalLabels";
import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";

import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import "./FileUpdate.css";

registerPlugin(
  FilePondPluginFilePoster,
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit,
  FilePondPluginFileValidateSize
);

const FileUpdate = ({
  refPond = null,
  files,
  allowDrop = true,
  allowBrowse = true,
  allowPaste = true,
  allowMultiple = false,
  allowReplace = true,
  allowRevert = true,
  allowRemove = true,
  allowProcess = true,
  allowReorder = false,
  allowFileEncode = false,
  credits = false,
  server = null,
  instantUpload = false,
  allowFileSizeValidation = true,
  maxFileSize,
  allowFileTypeValidation,
  acceptedFileTypes,
  allowImageCrop = true,
  imageCropAspectRatio = null,
  allowImageExifOrientation = true,
  allowImagePreview = true,
  imagePreviewMaxHeight,
  allowImageResize = true,
  imagePreviewHeight = null,
  imageResizeTargetWidth = null,
  imageResizeTargetHeight = null,
  allowImageValidateSize = true,
  maxFiles,
  name,
  stylePanelLayout = null,
  stylePanelAspectRatio = null,
  styleLoadIndicatorPosition = "",
  styleButtonRemoveItemPosition = "",
  onupdatefiles = null,
  onaddfile = null,
  onremovefile = null,
  //labels
}) => {
  // const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const label = useSelector(selectGlobalLabels);
  const defaulLang = {
    labelIdle: dispatch(t("common.file_dragndrop")),
    labelInvalidField: dispatch(t("common.invalid_field")),
    labelFileWaitingForSize: dispatch(t("common.file_waiting_for_size")),
    labelFileSizeNotAvailable: dispatch(t("common.file_size_not_available")), //Size not available
    labelFileLoading: dispatch(t("common.loading")), //Loading
    labelFileLoadError: dispatch(t("common.operation_error")), //Error during load
    labelFileProcessing: dispatch(t("common.uploading")), //Uploading
    labelFileProcessingComplete: dispatch(t("common.upload_complete")), //Upload complete
    labelFileProcessingAborted: dispatch(t("common.canceled")), //Upload cancelled
    labelFileProcessingError: dispatch(t("common.operation_error")), //Error during upload
    labelFileProcessingRevertError: dispatch(t("common.operation_error")), //Error during revert
    labelFileRemoveError: dispatch(t("common.operation_error")), //Error during remove
    labelTapToCancel: dispatch(t("common.cancel")), //tap to cancel
    labelTapToRetry: dispatch(t("common.retry")), //tap to retry
    labelTapToUndo: dispatch(t("common.undo")), //tap to undo
    labelButtonRemoveItem: dispatch(t("common.remove")), // Remove
    labelButtonAbortItemLoad: dispatch(t("common.abort")), // Abort
    labelButtonRetryItemLoad: dispatch(t("common.retry")), // Retry
    labelButtonAbortItemProcessing: dispatch(t("common.cancel")), // Cancel
    labelButtonUndoItemProcessing: dispatch(t("common.undo")), // Undo
    labelButtonRetryItemProcessing: dispatch(t("common.retry")), // Retry
    labelButtonProcessItem: dispatch(t("common.upload")), // Upload
    labelMaxFileSizeExceeded: dispatch(t("common.file_too_big")), // File is too large
    labelMaxFileSize: dispatch(t("common.maximum_file_size_is")), // Maximum file size is
    labelMaxTotalFileSizeExceeded: dispatch(
      t("common.maximum_total_size_exceeded")
    ), // Maximum total size exceeded
    labelFileTypeNotAllowed: dispatch(t("common.file_of_invalid_type")), // File of invalid type
    imageValidateSizeLabelFormatError: dispatch(
      t("common.image_type_not_supported")
    ), //Image type not supported
    imageValidateSizeLabelImageSizeTooSmall: dispatch(
      t("common.file_too_small")
    ), //Image is too small
    imageValidateSizeLabelImageSizeTooBig: dispatch(t("common.file_too_big")), //Image is too big
  };
  return (
    <FilePond
      {...defaulLang}
      ref={refPond}
      name={name}
      files={files}
      allowDrop={allowDrop}
      allowBrowse={allowBrowse}
      allowPaste={allowPaste}
      allowMultiple={allowMultiple}
      allowReplace={allowReplace}
      allowRevert={allowRevert}
      allowRemove={allowRemove}
      allowProcess={allowProcess}
      allowReorder={allowReorder}
      allowFileEncode={allowFileEncode}
      credits={credits}
      server={server}
      instantUpload={instantUpload}
      allowFileSizeValidation={allowFileSizeValidation}
      maxFileSize={maxFileSize}
      allowFileTypeValidation={allowFileTypeValidation}
      acceptedFileTypes={acceptedFileTypes}
      allowImageCrop={allowImageCrop}
      imageCropAspectRatio={imageCropAspectRatio}
      allowImageExifOrientation={allowImageExifOrientation}
      allowImagePreview={allowImagePreview}
      imagePreviewMaxHeight={imagePreviewMaxHeight}
      allowImageResize={allowImageResize}
      imageResizeTargetWidth={imageResizeTargetWidth}
      imageResizeTargetHeight={imageResizeTargetHeight}
      imagePreviewHeight={imagePreviewHeight}
      allowImageValidateSize={allowImageValidateSize}
      stylePanelLayout={stylePanelLayout}
      stylePanelAspectRatio={stylePanelAspectRatio}
      maxFiles={maxFiles}
      styleLoadIndicatorPosition={styleLoadIndicatorPosition}
      styleButtonRemoveItemPosition={styleButtonRemoveItemPosition}
      onupdatefiles={onupdatefiles}
      onaddfile={onaddfile}
      onremovefile={onremovefile}
    />
  );
};

export default FileUpdate;
