import NotificationsApp from "./NotificationsApp";
import { authRoles } from "src/app/auth";

const UserNotificationsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: "user/notifications",
      element: <NotificationsApp />,
    },
  ],
};

export default UserNotificationsConfig;
