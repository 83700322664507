import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import pushNotificationsService from "../service/PushNotificationsService";
import { action } from "mobx";
import { FormatDateSQL } from "app/shared-components/Functions";
import { PushMerge } from "../model/PushNotificationsModel";
import { ConstructionOutlined } from "@mui/icons-material";

export const getPushNotifications = createAsyncThunk(
  "push_notifications/list/getPushNotifications",
  async (_, { dispatch, getState }) => {
    try {
      const { language } = getState().i18n;

      const res = await axiosConfig.get(`/pushnotif/templates`);

      dispatch(getUserList());

      if (res.status === "error") throw new Error(res);

      const data = res.result.map((item) => PushMerge(item, language));

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addPushNotification = createAsyncThunk(
  "push_notification/list/addPushNotification",
  async (data, { dispatch, getState }) => {
    const { language } = getState().i18n;

    const formatData = {
      external_link: data.external,
      link_to: data.external == true ? data.url : data.screen,
    };

    const obj = {
      ...data,
      data: formatData,
      send_date_time:
        data.send_date_time === ""
          ? data.send_date_time
          : FormatDateSQL(data.send_date_time, true),
    };

    try {
      const res = await pushNotificationsService.addPushNotification(obj);

      // const _obj = {
      //   ...obj,
      //   message_lang: obj.message_lang.all
      //     ? obj.message_lang.all
      //     : obj.message_lang[language],
      //   title_lang: obj.title_lang.all
      //     ? obj.title_lang.all
      //     : obj.title_lang[language],
      //   pushnotif_template_id: res.result.last_id,
      //   image: obj.image ? obj.image.name : null,
      //   image_src: `https://api.antf.pt/api/live/box/pushnotif/${
      //     obj.image ? obj.image.name : null
      //   }`,
      // };

      dispatch(getUserPushLiist(res.result.last_id));

      return obj;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const deletePushNotification = createAsyncThunk(
  "push_notification/list/updatePushNotification",
  async (data, { dispatch, getState }) => {
    try {
      const res = await pushNotificationsService.removePushNotification(data);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const changeSwitchState = createAsyncThunk(
  "push_notifications/list/changeSwitchState",
  async (data, { dispatch, getState }) => {
    try {
      const res = await pushNotificationsService.editPushNotification(data);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getUserList = createAsyncThunk(
  "push_notifications/list/getUserList",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig(`/users`);

      if (res.status === "error") throw new Error(res);

      return res.result;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getScreens = createAsyncThunk(
  "push_notifications/list/getScreens",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig(`/menu/2?items=1&formated=1&workspaces=1`);

      if (res.status === "error") throw new Error(res);

      return res.result.items;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getUserPushLiist = createAsyncThunk(
  "push_notifications/list/getUserPushLiist",
  async (id, { dispatch, getState }) => {
    try {
      const res = await axiosConfig(`/pushnotif/templates/${id}`);

      if (res.status === "error") throw new Error(res);

      return res.result.users_list;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const pushNotificationsSlice = createSlice({
  name: "push_notifications/list",
  initialState: {
    push_notifications: [],
    users: [],
    screens: [],
    userPushList: [],
    status: "pending",
  },
  reducers: {
    setPushNotifications: (state, action) => {
      state.push_notifications = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPushNotifications.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getPushNotifications.fulfilled, (state, action) => {
        const obj = action.payload;

        state.push_notifications = action.payload;
        state.status = "fulfilled";
      })
      .addCase(getPushNotifications.rejected, (state) => {
        state.push_notifications = [];
        state.status = "rejected";
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(getScreens.fulfilled, (state, action) => {
        state.screens = action.payload;
      })
      .addCase(addPushNotification.fulfilled, (state, action) => {
        // state.push_notifications.push(action.payload);
      })
      .addCase(deletePushNotification.fulfilled, (state, action) => {
        state.push_notifications.splice(action.payload.index, 1);
      })
      .addCase(changeSwitchState.fulfilled, (state, action) => {
        state.push_notifications[action.payload.index] = action.payload;
      })
      .addCase(getUserPushLiist.fulfilled, (state, action) => {
        state.userPushList = action.payload;
      });
  },
});

export const { setPushNotifications, setStatus, setUserPushList } =
  pushNotificationsSlice.actions;

export const selectPushNotifications = ({ push_notifications }) =>
  push_notifications.list.push_notifications;
export const selectStatus = ({ push_notifications }) =>
  push_notifications.list.status;
export const selectPush = ({ push_notifications }) => push_notifications.list;

export default pushNotificationsSlice.reducer;
