import { lazy } from "react";

const SubscriptionApp = lazy(() => import("./SubscriptionApp"));

const Subscription = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/access_plans/subscription",
      element: <SubscriptionApp />,
    },
  ],
};

export default Subscription;
