import { lazy } from "react";

const PacksApp = lazy(() => import("./PacksApp"));

const PacksConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "access_plans/packs",
      element: <PacksApp />,
    },
  ],
};

export default PacksConfig;
