import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import _ from "@lodash";
import FuseLoading from "@fuse/core/FuseLoading";
import {
  addItem,
  getSocialMedia,
  removeItem,
  selectSocial,
  updateItem,
  updateSocial,
} from "../store/socialSlice";
import { useSelector } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import icons from "../../menu/components/assets/Icons";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import Selector from "./socialMedia/Selector";

const SocialMedia = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, status } = useSelector(selectSocial);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getSocialMedia());
  }, [dispatch]);

  const schema = yup.object().shape({});

  const { control, handleSubmit, watch, reset, formState, getValues } = useForm(
    {
      mode: "onChange",
      resolver: yupResolver(schema),
    }
  );

  const { dirtyFields, isValid, errors } = formState;

  const form = watch();

  useEffect(() => {
    reset({
      social_media: data,
    });
  }, [reset, data]);

  const handleAddItem = () => {
    dispatch(addItem());
  };

  const onSubmit = async (data) => {
    try {
      dispatch(updateSocial(data))
        .then(({ payload }) => {
          if (payload) navigate(`/system/configurations`);
        })
        .catch((err) => {});
    } catch (error) {}
  };

  if (_.isEmpty(form) || status !== "fulfilled") return <FuseLoading />;

  return (
    <>
      <Box className="relative w-full h-80 sm:h-80 px-32 sm:px-48 flex items-center">
        <Typography className="font-bold text-xl">
          {dispatch(t("common.social_media_network"))}
        </Typography>
      </Box>
      <div className="relative flex flex-col flex-auto items-center mb-32 p-24 pt-0 sm:p-48 sm:pt-0">
        <Controller
          control={control}
          name="social_media"
          render={({ field }) => <Selector className="mt-32" {...field} />}
        />
      </div>
      <Box
        className="fixed bottom-0 w-full py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t z-999"
        sx={{
          backgroundColor: "background.default",
        }}
      >
        <Button
          className="ml-auto"
          component={NavLinkAdapter}
          to={`/system/configurations`}
        >
          {dispatch(t("common.cancel"))}
        </Button>
        <LoadingButton
          className="ml-8"
          variant="contained"
          color="secondary"
          loading={isLoading}
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </LoadingButton>
      </Box>
    </>
  );
};

export default SocialMedia;
