import { useDispatch } from "react-redux";
import { useState } from "react";
import { Input } from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

const TablePropertiesSidebar = (/*{navigation}*/) => {
  const dispatch = useDispatch();
  const [text, setText] = useState();

  const handleChange = (e) => {
    setText(e.target.value);
    // dispatch(filterAccess_logs(e.target.value));
  };

  return (
    <div className="px-12 py-24 h-full">
      <div className="mt-20">
        <div className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-full shadow-none">
          <FuseSvgIcon color="action" size={20}>
            heroicons-solid:search
          </FuseSvgIcon>

          <Input
            placeholder={dispatch(t("common.search"))}
            className="flex flex-1 px-8"
            disableUnderline
            fullWidth
            value={text}
            inputProps={{
              "aria-label": "Search",
            }}
            onChange={handleChange}
          />
        </div>
        {/* <FuseNavigation
      navigation={navigation}
      className="px-0"
      hasActions={{
        active: true,
      }}
    /> */}
      </div>
    </div>
  );
};

export default TablePropertiesSidebar;
