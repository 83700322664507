import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import AddressSelector from "../address-selector/AddressSelector";

const EntityAddressInput = (props) => {
  const entity = props.entity;

  const { control, reset } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    reset({ ...entity });
  }, [entity, reset]);

  return (
    <>
      <Controller
        control={control}
        name="addresses"
        render={({ field }) => <AddressSelector {...field} />}
      />
    </>
  );
};

export default EntityAddressInput;
