import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import _ from "lodash";
import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import FilePondPluginGetFile from "filepond-plugin-get-file";

import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import clsx from "clsx";
import "./css/FilePond.css";
import "filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css";
import { useDispatch } from "react-redux";
import { t } from "app/store/labels/globalLabels";

registerPlugin(
  FilePondPluginFilePoster,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginGetFile
);

const FileManager = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { name, value, className, placeholder, onChange } = props;
  const [files, setFiles] = useState([]);

  const filePondRef = useRef(null);

  return (
    <div
      className={clsx(
        "w-full flex flex-col items-center justify-center",
        className
      )}
      ref={ref}
    >
      <div className="w-full justify-start">
        <Typography className="text-md">{placeholder}</Typography>
      </div>
      <div className={clsx("w-full", "mt-10")}>
        <FilePond
          ref={filePondRef}
          // files={files}
          files={value ? [value] : []}
          allowProcess={false}
          instantUpload={false}
          maxFileSize={`30MB`}
          credits={false}
          onaddfile={(e, file) => {
            // setFiles([file]);
            props.onChange(file.file);
          }}
          onremovefile={(e, file) => {
            // setFiles([]);
            props.onChange(null);
          }}
          styleLoadIndicatorPosition={"right"}
          styleButtonRemoveItemPosition={"right"}
          styleButtonProcessItemPosition={"right"}
          styleProgressIndicatorPosition={"right"}
          labelIdle={dispatch(t("common.file_dragndrop"))}
          labelInvalidField={dispatch(t("common.invalid_field"))}
          labelFileWaitingForSize={dispatch(t("common.file_waiting_for_size"))}
          labelFileSizeNotAvailable={dispatch(
            t("common.file_size_not_available")
          )}
          labelFileLoading={dispatch(t("common.loading"))}
          labelFileLoadError={dispatch(t("common.operation_error"))}
          labelFileProcessing={dispatch(t("common.uploading"))}
          labelFileProcessingComplete={dispatch(t("common.upload_complete"))}
          labelFileProcessingAborted={dispatch(t("common.canceled"))}
          labelFileProcessingError={dispatch(t("common.operation_error"))}
          labelFileProcessingRevertError={dispatch(t("common.operation_error"))}
          labelFileRemoveError={dispatch(t("common.operation_error"))}
          labelTapToCancel={dispatch(t("common.cancel"))}
          labelTapToRetry={dispatch(t("common.retry"))}
          labelTapToUndo={dispatch(t("common.undo"))}
          labelButtonRemoveItem={dispatch(t("common.remove"))}
          labelButtonAbortItemLoad={dispatch(t("common.abort"))}
          labelButtonRetryItemLoad={dispatch(t("common.retry"))}
          labelButtonAbortItemProcessing={dispatch(t("common.cancel"))}
          labelButtonUndoItemProcessing={dispatch(t("common.undo"))}
          labelButtonRetryItemProcessing={dispatch(t("common.retry"))}
          labelButtonProcessItem={dispatch(t("common.upload"))}
          labelMaxFileSizeExceeded={dispatch(t("common.file_too_big"))}
          labelMaxFileSize={dispatch(t("common.maximum_file_size_is"))}
          labelMaxTotalFileSizeExceeded={dispatch(
            t("common.maximum_total_size_exceeded")
          )}
          labelFileTypeNotAllowed={dispatch(t("common.file_of_invalid_type"))}
          imageValidateSizeLabelFormatError={dispatch(
            t("common.image_type_not_supported")
          )}
          imageValidateSizeLabelImageSizeTooSmall={dispatch(
            t("common.file_too_small")
          )}
          imageValidateSizeLabelImageSizeTooBig={dispatch(
            t("common.file_too_big")
          )}
        />
      </div>
    </div>
  );
});

export default FileManager;
