import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosService from "src/app/auth/services/axios/axiosService";
import PageComplement from "app/shared-components/PageComplement";

import { useContext } from "react";
import { UserContext } from "../../..//context/UserContext";
import LanguageSwitcher from "app/theme-layouts/shared-components/LanguageSwitcher";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";
import { useSnackbar } from "notistack";

import {
  selectDark,
  selectLight,
  selectLogos,
} from "app/store/labels/logosSlice";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { IconButton, Typography } from "@mui/material";
import { changeTheme, selectMainTheme } from "app/store/fuse/settingsSlice";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { selectApi } from "app/store/apiConfigSlice";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";

function SignInPage() {
  const navigate = useNavigate();
  const { setUserEmail, setUserPassword } = useContext(UserContext);
  const labels = useSelector(selectGlobalLabels);
  const d = useDispatch();
  const [disable, setDisable] = useState(false);
  const logos = useSelector(selectLight);
  const logosDark = useSelector(selectDark);
  const theme = axiosService.getTheme();
  const lang = useSelector(selectCurrentLanguageId);
  const mainTheme = useSelector(selectMainTheme);

  const { signup } = useSelector(selectApi);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(d(t("SYSTEM.signin_fields_email_helper_info")))
      .required(d(t("SYSTEM.signin_fields_email_helper"))),
    password: yup
      .string()
      .required(d(t("SYSTEM.signin_fields_password_helper"))),
  });
  const defaultValues = {
    email: "",
    password: "",
    token: "",
  };
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setDisable(true);

    axiosService
      .validateLogin(data.email, data.password, lang)
      .then((res) => {
        if (res?.result?.signin_token) {
          setUserEmail(data.email);
          setUserPassword(data.password);
          navigate("/secretcode");
        }

        setDisable(false);
      })
      .catch((err) => {
        setDisable(false);
      });
  };

  const handleTheme = (value) => {
    axiosService.setTheme(value);
    d(changeTheme(value));
  };

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="absolute top-0 left-0">
          {mainTheme.palette.mode === "light" ? (
            <IconButton onClick={() => handleTheme("defaultDark")}>
              <DarkModeOutlined />
            </IconButton>
          ) : (
            <IconButton onClick={() => handleTheme("default")}>
              <LightModeOutlined />
            </IconButton>
          )}
        </div>
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <div className="flex w-full items-center justify-center">
            <img
              className="w-[70%]"
              src={`${process.env.REACT_APP_API_ASSETS}/${
                theme === "defaultDark"
                  ? logosDark?.horizontal_logo
                  : logos?.horizontal_logo
              }`}
              alt=""
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
            <p className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
              {d(t("SYSTEM.signin_title_signin"))}
            </p>
            <div className="mt-32">
              <LanguageSwitcher />
            </div>
          </div>
          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label={d(t("SYSTEM.signin_fields_email_label"))}
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label={d(t("SYSTEM.signin_fields_password_label"))}
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
              {signup ? (
                <Button
                variant="text"
                component={NavLinkAdapter}
                className="text-md font-medium"
                style={{
                  textDecoration: "none",
                }}
                to="/sign-up"
              >
                {d(t("SYSTEM.signin_link_signup"))}
              </Button>
              ) : (
                <div></div>
              )}

              <Button
                variant="text"
                component={NavLinkAdapter}
                className="text-md font-medium"
                style={{
                  textDecoration: "none",
                }}
                to="/forgot-password"
              >
                {d(t("SYSTEM.signin_link_forgot_password"))}
              </Button>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-16"
              aria-label="Sign in"
              disabled={_.isEmpty(dirtyFields) || !isValid || disable}
              type="submit"
              size="large"
            >
              {d(t("SYSTEM.signin_buttons_signin"))}
            </Button>
          </form>
          <div className="mt-10">
            <Typography className="text-sm">
              Powered and Design by{" "}
              <a target="_blank" href="https://codemaker.pt">
                Codemaker.pt
              </a>
            </Typography>
            <Typography className="text-sm">
              APP Version {parseFloat(process.env.REACT_APP_VERSION)}
            </Typography>
          </div>
        </div>
      </Paper>

      <PageComplement />
    </div>
  );
}

export default SignInPage;
