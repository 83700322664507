import FuseLoading from "@fuse/core/FuseLoading";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import _ from "@lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import {
  getSecurity,
  selectSecurity,
  updateSecurity,
} from "../store/securitySlice";
import { useNavigate } from "react-router-dom";

const Security = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { apiConfig, status } = useSelector(selectSecurity);

  useEffect(() => {
    dispatch(getSecurity());
  }, [dispatch]);

  const schema = yup.object().shape({
    user: yup.object().shape({
      signin_token_required: yup.number().notRequired().nullable(),
      can_signup: yup.number().notRequired().nullable(),
    }),
    API_under_maintenance: yup.number().notRequired().nullable(),
  });

  const { control, handleSubmit, reset, watch, setValue, formState, trigger } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
    });

  const { dirtyFields, isValid, errors } = formState;

  const form = watch();

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      dispatch(updateSecurity(data)).then(({ payload }) => {
        if (payload) trigger();
      });
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    reset({ ...apiConfig });
  }, [reset, apiConfig]);

  if (_.isEmpty(form) || status !== "fulfilled") return <FuseLoading />;

  return (
    <>
      <Box className="relative w-full h-80 sm:h-80 px-32 sm:px-48 flex items-center">
        <Typography className="font-bold text-xl">
          {dispatch(t("common.security"))}
        </Typography>
      </Box>
      <div className="relative flex flex-col flex-auto items-start mb-32 p-24 pt-0 sm:p-48 sm:pt-0">
        <Controller
          control={control}
          name="user.signin_token_required"
          render={({ field: { value, onChange, ...field } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  name="signin_token_required"
                  checked={Boolean(value)}
                  onChange={(e) => {
                    onChange(e.target.checked ? 1 : 0);
                  }}
                />
              }
              label={dispatch(t("common.signin_token_required"))}
            />
          )}
        />
        <Typography color={"text.secondary"} className="text-md">
          {dispatch(t("common.signin_token_description"))}
        </Typography>

        <Controller
          control={control}
          name="user.can_signup"
          render={({ field: { value, onChange, ...field } }) => (
            <FormControlLabel
              className="mt-32"
              control={
                <Checkbox
                  name="can_signup"
                  checked={Boolean(value)}
                  onChange={(e) => {
                    onChange(e.target.checked ? 1 : 0);
                  }}
                />
              }
              label={dispatch(t("common.can_signup"))}
            />
          )}
        />
        <Typography color={"text.secondary"} className="text-md">
          {dispatch(t("common.can_signup_description"))}
        </Typography>

        <Controller
          control={control}
          name="API_under_maintenance"
          render={({ field: { value, onChange, ...field } }) => (
            <FormControlLabel
              className="mt-32"
              control={
                <Checkbox
                  name="API_under_maintenance"
                  checked={Boolean(value)}
                  onChange={(e) => {
                    onChange(e.target.checked ? 1 : 0);
                  }}
                />
              }
              label={dispatch(t("common.API_under_maintenance"))}
            />
          )}
        />
        <Typography color={"text.secondary"} className="text-md">
          {dispatch(t("common.API_under_maintenance_description"))}
        </Typography>
      </div>

      <Box
        className="fixed bottom-0 w-full py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t z-999"
        sx={{
          backgroundColor: "background.default",
        }}
      >
        <Button
          className="ml-auto"
          component={NavLinkAdapter}
          to={`/system/configurations`}
        >
          {dispatch(t("common.cancel"))}
        </Button>
        <LoadingButton
          className="ml-8"
          variant="contained"
          color="secondary"
          loading={isLoading}
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </LoadingButton>
      </Box>
    </>
  );
};

export default Security;
