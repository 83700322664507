import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import { EditLocationAltOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import { setDrawer, setEdit } from "../../store/drawerSlice";

const VoyagerGatewaysMenu = ({ gateways }) => {
  const dispatch = useDispatch();
  const [moreMenuEl, setMoreMenuEl] = useState(null);

  const openMenu = async (event) => {
    setMoreMenuEl(event.currentTarget);
  };

  const closeMenu = (event) => {
    setMoreMenuEl(false);
  };

  return (
    <div>
      <IconButton
        aria-owns={moreMenuEl ? "main-menu" : null}
        aria-haspopup="true"
        onClick={openMenu}
        size="large"
      >
        <FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
      </IconButton>
      <Menu
        id="menu"
        anchorEl={moreMenuEl}
        open={Boolean(moreMenuEl)}
        onClose={closeMenu}
        style={{ zIndex: 1000 }}
      >
        <MenuItem
          onClick={() => {
            dispatch(setDrawer(true));
            dispatch(setEdit(gateways));
            closeMenu();
          }}
        >
          <ListItemIcon>
            <EditLocationAltOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>{dispatch(t("common.location"))}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default VoyagerGatewaysMenu;
