import Access_logsApp from "./Access_logsApp";

const Access_logsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "system/access_logs",
      element: <Access_logsApp />,
      children: [
        {
          path: ":id",
          element: <Access_logsApp />,
        },
      ],
    },
  ],
};

export default Access_logsAppConfig;
