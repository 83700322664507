import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import { useDispatch } from "react-redux";

const ConfirmationDialog = ({ open, onClose, onSubmit, options, title }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {dispatch(t(title))}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {dispatch(t("common.confirm_action"))}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => onSubmit(options)}>
          {dispatch(t("common.yes"))}
        </Button>
        <Button autoFocus onClick={onClose}>
          {dispatch(t("common.cancel"))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
