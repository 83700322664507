import FusePageSimple from "@fuse/core/FusePageSimple";
import { motion } from "framer-motion";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import { useDispatch, useSelector } from "react-redux";
import Smtp_ServersAppHeader from "./Smtp_ServersAppHeader";
import Smtp_Servers from "./Smtp_Servers";
import { fetchSmtp_ServersList, selectSmtp } from "./store/smtp_serversSlice";

const Smtp_ServersApp = () => {
  const dispatch = useDispatch();
  const smtp = useSelector(selectSmtp);

  useEffect(() => {
    dispatch(fetchSmtp_ServersList());
  }, [dispatch]);

  return (
    <>
      <FusePageSimple
        header={<Smtp_ServersAppHeader />}
        content={
          <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
            {useMemo(() => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full mt-32">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <Smtp_Servers />
                    </motion.div>
                  </div>
                </motion.div>
              );
            }, [smtp])}
          </div>
        }
      />
    </>
  );
};

export default withReducer("smtp_servers", reducer)(Smtp_ServersApp);
