import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import { setActiveGroup } from "./groupsSlice";

export const fetchMenu = createAsyncThunk(
  "menu/menus/fetchMenu",
  async (id, { dispatch, getState }) => {
    const {
      i18n: { language },
    } = getState();

    try {
      const res = await axiosConfig.get(
        `/menu/?items=1&formated=1&workspaces=1&lang=${id || language}`
      );

      if (res.status === "error") return [];

      // dispatch(setActiveGroup(res.result[0].menu_id));

      return res.result;
    } catch (error) {
      return [];
    }
  }
);

export const fecthMenuChanges = createAsyncThunk(
  "menu/menus/fetchMenuChanges",
  async (_, { dispatch, getState }) => {
    try {
      const {
        menu: {
          groups: { groupActive },
          workspaces: { activeWorkspace },
          menus: {
            lang: { id },
          },
        },
        i18n: { language },
      } = getState();

      const res = await axiosConfig.get(
        `/menu/${groupActive || `1`}?items=1&formated=1&workspaces=1&lang=${
          id || language
        }`
      );

      if (res.status === "error") return {};

      const data = (await res.result) ? mountMenus(res.result?.items) : [];

      if (activeWorkspace) dispatch(changeWorkspace(activeWorkspace, data));
      else dispatch(setMenuSelected(data));

      return data;
    } catch (error) {
      return {};
    }
  }
);

export const mountMenus = (menu) => {
  const arrayNew = [];

  for (let index = 0; index < menu.length; index++) {
    const element = menu[index];

    arrayNew.push({
      ...element,
      title: element.title || `[${element.title_tag}]`,
      subtitle: element.subtitle || "",
      active: false,
    });

    if (element.subitems)
      arrayNew[index].children = mountMenus(element.subitems);
  }

  return arrayNew;
};

export const changeWorkspace = (id, menu) => (dispatch, getState) => {
  const { menuSelected } = getState().menu.menus;
  const res = createActiveTree(menu || menuSelected, id);
  return dispatch(setMenuSelected(res));
};

const createActiveTree = (menu, id) => {
  const arrayTree = [];

  for (let index = 0; index < menu.length; index++) {
    const element = menu[index];

    const result = element.workspaces?.some((i) => i.workspace_id == id);

    arrayTree.push({
      ...element,
      active: Boolean(result),
    });

    if (element.children)
      arrayTree[index].children = createActiveTree(element.children, id);
  }

  return arrayTree;
};

export const checkItems = (menu, ids, workspace_id) => {
  const arrayTree = [];

  for (let index = 0; index < menu.length; index++) {
    const element = menu[index];

    if (ids.includes(element.menu_item_id)) {
      const workspaces = [...element.workspaces];
      workspaces.push({
        menu_item_id: element.menu_item_id,
        workspace_id,
      });

      arrayTree.push({
        ...element,
        active: true,
        workspaces,
      });
    } else {
      arrayTree.push({
        ...element,
      });
    }

    if (element.children)
      arrayTree[index].children = checkItems(
        element.children,
        ids,
        workspace_id
      );
  }

  return arrayTree;
};

export const uncheckItems = (menu, ids, workspace_id) => {
  const arrayTree = [];

  for (let index = 0; index < menu.length; index++) {
    const element = menu[index];

    if (ids.includes(element.menu_item_id)) {
      const workspaces = [...element.workspaces];

      const indexOf = workspaces.findIndex(
        (i) => i.workspace_id == workspace_id
      );

      workspaces.splice(indexOf, 1);

      arrayTree.push({
        ...element,
        active: false,
        workspaces,
      });
    } else {
      arrayTree.push({
        ...element,
      });
    }

    if (element.children)
      arrayTree[index].children = uncheckItems(
        element.children,
        ids,
        workspace_id
      );
  }

  return arrayTree;
};

const menuSlice = createSlice({
  name: "menu/menus",
  initialState: {
    menus: [],
    menuSelected: [],
    menuInfo: {},
    lang: null,
    status: "idle",
  },
  reducers: {
    setMenuInfo: (state, action) => {
      state.menuInfo = action.payload;
    },
    setMenuSelected: (state, action) => {
      state.menuSelected = action.payload;
    },
    setFilterMenu: (state, action) => {
      const menu = state.menus.find((m) => m.menu_id == action.payload);

      state.menuSelected =
        menu?.items?.length > 0 ? mountMenus(menu.items) : [];
    },
    setLangMenu: (state, action) => {
      state.lang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenu.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchMenu.fulfilled, (state, action) => {
        state.menus = action.payload;

        state.menuSelected =
          action.payload && action.payload.length > 0
            ? mountMenus(action.payload[0]?.items)
            : [];

        state.status = "fulfilled";
      })
      .addCase(fetchMenu.rejected, (state, action) => {
        state.menus = [];
        state.menuSelected = [];
        state.status = "rejected";
      });
  },
});

export const { setMenuInfo, setMenuSelected, setFilterMenu, setLangMenu } =
  menuSlice.actions;

export const selectMenus = ({ menu }) => menu.menus;
export const selectMenuInfo = ({ menu }) => menu.menus.menuInfo;

export default menuSlice.reducer;
