import CentralNotificationsApp from "./CentralNotificationsApp";

const CentralNotificationsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "system/central_notifications",
      element: <CentralNotificationsApp />,
    },
  ],
};

export default CentralNotificationsConfig;
