import {
  selectPageSubTitle,
  selectPageTitle,
} from "app/store/labels/globalLabels";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";

const Access_logsAppHeader = (props) => {
  const pageTitle = useSelector(selectPageTitle);
  const pageSubtitle = useSelector(selectPageSubTitle);
  const { leftSidebar } = props;

  return (
    <>
      <div className="flex flex-col p-24 w-full sm:py-32 sm:px-40">
        <div className="flex flex-row items-center w-full mt-8 -mx-10">
          {leftSidebar && (
            <div className="flex shrink-0 items-center">
              <IconButton onClick={leftSidebar} aria-label="toggle sidebar">
                <FuseSvgIcon>heroicons-outline:menu</FuseSvgIcon>
              </IconButton>
            </div>
          )}
          <div className="flex flex-col mt-16">
            <Typography
              component="h2"
              className="flex-1 text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate mx-10"
            >
              {pageTitle}
            </Typography>
            <Typography
              component="h4"
              className=" flex-1 font-medium tracking-tight pl-10"
              color="text.secondary"
            >
              {pageSubtitle}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default Access_logsAppHeader;
