import React from "react";
import { useSelector } from "react-redux";
import { Drawer } from "rsuite";
import { useDispatch } from "react-redux";
import _ from "@lodash";
import { t } from "app/store/labels/globalLabels";
import LabelsForm from "./LabelsForm";
import { selectDrawer, setOpen } from "../store/drawerSlice";

const DrawerLabels = () => {
  const dispatch = useDispatch();
  const { open, data } = useSelector(selectDrawer);

  const handleClose = () => {
    dispatch(setOpen({ open: false, data: {} }));
  };

  return (
    <Drawer open={open} onClose={handleClose}>
      <Drawer.Header>
        <Drawer.Title>
          {data?.label_group_id
            ? dispatch(t("common.edit"))
            : dispatch(t("common.add"))}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body style={{ padding: 0 }}>
        {open && <LabelsForm close={handleClose} />}
      </Drawer.Body>
    </Drawer>
  );
};

export default DrawerLabels;
