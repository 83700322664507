import { Controller, useForm } from "react-hook-form";
import EntityEmailSelector from "../email-selector/EntityEmailSelector";
import { useEffect } from "react";

const EntityEmailInput = (props) => {
  const entity = props.entity;

  const { control, reset } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    reset({ ...entity });
  }, [entity, reset]);

  return (
    <>
      <Controller
        control={control}
        name="email_addresses"
        render={({ field }) => <EntityEmailSelector {...field} />}
      />
    </>
  );
};

export default EntityEmailInput;
