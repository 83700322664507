import _ from "@lodash";
import * as yup from "yup";

const PostModel = (data) =>
  _.mergeWith(
    {
      features: "",
      title_lang: "",
    },
    data,
    (objValue, srcValue, key) => {
      if (key == "features" || key == "name_lang") return JSON.parse(srcValue);

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export const PostList = (data, langs) => {
  const createSchema = (languages) => {
    const schema = {};

    languages.map((i) => {
      schema[i.code] = yup.string().required();
    });

    return {
      title_lang: yup.object().shape(schema),
    };
  };

  const schema = createSchema(langs);

  return _.mergeWith(
    {
      content: "",
      title_lang: "",
      schema,
    },
    data,
    (objValue, srcValue, key) => {
      if (key == "content" || key == "title_lang") return JSON.parse(srcValue);

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );
};

export const PostGroupList = (data) => {
  return _.mergeWith(
    {
      content: "",
      title_lang: "",
    },
    data,
    (objValue, srcValue, key) => {
      if (key == "content" || key == "title_lang") return JSON.parse(srcValue);

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );
};

export const NewPost = (data, langs) => {
  const createSchema = (languages) => {
    const schema = {};

    languages.map((i) => {
      schema[i.code] = yup.string().required();
    });

    return {
      title_lang: yup.object().shape(schema),
    };
  };

  const createValues = (languages) => {
    const schema = {};

    languages.map((i) => {
      schema[i.code] = "";
    });

    return schema;
  };

  const schema = createSchema(langs);
  const values = createValues(langs);

  return _.defaults(data || {}, {
    content: "",
    title_lang: values,
    schema,
  });
};

export const PostYup = (data, langs) => {
  const createSchema = (name, languages) => {
    const schema = {};

    languages.map((i) => {
      schema[i.code] = data.features.required
        ? yup.string().required()
        : yup.string();
    });

    return {
      [name]: yup.object().shape(schema),
    };
  };

  const createValues = (name, languages) => {
    const schema = {};

    languages.map((i) => {
      schema[i.code] = "";
    });

    return {
      [name]: schema,
    };
  };

  const schema = createSchema(data.name_tag, langs);
  const values = createValues(data.name_tag, langs);

  return _.defaults(data || {}, {
    ...data,
    schema,
    values,
  });
};

export const PostYupItems = (data) => {
  const createSchema = (name) => {
    return {
      [name]: data.features.required ? yup.string().required() : yup.string(),
    };
  };

  const createValues = (name) => {
    return {
      [name]: "",
    };
  };

  const schema = createSchema(data.name_tag);
  const values = createValues(data.name_tag);

  return _.defaults(data || {}, {
    ...data,
    schema,
    values,
  });
};

export const PostYupItemsCheck = (data) => {
  const createSchema = (name) => {
    const schema = {};

    data.features.items.map((i) => {
      schema[`check${i.value}`] = yup.boolean();
    });

    return {
      [name]: yup.object().shape(schema),
    };
  };

  const createValues = (name) => {
    const schema = {};

    data.features.items.map((i) => {
      schema[`check${i.value}`] = false;
    });

    return {
      [name]: schema,
    };
  };

  const schema = createSchema(data.name_tag);
  const values = createValues(data.name_tag);

  return _.defaults(data || {}, {
    ...data,
    schema,
    values,
  });
};

export const PostYupFile = (data) => {
  const createSchema = (name) => {
    return {
      [name]: data.features.required ? yup.mixed().required() : yup.mixed(),
    };
  };

  const createValues = (name) => {
    return {
      [name]: null,
    };
  };

  const schema = createSchema(data.name_tag);
  const values = createValues(data.name_tag);

  return _.defaults(data || {}, {
    ...data,
    schema,
    values,
  });
};

export const PostYupText = (data) => {
  const createSchema = (name) => {
    return {
      [name]: data.features.required ? yup.string().required() : yup.string(),
    };
  };

  const createValues = (name) => {
    return {
      [name]: "",
    };
  };

  const schema = createSchema(data.name_tag);
  const values = createValues(data.name_tag);

  return _.defaults(data || {}, {
    ...data,
    schema,
    values,
  });
};

export const PostYupDateTime = (data) => {
  const createSchema = (name) => {
    return {
      [name]: data.features.required
        ? yup.date().required()
        : yup.date().nullable(),
    };
  };

  const createValues = (name) => {
    return {
      [name]: null,
    };
  };

  const schema = createSchema(data.name_tag);
  const values = createValues(data.name_tag);

  return _.defaults(data || {}, {
    ...data,
    schema,
    values,
  });
};

export const PostYupNumber = (data) => {
  const createSchema = (name) => {
    return {
      [name]: data.features.required ? yup.number().required() : yup.number(),
    };
  };

  const createValues = (name) => {
    return {
      [name]: 0,
    };
  };

  const schema = createSchema(data.name_tag);
  const values = createValues(data.name_tag);

  return _.defaults(data || {}, {
    ...data,
    schema,
    values,
  });
};

export default PostModel;
