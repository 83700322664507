import { FormatDate } from "app/shared-components/Functions";

const MenuModal = (data) =>
  _.defaults(data || {}, {
    id: `${data}`,
    title: `${FormatDate(data)}`,
    type: "item",
    hasActions: true,
    url: `/system/access_logs/${data}`,
  });

export default MenuModal;
