import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "rsuite";
import { selectOpenMenu, setOpenDrawer } from "../store/drawerSlice";
import { t } from "app/store/labels/globalLabels";
import Translate from "src/app/main/components/inputLanguageSelector/Translate";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import icons from "./assets/Icons";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import * as yup from "yup";
import _ from "@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import menuService from "../service/menuService";
import {
  selectCurrentLanguageId,
  selectSystemLanguages,
} from "app/store/i18nSlice";
import {
  selectMenuInfo,
  selectMenus,
  setMenuInfo,
  setMenuSelected,
} from "../store/menuSlice";
import { styled } from "@mui/system";
import { selectUser } from "app/store/userSlice";

const CustomOption = styled("li")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const DrawerMenu = () => {
  const dispatch = useDispatch();
  const {
    node,
    path,
    changeNodeAtPath,
    addNodeUnderParent,
    getNodeKey,
    edit,
    isNew,
  } = useSelector(selectMenuInfo);
  const { menuSelected } = useSelector(selectMenus);
  const lang = useSelector(selectCurrentLanguageId);
  const user = useSelector(selectUser);
  const systemLang = useSelector(selectSystemLanguages);
  const open = useSelector(selectOpenMenu);
  const [icon, setIcon] = useState("");
  const [titleLang, setTitleLang] = useState("");
  const [subtitleLang, setSubitleLang] = useState("");

  const schema = yup.object().shape({
    url_tabs: yup.string(),
    title_tag: yup.string().required("common.input_required"),
  });

  const { control, formState, handleSubmit, setError, setValue, reset } =
    useForm({
      mode: "onChange",
      defaultValues: {
        url_tabs: "",
        title_tag: "",
        is_system: false,
      },
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (node && edit) {
      setTitleLang(JSON.parse(node.title_lang) || "");
      setSubitleLang(JSON.parse(node.subtitle_lang) || "");
      setIcon(node.icon || "");
      setValue("url_tabs", node.url_tabs || "", {
        shouldDirty: false,
        shouldValidate: false,
      });
      setValue("title_tag", node.title_tag || "", {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue("is_system", Boolean(node.is_system) || false, {
        shouldDirty: false,
        shouldValidate: false,
      });
    } else {
      reset({
        url_tabs: "",
        title_tag: "",
        is_system: false,
      });
      setTitleLang("");
      setSubitleLang("");
      setIcon("");
    }
  }, [node, edit, getNodeKey]);

  const handleClose = async () => {
    reset();
    setTitleLang("");
    setSubitleLang("");
    setIcon("");
    dispatch(setOpenDrawer(false));
  };

  const handleChangeTitle = async (e) => {
    setTitleLang(e);
  };
  const handleChangeSubtitle = async (e) => {
    setSubitleLang(e);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (edit) {
      try {
        const res = await menuService.editMenu(
          node,
          data,
          icon,
          titleLang,
          subtitleLang,
          systemLang
        );

        const newValues = {
          ...data,
          icon,
          title_lang: JSON.stringify(titleLang),
          subtitle_lang: JSON.stringify(subtitleLang),
          subtitle: subtitleLang[lang] || "",
          title: titleLang[lang] || "",
        };

        const newData = changeNodeAtPath({
          treeData: menuSelected,
          path,
          getNodeKey,
          newNode: { ...node, ...newValues },
        });

        dispatch(setMenuSelected(newData));
        handleClose();
      } catch (error) {}
    } else {
      try {
        const res = await menuService.addMenu(
          node,
          data,
          icon,
          titleLang,
          subtitleLang,
          systemLang,
          isNew
        );

        const newValues = {
          ...data,
          menu_id: node.menu_id,
          menu_item_in: isNew ? 0 : node.menu_item_id,
          menu_item_id: res.result.last_id,
          title: titleLang[lang] || "",
          subtitle: subtitleLang[lang] || "",
          icon,
          title_lang: JSON.stringify(titleLang),
          subtitle_lang: JSON.stringify(subtitleLang),
          workspaces: [],
        };

        const newData = addNodeUnderParent({
          treeData: menuSelected,
          parentKey: path[path.length - 1],
          getNodeKey,
          expandParent: true,
          newNode: { ...newValues },
          addAsFirstChild: true,
        }).treeData;

        dispatch(setMenuSelected(newData));
        handleClose();
      } catch (error) {}
    }
  };

  return (
    <Drawer backdrop={true} open={open} onClose={handleClose}>
      <Drawer.Header>
        <Drawer.Title>{dispatch(t("common.edit"))}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <form
          name="menuForm"
          noValidate
          className="flex flex-col justify-center w-full mt-32"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Translate
            id={"title_lang"}
            onChangeText={handleChangeTitle}
            lang={edit ? node.title_lang : ""}
            placeholder={dispatch(t("common.title"))}
          />
          <Translate
            id={"subtitle_lang"}
            onChangeText={handleChangeSubtitle}
            lang={edit ? node.subtitle_lang : ""}
            placeholder={dispatch(t("common.subtitle"))}
          />
          <Autocomplete
            className="mb-24"
            options={icons}
            value={icon}
            freeSolo
            onChange={(event, value) => {
              /*  onChange(value); */
              setIcon(value);
            }}
            isOptionEqualToValue={(option, value) => option}
            disablePortal
            sx={{ width: "100%" }}
            getOptionLabel={(option) => option}
            renderOption={(props, option, state) => (
              <CustomOption {...props}>
                <FuseSvgIcon>{option}</FuseSvgIcon>
                <Typography className="ml-5">{option}</Typography>
              </CustomOption>
              // <Box
              //   component="li"
              //   sx={{
              //     "& > img": { mr: 2, flexShrink: 0 },
              //     backgroundColor: "white !important",
              //     "&:hover": {
              //       backgroundColor: state.hover ? "#f5f5f5" : "white",
              //     },
              //   }}
              //   {...props}
              // >
              //   <FuseSvgIcon>{option}</FuseSvgIcon>
              //   <Typography className="ml-5">{option}</Typography>
              // </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Icon"
                placeholder="Icon"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <React.Fragment>
                      {icon && <FuseSvgIcon>{icon}</FuseSvgIcon>}
                      {params.InputProps.startAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="url_tabs"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label={dispatch(t("common.path"))}
                placeholder={dispatch(t("common.path"))}
                error={!!errors.url_tabs}
                helperText={errors?.url_tabs?.message}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="title_tag"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label={dispatch(t("common.tag"))}
                disabled={
                  node && edit
                    ? !Boolean(user.data.access_level?.is_master) ||
                      Boolean(node.is_system)
                    : false
                }
                placeholder={dispatch(t("common.tag"))}
                error={!!errors.title_tag}
                helperText={errors?.title_tag?.message}
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Controller
            name="is_system"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                className="mb-8"
                control={
                  <Checkbox
                    checked={Boolean(value)}
                    disabled={
                      user.data.access_level?.is_master
                        ? !Boolean(user.data.access_level?.is_master)
                        : true
                    }
                    onChange={(event) => onChange(event.target.checked ? 1 : 0)}
                    name={dispatch(t("common.system"))}
                    color="secondary"
                  />
                }
                label={dispatch(t("common.system"))}
                id="is_system"
              />
            )}
          />

          <Button
            variant="contained"
            color="secondary"
            className="w-full mt-16"
            aria-label="Sign in"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            type="submit"
            size="large"
          >
            {dispatch(t("common.save"))}
          </Button>
        </form>
      </Drawer.Body>
    </Drawer>
  );
};

export default DrawerMenu;
