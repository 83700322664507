import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class PostsService {
  addPost = (section, data, title_lang) => {
    const body = {
      content: JSON.stringify(data),
      title_lang,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/posts/sections/${section.post_section_id}/content`, body)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  addFiles = (section, post_id, data) => {
    const formData = new FormData();

    Object.keys(data).forEach((d) => {
      formData.append(`file[${d}]`, data[d]);
    });

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/posts/sections/${section.post_section_id}/content/${post_id}`,
          formData
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  addSingleFile = (section, post, data) => {
    const body = {
      file: data,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/posts/sections/${section.post_section_id}/content/${post.post_content_id}`,
          body
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  deleteFiles = (section, post_id) => {
    const body = {
      file: "*",
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(
          `/posts/sections/${section.post_section_id}/content/${post_id}`,
          { data: { ...body } }
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  deleteSingleFile = (section, post, data) => {
    const body = {
      file: data,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(
          `/posts/sections/${section.post_section_id}/content/${post.post_content_id}`,
          { data: { ...body } }
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  updatePost = (section, post, data, title_lang) => {
    const body = {
      content: JSON.stringify(data),
      title_lang,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/posts/sections/${section.post_section_id}/content/${post}`,
          body
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  deletePost = (section, post) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(
          `/posts/sections/${section.post_section_id}/content/${post.post_content_id}`
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  reorderPosts = (section, items) => {
    const data = new FormData();

    for (let index = 0; index < items.length; index++)
      data.append("ids[]", items[index].post_content_id);

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/posts/sections/${section.post_section_id}/content/reorder`,
          data
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  addGroup = (section, data) => {
    const body = {
      title_lang: { ...data },
      is_group: 1,
      is_active: 1,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/posts/sections/${section.post_section_id}/content`, body)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  updateGroup = (section, post, data) => {
    const body = {
      title_lang: { ...data },
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/posts/sections/${section.post_section_id}/content/${post.post_content_id}`,
          body
        )
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };
}

const postsService = new PostsService();
export default postsService;
