import {
  selectCurrentLanguage,
  selectCurrentLanguageId,
} from "app/store/i18nSlice";
import { getAccessLevelList, selectStatus } from "./store/accessLevelsSlice";
import { fetchMenu, setLangMenu } from "../menu/store/menuSlice";
import { getEndpointsList } from "./store/endpointsSlice";
import { fetchWorkspaces } from "../menu/store/workspacesSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchGroups } from "../menu/store/groupsSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import withReducer from "app/store/withReducer";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import reducer from "./store";

const AccessLevelApp = () => {
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const lang = useSelector(selectCurrentLanguageId);
  const langCurrent = useSelector(selectCurrentLanguage);

  useEffect(() => {
    Promise.all([
      dispatch(fetchMenu()),
      dispatch(setLangMenu(langCurrent)),
      dispatch(getAccessLevelList()),
      dispatch(getEndpointsList()),
    ]).then((r) => {
      dispatch(fetchGroups());
      dispatch(fetchWorkspaces());
    });
  }, [dispatch, lang]);

  if (status === "pending") {
    return <FuseLoading />;
  }

  return <Outlet />;
};

export default withReducer("access_level", reducer)(AccessLevelApp);
