import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  deleteNotification,
  fetchNotificationsList,
  selectNotifications,
  selectStatus,
} from "./store/notificationsSlice";
import { useMemo } from "react";
import { selectLanguages, selectTableLanguage } from "app/store/i18nSlice";
import { MaterialReactTable } from "material-react-table";
import { t } from "app/store/labels/globalLabels";
import {
  Chip,
  ListItem,
  MenuItem,
  SpeedDial,
  SpeedDialAction,
} from "@mui/material";
import {
  Add,
  Delete,
  Edit,
  FormatListBulletedOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { setDrawerState, setEditRow, setEmptyRow } from "./store/drawerSlice";
import { useState } from "react";
import DrawerMenu from "./components/DrawerMenu";
import ConfirmationDialog from "../../components/confirmationDialog/ConfirmationDialog";
import centralNotificationService from "./service/CentralNotificationsService";
import { selectUsers, selectStatus as statusUsers } from "./store/usersSlice";
import FuseLoading from "@fuse/core/FuseLoading";

const CentralNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const lang = useSelector(selectLanguages);
  const loading = useSelector(selectStatus);
  const loadingUser = useSelector(statusUsers);
  const tableLang = useSelector(selectTableLanguage);
  const users = useSelector(selectUsers);

  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [not, setNot] = useState("");

  const handleOpenValidation = () => {
    setOpenValidationDialog(true);
  };

  const handleNo = () => {
    setOpenValidationDialog(false);
  };

  const handleDelete = async () => {
    try {
      const res = await centralNotificationService.deleteCentralNotification(
        not.original.notifications_preset_id
      );

      dispatch(deleteNotification(not.index));
    } catch (error) {}

    handleNo();
  };

  const notificationsTable = useMemo(() => {
    const staticNotifications = [
      {
        accessorKey: "notifications_preset_id",
        header: dispatch(t("common.id")),
        maxSize: 80,
      },
      {
        accessorKey: "name",
        header: dispatch(t("common.name")),
        size: 150,
      },
      {
        accessorKey: "user",
        header: dispatch(t("common.users")),
        size: 350,
        Cell: ({ row }) =>
          row.original.user.map((u, _index) => {
            const findedUser = _.find(users, { user_id: Number(u) });
            return (
              <Chip
                key={_index}
                label={
                  `${findedUser?.name} (${findedUser?.email})` ||
                  findedUser.user_id
                }
              />
            );
          }),
      },
      {
        accessorKey: "emails",
        header: dispatch(t("common.emails")),
        size: 300,
        Cell: ({ row }) =>
          row.original.emails.map((u, _index) => (
            <Chip label={u} key={_index} />
          )),
      },
    ];
    return [...staticNotifications];
  }, [notifications, lang, users]);

  if (loading != "fulfilled" || loadingUser != "fulfilled")
    return <FuseLoading />;

  return (
    <div>
      <MaterialReactTable
        columns={notificationsTable}
        data={notifications}
        localization={tableLang}
        enableColumnOrdering
        enableGrouping
        enablePinning
        enableColumnResizing
        enableStickyHeader
        enableFullScreenToggle={false}
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 332px)" } }}
        state={{
          showSkeletons: loading != "fulfilled" ? true : false,
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": { size: 55 },
        }}
        enableRowActions
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            key={1}
            onClick={() => {
              dispatch(setDrawerState(true));
              dispatch(setEditRow({ ...row.original, index: row.index }));
              closeMenu();
            }}
          >
            <Edit />
            {dispatch(t("common.edit"))}
          </MenuItem>,
          <MenuItem
            key={2}
            color="error"
            onClick={() => {
              setNot(row);
              handleOpenValidation();
              closeMenu();
            }}
          >
            <Delete /> {dispatch(t("common.delete"))}
          </MenuItem>,
        ]}
      />
      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle={dispatch(t("common.add"))}
          tooltipOpen
          onClick={() => dispatch(setEmptyRow())}
        />

        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh_table"))}
          tooltipOpen
          onClick={() => {
            dispatch(fetchNotificationsList());
          }}
        />
      </SpeedDial>
      <DrawerMenu />
      <ConfirmationDialog
        open={openValidationDialog}
        onClose={handleNo}
        onSubmit={() => handleDelete(not)}
        title="common.delete"
      />
    </div>
  );
};

export default CentralNotifications;
