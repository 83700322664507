import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { Alert, Button, IconButton } from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";

const SubscriptionAlert = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  return (
    <div className="w-full" ref={ref}>
      <Alert
        className="items-center flex flex-1 w-full"
        severity="warning"
        action={
          <Button
            component={NavLinkAdapter}
            to={"/access_plans/subscription"}
            variant="contained"
          >
            {dispatch(t("common.subscription"))}
          </Button>
        }
      >
        {dispatch(t("access_plan.invalid_subscription"))}
      </Alert>
    </div>
  );
});

export default SubscriptionAlert;
