import { useDispatch, useSelector } from "react-redux";
import FuseNavigation from "@fuse/core/FuseNavigation/FuseNavigation";
import { useState } from "react";
import { updateNavigationItem } from "../../store/navigationGroupsSlice";
import { useEffect } from "react";
import {
  changeWorkspace,
  fecthMenuChanges,
  setFilterMenu,
} from "../../store/menuSlice";
import {
  fetchGroups,
  filterGroup,
  setActiveGroup,
  setGroupInfo,
} from "../../store/groupsSlice";
import { selectWorkspaces } from "../../store/workspacesSlice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import { t } from "app/store/labels/globalLabels";
import { Add, Delete, Edit } from "@mui/icons-material";
import { setDrawerGroup } from "../../store/drawerSlice";
import groupService from "../../service/groupService";

const Groups = ({ navigation }) => {
  const dispatch = useDispatch();
  const { activeWorkspace } = useSelector(selectWorkspaces);
  const [menu, setMenu] = useState({});
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);

  console.log(navigation);

  const handleClick = async (item) => {
    if (item.type === "item") {
      const active = await navigation[0].children.find((i) =>
        Boolean(i.active)
      );
      if (active.id !== item.id) {
        dispatch(
          updateNavigationItem(active.id, {
            active: false,
          })
        );
        dispatch(
          updateNavigationItem(item.id, {
            active: true,
          })
        );

        Promise.all([
          dispatch(setActiveGroup(item.menu_id)),
          dispatch(fecthMenuChanges()),
        ]);

        // dispatch(setFilterMenu(item.id)),
        // if (activeWorkspace) dispatch(changeWorkspace(activeWorkspace));
        setMenu(item);
      }
    }
  };

  const handleAdd = async () => {
    // const active = await navigation[0].children.find((i) => Boolean(i.active));
    // setMenu(active);
    Promise.all([dispatch(setGroupInfo({})), dispatch(setDrawerGroup(true))]);
  };

  const handleEdit = async (item) => {
    // const active = await navigation[0].children.find((i) => Boolean(i.active));
    setMenu(item);
    Promise.all([dispatch(setGroupInfo(item)), dispatch(setDrawerGroup(true))]);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    dispatch(filterGroup(e.target.value));
  };

  const handleDelete = async (item) => {
    setMenu(item);
    setOpen(true);
  };

  const handleCancelDialog = () => {
    setOpen(false);
  };

  const handleSubmitDialog = async () => {
    try {
      const res = await groupService.deleteMenu(menu);

      dispatch(fetchGroups());
    } catch (error) {}

    setOpen(false);
  };

  return (
    <div className="px-12 py-24 h-full">
      <Paper className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-full shadow-none">
        <FuseSvgIcon color="action" size={20}>
          heroicons-solid:search
        </FuseSvgIcon>

        <Input
          placeholder={dispatch(t("common.search"))}
          className="flex flex-1 px-8"
          disableUnderline
          fullWidth
          value={text}
          inputProps={{
            "aria-label": "Search",
          }}
          onChange={handleTextChange}
        />
      </Paper>
      <FuseNavigation
        onItemClick={handleClick}
        navigation={navigation}
        className="px-0"
        hasActions={{
          active: true,
          onEdit: handleEdit,
          onDelete: handleDelete,
        }}
        hasActionsGroup={{
          active: true,
          onAdd: handleAdd,
        }}
      />
      <ConfirmationDialog
        open={open}
        onClose={handleCancelDialog}
        onSubmit={handleSubmitDialog}
      />
    </div>
  );
};

const ConfirmationDialog = ({ open, onClose, onSubmit }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {dispatch(t("common.delete"))}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {dispatch(t("common.confirm_action"))}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onSubmit}>
          {dispatch(t("common.yes"))}
        </Button>
        <Button autoFocus onClick={onClose}>
          {dispatch(t("common.cancel"))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Groups;
