import _ from "@lodash";

const EntityModel = (data) =>
  _.defaults(data || {}, {
    picture: null,
    name: "",
    email_addresses: [{ email_address: "", label: "", is_main: false }],
    phone_numbers: [{ phone_number: "", label: "", is_main: false }],
    addresses: [
      {
        street: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
        label: "",
        is_main: false,
      },
    ],
    notes: "",
  });

export const EntityModelMerge = (data) =>
  _.mergeWith(
    {
      email_addresses: [{ email_address: "", label: "", is_main: false }],
      phone_numbers: [{ phone_number: "", label: "", is_main: false }],
      addresses: [
        {
          street: "",
          city: "",
          state: "",
          zip_code: "",
          country: "",
          label: "",
          is_main: false,
        },
      ],
    },
    data,
    (objValue, srcValue, key) => {
      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export default EntityModel;
