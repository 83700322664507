import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import _ from "@lodash";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/system/Box";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import {
  addUser,
  getUser,
  newUser,
  removeUser,
  selectUser,
  updateUser,
} from "../store/contactSlice";
import {
  selectUser as selectSystemUser,
  updateUserProfile,
} from "app/store/userSlice";
import { t } from "app/store/labels/globalLabels";
import CountryCodeSelector from "./phone-number-selector/CountryCodeSelector";
import { FormatDateSQL } from "app/shared-components/Functions";
import FileUpdate from "src/app/main/components/fileUpdate/FileUpdate";
import { getAccess, selectAccess } from "../store/accessSlice";
import { ThumbDownOffAlt, ThumbUpOffAlt } from "@mui/icons-material";
import { getWorkspace, selectWorkspace } from "../store/workspaceSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { selectLanguages } from "app/store/i18nSlice";
import countries from "src/app/main/components/countriesPhone";

const UserForm = (props) => {
  const contact = useSelector(selectUser);
  const access = useSelector(selectAccess);
  const workspaces = useSelector(selectWorkspace);
  const routeParams = useParams();
  const user = useSelector(selectSystemUser);
  const languages = useSelector(selectLanguages);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [ac, setAc] = useState(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState([
    {
      label: dispatch(t("common.active")),
      value: "OK",
    },
    {
      label: dispatch(t("common.blocked")),
      value: "BLOCKED",
    },
    {
      label: dispatch(t("common.await")),
      value: "AWAIT",
    },
  ]);

  const pond = useRef(null);

  const schema = yup.object().shape({
    name: yup.string().required(dispatch(t("common.input_required"))),
    email: yup
      .string()
      .email(dispatch(t("common.set_valid_email")))
      .required(dispatch(t("common.input_required"))),
    access_level_id: yup
      .number()
      .required(dispatch(t("common.input_required"))),
    workspace_id: yup.number().required(dispatch(t("common.input_required"))),
    lang: yup.string().required(dispatch(t("common.input_required"))),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setValue,
    resetField,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  useEffect(() => {
    Promise.all([dispatch(getAccess()), dispatch(getWorkspace())]);

    if (routeParams.id === "new") {
      dispatch(newUser());
    } else {
      dispatch(getUser(routeParams.id));
    }
  }, [dispatch, routeParams]);

  useEffect(() => {
    reset({ ...contact });
  }, [contact, reset]);

  useEffect(() => {
    if (contact) {
      setFiles(contact?.picture_src);
      setAc(contact?.access_level_id || null);
    }
  }, [contact]);

  const onSubmit = (data) => {
    const body = {
      ...data,
      [`phone_number[number]`]: data.phone_number,
      [`phone_number[country_code]`]: data.phone_country_code,
      birthdate: FormatDateSQL(data.birthdate, false),
      picture: data.picture_src || "",
      picture_src: "",
      workspaces: data.workspace_id,
    };

    if (routeParams.id === "new") {
      dispatch(addUser(body))
        .then(({ payload }) => {
          navigate(`/system/users/${payload.id}`);
        })
        .catch((err) => console.log(err));
    } else {
      dispatch(updateUser(body))
        .then(({ payload }) => {
          navigate(`/system/users/${payload.id}`);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleRemoveContact = () => {
    setOpen(true);
  };

  const handleCancelDialog = () => {
    setOpen(false);
  };

  const handleSubmitDialog = () => {
    setOpen(false);
    dispatch(removeUser(contact.id)).then(() => {
      navigate("/system/users");
    });
  };

  if (_.isEmpty(form) || !contact) {
    return <FuseLoading />;
  }

  return (
    <>
      <Box
        className="relative w-full h-136 sm:h-120 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.paper",
        }}
      >
        {contact.background && (
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={contact.background}
            alt="user background"
          />
        )}
      </Box>

      {/*
       */}
      <div className="relative flex flex-1 flex-col h-full px-24 sm:px-48">
        <div className="w-full">
          <div className="w-128 h-128 -mt-64">
            <Controller
              control={control}
              name="picture_src"
              render={({ field: { onChange, value } }) => (
                <FileUpdate
                  refPond={pond}
                  // Preview
                  imagePreviewMaxHeight={200}
                  imagePreviewHeight={170}
                  // file-validate-type
                  imageCropAspectRatio={"1:1"}
                  acceptedFileTypes={"image/*"}
                  stylePanelLayout={"compact circle"}
                  //core
                  files={files}
                  allowProcess={false}
                  instantUpload={true}
                  maxFileSize={"5MB"}
                  // onupdatefiles={onChange}
                  onupdatefiles={(fileItems) => {
                    const files = fileItems.map((fileItem) => fileItem.file);
                    setValue("picture", files[0]);
                    onChange(files[0]);
                    setFiles(files);
                  }}
                  styleLoadIndicatorPosition={"center bottom"}
                  styleButtonRemoveItemPosition={"center bottom"}
                />
              )}
            />
          </div>
        </div>

        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.name"))}
              placeholder={dispatch(t("common.name"))}
              id="name"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              label="Email"
              placeholder="Email"
              variant="outlined"
              disabled={routeParams.id !== "new"}
              required
              fullWidth
              error={!!errors.email}
              helperText={errors?.email?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-solid:mail</FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="phone_number"
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              label={dispatch(t("common.phone"))}
              placeholder={dispatch(t("common.phone"))}
              variant="outlined"
              fullWidth
              error={!!errors.phone_number}
              helperText={errors?.phone_number?.message}
              InputProps={{
                startAdornment: (
                  <Controller
                    control={control}
                    name="phone_country_code"
                    render={({ field: _field }) => (
                      <InputAdornment position="start">
                        <CountryCodeSelector {..._field} />
                      </InputAdornment>
                    )}
                  />
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="lang"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="lang"
              className="mt-32"
              options={languages}
              value={
                value ? _.find(languages, { id: value?.id || value }) : null
              }
              onChange={(event, newValue) => {
                if (newValue != null && typeof newValue === "object")
                  return onChange(newValue.id);

                return onChange(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                value && option.id == value?.id
              }
              getOptionLabel={(option) => option.title}
              renderOption={(_props, option) => (
                <Box
                  {..._props}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`${option.flag}`}
                    alt=""
                  />
                  {option.title}
                </Box>
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={dispatch(t("common.language"))}
                  placeholder={dispatch(t("common.language"))}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Controller
                        control={control}
                        name="lang"
                        render={({ field: _field }) => {
                          const langSrc = languages.find(
                            (l) => _field.value == l.id
                          );
                          return (
                            <InputAdornment position="start">
                              {langSrc && (
                                <img
                                  {..._field}
                                  className="mx-4 min-w-20 object-contain w-20 h-20"
                                  src={langSrc?.flag || ""}
                                  alt={langSrc?.flag || ""}
                                />
                              )}
                            </InputAdornment>
                          );
                        }}
                      />
                    ),
                  }}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="access_level_id"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="access_level_id"
              className="mt-32"
              options={access}
              value={value ? _.find(access, { id: value?.id || value }) : null}
              onChange={(event, newValue) => {
                if (newValue != null && typeof newValue === "object")
                  return onChange(newValue.access_level_id);

                return onChange(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                value && option.access_level_id == value?.access_level_id
              }
              getOptionLabel={(option) => option.name}
              getOptionDisabled={(option) => !Boolean(option.is_active)}
              renderOption={(_props, option) => (
                <li {..._props}>{option.name}</li>
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={dispatch(t("common.access_level"))}
                  placeholder={dispatch(t("common.access_level"))}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="workspace_id"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="workspace_id"
              className="mt-32"
              options={workspaces}
              value={
                value ? _.find(workspaces, { id: value?.id || value }) : null
              }
              onChange={(event, newValue) => {
                if (newValue != null && typeof newValue === "object")
                  return onChange(newValue.workspace_id);

                return onChange(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                value && option.workspace_id == value?.workspace_id
              }
              getOptionLabel={(option) => option.name}
              getOptionDisabled={(option) => !Boolean(option.is_active)}
              renderOption={(_props, option) => (
                <li {..._props}>{option.name}</li>
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={dispatch(t("common.workspace"))}
                  placeholder={dispatch(t("common.workspace"))}
                />
              )}
            />
          )}
        />

        <Controller
          name="signin_token_required"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              className="mt-32"
              control={
                <Checkbox
                  checked={Boolean(value)}
                  onChange={(event) => onChange(event.target.checked ? 1 : 0)}
                  name={dispatch(t("common.2fa"))}
                  color="secondary"
                />
              }
              label={dispatch(t("common.2fa"))}
              id="signin_token_required"
            />
          )}
        />

        <Controller
          control={control}
          name="status"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="status"
              className="mt-32"
              options={status}
              value={
                value ? _.find(status, { value: value?.value || value }) : null
              }
              onChange={(event, newValue) => {
                if (newValue != null && typeof newValue === "object")
                  return onChange(newValue.value);

                return onChange(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                value && option?.value == value?.value
              }
              getOptionLabel={(option) => option.label}
              renderOption={(_props, option) => (
                <li {..._props}>{option.label}</li>
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={dispatch(t("common.status"))}
                  placeholder={dispatch(t("common.status"))}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="birthdate"
          render={({ field }) => (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt"}
            >
              <DatePicker
                {...field}
                className="w-full"
                clearable
                renderInput={(_props) => (
                  <TextField
                    {..._props}
                    value={_props.value || ""}
                    onChange={_props.onChange}
                    className="mt-32"
                    id="birthdate"
                    label={dispatch(t("common.birthday"))}
                    placeholder="DD/MM/YYYY"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FuseSvgIcon size={20}>
                            heroicons-solid:cake
                          </FuseSvgIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />

        <Controller
          name="email_signature"
          control={control}
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.signature"))}
              placeholder={dispatch(t("common.signature"))}
              id="notes"
              error={!!errors.email_signature}
              helperText={errors?.email_signature?.message}
              variant="outlined"
              fullWidth
              multiline
              minRows={5}
              maxRows={10}
              InputProps={{
                className: "max-h-min h-min items-start",
                startAdornment: (
                  <InputAdornment className="mt-16" position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:menu-alt-2
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>

      <Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t">
        {routeParams.id !== "new" &&
          (user.data?.access_level?.is_master ||
            user.data?.access_level?.is_admin) && (
            <Button color="error" onClick={handleRemoveContact}>
              {dispatch(t("common.delete"))}
            </Button>
          )}
        <Button className="ml-auto" component={NavLinkAdapter} to={-1}>
          {dispatch(t("common.cancel"))}
        </Button>
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </Button>
      </Box>
      <ConfirmationDialog
        open={open}
        onClose={handleCancelDialog}
        onSubmit={handleSubmitDialog}
      />
    </>
  );
};

const ConfirmationDialog = ({ open, onClose, onSubmit }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {dispatch(t("common.delete"))}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {dispatch(t("common.confirm_action"))}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onSubmit}>
          {dispatch(t("common.yes"))}
        </Button>
        <Button autoFocus onClick={onClose}>
          {dispatch(t("common.cancel"))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserForm;
