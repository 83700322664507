import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import history from "@history";
// import EntityModel, { EntityModelMerge } from "../model/EntityModel";

import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import _ from "lodash";
import EntityModel, { EntityModelMerge } from "../model/EntityModel";

export const getEntity = createAsyncThunk(
  "entities/profile/getEntity",
  async (id, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/entities/${id}`);

      if (res.status === "error") throw new Error();

      const data = await res.result;

      return data;
    } catch (error) {
      history.push({ pathname: `/system/entities` });

      return null;
    }
  }
);

export const addEntity = createAsyncThunk(
  "entities/list/addEntity",
  async (entity, { dispatch, getState }) => {
    const res = await axiosConfig.post("/entities", entity);

    const updatedEntity = await axiosConfig.get(
      `/entities/${res.result.last_id}`
    );

    const data = await {
      ...updatedEntity.result,
      id: res.result.last_id,
    };

    return data;
  }
);

export const updateEntity = createAsyncThunk(
  "entities/list/updateEntity",
  async (entity, { dispatch, getState }) => {
    const res = await axiosConfig.post(`/entities/${entity.entity_id}`, entity);

    const updatedEntity = await axiosConfig.get(
      `/entities/${entity.entity_id}`
    );

    const data = await {
      ...updatedEntity.result,
      id: updatedEntity.result.entity_id,
    };

    return data;
  }
);

export const removeEntity = createAsyncThunk(
  "entities/list/removeEntity",
  async (id, { dispatch, getState }) => {
    const res = await axiosConfig.delete(`/entities/${id}`);

    await res.result;

    return id;
  }
);

export const EntityEmail = createAsyncThunk(
  "entities/list/EntityEmail",
  async (entity, { dispatch, getState }) => {
    if (entity.hasOwnProperty("email_address_id")) {
      const res = await axiosConfig.post(
        `/entities/${entity.entity_id}/email_addresses/${entity.email_address_id}`,
        entity
      );
      //Change email
      return res.status;
    } else {
      const res = await axiosConfig.post(
        `/entities/${entity.entity_id}/email_addresses`,
        entity
      );
      //Add email
      return res.status;
    }
  }
);

export const removeEntityEmail = createAsyncThunk(
  "entities/list/removeEntityEmail",
  async (entity, { dispatch, getState }) => {
    const res = await axiosConfig.delete(
      `/entities/${entity.entity_id}/email_addresses/${entity.email_address}`
    );

    return res.status;
  }
);

export const EntityPhone = createAsyncThunk(
  "entities/list/EntityPhone",
  async (entity, { dispatch, getState }) => {
    if (entity.hasOwnProperty("phone_number_id")) {
      const res = await axiosConfig.post(
        `/entities/${entity.entity_id}/phone_numbers/${entity.phone_number_id}`,
        entity
      );
      //Change phone
      return res.status;
    } else {
      const res = await axiosConfig.post(
        `/entities/${entity.entity_id}/phone_numbers`,
        entity
      );
      //Add phone
      return res.status;
    }
  }
);

export const removeEntityPhone = createAsyncThunk(
  "entities/list/removeEntityPhone",
  async (entity, { dispatch, getState }) => {
    const res = await axiosConfig.delete(
      `/entities/${entity.entity_id}/phone_numbers/${entity.phone_number_id}`
    );

    return res.status;
  }
);
export const EntityAddress = createAsyncThunk(
  "entities/list/EntityAddress",
  async (entity, { dispatch, getState }) => {
    if (entity.hasOwnProperty("address_id")) {
      const res = await axiosConfig.post(
        `/entities/${entity.entity_id}/addresses/${entity.address_id}`,
        entity
      );
      //Change address
      return res.status;
    } else {
      const res = await axiosConfig.post(
        `/entities/${entity.entity_id}/addresses`,
        entity
      );
      //Add address
      return res.status;
    }
  }
);

export const removeEntityAddress = createAsyncThunk(
  "entities/list/removeEntityAddress",
  async (entity, { dispatch, getState }) => {
    const res = await axiosConfig.delete(
      `/entities/${entity.entity_id}/addresses/${entity.address_id}`
    );

    return res.status;
  }
);

export const selectEntity = ({ entities }) => {
  return entities.entity;
};

const entitySlice = createSlice({
  name: "entities/list",
  initialState: null,

  reducers: {
    newEntity: (state, action) => EntityModel(),
    resetEntity: () => null,
  },
  extraReducers: {
    [getEntity.pending]: (state, action) => null,
    [getEntity.fulfilled]: (state, action) => {
      return action.payload;
    },
    [updateEntity.fulfilled]: (state, action) => action.payload,
    [removeEntity.fulfilled]: (state, action) => null,
  },
});

export const { resetEntity, newEntity } = entitySlice.actions;

export default entitySlice.reducer;
