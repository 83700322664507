import PageComplement from "app/shared-components/PageComplement";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "@lodash";
import { useState } from "react";
import axiosService from "src/app/auth/services/axios/axiosService";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";
import { useSnackbar } from "notistack";
import history from "@history";
import {
  selectDark,
  selectLight,
  selectLogos,
} from "app/store/labels/logosSlice";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { Typography } from "@mui/material";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";

const ForgotPassword = () => {
  const labels = useSelector(selectGlobalLabels);
  const d = useDispatch();
  const [disabledButton, setDisabledButton] = useState(false);
  const logos = useSelector(selectLight);
  const logosDark = useSelector(selectDark);
  const lang = useSelector(selectCurrentLanguageId);
  const theme = axiosService.getTheme();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(d(t("SYSTEM.signin_fields_email_helper_info")))
      .required(d(t("SYSTEM.signin_fields_email_helper"))),
  });

  const defaultValues = {
    email: "",
  };

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = (data, e) => {
    e.preventDefault();

    setDisabledButton(true);

    axiosService
      .requestForgotPassword(data.email, lang)
      .then((res) => {
        history.push("/sign-in");
      })
      .catch((err) => {
        setDisabledButton(false);
      });
  };

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="w-[70%]"
            src={`${process.env.REACT_APP_API_ASSETS}/${
              theme === "defaultDark"
                ? logosDark?.horizontal_logo
                : logos?.horizontal_logo
            }`}
            alt=""
          />
          <p className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            {d(t("SYSTEM.signin_title_reset_password_request"))}
          </p>
          <form
            name="forgotPassForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label={d(t("SYSTEM.signin_fields_email_label"))}
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
            <Button
                variant="text"
                component={NavLinkAdapter}
                className="text-md font-medium "
                style={{
                  textDecoration: "none",
                }}
                to="/sign-in"
              >
                {d(t("SYSTEM.signin_link_signin"))}
              </Button>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className={`w-full mt-16 disabled`}
              aria-label="Forgot Password"
              disabled={_.isEmpty(dirtyFields) || !isValid || disabledButton}
              type="submit"
              size="large"
            >
              {d(t("SYSTEM.signin_buttons_reset_password_request"))}
            </Button>
          </form>
          <div className="mt-10">
            <Typography className="text-sm">
              Powered and Design by{" "}
              <a target="_blank" href="https://codemaker.pt">
                Codemaker.pt
              </a>
            </Typography>
            <Typography className="text-sm">
              APP Version {parseFloat(process.env.REACT_APP_VERSION)}
            </Typography>
          </div>
        </div>
      </Paper>

      <PageComplement />
    </div>
  );
};

export default ForgotPassword;
