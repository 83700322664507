import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class ProfileService {
  updateProfile = (data, user) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/user_data/${user.data.user_id}`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const profileService = new ProfileService();
export default profileService;
