import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import countriesData from "../../../components/json/countries";

export const getCountries = createAsyncThunk(
  "entities/countries/getCountries",
  async (params, { getState }) => {
    const data = countriesData.countries.value;

    return data;
  }
);

const countriesAdapter = createEntityAdapter({});

export const { selectAll: selectCountries, selectById: selectCountriesById } =
  countriesAdapter.getSelectors((state) => state.entities.countries);

const countriesSlice = createSlice({
  name: "entities/countries",
  initialState: countriesAdapter.getInitialState([]),
  reducers: {},
  extraReducers: {
    [getCountries.fulfilled]: countriesAdapter.setAll,
  },
});

export default countriesSlice.reducer;
