import ContactSupport from "./ContactSupport";
import ContactSupportApp from "./ContactSupportApp";

const ContactSupportAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "system/contact_support",
      element: <ContactSupportApp />,
      children: [
        {
          path: "",
          element: <ContactSupport />,
        },
      ],
    },
  ],
};

export default ContactSupportAppConfig;
