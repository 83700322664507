import HelpCenterHome from "./home/HelpCenterHome";
import HelpCenterApp from "./HelpCenterApp";

const HelpCenterAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "system/help_center",
      element: <HelpCenterApp />,
      children: [
        {
          path: "",
          element: <HelpCenterHome />,
        },
      ],
    },
  ],
};

export default HelpCenterAppConfig;
