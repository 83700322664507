import Error_logsApp from "./Error_logsApp";

const Error_logsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "system/error_logs",
      element: <Error_logsApp />,
    },
  ],
};

export default Error_logsAppConfig;
