import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useEffect, useLayoutEffect } from "react";
import axiosService from "src/app/auth/services/axios/axiosService";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";
import {
  selectDark,
  selectLight,
  selectLogos,
} from "app/store/labels/logosSlice";

function SignOutPage() {
  const tags = useSelector(selectGlobalLabels);
  const logos = useSelector(selectLight);
  const logosDark = useSelector(selectDark);
  const theme = axiosService.getTheme();
  const d = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      axiosService.logout();
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="m-auto w-[70%]"
            src={`${process.env.REACT_APP_API_ASSETS}/${
              theme === "defaultDark"
                ? logosDark?.horizontal_logo
                : logos?.horizontal_logo
            }`}
            alt=""
          />

          <Typography className="mt-32 text-2xl font-extrabold tracking-tight leading-tight text-center">
            {d(t("SYSTEM.signin_title_signout"))}
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

export default SignOutPage;
