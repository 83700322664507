import {
  Add,
  FormatListBulletedOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { selectLanguages, selectTableLanguage } from "app/store/i18nSlice";
import { MenuItem, SpeedDial, SpeedDialAction } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { t } from "app/store/labels/globalLabels";
import MaterialReactTable from "material-react-table";
import { setDrawerSelector, setPropertyDrawerState } from "./store/drawerSlice";
import DrawerForm from "./components/drawer/DrawerForm";

const TableProperties = () => {
  const dispatch = useDispatch();
  //const table_properties = dispatch(selectTableProperties);
  //const loading = useSelector(selectStatus);
  const tableLang = useSelector(selectTableLanguage);
  const lang = useSelector(selectLanguages);

  const columns = useMemo(() => {
    const table_propertiesInfo = [
      {
        header: dispatch(t("property_tag")),
        accessorKey: "property_tag",
        size: 100,
      },
      {
        header: dispatch(t("is_required")),
        accessorKey: "is_required",
        size: 100,
        Cell: ({ row }) =>
          row.origianl.is_required === 1
            ? dispatch(t("common.required"))
            : dispatch(t("common.not_required")),
      },
      {
        header: dispatch(t("input_type")),
        accessorKey: "input_type",
        size: 100,
      },
      {
        header: dispatch(t("preset_value")),
        accessorKey: "preset_value",
        size: 100,
      },
      {
        header: dispatch(t("must_select_from_preset")),
        accessorKey: "must_select_from_preset",
        size: 100,
        Cell: ({ row }) =>
          row.origianl.is_required === 1
            ? dispatch(t("common.true"))
            : dispatch(t("common.false")),
      },
      {
        header: dispatch(t("icon")),
        accessorKey: "icon",
        size: 100,
      },
    ];
    return [...table_propertiesInfo];
  }, [, /*table_properties*/ lang]);

  return (
    <div>
      {/* <MaterialReactTable
        data={table_properties}
        columns={columns}
        localization={tableLang}
        enableColumnOrdering
        enableGrouping
        enablePinning
        enableColumnResizing
        enableStickyHeader
        enableFullScreenToggle={false}
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 332px)" } }}
        initialState={{
          sorting: [{ id: "date_time", desc: true }],
          pagination: { pageIndex: 0, pageSize: 15 },
        }}
        state={{
          showSkeletons: loading !== "success" || isLoading ? true : false,
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        defaultColumn={{ minSize: 20 }}
        enableRowActions
      /> */}

      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle={dispatch(t("common.add"))}
          tooltipOpen
          onClick={() => {
            dispatch(setPropertyDrawerState(true));
            dispatch(setDrawerSelector(true));
          }}
        />
        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh_table"))}
          tooltipOpen
        />
      </SpeedDial>
      <DrawerForm />
    </div>
  );
};

export default TableProperties;
