import { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";

function FuseSplashScreen() {
  const [count, setCount] = useState(0);
  const [error, setError] = useState("");

  const [countPage, setCountPage] = useState(0);

  useEffect(() => {
    const loadingTimer = setInterval(() => {
      if (count === 15) {
        setError("System Error, please reload the web page!");
        clearInterval(loadingTimer);
        return 0;
      }
      setCount(count + 1);
    }, 1000);

    return () => clearInterval(loadingTimer);
  }, [count]);

  useEffect(() => {
    const pageTimer =
      error &&
      setInterval(() => {
        if (countPage === 5) {
          window.location.reload(false);
          clearInterval(pageTimer);
          return 0;
        }
        setCountPage(countPage + 1);
      }, 1000);

    return () => clearInterval(pageTimer);
  }, [countPage, error]);

  return (
    <div id="fuse-splash-screen">
      <div className="logo">
        {/* <img width="128" src="assets/images/logo/logo.svg" alt="logo" /> */}
      </div>
      <Box
        id="spinner"
        sx={{
          "& > div": {
            backgroundColor: "#f0f0f0",
          },
        }}
      >
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </Box>

      {error && (
        <div className="bg-red-800 text-white rounded-md p-5 mt-10">
          {error}
        </div>
      )}
    </div>
  );
}

export default memo(FuseSplashScreen);
