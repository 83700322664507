import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { selectUser, updateUserProfile } from "app/store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState, useRef } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import _ from "@lodash";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import FileUpdate from "../components/fileUpdate/FileUpdate";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import CountryCodeSelector from "../system/users/contact/phone-number-selector/CountryCodeSelector";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { changeLanguage, selectLanguages } from "app/store/i18nSlice";
import profileService from "./service/profileService";
import { FormatDateSQL } from "app/shared-components/Functions";
import { ProfileModelMerge } from "./model/ProfileModel";

const ProfileEdit = ({ isEdit }) => {
  const user = useSelector(selectUser);
  const languages = useSelector(selectLanguages);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const pond = useRef(null);

  const schema = yup.object().shape({
    name: yup.string().required(dispatch(t("common.input_required"))),
    lang: yup.string().required(dispatch(t("common.input_required"))),
  });

  const {
    control,
    watch,
    formState,
    handleSubmit,
    getValues,
    setValue,
    reset,
    resetField,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  useEffect(() => {
    reset({ ...ProfileModelMerge(user.data) });
  }, [user, reset]);

  useEffect(() => {
    if (user) {
      setFiles(user?.data.picture_src);
    }
  }, [user]);

  const onSubmit = async (data) => {
    const body = {
      ...data,
      [`phone_number[number]`]: data.phone_number,
      [`phone_number[country_code]`]: data.phone_country_code,
      birthdate: FormatDateSQL(data.birthdate, false),
      picture: data.picture_src || "",
      picture_src: "",
    };

    try {
      const res = await profileService.updateProfile(body, user);

      dispatch(updateUserProfile(body)).then((r) => {
        isEdit(false);
        dispatch(changeLanguage(data.lang));
      });
    } catch (error) {}
  };

  const handleCancel = () => {
    isEdit(false);
  };

  if (_.isEmpty(form) || !user) {
    return <FuseLoading />;
  }

  return (
    <>
      <Box
        className="relative w-full h-136 sm:h-120 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.paper",
        }}
      />

      {/*
       */}
      <div className="relative flex flex-1 flex-col h-auto items-center px-24 sm:px-48 mb-10">
        <div className="w-full">
          <div className="w-128 h-128 -mt-64">
            <Controller
              control={control}
              name="picture_src"
              render={({ field: { onChange, value } }) => (
                <FileUpdate
                  refPond={pond}
                  // Preview
                  imagePreviewMaxHeight={200}
                  imagePreviewHeight={170}
                  // file-validate-type
                  imageCropAspectRatio={"1:1"}
                  acceptedFileTypes={"image/*"}
                  stylePanelLayout={"compact circle"}
                  //core
                  files={files}
                  allowProcess={false}
                  instantUpload={true}
                  maxFileSize={"5MB"}
                  // onupdatefiles={onChange}
                  onupdatefiles={(fileItems) => {
                    const files = fileItems.map((fileItem) => fileItem.file);
                    setValue("picture", files[0]);
                    onChange(files[0]);
                    setFiles(files);
                  }}
                  styleLoadIndicatorPosition={"center bottom"}
                  styleButtonRemoveItemPosition={"center bottom"}
                />
              )}
            />
          </div>
        </div>

        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.name"))}
              placeholder={dispatch(t("common.name"))}
              id="name"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              label="Email"
              placeholder="Email"
              variant="outlined"
              disabled={true}
              required
              fullWidth
              error={!!errors.email}
              helperText={errors?.email?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-solid:mail</FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="phone_number"
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              label={dispatch(t("common.phone"))}
              placeholder={dispatch(t("common.phone"))}
              variant="outlined"
              fullWidth
              error={!!errors.phone_number}
              helperText={errors?.phone_number?.message}
              InputProps={{
                startAdornment: (
                  <Controller
                    control={control}
                    name="phone_country_code"
                    render={({ field: _field }) => (
                      <InputAdornment position="start">
                        <CountryCodeSelector {..._field} />
                      </InputAdornment>
                    )}
                  />
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="lang"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="lang"
              className="mt-32"
              options={languages}
              value={
                value ? _.find(languages, { id: value?.id || value }) : null
              }
              onChange={(event, newValue) => {
                if (newValue != null && typeof newValue === "object")
                  return onChange(newValue.id);

                return onChange(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                value && option.id == value?.id
              }
              getOptionLabel={(option) => option.title}
              renderOption={(_props, option) => (
                <Box
                  {..._props}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`${option.flag}`}
                    alt=""
                  />
                  {option.title}
                </Box>
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={dispatch(t("common.language"))}
                  placeholder={dispatch(t("common.language"))}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Controller
                        control={control}
                        name="lang"
                        render={({ field: _field }) => {
                          const langSrc = languages.find(
                            (l) => _field.value == l.id
                          );
                          return (
                            <InputAdornment position="start">
                              {langSrc && (
                                <img
                                  {..._field}
                                  className="mx-4 min-w-20 object-contain w-20 h-20"
                                  src={langSrc?.flag || ""}
                                  alt={langSrc?.flag || ""}
                                />
                              )}
                            </InputAdornment>
                          );
                        }}
                      />
                    ),
                  }}
                />
              )}
            />
          )}
        />

        <Controller
          name="signin_token_required"
          className=""
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              className="mt-32 w-full"
              control={
                <Checkbox
                  checked={Boolean(value)}
                  onChange={(event) => onChange(event.target.checked ? 1 : 0)}
                  name={dispatch(t("common.2fa"))}
                  color="secondary"
                />
              }
              label={dispatch(t("common.2fa"))}
              id="signin_token_required"
            />
          )}
        />

        <Controller
          control={control}
          name="birthdate"
          render={({ field }) => (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt"}
            >
              <DatePicker
                {...field}
                className="w-full"
                clearable
                renderInput={(_props) => (
                  <TextField
                    {..._props}
                    value={_props.value || ""}
                    onChange={_props.onChange}
                    className="mt-32"
                    id="birthdate"
                    label={dispatch(t("common.birthday"))}
                    placeholder="DD/MM/YYYY"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FuseSvgIcon size={20}>
                            heroicons-solid:cake
                          </FuseSvgIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />

        <Controller
          name="email_signature"
          control={control}
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.signature"))}
              placeholder={dispatch(t("common.signature"))}
              id="notes"
              error={!!errors.email_signature}
              helperText={errors?.email_signature?.message}
              variant="outlined"
              fullWidth
              multiline
              minRows={5}
              maxRows={10}
              InputProps={{
                className: "max-h-min h-min items-start",
                startAdornment: (
                  <InputAdornment className="mt-16" position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:menu-alt-2
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>

      <Box
        className="flex items-center py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
      >
        <Button className="ml-auto" onClick={handleCancel}>
          {dispatch(t("common.cancel"))}
        </Button>
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </Button>
      </Box>
    </>
  );
};

export default ProfileEdit;
