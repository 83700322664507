import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  selectEditState,
  selectOpenState,
  selectRowInfo,
  setEditDrawer,
  setOpenDrawer,
} from "../store/drawerSlice";
import { t } from "app/store/labels/globalLabels";
import { Drawer } from "rsuite";
import { useState } from "react";
import { Button, TextField, Autocomplete } from "@mui/material";
import { Cron } from "react-js-cron";
import {
  addCronjobs,
  selectFiles,
  updateCronjobs,
} from "../store/cronjobsSlice";
import { Controller, useForm } from "react-hook-form";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormatDateSQL } from "app/shared-components/Functions";
import { useEffect } from "react";
import dayjs from "dayjs";
import cronjobsService from "../service/CronjobsService";
import "dayjs/locale/pt";
import "react-js-cron/dist/styles.css";

const DrawerMenu = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectOpenState);
  const edit = useSelector(selectEditState);
  const files = useSelector(selectFiles);
  const editRow = useSelector(selectRowInfo);

  const [cronjob, setCronjob] = useState("* * * * *");
  const [date, setDate] = useState("");

  const defaultValues = {
    routine: cronjob,
    name: "",
    cjr_data: "",
    startDate: "",
    endDate: "",
    file_path: "",
  };

  const changeCronValue = (cron) => {
    setCronjob(cron);

    setValue("routine", cron),
      {
        shouldDirty: true,
        shouldValidate: true,
      };
  };

  const schema = yup.object().shape({
    routine: yup.string(),
    name: yup.string().required(),
    startDate: yup.string(),
    endDate: yup
      .string()
      .test(
        "is-later-than-start",
        "End date must be later than start date",
        function (value) {
          const { startDate } = this.parent;
          if (!startDate || !value) {
            return true; // Skip validation if start or end date is not set
          }
          return dayjs(value).isAfter(dayjs(startDate)); // Compare the dates using dayjs or your preferred date library
        }
      ),
    file_path: yup.string().required("File path is required"),
  });

  const { control, formState, handleSubmit, reset, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;

  useEffect(() => {
    if (edit) {
      setCronjob(editRow.original.cjroutine);
      setValue("routine", editRow.original.cjroutine),
        {
          shouldDirty: true,
          shouldValidate: true,
        };

      setValue("name", editRow.original.name),
        {
          shouldDirty: true,
          shouldValidate: true,
        };

      setValue("cjr_data", editRow.original.cjr_data),
        {
          shouldDirty: true,
          shouldValidate: true,
        };

      setValue(
        "startDate",
        editRow.original.start_date_time
          ? new Date(editRow.original.start_date_time)
          : ""
      ),
        {
          shouldDirty: true,
          shouldValidate: true,
        };

      setValue(
        "endDate",
        editRow.original.end_date_time
          ? new Date(editRow.original.end_date_time)
          : ""
      ),
        {
          shouldDirty: true,
          shouldValidate: true,
        };

      setValue("file_path", editRow.original.file_path),
        {
          shouldDirty: true,
          shouldValidate: true,
        };
    }
  }, [edit, editRow, setValue]);

  const handleClose = () => {
    reset();
    dispatch(setOpenDrawer(false));
    dispatch(setEditDrawer(false));
  };

  const submit = async (data) => {
    const sData = data?.startDate ? FormatDateSQL(data?.startDate) : "";
    const eData = data?.endDate ? FormatDateSQL(data?.endDate) : "";

    const value = {
      name: data?.name,
      cjr_data: data?.cjr_data,
      file_path: data?.file_path,
      start_date_time: sData,
      end_date_time: eData,
      cjroutine: data?.routine,
    };

    try {
      let res;
      if (edit) {
        res = await cronjobsService.editCronjob(
          value,
          editRow.original.cronjob_id
        );
      } else {
        res = await cronjobsService.addCronjob(value);
      }

      if (edit) {
        const changedRow = {
          ...value,
          cronjob_id: editRow.original.cronjob_id,
          is_active: editRow.original.is_active,
          save_request: editRow.original.save_request,
        };

        dispatch(updateCronjobs({ value: changedRow, index: editRow.index }));
      } else {
        const newRow = {
          ...value,
          is_active: true,
          save_request: true,
          cronjob_id: res.result.last_id,
          created: FormatDateSQL(new Date()),
        };
        dispatch(addCronjobs(newRow));
      }
    } catch (error) {}

    handleClose();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Drawer backdrop={true} open={open} onClose={handleClose}>
      <Drawer.Header>
        <Drawer.Title>
          {edit ? dispatch(t("common.edit")) : dispatch(t("common.add"))}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <form
          name="cronjobForm"
          className="mt-20"
          noValidate
          onSubmit={handleSubmit(submit)}
        >
          <Controller
            name="routine"
            control={control}
            render={({ field }) => (
              <TextField
                disabled={true}
                {...field}
                className="mb-24"
                label={dispatch(t("common.routine"))}
                type="string"
                error={!!errors.routine}
                helperText={errors?.routine?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          <div className="mt-10 mb-10">
            <Cron
              className="!flow-root"
              value={cronjob}
              setValue={changeCronValue}
            />
          </div>

          <Controller
            name="file_path"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="file_path"
                isOptionEqualToValue={(value) => value}
                value={value || null}
                onChange={(e, newValue) => {
                  if (typeof newValue === "string") {
                    onChange("");
                  } else if (newValue === null) {
                    onChange(null);
                  } else {
                    onChange(`${newValue.file_path}`);
                  }
                }}
                selectOnFocus
                clearOnBlur
                options={files}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return `${option.file_path}: ${option.data.name} - ${option.data.description}`;
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={`${option.file_path}: ${option.data.name} ${option.data.description}`}
                  >
                    {option.file_path}: {option.data.name}(
                    {option.data.description})
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    className="mt-10 mb-20"
                    {...params}
                    label={dispatch(t("common.file"))}
                    type="string"
                    error={!!errors.file_path}
                    helperText={errors?.file_path?.message}
                    variant="outlined"
                    required
                    fullWidth
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label={dispatch(t("common.name"))}
                type="string"
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="cjr_data"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ""}
                className="mb-24"
                label={"Request Data (JSON)"}
                type="string"
                error={!!errors.cjr_data}
                helperText={errors?.cjr_data?.message}
                variant="outlined"
                required
                fullWidth
                multiline
                minRows={5}
                maxRows={10}
              />
            )}
          />
          <Controller
            name="startDate"
            control={control}
            defaultValue={null}
            className="mr-4"
            render={({ field: { onChange, ...restField } }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt"}
              >
                <DateTimePicker
                  label={dispatch(t("common.start_date"))}
                  value={date || null}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      error={!!errors.startDate}
                      helperText={errors?.startDate?.message}
                    />
                  )}
                  {...restField}
                />
              </LocalizationProvider>
            )}
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={null}
            render={({ field: { onChange, ...restField } }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt"}
              >
                <DateTimePicker
                  label={dispatch(t("common.end_date"))}
                  value={date || null}
                  minDate={date ? dayjs(value).add(1, "second").toDate() : null}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      error={!!errors.endDate}
                      helperText={errors?.endDate?.message}
                    />
                  )}
                  {...restField}
                />
              </LocalizationProvider>
            )}
          />

          <Button
            variant="contained"
            color="secondary"
            className="mt-20 mb-10"
            disabled={!edit && (_.isEmpty(dirtyFields) || !isValid)}
            onKeyPress={handleKeyPress}
            type="submit"
            fullWidth
          >
            {edit ? dispatch(t("common.edit")) : dispatch(t("common.add"))}
          </Button>
        </form>
      </Drawer.Body>
    </Drawer>
  );
};

export default DrawerMenu;
