import FusePageSimple from "@fuse/core/FusePageSimple";
import { motion } from "framer-motion";
import _ from "lodash";
import Cronjobs from "./Cronjobs";
import CronjobsAppHeader from "./CronjobsAppHeader";
import { useEffect, useMemo } from "react";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCronjobsList,
  selectCronjobs,
  selectFiles,
} from "./store/cronjobsSlice";

const CronjobsApp = () => {
  const dispatch = useDispatch();
  const cronjobs = useSelector(selectCronjobs);
  const files = useSelector(selectFiles);

  useEffect(() => {
    dispatch(fetchCronjobsList());
  }, [dispatch]);

  return (
    <>
      <FusePageSimple
        header={<CronjobsAppHeader />}
        content={
          <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
            {useMemo(() => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full mt-32">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <Cronjobs />
                    </motion.div>
                  </div>
                </motion.div>
              );
            }, [cronjobs])}
          </div>
        }
      />
    </>
  );
};

export default withReducer("cronjobs", reducer)(CronjobsApp);
