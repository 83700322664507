import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  changeLanguage,
  selectCurrentLanguage,
  selectLanguages,
} from "app/store/i18nSlice";
import { selectUser, updateUserLang } from "app/store/userSlice";

function LanguageSwitcher(props) {
  const currentLanguage = useSelector(selectCurrentLanguage);
  const languages = useSelector(selectLanguages);
  const [menu, setMenu] = useState(null);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };

  function handleLanguageChange(lng) {
    dispatch(changeLanguage(lng.id));

    if (user.role?.length > 0) dispatch(updateUserLang(lng.id));

    langMenuClose();
  }

  return (
    <>
      <Button className="h-40 w-64" onClick={langMenuClick}>
        {currentLanguage?.flag && (
          <img
            className="mx-4 min-w-20 object-contain w-20 h-20"
            src={currentLanguage?.flag}
            alt={currentLanguage?.title}
          />
        )}

        <Typography className="mx-4 font-semibold uppercase">
          {currentLanguage?.id}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {languages?.map((lng) => (
          <MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
            <ListItemIcon className="min-w-40">
              {currentLanguage?.flag && (
                <img
                  className="min-w-20 object-contain w-20 h-20"
                  // src={`assets/images/flags/${lng.flag}.png`}
                  src={lng.flag}
                  alt={lng.title}
                />
              )}
            </ListItemIcon>
            <ListItemText primary={lng.title} />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
