import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneNumberSelector from "../phone-number-selector/PhoneNumberSelector";

const EntityPhoneInput = (props) => {
  const entity = props.entity;

  const { control, reset } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    reset({ ...entity });
  }, [entity, reset]);

  return (
    <>
      <Controller
        control={control}
        name="phone_numbers"
        render={({ field }) => <PhoneNumberSelector {...field} />}
      />
    </>
  );
};

export default EntityPhoneInput;
