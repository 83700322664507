import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import history from "@history";
import AccessLevelCardModel from "../components/model/AccessLevelCardModel";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getCard = createAsyncThunk(
  "access_level/card/getCard",
  async (id, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/access_level/${id}`);

      return res.result;
    } catch (error) {
      history.push({ pathname: `/system/access-level/cards` });

      return null;
    }
  }
);

export const addCard = createAsyncThunk(
  "access_level/list/addCard",
  async (card, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.post("/access_level", card);

      if (res.status === "error") throw new Error(res);

      return { ...card, access_level_id: res.result.last_id };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateCard = createAsyncThunk(
  "access_level/list/updateCard",
  async (card, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.post(
        `/access_level/${card.access_level_id}`,
        card
      );

      if (res.status === "error") throw new Error(res);

      return { ...card };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const removeCard = createAsyncThunk(
  "access_level/list/removeCard",
  async (card, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.delete(
        `/access_level/${card.access_level_id}`
      );

      if (res.status === "error") throw new Error(res);

      return card;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const selectCard = ({ access_level }) => {
  return access_level.card;
};

const accessLevelSlice = createSlice({
  name: "access_level/card",
  initialState: null,
  reducers: {
    newCard: (state, action) => AccessLevelCardModel(),
    resetCard: () => null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCard.pending, (state, action) => null)
      .addCase(getCard.fulfilled, (state, action) => {
        return action.payload;
      });
  },
});

export const { resetCard, newCard } = accessLevelSlice.actions;

export default accessLevelSlice.reducer;
