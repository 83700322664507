import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { showMessage } from "app/store/fuse/messageSlice";
import { getUserShortcuts, logoutUser, setUser } from "app/store/userSlice";
import { mountNavigation, setNavigation } from "../store/fuse/navigationSlice";
import axiosService from "./services/axios/axiosService";
import axiosConfig from "./services/axios/axiosConfig";
import history from "@history";
import { changeLanguage } from "app/store/i18nSlice";

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    axiosService.on("onAutoLogin", (user) => {
      success(user, `${user.name ? user.name : ""}`);
    });

    axiosService.on("onToken", () => {
      dispatch(
        showMessage({ message: "A Secret Code was sent to your E-mail!" })
      );
    });

    axiosService.on("onLogin", (session_token, brs_tr) => {
      axiosConfig
        .post("?menu_id=1")
        .then((res) => {
          if (res?.message === "invalid_session_token") {
            pass(null);
          }
          success(res.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });

    axiosService.on("onAutoLogout", (err) => {
      if (err === "invalid_session_token") {
        pass(err);
      } else {
        setWaitAuthCheck(false);
        setIsAuthenticated(false);
      }
    });

    axiosService.on("onLogout", () => {
      pass(null);
      dispatch(logoutUser());
    });

    axiosService.on("onErrorSessionToken", () => {
      dispatch(showMessage({ message: "XXXXXXXXXXXXXXXXXXXXXXXX" }));
    });

    axiosService.on("onNoAccessToken", () => {
      pass();
    });

    axiosService.on("onInvalidTokenPass", (err) => {
      err && dispatch(showMessage({ message: err }));
      history.push("/sign-in");
    });

    axiosService.on("SignUp", (msg) => {
      dispatch(showMessage({ message: msg }));
    });

    axiosService.on("noAccessUser", () => {
      history.push("/");
    });

    axiosService.on("okAccessAs", (userData) => {
      accessAsUser(userData);
    });

    axiosService.init();

    async function success(user, message) {
      axiosService.setAdminDebug(null);
      const displayName = user.name;

      const userRole = (is_master) => {
        if (is_master) {
          axiosService.setAdminDebug(true);
        } else {
          axiosService.setAdminDebug(null);
        }
      };
      userRole(user.access_level?.is_master || null);

      const data = {
        data: {
          displayName,
          ...user,
        },
        role: "admin",
      };

      dispatch(setUser(data))
        .then((payload) => {
          dispatch(
            setNavigation(mountNavigation(data.data.workspace?.menu || []))
          );
        })
        .then((r) => {
          dispatch(changeLanguage(data.data.lang || data.data.session_lang));
        })
        .then((r) => {
          setWaitAuthCheck(false);
          setIsAuthenticated(true);
        })
        .catch((err) => {
          setWaitAuthCheck(true);
          setIsAuthenticated(false);
        });

      // Promise.all([
      //   dispatch(setUser(data)),
      //   dispatch(
      //     setNavigation(mountNavigation(data.data.workspace?.menu || []))
      //   ),
      //   dispatch(changeLanguage(data.data.lang)),
      // ]).then((values) => {
      //   setWaitAuthCheck(false);
      //   setIsAuthenticated(true);
      // });
    }

    async function accessAsUser(user, message) {
      const displayName = user.name;

      const data = {
        data: {
          displayName,
          ...user,
        },
        role: "admin",
      };

      dispatch(setUser(data))
        .then((payload) => {
          dispatch(
            setNavigation(mountNavigation(data.data.workspace?.menu || []))
          );
        })
        .then((r) => {
          setWaitAuthCheck(false);
          history.push("/");
        })
        .catch((e) => {
          setWaitAuthCheck(true);
          setIsAuthenticated(false);
          axiosService.handleAuthentication();
        });
    }

    function pass(message) {
      // if (message) {
      //   dispatch(showMessage({ message }));
      // }
      axiosService.setSignInToken(null);
      axiosService.setSessionToken(null);
      axiosService.setBRSTR(null);
      axiosService.setClientAccess(null);
      axiosService.setAdminDebug(null);

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  return waitAuthCheck ? (
    <FuseSplashScreen />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated, setWaitAuthCheck }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
