import { combineReducers } from "@reduxjs/toolkit";
import list from "./notificationsSlice";
import drawer from "./drawerSlice";
import users from "./usersSlice";

const reducer = combineReducers({
  list,
  drawer,
  users,
});

export default reducer;
