import SecretCode from './SecretToken';
import authRoles from '../../../auth/authRoles';

const SecretTokenConfig = {
    settings: {
        layout: {
          config: {
            navbar: {
              display: false,
            },
            toolbar: {
              display: false,
            },
            footer: {
              display: false,
            },
            leftSidePanel: {
              display: false,
            },
            rightSidePanel: {
              display: false,
            },
          },
        },
      },
      auth: authRoles.onlyGuest,
      routes: [
        {
            path: 'secretcode',
            element: <SecretCode />
        }
      ]
}

export default SecretTokenConfig