import FuseUtils from "@fuse/utils/FuseUtils";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import menu from "../config/menu";
import { SectionNewNaviModel, TemplateNaviModel } from "../model/SectionModel";

const navigationAdapter = createEntityAdapter({});
const emptyInitialState = navigationAdapter.getInitialState({});
const initialState = navigationAdapter.upsertMany(emptyInitialState, menu);

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

const prependNavigationItem = (item, parentId) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(
    setNavigation(FuseUtils.appendNavItem(navigation, item, parentId))
  );
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const {
  selectAll: selectNavigationAll,
  selectIds: selectNavigationIds,
  selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors((state) => state.section.navigationSections);

const navigationSectionsSlice = createSlice({
  name: "section/navigationSections",
  initialState,
  reducers: {
    setNavigation: navigationAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },
  extraReducers: {},
});

export const createNavigation = createAsyncThunk(
  "section/navigationSections/createNavigation",
  async (data, { dispatch, getState }) => {
    await data.map((i, index) => {
      dispatch(
        prependNavigationItem(
          {
            ...i,
          },
          `temp_${i.post_template_id}`
        )
      );
    });
  }
);

export const createTemplatesGroups = createAsyncThunk(
  "section/navigationSections/createTemplatesGroups",
  async (data, { dispatch, getState }) => {
    await data.map((i, index) => {
      dispatch(
        prependNavigationItem({
          ...i,
        })
      );
    });
  }
);

export const mountNavigation = (navigation) => {
  const arrayNew = [];

  for (let index = 0; index < navigation.length; index++) {
    const element = navigation[index];

    arrayNew.push(SectionNewNaviModel(element));

    if (element.subitems)
      arrayNew[index].children = mountNavigation(element.subitems);
  }

  return arrayNew;
};

export const mountTemplatesGroups = (navigation) => {
  const arrayNew = [];

  for (let index = 0; index < navigation.length; index++) {
    const element = navigation[index];

    arrayNew.push(TemplateNaviModel(element));
  }

  return arrayNew;
};

export const { setNavigation, resetNavigation } =
  navigationSectionsSlice.actions;

export const selectNavigation = createSelector(
  [selectNavigationAll],
  (navigation) => {
    return navigation;
  }
);

export default navigationSectionsSlice.reducer;
