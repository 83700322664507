import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class GatewaysService {
  editGroup = (_obj) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/devices/${_obj.user_id}/gateways/voyager_wifi/${_obj.deviceId}`,
          _obj
        )
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const gatewayService = new GatewaysService();
export default gatewayService;
