import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { t } from "app/store/labels/globalLabels";
import {
  fetchErrorsList,
  fiterError_log,
  setActiveError_logs,
  setFilterError_logs,
  setFilterError_logs_menu,
  setFilterError_logs_table,
} from "../store/errorsSlice";
import FuseNavigation from "@fuse/core/FuseNavigation/FuseNavigation";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import { updateNavigationItem } from "../store/navigationError_logsSlice";
import ConfirmationDialog from "src/app/main/components/confirmationDialog/ConfirmationDialog";
import errorsService from "../service/Error_logsService";

const Error_logsSideBar = ({ navigation }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState("");
  const [dis, setDis] = useState(true);
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [menu, setMenu] = useState({});
  const [text, setText] = useState("");

  const handleOpenValidation = (file) => {
    setSelectedFile(file);
    setOpenValidationDialog(true);
  };

  const handleNo = () => {
    setOpenValidationDialog(false);
  };

  const handleClick = async (item) => {
    if (item.type === "item") {
      const active = await navigation[0].children.find((i) =>
        Boolean(i.active)
      );
      if (active.id !== item.id) {
        dispatch(
          updateNavigationItem(active.id, {
            active: false,
          })
        );
        dispatch(
          updateNavigationItem(item.id, {
            active: true,
          })
        );
        dispatch(setActiveError_logs(item.id));
        setMenu(item);
      }
      dispatch(setFilterError_logs_table(item.id));
      setSelectedFile(item);
    }
  };

  const handleChange = (e) => {
    setText(e.target.value);
    dispatch(fiterError_log(e.target.value));
  };

  const handleDelete = async (selectedFile) => {
    try {
      const res = await errorsService.removeFile(selectedFile.id);

      dispatch(fetchErrorsList());
    } catch (error) {}
    handleNo();
    setSelectedFile("");
    setDis(true);
  };

  return (
    <div className="px-12 py-24 h-full">
      <div className="mt-20">
        <div className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-full shadow-none">
          <FuseSvgIcon color="action" size={20}>
            heroicons-solid:search
          </FuseSvgIcon>

          <Input
            placeholder={dispatch(t("common.search"))}
            className="flex flex-1 px-8"
            disableUnderline
            fullWidth
            value={text}
            inputProps={{
              "aria-label": "Search",
            }}
            onChange={handleChange}
          />
        </div>
        <FuseNavigation
          onItemClick={handleClick}
          navigation={navigation}
          className="px-0"
          hasActions={{
            active: true,
            onDelete: handleOpenValidation,
          }}
        />
      </div>
      <ConfirmationDialog
        open={openValidationDialog}
        onClose={handleNo}
        onSubmit={() => handleDelete(selectedFile)}
        title="common.delete"
      />
    </div>
  );
};

export default Error_logsSideBar;
