import { createSlice, current } from "@reduxjs/toolkit";
import PushNotificationsModel, {
  PushNotificationsModelMerge,
} from "../model/PushNotificationsModel";

const drawerSlice = createSlice({
  name: "push_notifications/drawer",
  initialState: {
    drawerState: false,
    drawerUserState: false,
    users: [],
  },

  reducers: {
    setEmptyPushNotification: (state) => {
      state.editRow = PushNotificationsModel();
      state.drawerState = true;
    },
    setDrawerState: (state, action) => {
      state.drawerState = action.payload;
    },
    setDrawerUserState: (state, action) => {
      state.drawerUserState = action.payload;
    },
    setUserDrawer: (state, action) => {
      state.users = action.payload;
      state.drawerUserState = true;
    },
  },
  extraReducers: {},
});

export const selectDrawerState = ({ push_notifications }) =>
  push_notifications.drawer.drawerState;
export const selectDrawerUserState = ({ push_notifications }) =>
  push_notifications.drawer.drawerUserState;
export const selectUsers = ({ push_notifications }) =>
  push_notifications.drawer.users;

export const {
  setDrawerState,
  setDrawerUserState,
  setEmptyPushNotification,
  setUserDrawer,
} = drawerSlice.actions;

export default drawerSlice.reducer;
