import FuseScrollbars from "@fuse/core/FuseScrollbars";
import FuseThemeSchemes from "@fuse/core/FuseThemeSchemes";
import themesConfig from "app/configs/themesConfig";
import { changeFuseTheme } from "app/store/fuse/settingsSlice";
import React from "react";
import { useDispatch } from "react-redux";

const ThemeChange = () => {
  const dispatch = useDispatch();

  return (
    <FuseScrollbars className="p-16 sm:p-32">
      <FuseThemeSchemes
        themes={themesConfig}
        onSelect={(_theme) => {
          dispatch(changeFuseTheme(_theme));
        }}
      />
    </FuseScrollbars>
  );
};

export default ThemeChange;
