import _ from "@lodash";

const RelationModel = (obj) =>
  _.defaults(obj || {}, {
    request_permissions: [],
    request_method_exceptions: [],
    request_exceptions: "",
    response_exceptions: "",
    is_active: false,
  });

export const RelationModelMerge = (data) =>
  _.mergeWith(
    {
      request_permissions: [],
      request_method_exceptions: [],
      request_exceptions: "",
      response_exceptions: "",
    },
    data,
    (objValue, srcValue, key) => {
      if (key == "request_permissions" || key == "request_method_exceptions")
        return JSON.parse(srcValue);

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export default RelationModel;
