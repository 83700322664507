import { combineReducers } from "@reduxjs/toolkit";
import menu from "./access_logsSlice";
import table from "./access_logTableSlice";
import navigationAccess_logs from "./navigationAccess_logsSlice";

const reducer = combineReducers({
  menu,
  table,
  navigationAccess_logs,
});

export default reducer;
