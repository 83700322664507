import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  selectGlobalLabels,
  selectPageSubTitle,
  selectPageTitle,
} from "app/store/labels/globalLabels";
import { useDispatch, useSelector } from "react-redux";

const LanguagesAppHeader = (props) => {
  const dispatch = useDispatch();
  const labels = useSelector(selectGlobalLabels);
  const pageTitle = useSelector(selectPageTitle);
  const pageSubtitle = useSelector(selectPageSubTitle);

  return (
    <div className="flex w-full container">
      <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-24 md:p-32 pb-0 md:pb-0">
        <div className="flex flex-col flex-auto">
          <Typography className="text-3xl font-semibold tracking-tight leading-8">
            {pageTitle}
          </Typography>
          <Typography
            className="font-medium tracking-tight"
            color="text.secondary"
          >
            {pageSubtitle}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LanguagesAppHeader;
