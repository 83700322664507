import { combineReducers } from "@reduxjs/toolkit";
import table from "./errorsSlice";
import navigationError_logs from "./navigationError_logsSlice";

const reducer = combineReducers({
  table,
  navigationError_logs,
});

export default reducer;
