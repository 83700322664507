import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { action } from "mobx";
import { axiosInitial } from "src/app/auth/services/axios/axiosConfig";

export const fetchLanguageList = createAsyncThunk(
  "languages/table/fetchLanguageList",
  async (langId, { dispatch, getState }) => {
    try {
      const res = await axiosInitial.get(`?all_lang_list=1&lang=${langId}`);

      if (res.status === "error") throw new Error(res);

      return res.result;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const tableSlice = createSlice({
  name: "languages/table",
  initialState: {
    activeLang: [],
    systemLang: [],
    allLang: [],
    columns: [],
    row: {},
    status: "pending",
  },
  reducers: {
    setActiveLang: (state, action) => {
      state.activeLang = action.payload;
    },
    setSystemLang: (state, action) => {
      state.systemLang = action.payload;
    },
    setAllLang: (state, action) => {
      state.allLang = action.payload;
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setRows: (state, action) => {
      state.row = action.payload;
    },
    updateFlag: (state, action) => {
      const { index, flag } = action.payload;
      state.systemLang[index].flag = flag;
    },
    updateLangState: (state, action) => {
      const { index, bool } = action.payload;
      state.systemLang[index].active = bool;
    },
    removeLanguage: (state, action) => {
      const { index, original } = action.payload;
      state.systemLang.splice(index, 1);
      state.allLang.push({ name: original.name, code: original.code });
    },
    addLanguage: (state, action) => {
      state.systemLang.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguageList.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchLanguageList.fulfilled, (state, action) => {
        state.activeLang = action.payload.lang_list;

        state.systemLang = action.payload.system_lang_list.map((lang) => {
          const bool = action.payload.lang_list.some(
            (l) => l.code === lang.code
          );
          return {
            name: lang.name,
            code: lang.code,
            flag: lang.flag_src,
            active: bool,
          };
        });

        state.allLang =
          action.payload.all_lang_list.filter(
            (lang) =>
              !action.payload.system_lang_list.some(
                (lang2) => lang.code === lang2.code
              )
          ) || [];
        state.status = "success";
      })
      .addCase(fetchLanguageList.rejected, (state, action) => {
        state.activeLang = [];
        state.systemLang = [];
        state.allLang = [];
        state.columns = [];
        state.status = "rejected";
      });
  },
});

export const {
  setActiveLang,
  setSystemLang,
  setAllLang,
  setColumns,
  setRows,
  addLanguage,
  removeLanguage,
  updateLangState,
  updateFlag,
} = tableSlice.actions;

export const selectActiveLang = ({ languages }) => languages.table.activeLang;
export const selectSystemLang = ({ languages }) => languages.table.systemLang;
export const selectAllLang = ({ languages }) => languages.table.allLang;
export const selectStatus = ({ languages }) => languages.table.status;
export const selectRow = ({ languages }) => languages.table.row;

export default tableSlice.reducer;
