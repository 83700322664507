import FuseUtils from "@fuse/utils/FuseUtils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import {
  createNavigation,
  removeNavigationItem,
  resetNavigation,
  updateNavigationItem,
} from "./navigationTemplatesSlice";
import templatesService from "../service/templatesService";
import TemplateModel, {
  TemplateNaviModel,
  TemplateNewModel,
  TemplateNewNaviModel,
} from "../model/TemplateModel";

export const getTemplates = createAsyncThunk(
  "template/templates/getTemplates",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/posts/templates`);

      if (res.status === "error") return [];

      dispatch(resetNavigation());
      const items = await res.result.map((r) => TemplateNewNaviModel(r));
      dispatch(createNavigation(items));

      return items;
    } catch (error) {
      return [];
    }
  }
);

export const getTemplate = createAsyncThunk(
  "template/templates/getTemplate",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/posts/templates/${_}`);

      if (res.status === "error") return TemplateNewModel();

      return TemplateNewNaviModel(res.result);
    } catch (error) {
      return TemplateNewModel();
    }
  }
);

export const addTemplate = createAsyncThunk(
  "template/templates/addTemplate",
  async (data, { dispatch, getState }) => {
    try {
      const { language } = getState().i18n;

      const res = await templatesService.addTemplate(data);

      const newItem = {
        ...data,
        last_id: res.result.last_id,
        name: data.name_lang[language] || `${data.name_tag}`,
        title: data.name_lang[language] || `${data.name_tag}`,
      };

      dispatch(createNavigation([TemplateNaviModel(newItem)]));

      return TemplateNaviModel(newItem);
    } catch (error) {}
  }
);

export const updateTemplate = createAsyncThunk(
  "template/templates/updateTemplate",
  async (data, { dispatch, getState }) => {
    try {
      const { language } = getState().i18n;
      const res = await templatesService.updateTemplate(data);

      const newItem = {
        ...data,
        name: data.name_lang[language] || `${data.name_tag}`,
        title: data.name_lang[language] || `${data.name_tag}`,
      };

      await dispatch(updateNavigationItem(newItem.id, newItem));

      return newItem;
    } catch (error) {}
  }
);

export const removeTemplate = createAsyncThunk(
  "template/templates/removeTemplate",
  async (data, { dispatch, getState }) => {
    try {
      const res = await templatesService.deleteTemplate(data);

      await dispatch(removeNavigationItem(data.id));

      return data;
    } catch (error) {}
  }
);

export const searchNavigation = createAsyncThunk(
  "template/templates/searchNavigation",
  async (text, { dispatch, getState }) => {
    try {
      const { templates } = getState().template.templates;

      dispatch(resetNavigation());

      if (text.length == 0) return dispatch(createNavigation(templates));

      const filterArray = FuseUtils.filterArrayByString([...templates], text);

      return dispatch(createNavigation(filterArray));
    } catch (error) {}
  }
);

const templatesSlice = createSlice({
  name: "template/templates",
  initialState: {
    templates: [],
    template: TemplateNewModel(),
    status: "pending",
  },
  reducers: {
    newTemplate: (state, action) => {
      state.template = TemplateNewModel();
    },
    resetTemplate: (state) => {
      state.template = TemplateNewModel();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplates.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.templates = action.payload;
        state.status = "fulfilled";
      })
      .addCase(getTemplates.rejected, (state, action) => {
        state.templates = [];
        state.status = "rejected";
      })
      .addCase(addTemplate.fulfilled, (state, action) => {
        state.templates.push(action.payload);
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        const index = state.templates.findIndex(
          (t) => t.id == action.payload.id
        );

        state.templates[index] = action.payload;
      })
      .addCase(removeTemplate.fulfilled, (state, action) => {
        const index = state.templates.findIndex(
          (t) => t.id == action.payload.id
        );

        state.templates = state.templates.splice(index, 1);
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.template = action.payload;
      });
  },
});

export const { newTemplate, resetTemplate } = templatesSlice.actions;

export const selectTemplates = ({ template }) => template.templates;
export const selectTemplate = ({ template }) => template.templates.template;

export default templatesSlice.reducer;
