import FusePageSimple from "@fuse/core/FusePageSimple";
import { motion } from "framer-motion";
import _ from "lodash";
import Languages from "./Languages";
import LanguagesAppHeader from "./LanguagesAppHeader";
import { useMemo } from "react";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguageList, selectSystemLang } from "./store/tableSlice";
import { useEffect } from "react";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

const LanguagesApp = (props) => {
  const dispatch = useDispatch();
  const languages = useSelector(selectSystemLang);
  const langId = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    dispatch(fetchLanguageList(langId));
  }, [dispatch]);

  return (
    <>
      <FusePageSimple
        header={<LanguagesAppHeader />}
        content={
          <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
            {useMemo(() => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                // !_.isEmpty(languages) && (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full mt-32">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <Languages />
                    </motion.div>
                  </div>
                </motion.div>
              );
              // );
            }, [languages])}
          </div>
        }
      />
    </>
  );
};

export default withReducer("languages", reducer)(LanguagesApp);
