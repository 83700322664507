import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "@lodash";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/system/Box";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CountryCodeSelector from "./phone-number-selector/CountryCodeSelector";
import { addEntity } from "../store/entitySlice";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import { selectCountries } from "../store/countriesSlice";
import { selectTypes } from "../store/entitiesTypeSlice";
import { t } from "app/store/labels/globalLabels";
import FileUpdate from "src/app/main/components/fileUpdate/FileUpdate";
import { useEffect, useState } from "react";
import { useRef } from "react";
import FuseLoading from "@fuse/core/FuseLoading";

/**
 * Form Validation Schema
 */
const defaultValues = {
  name: "",
  entity_type: "",
  email_address: "",
  phone_number: "",
  countryPhone_code: "pt",
  street: "",
  city: "",
  state: "",
  zip_code: "",
  notes: "",
  vat: "",
};

const EntityNewForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const countries = useSelector(selectCountries);
  const types = useSelector(selectTypes);

  const [files, setFiles] = useState([]);

  const pond = useRef(null);

  const schema = yup.object().shape({
    name: yup.string().required(dispatch(t("common.input_required"))),
    entity_type: yup.string().required(dispatch(t("common.input_required"))),
    email_address: yup.string().email(dispatch(t("common.set_valid_email"))),
    phone_number: yup
      .string()
      .matches(/^\d*$/, dispatch(t("common.numbers_only"))),
    street: yup.string(),
    country_code: yup.string(),
    zip_code: yup
      .string()
      .matches(/^[\d-]*$/, dispatch(t("common.numbers_only"))),
    city: yup.string(),
    state: yup.string(),
    notes: yup.string(),
  });

  const {
    control,
    formState,
    watch,
    reset,
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("entity_type", routeParams.type);
  }, [setValue]);

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  /**
   * Form Submit
   */
  const onSubmit = (obj) => {
    const data = {
      ...obj,
      [`phone_number[number]`]: obj.phone_number,
      [`phone_number[country_code]`]: obj.countryPhone_code,
      [`address[street]`]: obj.street,
      [`address[city]`]: obj.city,
      [`address[state]`]: obj.state,
      [`address[country_code]`]: obj.country_code,
      [`address[zip_code]`]: obj.zip_code,
      picture: obj.picture_src || "",
      picture_src: "",
    };

    dispatch(addEntity(data)).then(({ payload }) => {
      navigate(`/system/entities/${routeParams.type}/${payload.id}`);
    });
  };

  if (_.isEmpty(form) || _.isEmpty(types)) {
    return (
      <div className="w-full h-full">
        <FuseLoading />
      </div>
    );
  }

  return (
    <>
      <Box
        className="absolute w-full h-136 sm:h-120 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.paper",
        }}
      ></Box>

      <div className=" flex flex-1 flex-col h-full mt-64 px-24 sm:px-48">
        <div className="w-full">
          <div className="w-128 h-128">
            <Controller
              control={control}
              name="picture_src"
              render={({ field: { onChange, value } }) => (
                <FileUpdate
                  refPond={pond}
                  // Preview
                  imagePreviewMaxHeight={200}
                  imagePreviewHeight={170}
                  // file-validate-type
                  imageCropAspectRatio={"1:1"}
                  acceptedFileTypes={"image/*"}
                  stylePanelLayout={"compact circle"}
                  //core
                  files={files}
                  allowProcess={false}
                  instantUpload={true}
                  maxFileSize={"5MB"}
                  // onupdatefiles={onChange}
                  onupdatefiles={(fileItems) => {
                    const files = fileItems.map((fileItem) => fileItem.file);
                    setValue("picture", files[0]);
                    onChange(files[0]);
                    setFiles(files);
                  }}
                  styleLoadIndicatorPosition={"center bottom"}
                  styleButtonRemoveItemPosition={"center bottom"}
                />
              )}
            />
          </div>
        </div>

        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.name"))}
              placeholder={dispatch(t("common.name"))}
              id="name"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="entity_type"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="entity_type"
              options={types}
              disabled
              value={
                value
                  ? _.find(types, { type_tag: value?.type_tag || value })
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue != null && typeof newValue === "object")
                  return onChange(newValue.type_tag);

                return onChange(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                value && option.type_tag == value?.type_tag
              }
              getOptionLabel={(option) => option.type}
              renderOption={(_props, option) => (
                <li {..._props}>{option.type}</li>
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  className="mt-32 ml-2 grow"
                  {...params}
                  required
                  label={dispatch(t("common.entity_type"))}
                  placeholder={dispatch(t("common.entity_type"))}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="email_address"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Email"
              placeholder="Email"
              id="email_address"
              error={!!errors.email_address}
              helperText={errors?.email_address?.message}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-solid:mail</FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="phone_number"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.phone"))}
              placeholder={dispatch(t("common.phone"))}
              id="phone_number"
              error={!!errors.phone_number}
              helperText={errors?.phone_number?.message}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <Controller
                    control={control}
                    name="countryPhone_code"
                    render={({ field: _field }) => (
                      <InputAdornment position="start">
                        <CountryCodeSelector {..._field} />
                      </InputAdornment>
                    )}
                  />
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="vat"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.vat"))}
              placeholder={dispatch(t("common.vat"))}
              id="vat"
              error={!!errors.vat}
              helperText={errors?.vat?.message}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-outline:identification
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="street"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.street"))}
              placeholder={dispatch(t("common.street"))}
              id="street"
              error={!!errors.street}
              helperText={errors?.street?.message}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-outline:home</FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between">
            <Controller
              control={control}
              name="state"
              render={({ field }) => (
                <TextField
                  className="mt-32 w-1/2 mr-2"
                  {...field}
                  label={dispatch(t("common.status"))}
                  placeholder={dispatch(t("common.status"))}
                  id="state"
                  error={!!errors.state}
                  helperText={errors?.state?.message}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-outline:home
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="city"
              render={({ field }) => (
                <TextField
                  className="mt-32 ml-2 grow"
                  {...field}
                  label={dispatch(t("common.city"))}
                  placeholder={dispatch(t("common.city"))}
                  id="city"
                  error={!!errors.city}
                  helperText={errors?.city?.message}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          material-outline:location_city
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          <div className="flex flex-row justify-between">
            <Controller
              control={control}
              name="zip_code"
              render={({ field }) => (
                <TextField
                  className="mt-32 mr-2 w-1/2"
                  {...field}
                  label="Zip Code"
                  placeholder="Zip Code"
                  id="zip_code"
                  error={!!errors.zip_code}
                  helperText={errors?.zip_code?.message}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-outline:location-marker
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="country_code"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  className="mt-32 w-1/2"
                  id="country_code"
                  options={countries}
                  value={
                    value
                      ? _.find(countries, { iso: value?.iso || value })
                      : null
                  }
                  onChange={(event, newValue) => {
                    if (newValue != null && typeof newValue === "object")
                      return onChange(newValue.iso);

                    return onChange(newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value && option.iso == value?.iso
                  }
                  getOptionLabel={(option) => option.name}
                  renderOption={(_props, option) => (
                    <li {..._props}>{option.name}</li>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={dispatch(t("common.country"))}
                      placeholder={dispatch(t("common.country"))}
                    />
                  )}
                />
              )}
            />
          </div>
        </div>

        <Controller
          control={control}
          name="notes"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label={dispatch(t("common.notes"))}
              placeholder={dispatch(t("common.notes"))}
              id="notes"
              error={!!errors.notes}
              helperText={errors?.notes?.message}
              variant="outlined"
              fullWidth
              multiline
              minRows={5}
              maxRows={10}
              InputProps={{
                className: "max-h-min h-min items-start",
                startAdornment: (
                  <InputAdornment className="mt-16" position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:menu-alt-2
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>

      <Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t">
        <Button className="ml-auto" component={NavLinkAdapter} to={-1}>
          {dispatch(t("common.cancel"))}
        </Button>
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </Button>
      </Box>
    </>
  );
};

export default EntityNewForm;
