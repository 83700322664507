import { useEffect, useLayoutEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import {
  fetchNotificationsList,
  selectNotifications,
} from "./store/notificationsSlice";
import { fetchUserList, selectUsers } from "./store/usersSlice";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import CentralNotificationsHeader from "./CentralNotificationsHeader";
import CentralNotifications from "./CentralNotifications";
import withReducer from "app/store/withReducer";
import reducer from "./store";

const CentralNotificationsApp = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const users = useSelector(selectUsers);

  useLayoutEffect(() => {
    dispatch(fetchNotificationsList());
    dispatch(fetchUserList());
  }, [dispatch]);

  return (
    <>
      <FusePageSimple
        header={<CentralNotificationsHeader />}
        content={
          <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
            {useMemo(() => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full mt-32">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <CentralNotifications />
                    </motion.div>
                  </div>
                </motion.div>
              );
            }, [notifications, users])}
          </div>
        }
      />
    </>
  );
};

export default withReducer("notifications", reducer)(CentralNotificationsApp);
