/** MUI IMPORTS
 *  Components do mui usados nesse file
 */
import Avatar from "@mui/material/Avatar";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { IconButton, TextField } from "@mui/material";
import history from "@history";
import * as yup from "yup";
import _ from "@lodash";

/** @FUSE IMPORTS
 *  Components próprio fuse theme
 */
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import FuseScrollbars from "@fuse/core/FuseScrollbars";

/** REACT*/
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

/** REDUX STORE */
import { selectUser, updateUserProfile } from "app/store/userSlice";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";
import axiosService from "src/app/auth/services/axios/axiosService";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import userService from "src/app/service/userService";
import { useSnackbar } from "notistack";
import { setOpen as openProfile } from "src/app/main/profile/store/profileSlice";
import Profile from "src/app/main/profile/Profile";
import { useAuth } from "src/app/auth/AuthContext";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    position: "fixed",
    width: 480,
    maxWidth: "90vw",
    backgroundColor: theme.palette.background.paper,
    top: 0,
    height: "100%",
    minHeight: "100%",
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    borderRadius: 0,
  },
}));

const UserMenu = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const labels = useSelector(selectGlobalLabels);
  const clientAccess = axiosService.getClientAccess();
  const accessAs = axiosService.getAccessAs();
  const { setWaitAuthCheck } = useAuth();

  const [userMenu, setUserMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required(dispatch(t("common.name"))),
    // email: yup
    //   .string()
    //   .email(dispatch(t("USER_profile.field_email_info")))
    //   .required(dispatch(t("USER_profile.field_email_helper"))),
  });

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      name: user.data.name,
      email: user.data.email,
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const handleOpen = () => {
    userMenuClose();
    dispatch(openProfile(true));

    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEnableEdit(false);
    reset();
  };

  const handleEdit = () => {
    setEnableEdit(true);
  };

  const onSubmit = async (data) => {
    try {
      const res = await userService.updateUser(data, user);

      if (res.status != "error") {
        dispatch(updateUserProfile(data));
        setEnableEdit(false);
        updateDefaultValues(data);
      }
    } catch (error) {}
  };

  const updateDefaultValues = (newData) => {
    reset({
      name: newData.name,
      email: newData.email,
    });
  };

  const handleCancelForm = () => {
    setEnableEdit(false);
    reset();
  };

  const handleLogoutAccessAs = async () => {
    setWaitAuthCheck(true);
    userMenuClose();

    axiosService.removeAccessAs();
  };

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography
            component="span"
            className="font-semibold flex "
          >
            {user.data.name}
          </Typography>
          <Typography
            className="text-11 font-medium "
            color="text.secondary"
          >
            {user.data?.workspace?.name?.toString()}
          </Typography>
        </div>

        {user.data.picture_src ? (
          <Avatar
            className="md:mx-4 capitalize xs:w-[30px] xs:h-[30px] md:w-[40px] md:h-[40px]"
            alt="user photo"
            src={user.data.picture_src}
          />
        ) : (
          <Avatar className="md:mx-4 capitalize xs:w-[30px] xs:h-[30px] md:w-[40px] md:h-[40px]">
            {user.data?.name ? user.data?.name[0] : ""}
          </Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <>
          {accessAs ? (
            <MenuItem onClick={handleLogoutAccessAs}>
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={dispatch(t("SYSTEM.sign_out_customer_title"))}
              />
            </MenuItem>
          ) : (
            <>
              <MenuItem onClick={handleOpen} role="button">
                <ListItemIcon className="min-w-40">
                  <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
                </ListItemIcon>
                <ListItemText primary={dispatch(t("common.myprofile"))} />
              </MenuItem>

              <MenuItem
                component={NavLink}
                to="/sign-out"
                onClick={() => {
                  userMenuClose();
                }}
              >
                <ListItemIcon className="min-w-40">
                  <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
                </ListItemIcon>
                <ListItemText primary={dispatch(t("common.logout"))} />
              </MenuItem>
            </>
          )}
        </>
      </Popover>

      <StyledDialog
        aria-labelledby="profile-panel"
        aria-describedby="profile"
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: "shadow-lg",
        }}
      >
        <FuseScrollbars className="p-16 sm:p-32">
          <IconButton
            className="fixed top-0 ltr:right-0 rtl:left-0 z-10"
            onClick={handleClose}
            size="large"
          >
            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>

          <Box
            className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
            sx={{
              backgroundColor: "background.default",
            }}
          >
            {user.data.background && (
              <img
                className="absolute inset-0 object-cover w-full h-full"
                src={user.data.background}
                alt="user background"
              />
            )}
          </Box>
          <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
            <div className="w-full max-w-3xl">
              <div className="flex flex-auto items-end -mt-64">
                <Avatar
                  sx={{
                    borderWidth: 4,
                    borderStyle: "solid",
                    borderColor: "background.paper",
                    backgroundColor: "background.default",
                    color: "text.secondary",
                  }}
                  className="w-128 h-128 text-64 font-bold"
                  src={user.data.avatar}
                  alt={user.data.name?.toString()}
                >
                  {user.data.name?.toString().charAt(0)}
                </Avatar>
                <div className="flex items-center ml-auto mb-4">
                  {!enableEdit && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleEdit}
                    >
                      <FuseSvgIcon size={20}>
                        heroicons-outline:pencil-alt
                      </FuseSvgIcon>
                      <span className="mx-8">{dispatch(t("common.edit"))}</span>
                    </Button>
                  )}
                </div>
              </div>

              {!enableEdit ? (
                <>
                  <Typography className="mt-12 text-4xl font-bold truncate">
                    {user.data.name}
                  </Typography>

                  <Divider className="mt-16 mb-24" />

                  <div className="flex flex-col space-y-32">
                    {user.data.email && (
                      <div className="flex">
                        <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
                        <div className="min-w-0 ml-24 space-y-4">
                          <div
                            className="flex items-center leading-6"
                            key={user.data.email}
                          >
                            <a
                              className="hover:underline text-primary-500"
                              href={`mailto: ${user.data.email}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {user.data.email}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <form
                  name="updateUser"
                  className="flex flex-col justify-center w-full mt-32"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-24"
                        type="name"
                        label={dispatch(t("common.name"))}
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        required
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-24"
                        type="email"
                        label={dispatch(t("common.email"))}
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        disabled={true}
                        fullWidth
                      />
                    )}
                  />

                  <div className="flex flex-row justify-between items-center">
                    <Button
                      variant="text"
                      className="mx-5"
                      color="info"
                      onClick={handleCancelForm}
                      size="medium"
                    >
                      {dispatch(t("common.cancel"))}
                    </Button>
                    <Button
                      variant="contained"
                      className="mx-5"
                      color="secondary"
                      type="submit"
                      disabled={_.isEmpty(dirtyFields) || !isValid}
                      size="medium"
                    >
                      {dispatch(t("common.edit"))}
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </FuseScrollbars>
      </StyledDialog>
      <Profile />
    </>
  );
};

export default UserMenu;
