import { combineReducers } from "@reduxjs/toolkit";
import fuse from "./fuse";
import i18n from "./i18nSlice";
import user from "./userSlice";
import labelsApp from "./labels";
import client from "./clientSlice";
import version from "./versionSlice";
import profile from "../main/profile/store/profileSlice";
import api from "./apiConfigSlice";

import subscription from "src/modules/pages/access_plans/subscription/store/subscriptionSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    fuse,
    i18n,
    user,
    labelsApp,
    client,
    version,
    profile,
    api,

    subscription,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === "user/userLoggedOut") {
    // state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
