import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import history from "@history";
import _ from "@lodash";
import axiosConfig from "../auth/services/axios/axiosConfig";
import axiosService from "../auth/services/axios/axiosService";
import { showMessage } from "./fuse/messageSlice";
import { mountNavigation, setNavigation } from "./fuse/navigationSlice";

export const fetchClient = createAsyncThunk(
  "client/fetchClient",
  async (clientID, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.get(
        `/users/${clientID}?session-data=1&workspace_id=2&menu_id=1`
      );

      if (response.status === "error") {
        // dispatch(showMessage({ message: response.data.error.message }));
        return null;
      }

      const userRole = (role) => {
        if (role === "Developer") return "admin";
        if (role === "Admin") return "staff";
        if (role === "Customer") return "user";
        if (role === null) return "onlyGuest";
      };

      const data = {
        data: {
          displayName: response.result.name.toString(),
          ...response.result,
        },
        role: userRole(response.result.access_level.name),
      };

      axiosService.setClientAccess(response.result.user_id);
      dispatch(setNavigation(mountNavigation(data.data.workspace.menu)));
      history.push({
        pathname: "/",
      });
      return data;
    } catch (error) {
      // dispatch(showMessage({ message: error.message }));
    }
  }
);

export const logoutClient = () => async (dispatch, getState) => {
  axiosService.setClientAccess();

  const { user } = getState();
  dispatch(setNavigation(mountNavigation(user.data.workspace.menu)));
  history.push({
    pathname: "/customers",
  });

  return dispatch(setClientLoggedOut());
};

export const clearClientData = () => async (dispatch, getState) => {
  axiosService.setClientAccess(null);
  return dispatch(setClientLoggedOut());
};

const clientSlice = createSlice({
  name: "client",
  initialState: null,
  reducers: {
    setClientLoggedOut: (state, action) => null,
  },
  extraReducers: {
    [fetchClient.fulfilled]: (state, action) => action.payload,
  },
});

export const { setClientLoggedOut } = clientSlice.actions;

export const selectClient = ({ client }) => client;

export default clientSlice.reducer;
