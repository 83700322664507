import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import _ from "@lodash";
import { useState, useEffect } from "react";
import ButtonIcon from "../../components/ButtonIcon";
import axiosService from "src/app/auth/services/axios/axiosService";
import PageComplement from "app/shared-components/PageComplement";
import Paper from "@mui/material/Paper";
import history from "@history";

import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";
import { useSnackbar } from "notistack";
import {
  selectDark,
  selectLight,
  selectLogos,
} from "app/store/labels/logosSlice";

const SecretCode = () => {
  const { userEmail, userPassword } = useContext(UserContext);
  const [disable, setDisable] = useState(false);
  const labels = useSelector(selectGlobalLabels);
  const d = useDispatch();
  const logos = useSelector(selectLight);
  const logosDark = useSelector(selectDark);
  const theme = axiosService.getTheme();

  const schema = yup.object().shape({
    token: yup
      .string()
      .required(d(t("SYSTEM.signin_fields_secretcode_helper")))
      .matches(/^[0-9]+$/)
      .min(6, ""),
  });

  const defaultValues = {
    token: "",
  };

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const handleResendCode = async () => {
    await axiosService
      .validateLogin(userEmail, userPassword)
      .then((res) => {})
      .catch((err) => {});
  };

  const handleGoBack = (e) => {
    if (e.nativeEvent.pointerType != "mouse") {
      return null;
    }
    history.push("/sign-in");
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setDisable(true);
    axiosService
      .validateSecretCode(data.token, axiosService.getSignInToken())
      .then((res) => {
        setDisable(false);
      })
      .catch((err) => {
        setDisable(false);
      });
  };

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="w-[70%]"
            src={`${process.env.REACT_APP_API_ASSETS}/${
              theme === "defaultDark"
                ? logosDark?.horizontal_logo
                : logos?.horizontal_logo
            }`}
            alt=""
          />

          <p className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            {d(t("SYSTEM.signin_title_secretcode"))}
          </p>

          <form
            name="form-secretcode"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <Message
              style={`flex w-full p-8 my-10`}
              type={"info"}
              message={labelsLogin.alerts.secretcode}
              counter={15000000}
              showMessage={true}
            /> */}

            <Controller
              name="token"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label={d(t("SYSTEM.signin_fields_secretcode_label"))}
                  type="number"
                  error={!!errors.token}
                  helperText={errors?.token?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <div className="flex flex-col xs:w-full xs:flex-row xs:justify-between sm:flex-row items-center justify-center sm:justify-between">
              <ButtonIcon
                ariaLabel={"Go Back"}
                text={d(t("common.previous"))}
                style={"mt-16"}
                color={"secondary"}
                typeIcon={"ArrowBack"}
                handleClick={(e) => handleGoBack(e)}
                iconPosition={0}
              />

              <ButtonIcon
                ariaLabel={"Resend Code"}
                text={d(t("SYSTEM.signin_link_secretcode_resend"))}
                style={"mt-16"}
                color={"secondary"}
                handleClick={handleResendCode}
                hasTimer={true}
                timer={60}
              />
            </div>

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="Secret Code"
              disabled={_.isEmpty(dirtyFields) || !isValid || disable}
              type="submit"
              size="large"
              onClick={handleSubmit(onSubmit)}
            >
              {d(t("SYSTEM.signin_buttons_secretcode"))}
            </Button>
          </form>
        </div>
      </Paper>

      <PageComplement />
    </div>
  );
};

export default SecretCode;
