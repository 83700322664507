import MaterialReactTable from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  ImageList,
  SpeedDial,
  SpeedDialAction,
  Switch,
} from "@mui/material";
import {
  Add,
  RefreshOutlined,
  FormatListBulletedOutlined,
  Flag,
  Delete,
  UploadRounded,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLanguageList,
  selectActiveLang,
  selectStatus,
  selectSystemLang,
  setColumns,
  setRows,
  setSystemLang,
  removeLanguage,
  updateLangState,
} from "./store/tableSlice";
import { setFlagDrawer, setOpenDrawer } from "./store/drawerSlice";
import LanguageAddMenu from "./components/LanguageAddMenu";
import LanguageAddFlagMenu from "./components/LanguageAddFlagMenu";
import { t } from "app/store/labels/globalLabels";
import { selectLanguages, selectTableLanguage } from "app/store/i18nSlice";
import languageService from "./service/languageService";
import { useSnackbar } from "notistack";

const Languages = () => {
  const dispatch = useDispatch();
  const systemLang = useSelector(selectSystemLang);
  const activeLang = useSelector(selectActiveLang);
  const loading = useSelector(selectStatus);
  const lang = useSelector(selectLanguages);
  const tableLang = useSelector(selectTableLanguage);

  const [dataTable, setDataTable] = useState(systemLang);
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const [row, setRow] = useState();

  useEffect(() => {
    if (systemLang.length > 0) {
      setDataTable(systemLang);
    }
  }, [systemLang]);

  const handleChangeCheckbox = (event) => {
    setCheckBoxValue(event.target.checked);
  };

  const handleOpenValidation = () => {
    setOpenValidationDialog(true);
  };

  const handleNo = () => {
    setOpenValidationDialog(false);
    setCheckBoxValue(false);
  };

  const handleDeleteRow = async (row) => {
    try {
      const res = await languageService.deleteRow(
        row.original.code,
        systemLang,
        checkBoxValue
      );

      dispatch(removeLanguage(row));
    } catch (error) {
      setDataTable(systemLang);
    }

    handleNo();
  };

  const handleLanguageState = async (bool, row) => {
    const updatedLanguages = [...dataTable];
    const updatedLanguage = { ...updatedLanguages[row.index], active: bool };
    updatedLanguages[row.index] = updatedLanguage;

    try {
      const res = await languageService.updateActiveLang(updatedLanguages);

      dispatch(updateLangState({ index: row.index, bool }));
    } catch (error) {}
    handleNo();
  };

  const updateOrder = async (values) => {
    try {
      const res = await languageService.updateOrder(values);

      dispatch(setSystemLang(values));
    } catch (error) {
      setDataTable(systemLang);
    }
  };

  const columns = useMemo(() => {
    const staticColumns = [
      {
        id: "active",
        header: dispatch(t("common.status")),
        accessorKey: "active",
        editVariant: "select",
        enableEditing: true,
        Cell: ({ row }) => (
          <Switch
            checked={row.original.active}
            onChange={(e) => {
              handleLanguageState(e.target.checked, row);
            }}
          />
        ),
        maxSize: 100,
      },
      {
        accessorKey: "code",
        enableEditing: false,
        header: dispatch(t("common.code")),
        size: 100,
      },
      {
        accessorKey: "name",
        enableEditing: false,
        header: dispatch(t("common.name")),
      },
      {
        muiTableBodyCellProps: {
          align: "left",
          width: "50%",
        },
        accessorKey: "flag",
        header: "",
        size: 170,
        Cell: ({ row }) => (
          <div>
            {row.original.flag ? (
              <ImageList className="cursor-pointer w-52 pr-10">
                <img
                  src={`${row.original.flag}`}
                  onClick={() => {
                    dispatch(setRows(row));
                    dispatch(setFlagDrawer(true));
                  }}
                />
              </ImageList>
            ) : (
              <IconButton
                onClick={() => {
                  dispatch(setRows(row));
                  dispatch(setFlagDrawer(true));
                }}
              >
                <UploadRounded />
              </IconButton>
            )}
          </div>
        ),
        size: 30,
      },
      {
        accessorKey: "delete",
        enableColumnActions: false,
        header: "",
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              setRow(row);
              handleOpenValidation();
            }}
          >
            <Delete />
          </IconButton>
        ),
        size: 30,
      },
    ];
    return [...staticColumns];
  }, [dataTable, activeLang]);
  // activeLang, lang, dataTable

  const renderConfirmDialog = () => {
    return (
      <Dialog maxWidth="xs" open={openValidationDialog}>
        <DialogTitle>{dispatch(t("common.confirmation"))}</DialogTitle>
        <DialogContent dividers>
          {dispatch(t("common.confirm_action"))}
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={<Checkbox checked={checkBoxValue} />}
            className="pr-36"
            onChange={handleChangeCheckbox}
            label={dispatch(t("SYSTEM.confirm_delete_tag"))}
          />
          <Button onClick={handleNo}>{dispatch(t("common.cancel"))}</Button>
          <Button onClick={() => handleDeleteRow(row)}>
            {dispatch(t("common.yes"))}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {renderConfirmDialog()}
      <MaterialReactTable
        displayColumnDefOptions={{ "mrt-row-drag": { size: 55 } }}
        defaultColumn={{
          minSize: 10, //allow columns to get smaller than default
          maxSize: 9001, //allow columns to get larger than default
          size: 260, //make columns wider by default
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        enableResizing
        //COLUM
        enableStickyHeader
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 332px)" } }}
        columns={columns}
        enableColumnActions={false}
        localization={tableLang}
        //DATA
        data={dataTable}
        enableRowOrdering
        enableSorting={false}
        editingMode="row"
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: async () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              const newArray = [...dataTable];
              const objectToMove = newArray.splice(draggingRow.index, 1)[0];
              newArray.splice(hoveredRow.index, 0, objectToMove);
              setDataTable(newArray);
              updateOrder(newArray);
            }
          },
        })}
        // STATE { INITIAL, ONCHANGE, ERRORS }
        initialState={{
          showColumnFilters: false,
        }}
        state={{
          showSkeletons: loading == "pending" ? true : false,
        }}
      />
      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle={dispatch(t("common.add"))}
          tooltipOpen
          onClick={() => {
            // dispatch(setColumns(columns));
            dispatch(setOpenDrawer(true));
          }}
        />
        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh_table"))}
          tooltipOpen
          onClick={() => {
            dispatch(fetchLanguageList());
          }}
        />
      </SpeedDial>
      <LanguageAddMenu />
      <LanguageAddFlagMenu />
    </>
  );
};

export default Languages;
