import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "languages/drawer",
  initialState: {
    drawerLanguage: false,
    drawerAdd: false,
    drawerFlag: false,
  },
  reducers: {
    setOpenDrawer: (state, action) => {
      state.open = action.payload;
    },
    setFlagDrawer: (state, action) => {
      state.drawerFlag = action.payload;
    },
  },
  extraReducers: {},
});

export const selectOpenState = ({ languages }) => languages.drawer.open;
export const selectOpenFlagState = ({ languages }) =>
  languages.drawer.drawerFlag;

export const { setOpenDrawer, setFlagDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
