import { authRoles } from "src/app/auth";
import LanguagesApp from "./LanguagesApp";

const LanguagesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.staff,
  routes: [
    {
      path: "system/languages",
      element: <LanguagesApp />,
    },
  ],
};

export default LanguagesConfig;
