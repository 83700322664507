import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getVersions,
  selectVersions,
  setAndroidVersion,
  setIosVersion,
  setWebVersion,
} from "../store/versionsSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { t } from "app/store/labels/globalLabels";
import { InputNumber } from "rsuite";
import { useEffect } from "react";
import { Check } from "@mui/icons-material";
import _ from "lodash";

const VersionControl = () => {
  const dispatch = useDispatch();
  const { web, android, ios } = useSelector(selectVersions);

  useEffect(() => {
    dispatch(getVersions());
  }, [dispatch]);

  const schemaWeb = yup.object().shape({
    version: yup
      .number()
      .required(dispatch(t("common.input_required")))
      .min(0.005),
  });
  const webForm = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaWeb),
  });
  const webState = webForm.formState;
  const webWatch = webForm.watch();

  const webSubmit = async (data) => {
    try {
      dispatch(setWebVersion(data)).then(({ payload }) => {
        webForm.reset({ ...payload });
      });
    } catch (error) {}
  };

  const schemaAndroid = yup.object().shape({
    version: yup
      .number()
      .required(dispatch(t("common.input_required")))
      .min(0.005),
  });
  const androidForm = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaAndroid),
  });
  const androidState = androidForm.formState;
  const androidWatch = androidForm.watch();

  const androidSubmit = async (data) => {
    try {
      dispatch(setAndroidVersion(data)).then(({ payload }) => {
        androidForm.reset({ ...payload });
      });
    } catch (error) {}
  };

  const schemaIOS = yup.object().shape({
    version: yup
      .number()
      .required(dispatch(t("common.input_required")))
      .min(0.005),
  });
  const iosForm = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaIOS),
  });
  const iosState = iosForm.formState;
  const iosWatch = iosForm.watch();

  const iosSubmit = async (data) => {
    try {
      dispatch(setIosVersion(data)).then(({ payload }) => {
        iosForm.reset({ ...payload });
      });
    } catch (error) {}
  };

  useEffect(() => {
    webForm.reset({ ...web });
    androidForm.reset({ ...android });
    iosForm.reset({ ...ios });
  }, [web, android, ios]);

  return (
    <>
      <Box className="relative w-full h-80 sm:h-80 px-32 sm:px-48 flex items-center">
        <Typography className="font-bold text-xl">
          {dispatch(t("common.version_control"))}
        </Typography>
      </Box>
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
        <div className="mb-20 w-full">
          <Typography className="text-lg mb-5">WEB</Typography>
          <div className="flex flex-row justify-between">
            <Controller
              name={"version"}
              control={webForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  className="w-1/2"
                  inputProps={{
                    shrink: true,
                    step: 0.001,
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={webForm.handleSubmit(webSubmit)}
              disabled={_.isEmpty(webState.dirtyFields) || !webState.isValid}
            >
              <Check />
            </Button>
          </div>
        </div>
        <div className="mb-20 w-full">
          <Typography className="text-lg mb-5">Android</Typography>
          <div className="flex flex-row justify-between">
            <Controller
              name={"version"}
              control={androidForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  className="w-1/2"
                  inputProps={{
                    shrink: true,
                    step: 0.001,
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={androidForm.handleSubmit(androidSubmit)}
              disabled={
                _.isEmpty(androidState.dirtyFields) || !androidState.isValid
              }
            >
              <Check />
            </Button>
          </div>
        </div>
        <div className="mb-20 w-full">
          <Typography className="text-lg mb-5">IOS</Typography>
          <div className="flex flex-row justify-between">
            <Controller
              name={"version"}
              control={iosForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  className="w-1/2"
                  inputProps={{
                    shrink: true,
                    step: 0.001,
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={iosForm.handleSubmit(iosSubmit)}
              disabled={_.isEmpty(iosState.dirtyFields) || !iosState.isValid}
            >
              <Check />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VersionControl;
