import axiosConfig from "../../../../auth/services/axios/axiosConfig";

class LabelsService {
  addLabel = (data) => {
    const { label_group_tag, label_tag, ...rest } = data;
    const body = {};

    Object.keys(rest).map((key) => (body[`label_lang[${key}]`] = rest[key]));

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/labels/${label_group_tag}.${label_tag}`, body)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  updateLabel = (data) => {
    const { label_group_tag, label_tag, ...rest } = data;
    const body = {};

    Object.keys(rest).map((key) => (body[`label_lang[${key}]`] = rest[key]));

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/labels/${label_group_tag}.${label_tag}`, body)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  deleteLabel = (data) => {
    const { label_group_tag, label_tag, ...rest } = data;
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/labels/${label_group_tag}.${label_tag}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => reject(err));
    });
  };
}

const labelsService = new LabelsService();
export default labelsService;
