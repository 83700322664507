import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "rsuite";
import { selectOpenFlagState, setFlagDrawer } from "../store/drawerSlice";
import { t } from "app/store/labels/globalLabels";
import FileUpdate from "src/app/main/components/fileUpdate/FileUpdate";
import { useSnackbar } from "notistack";
import { fetchLanguageList, selectRow, updateFlag } from "../store/tableSlice";
import languageService from "../service/languageService";

const LanguageAddFlagMenu = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectOpenFlagState);
  const row = useSelector(selectRow);
  const [files, setFiles] = useState([]);

  const handleClose = () => {
    dispatch(fetchLanguageList());
    dispatch(setFlagDrawer(false));
  };

  const handleFlag = async (file, row) => {
    if (file) {
      try {
        const res = await languageService.addFlag(file, row.original.code);

        return true;
      } catch (error) {}
      return false;
    }
  };
  return (
    <>
      <Drawer backdrop={true} open={open} onClose={handleClose}>
        <Drawer.Header>
          <Drawer.Title>{dispatch(t("common.upload_file"))}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <FileUpdate
            // Preview
            imagePreviewMaxHeight={300}
            // file-validate-type
            acceptedFileTypes={"image/png"}
            //core
            files={files}
            instantUpload={false}
            maxFileSize={"50KB"}
            name="files"
            server={{
              process: async (
                fieldName,
                file,
                metadata,
                load,
                error,
                progress,
                abort,
                transfer,
                options
              ) => {
                if ((await handleFlag(file, row)) === true) {
                  load();
                } else {
                  error();
                  abort();
                }
              },
            }}
          />
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default LanguageAddFlagMenu;
