import TableLabelsApp from "./TableLabelsApp";
import { authRoles } from "src/app/auth";

const TableLabelsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.staff,
  routes: [
    {
      path: "system/labels",
      element: <TableLabelsApp />,
    },
  ],
};

export default TableLabelsConfig;
