import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, Typography } from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import _ from "@lodash";
import FileManager from "src/app/main/components/fileManager/FileManager";
import {
  getConfigs,
  selectClientConfigs,
  updateConfigs,
} from "../store/clientConfigsSlice";
import { useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import { useNavigate } from "react-router-dom";

const ClientConfigs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { corporate, status } = useSelector(selectClientConfigs);

  useEffect(() => {
    dispatch(getConfigs());
  }, [dispatch]);

  const schema = yup.object().shape({
    corporate: yup.object().shape({
      name: yup.string().required(),
      long_name: yup.string().required(),
      favicon: yup.mixed(),
      logos: yup.object().shape({
        dark_theme: yup.object().shape({
          vertical_logo: yup.mixed(),
          horizontal_logo: yup.mixed(),
        }),
        light_theme: yup.object().shape({
          vertical_logo: yup.mixed(),
          horizontal_logo: yup.mixed(),
        }),
      }),
    }),
    photo: yup.mixed(),
  });

  const {
    control,
    handleSubmit,
    formState,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { dirtyFields, isValid, errors } = formState;

  const form = watch();

  useEffect(() => {
    reset({ corporate: { ...corporate } });
  }, [reset, corporate]);

  const onSubmit = async (data) => {
    try {
      dispatch(updateConfigs(data)).then(({ payload }) => {
        if (payload) navigate(`/system/configurations`);
      });
    } catch (error) {}
  };

  if (_.isEmpty(form) || status !== "fulfilled") return <FuseLoading />;

  return (
    <>
      <Box className="relative w-full h-80 sm:h-80 px-32 sm:px-48 flex items-center">
        <Typography className="font-bold text-xl">
          {dispatch(t("common.social_media_network"))}
        </Typography>
      </Box>
      <div className="relative flex flex-col flex-auto items-center mb-32 p-24 pt-0 sm:p-48 sm:pt-0">
        <Controller
          control={control}
          name={"corporate.name"}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              placeholder={dispatch(t("common.name"))}
              label={dispatch(t("common.name"))}
              error={!!errors.name}
              helperText={errors.name?.message}
              variant="outlined"
              fullWidth
            />
          )}
        />

        <Controller
          control={control}
          name={"corporate.long_name"}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              placeholder={dispatch(t("common.long_name"))}
              label={dispatch(t("common.long_name"))}
              error={!!errors.long_name}
              helperText={errors.long_name?.message}
              variant="outlined"
              fullWidth
            />
          )}
        />

        <Controller
          control={control}
          name={"corporate.favicon"}
          render={({ field }) => (
            <FileManager
              {...field}
              placeholder={dispatch(t("common.favicon"))}
              className={"mt-32"}
            />
          )}
        />

        <Controller
          control={control}
          name={"corporate.logos.dark_theme.vertical_logo"}
          render={({ field }) => (
            <FileManager
              {...field}
              placeholder={dispatch(t("common.dark_vertical"))}
              className={"mt-32"}
            />
          )}
        />

        <Controller
          control={control}
          name={"corporate.logos.dark_theme.horizontal_logo"}
          render={({ field }) => (
            <FileManager
              {...field}
              placeholder={dispatch(t("common.dark_horizontal"))}
              className={"mt-32"}
            />
          )}
        />

        <Controller
          control={control}
          name={"corporate.logos.light_theme.vertical_logo"}
          render={({ field }) => (
            <FileManager
              {...field}
              placeholder={dispatch(t("common.light_vertical"))}
              className={"mt-32"}
            />
          )}
        />

        <Controller
          control={control}
          name={"corporate.logos.light_theme.horizontal_logo"}
          render={({ field }) => (
            <FileManager
              {...field}
              placeholder={dispatch(t("common.light_horizontal"))}
              className={"mt-32"}
            />
          )}
        />
      </div>
      <Box
        className="fixed bottom-0 w-full py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t z-999"
        sx={{
          backgroundColor: "background.default",
        }}
      >
        <Button
          className="ml-auto"
          component={NavLinkAdapter}
          to={`/system/configurations`}
        >
          {dispatch(t("common.cancel"))}
        </Button>
        <LoadingButton
          className="ml-8"
          variant="contained"
          color="secondary"
          loading={isLoading}
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </LoadingButton>
      </Box>
    </>
  );
};

export default ClientConfigs;
