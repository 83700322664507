import Faqs from "./Faqs";
import FaqsApp from "./FaqsApp";

const FaqsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "system/faqs",
      element: <FaqsApp />,
      children: [
        {
          path: "",
          element: <Faqs />,
        },
      ],
    },
  ],
};

export default FaqsAppConfig;
