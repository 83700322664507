import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { selectEditRow, setDrawerState } from "../store/drawerSlice";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "app/store/labels/globalLabels";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import _ from "lodash";
import * as yup from "yup";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import InputTranslate from "src/app/main/components/textTranslate/InputTranslate";
import {
  addPushNotification,
  getPushNotifications,
  selectPush,
} from "../store/pushNotificationsSlice";
import { DateTimePicker } from "@mui/x-date-pickers";
import FileManager from "src/app/main/components/fileManager/FileManager";
import { PushNotificationsModelMerge } from "../model/PushNotificationsModel";
import { FormatDateSQL } from "app/shared-components/Functions";

const PushForm = () => {
  const dispatch = useDispatch();
  const { users, screens } = useSelector(selectPush);
  const [plataforms, setPlataforms] = useState(["android", "ios", "web"]);
  const [recipients, setRecipients] = useState(["loggedin", "loggedout"]);
  const [_disable, _setDisable] = useState(false);
  const [external, setExternal] = useState(false);

  const schema = yup.object().shape({
    recipients: yup.string().nullable(),
    users_list: yup.array(),
    send_date_time: yup
      .string()
      .nullable()
      .required()
      .typeError(
        dispatch(
          t("common.invalid_type", {
            type: "DD/MM/AAAA HH:MM",
          })
        )
      ),
    device_type: yup.array(),
    image_url: yup.string().nullable().notRequired(),
    image: yup.mixed(),
    title_lang: yup.object().required(dispatch(t("common.input_required"))),
    message_lang: yup.object().required(dispatch(t("common.input_required"))),
    url: yup.string().nullable().notRequired(),
    screen: yup.string().nullable().notRequired(),
  });

  const { control, formState, handleSubmit, reset, watch, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  useEffect(() => {
    reset(PushNotificationsModelMerge());
  }, [reset]);

  const submit = async (obj) => {
    const _obj = {
      ...obj,
      external: external,
    };

    try {
      dispatch(addPushNotification(_obj)).then((res) => {
        if (res.error) return;

        if (res.payload) {
          dispatch(setDrawerState(false));
          dispatch(getPushNotifications());
        }
      });
    } catch (error) {}
  };

  if (_.isEmpty(form)) return <FuseLoading />;

  return (
    <div className="mt-20">
      <Controller
        control={control}
        name={"title_lang"}
        render={({ field }) => (
          <InputTranslate
            {...field}
            className={"mt-32"}
            placeholder={dispatch(t("common.title"))}
            error={!!errors?.title_lang}
            helperText={errors?.title_lang?.message}
            required
          />
        )}
      />
      <Controller
        control={control}
        name={"message_lang"}
        render={({ field }) => (
          <InputTranslate
            {...field}
            className={"mt-32"}
            placeholder={dispatch(t("common.message"))}
            error={!!errors?.message_lang}
            helperText={errors?.message_lang?.message}
            required
          />
        )}
      />
      <FormControl className="mt-32">
        <FormLabel id="input_type">
          {dispatch(t("common.image_type"))}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="image"
          name="row-radio-buttons-group"
          onChange={() =>
            _disable === false
              ? setValue("image_url", "")
              : setValue("image", "")
          }
        >
          <FormControlLabel
            value="image"
            control={<Radio onClick={() => _setDisable(false)} />}
            label={dispatch(t("common.image"))}
          />
          <FormControlLabel
            value="image_url"
            control={<Radio onClick={() => _setDisable(true)} />}
            label={dispatch(t("common.image_url"))}
          />
        </RadioGroup>
      </FormControl>
      {_disable === false && (
        <Controller
          control={control}
          name={"image"}
          render={({ field }) => (
            <FileManager
              {...field}
              placeholder={dispatch(t("common.image"))}
              className={"mt-32"}
              error={!!errors?.image}
              helperText={errors?.image?.message}
            />
          )}
        />
      )}
      {_disable === true && (
        <Controller
          control={control}
          name={"image_url"}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              className={"mt-32"}
              label={dispatch(t("common.image_url"))}
              placeholder={dispatch(t("common.image_url"))}
              error={!!errors.image_url}
              helperText={errors.image_url?.message}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name={"users_list"}
        render={({ field: { value, onChange, ...field } }) => (
          <Autocomplete
            className="mt-32"
            multiple
            disableCloseOnSelect
            disableListWrap
            options={users}
            value={value}
            onChange={(e, newValue) => {
              if (newValue) {
                const items = newValue.map((i) => i?.user_id || i);
                return onChange([...items]);
              }

              return onChange([]);
            }}
            getOptionLabel={(option) => `${option.name} ${option.email}`}
            renderOption={(_props, value) => (
              <li {..._props}>
                {value.name} ({value.email})
              </li>
            )}
            isOptionEqualToValue={(option, value) => {
              return `${option.user_id}` === `${value.user_id || value}`;
            }}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => {
                const findedUser = _.find(users, {
                  user_id: Number(option),
                });

                return (
                  <Chip
                    key={findedUser.user_id}
                    label={findedUser?.name}
                    {...getTagProps({ index })}
                  />
                );
              });
            }}
            renderInput={(render) => (
              <TextField
                {...render}
                label={dispatch(t("common.user_list"))}
                placeholder={dispatch(t("common.user_list"))}
                error={!!errors.users_list}
                helperText={errors.users_list?.message}
                variant="outlined"
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name={"device_type"}
        render={({ field: { value, onChange, ...field } }) => (
          <Autocomplete
            className="mt-32"
            multiple
            disableCloseOnSelect
            disableListWrap
            options={plataforms}
            value={value}
            onChange={(e, newValue) => {
              if (newValue) return onChange([...newValue]);
              return onChange([]);
            }}
            getOptionLabel={(option) => option}
            renderOption={(_props, value) => (
              <li {..._props}>
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option === value}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => {
                return (
                  <Chip
                    key={index}
                    label={option.charAt(0).toUpperCase() + option.slice(1)}
                    {...getTagProps({ index })}
                  />
                );
              });
            }}
            renderInput={(render) => (
              <TextField
                {...render}
                label={dispatch(t("common.device_type"))}
                placeholder={dispatch(t("common.device_type"))}
                error={!!errors.device_type}
                helperText={errors.device_type?.message}
                variant="outlined"
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name={"send_date_time"}
        render={({ field: { onChange, value, ...field } }) => (
          <DateTimePicker
            value={value}
            onChange={(e, k) => {
              onChange(e);
            }}
            renderInput={(_props) => (
              <TextField
                {..._props}
                className="w-full mt-32"
                error={!!errors.send_date_time}
                helperText={errors.send_date_time?.message}
                required
              />
            )}
            ampm={false}
            inputFormat="dd/MM/yyyy HH:mm"
            mask="__/__/____ __:__"
            className="w-full mt-32"
            label={dispatch(t("common.date"))}
            placeholder={dispatch(t("common.date"))}
            variant="outlined"
          />
        )}
      />
      <Controller
        control={control}
        name={"recipients"}
        render={({ field: { value, onChange, ...field } }) => (
          <Autocomplete
            className="mt-32"
            disableListWrap
            options={recipients}
            value={value.charAt(0).toUpperCase() + value.slice(1)}
            onChange={(e, newValue) => {
              if (newValue) return onChange(newValue);

              return onChange("");
            }}
            getOptionLabel={(option) => option}
            renderOption={(_props, value) => (
              <li {..._props}>
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(render) => (
              <TextField
                {...render}
                label={dispatch(t("common.recipients"))}
                placeholder={dispatch(t("common.recipients"))}
                error={!!errors.recipients}
                helperText={errors.recipients?.message}
                variant="outlined"
              />
            )}
          />
        )}
      />
      <Divider className="w-full mt-32" />
      <FormControl className="mt-32">
        <FormLabel id="input_type">{dispatch(t("common.data"))}</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={"screen"}
          name="radio-buttons-group"
          onChange={() =>
            external === true ? setValue("url", "") : setValue("screen", "")
          }
        >
          <FormControlLabel
            value={"screen"}
            control={<Radio onClick={() => setExternal(false)} />}
            label={dispatch(t("common.screen"))}
          />
          <FormControlLabel
            value={"url"}
            control={<Radio onClick={() => setExternal(true)} />}
            label={"Url"}
          />
        </RadioGroup>
      </FormControl>
      {external === false && (
        <Controller
          control={control}
          name={"screen"}
          render={({ field: { value, onChange, ...field } }) => (
            <Autocomplete
              className="mt-32"
              options={screens}
              value={
                value
                  ? _.find(screens, {
                      url_tabs: value?.url_tabs || value,
                    })
                  : null
              }
              onChange={(e, newValue) => {
                if (newValue) return onChange(newValue.url_tabs);

                return onChange("");
              }}
              getOptionLabel={(option) => option.title}
              renderOption={(_props, value) => (
                <li {..._props}>{value.title}</li>
              )}
              isOptionEqualToValue={(option, value) =>
                option.url_tabs === value.url_tabs
              }
              renderInput={(render) => (
                <TextField
                  {...render}
                  label={dispatch(t("common.screen"))}
                  placeholder={dispatch(t("common.screen"))}
                  error={!!errors.screen}
                  helperText={errors.screen?.message}
                  variant="outlined"
                />
              )}
            />
          )}
        />
      )}{" "}
      {external === true && (
        <Controller
          control={control}
          name={"url"}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              className={"mt-32"}
              label={dispatch(t("common.url"))}
              placeholder={dispatch(t("common.url"))}
              error={!!errors.url}
              helperText={errors.url?.message}
            />
          )}
        />
      )}
      <Button
        variant="contained"
        color="secondary"
        className="mt-20 mb-10"
        disabled={_.isEmpty(dirtyFields) || !isValid}
        fullWidth
        onClick={handleSubmit(submit)}
      >
        {dispatch(t("common.add"))}
      </Button>
    </div>
  );
};

export default PushForm;
