import { createSlice } from "@reduxjs/toolkit";
import Model from "../model/Model";

const drawerSlice = createSlice({
  name: "smtp_servers/drawer",
  initialState: {
    drawerState: false,
    editSmtp: Model(),
  },

  reducers: {
    setEmptySmtp: (state, action) => {
      state.editSmtp = Model();
      state.drawerState = true;
    },
    setDrawerState: (state, action) => {
      state.drawerState = action.payload;
    },
    setEditSmtp: (state, action) => {
      state.editSmtp = action.payload;
    },
  },
  extraReducers: {},
});

export const { setDrawerState, setEditSmtp, setEmptySmtp } =
  drawerSlice.actions;

export const selectDrawerState = ({ smtp_servers }) =>
  smtp_servers.drawer.drawerState;
export const selectEditSmtp = ({ smtp_servers }) =>
  smtp_servers.drawer.editSmtp;

export default drawerSlice.reducer;
