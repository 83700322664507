import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AccessLevelApp from "./AccessLevelApp";
import AccessLevelFormCardItem from "./components/sidebar/AccessLevelFormCardItem";

const AccessLevelEndpoints = lazy(() =>
  import("./components/AccessLevelEndpoints/AccessLevelEndpoints")
);
const AccessLevelCards = lazy(() => import("./components/AccessLevelCards"));

const AccessLevelConfig = {
  routes: [
    {
      path: "system/access-level",
      element: <AccessLevelApp />,
      children: [
        {
          path: "",
          element: <Navigate to="/system/access-level/cards" />,
        },
        {
          path: "cards",
          element: <AccessLevelCards />,
          children: [
            {
              path: ":id/edit",
              element: <AccessLevelFormCardItem />,
            },
          ],
        },
        {
          path: "cards/endpoints/:al",
          element: <AccessLevelEndpoints />,
        },
      ],
    },
  ],
};

export default AccessLevelConfig;
