import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import {
  changeNavigationLang,
  mountNavigation,
  resetNavigation,
  updateNavigationItem,
} from "./navigationSlice";
import { getSubscription } from "src/modules/pages/access_plans/subscription/store/subscriptionSlice";
import { action } from "mobx";

export const getDevices = createAsyncThunk(
  "voyager/devices/getDevices",
  async (userID, { dispatch, getState }) => {
    try {
      const { user } = getState();
      // const response = await axiosConfig.get(
      //   `/JNF_VOYAGER/customer/device/getalldevices?userid=${userID}&`
      // );

      const response = await axiosConfig.get(`/devices/${user.data.user_id}`);

      dispatch(resetNavigation());
      dispatch(changeNavigationLang());
      dispatch(getSubscription());

      if (response?.status === "error") {
        return [];
      }
      const devices = await response.result?.voyager_wifi;
      if (devices) dispatch(mountNavigation(devices));
      return devices;
    } catch (error) {
    }
  }
);

export const getDevicesShared = createAsyncThunk(
  "voyager/devices/getDevicesShared",
  async (userID, { dispatch, getState }) => {
    const response = await axiosConfig.get(
      `/JNF_VOYAGER/customer/device/getdeviceshareto?userid=${userID}`
    );

    if (response?.status === "error") {
      return [];
    }

    const devicesShared = await response.result?.content.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (f) => f.deviceid === value.deviceid && f.subid === value.subid
        )
    );
    // .map((item) => {
    //   return { deviceid: item.deviceid, subid: item.subid };
    // });

    devicesShared.length > 0 &&
      dispatch(
        updateNavigationItem("shared_devices", {
          badge: {
            title: devicesShared.length,
            classes: "w-20 h-20 bg-deep-purple text-white rounded-full",
          },
        })
      );

    return devicesShared;
  }
);

export const clearDevices = () => async (dispatch, getState) => {
  return dispatch(setDevices());
};

const devicesSlice = createSlice({
  name: "voyager/devices",
  initialState: {
    allDevices: [],
    devicesFiltered: [],
    filterValue: null,
    status: "idle",
    devicesShared: [],
    device: null,
  },
  reducers: {
    setDevices: (state, action) => {
      state.allDevices = null;
    },
    setFilterValue: (state, action) => {
      const { id, shared, title, subtitle } = action.payload;
      state.filterValue = id;

      if (id === "all_devices") {
        state.devicesFiltered = state.allDevices;
      } else if (id === "my_all_devices") {
        state.devicesFiltered = state.allDevices?.filter(
          (item) => item.isshared === 0
        );
      } else if (id === "shared_devices") {
        state.devicesFiltered = state.allDevices?.filter(
          (item) => item.isshared === 0 && item.totals.shared > 0
        );
      } else if (id === "my_all_shared_devices") {
        state.devicesFiltered = state.allDevices?.filter(
          (item) => item.isshared === 1
        );
      } else {
        state.devicesFiltered = state.allDevices?.filter(
          (item) => item.place_group_id == id
        );
      }
    },
    setDevicesFiltered: (state, action) => {
      state.devicesFiltered = action.payload;
    },
    setDevice: (state, action) => {
      state.device = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDevices.pending, (state) => {
      state.status = "pending";
    }),
      builder.addCase(getDevices.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.allDevices = action.payload;
        state.devicesFiltered = action.payload;
      }),
      builder.addCase(getDevices.rejected, (state) => {
        state.status = "reject";
      }),
      builder.addCase(getDevicesShared.fulfilled, (state, action) => {
        state.devicesShared = action.payload;
      });
  },
});

export const { setDevices, setFilterValue, setDevicesFiltered, setDevice } =
  devicesSlice.actions;

export const selectDevices = ({ voyager }) => voyager.devices.allDevices;
export const selectFilteredDevices = ({ voyager }) =>
  voyager.devices.devicesFiltered;
export const selectDeviceStatus = ({ voyager }) => voyager.devices.status;
export const selectDeviceShared = ({ voyager }) =>
  voyager.deviceid.devicesShared;
export const selectDevice = ({ voyager }) => voyager.devices.device;

export default devicesSlice.reducer;
