import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import accessLevelService from "../service/AccessLevelService";
import FuseUtils from "@fuse/utils/FuseUtils";
import { t } from "app/store/labels/globalLabels";
import _ from "lodash";

export const getEndpointsList = createAsyncThunk(
  "access_level/endpoints/getEndpointsList",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get("/APIendpoints");

      if (res.status === "error") throw new Error(res);

      return res.result;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getRelation = createAsyncThunk(
  "access_level/endpoints/getRelation",
  async (obj, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/access_level/${obj.id}`);

      if (res.status === "error") throw new Error(res);

      const _obj = {
        ...res.result,
        title: [
          { relation: dispatch(t("SYSTEM.endpoint_relation")) },
          {
            inactive_relation: dispatch(t("SYSTEM.endpoint_inactive_relation")),
          },
          { endpoints: dispatch(t("SYSTEM.endpoints")) },
          // { inactive: dispatch(t("SYSTEM.endpoint_inactives")) },
        ],
      };

      return _obj;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const reorderCard = createAsyncThunk(
  "access_level/endpoints/reorderCard",
  async (
    { result, endpoint, access_level_id, user_type },
    { dispatch, getState }
  ) => {
    try {
      dispatch(setFilteredCards({ result: result }));
      if (result.source.droppableId === "all_endpoints") {
        if (result.destination.droppableId === "access_levelEndpoints") {
          const res = await accessLevelService.activeRelation({
            endpoint: endpoint,
            is_active: 1,
            access_level_id: access_level_id,
          });

          const endp = {
            ...endpoint,
            access_level_id: Number(access_level_id),
          };

          const obj = {
            result,
            endp,
            is_active: 1,
          };

          return obj;
        }
      } else if (result.source.droppableId === "access_levelEndpoints") {
        if (result.destination.droppableId === "all_endpoints") {
          const res = await accessLevelService.removeRelation({
            endpoint: endpoint,
            access_level_id: access_level_id,
          });

          const obj = {
            result,
            endpoint,
            access_level_id,
          };

          return obj;
        }
      }
    } catch (error) {}
  }
);

export const updateRelation = createAsyncThunk(
  "access_level/endpoints/updateRelation",
  async (data, { dispatch, getState }) => {
    try {
      const res = await accessLevelService.editRelation(data);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const endpointsSlice = createSlice({
  name: "access_level/endpoints",
  initialState: {
    endpoints: [],
    filterEndpoints: [],
    relation: [],
    filteredCards: [],
    search: "",
  },
  reducers: {
    setSearchText: (state, action) => {
      state.search = action.payload;
    },
    setEnpoints: (state, action) => {
      state.endpoints = action.payload;
    },
    setRelation: (state, action) => {
      state.relation = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFilteredCards: (state, action) => {
      const { result } = action.payload;

      const sourceListIndex = state.filteredCards.list.findIndex(
        (list) => list.board_id === result.source.droppableId
      );

      const sourceList = state.filteredCards.list[sourceListIndex];

      const card = sourceList.cards[result.source.index];

      const destListIndex = state.filteredCards.list.findIndex(
        (list) => list.board_id === result.destination.droppableId
      );

      sourceList.cards.splice(result.source.index, 1);

      if (!state.filteredCards.list[destListIndex].cards) {
        state.filteredCards.list[destListIndex].cards = [];
      }

      state.filteredCards.list[destListIndex].cards.splice(
        result.destination.index,
        0,
        card
      );
    },
    addEndpoint: (state, action) => {
      state.endpoints.push(action.payload);
      state.filteredCards.list[0].cards.push(action.payload);
    },
    removeEndpoint: (state, action) => {
      const { index, boardId } = action.payload;

      const list = state.filteredCards.list.findIndex(
        (list) => list.board_id === boardId
      );

      state.filteredCards.list[list].cards.splice(index, 1);
      state.endpoints.splice(index, 1);
    },
    updateEndpoint: (state, action) => {
      const { value, boardId, index } = action.payload;

      const endpointIndex = state.endpoints.findIndex(
        (i) => i.APIendpoint_id === value.APIendpoint_id
      );

      const relationIndex = state.filteredCards.list.findIndex(
        (i) => i.board_id === boardId
      );

      state.filteredCards.list[relationIndex].cards[index] = value;
      state.endpoints[endpointIndex] = value;
    },
    filterCards: (state, action) => {
      const { searchText } = action.payload;

      // const filteredRelation = FuseUtils.filterArrayByString(
      //   [...state.relation.list],
      //   searchText
      // );

      const filteredRelation = state.relation.list.map((list) => {
        const filteredCards = list.cards.filter((card) => {
          const cardName = card.name.toLowerCase();
          const searchLetter = searchText.toLowerCase();
          return cardName.includes(searchLetter);
        });
        return { ...list, cards: filteredCards };
      });

      state.filteredCards = {
        list: filteredRelation,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEndpointsList.fulfilled, (state, action) => {
        const orderedPayload = action?.payload?.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        state.endpoints = orderedPayload;
        state.filterEndpoints = state.endpoints;
      })
      .addCase(getRelation.fulfilled, (state, action) => {
        const _relation = action.payload;
        if (_relation.user_type === "master") {
          state.filteredCards = state.filterEndpoints;

          const _obj = {
            list: [
              {
                board_id: "all_endpoints",
                title: "Endpoints",
                cards: state.filteredCards,
              },
            ],
          };

          state.relation = _obj;
          state.filteredCards = _obj;
        } else {
          const filteredEndpoints = state.filterEndpoints.filter((item) => {
            return !item.access_level?.some(
              (accessItem) =>
                accessItem.access_level_id === _relation.access_level_id
            );
          });

          const _obj = {
            list: [
              {
                board_id: "all_endpoints",
                title: `${_relation.title[2].endpoints}`,
                cards: filteredEndpoints.filter((i) => {
                  return i.is_hidden === 0;
                }),
              },
              {
                board_id: "access_levelEndpoints",
                title: `${_relation.title[0].relation}`,
                cards: _relation.APIendpoints.filter((i) => {
                  return i.is_hidden === 0;
                }),
              },
            ],
          };

          state.relation = _obj;
          state.filteredCards = _obj;

          console.log(_obj);
        }
      })
      .addCase(reorderCard.fulfilled, (state, action) => {
        const { result, is_active } = action.payload;

        const destListIndex = state.filteredCards.list.findIndex(
          (list) => list.board_id === result.destination.droppableId
        );

        const relationListIndex = state.relation.list.findIndex(
          (list) => list.board_id === result.source.droppableId
        );

        if (relationListIndex !== -1) {
          const relationList = state.relation.list[relationListIndex];
          const relationCard = relationList.cards[result.source.index];

          relationList.cards.splice(result.source.index, 1);

          if (!state.relation.list[destListIndex].cards) {
            state.relation.list[destListIndex].cards = [];
          }

          state.relation.list[destListIndex].cards.splice(
            result.destination.index,
            0,
            relationCard
          );
        }
      })
      .addCase(updateRelation.fulfilled, (state, action) => {
        const { obj, boardId } = action.payload;

        const _obj = {
          ...obj,
          request_method_exceptions: JSON.stringify(
            obj.request_method_exceptions
          ),
          request_permissions: JSON.stringify(obj.request_permissions),
        };

        const listIndex = state.filteredCards.list.findIndex(
          (i) => i.board_id === boardId
        );

        const cardIndex = state.filteredCards.list[listIndex].cards.findIndex(
          (i) => i.APIendpoint_id === obj.APIendpoint_id
        );

        state.filteredCards.list[listIndex].cards[cardIndex] = _obj;
        state.relation.list[listIndex].cards[cardIndex] = _obj;
      });
  },
});

export const {
  setEnpoints,
  resetNavigation,
  filterCards,
  setFilteredCards,
  addEndpoint,
  removeEndpoint,
  updateEndpoint,
  setRelation,
  setEndpointState,
  setInactive,
  setSearchText,
} = endpointsSlice.actions;

export const selectEndpoints = ({ access_level }) =>
  access_level.endpoints.endpoints;
export const selectRelation = ({ access_level }) =>
  access_level.endpoints.relation;
export const selectRelationFilter = ({ access_level }) =>
  access_level.endpoints.filteredCards;

export default endpointsSlice.reducer;
