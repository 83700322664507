import { combineReducers } from "@reduxjs/toolkit";
import drawer from "./drawerSlice";
import menus from "./menuSlice";
import groups from "./groupsSlice";
import navigationGroups from "./navigationGroupsSlice";
import workspaces from "./workspacesSlice";
import navigationWorkspaces from "./navigationWorkspacesSlices";

const reducer = combineReducers({
  drawer,
  menus,
  groups,
  workspaces,
  navigationGroups,
  navigationWorkspaces,
});
export default reducer;
