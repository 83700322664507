import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const apiConfigSlice = createSlice({
  name: "api",
  initialState: {
    maintenance: false,
    signup: false,
  },
  reducers: {
    setSignup: (state, action) => {
      state.signup = action.payload;
    },
  },
  extraReducers: {},
});

export const selectApi = ({ api }) => api;

export const { setSignup } = apiConfigSlice.actions;
export default apiConfigSlice.reducer;
