/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import history from "@history";
import _ from "@lodash";
import { setInitialSettings } from "app/store/fuse/settingsSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import settingsConfig from "app/configs/settingsConfig";
import axiosService from "../auth/services/axios/axiosService";
import { clearClientData, fetchClient, logoutClient } from "./clientSlice";
import axiosConfig from "../auth/services/axios/axiosConfig";
import { selectNavigationAll } from "./fuse/navigationSlice";

export const setUser = createAsyncThunk(
  "user/setUser",
  async (user, { dispatch, getState }) => {
    const userRoutes =
      user.data?.workspace && user.data?.workspace?.menu
        ? await mountRoutes(user.data.workspace.menu)
        : [];
    userRoutes.push(
      "/dashboard",
      "/",
      "*",
      "/loading",
      "/maintenance",
      "/404",
      "/forgot-password",
      "/reset-password",
      "/sign-up",
      "/sign-out",
      "/sign-in",
      "/secretcode",
      "/webkey"
    );

    const shortcuts =
      user.data.shortcuts && user.data.shortcuts.map((s) => s.title_tag);

    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(setUserState({ ...newUser, routes: userRoutes }));

    return { ...newUser, routes: userRoutes };
  }
);

export const mountRoutes = (navigation) => {
  const arrayNew = [];

  for (let index = 0; index < navigation.length; index++) {
    const element = navigation[index];

    const url = element.url_tabs.split("/");
    const urlNew = url.length > 2 ? url.slice(0, -1).join("/") : url.join("/");

    arrayNew.push(
      element.url_tabs.substring(0, 1) === "/" ? urlNew : `/${urlNew}`
    );

    if (element.subitems) arrayNew.push(...mountRoutes(element.subitems));
  }

  return arrayNew;
};

export const updateUserSettings = createAsyncThunk(
  "user/updateSettings",
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    return newUser;
  }
);

export const updateUserLang = createAsyncThunk(
  "user/updateLang",
  async (lang, { dispatch, getState }) => {
    const { user } = getState();

    const data = {
      lang: lang,
    };

    try {
      const res = await axiosConfig.post(
        `/user_data/${user.data.user_id}`,
        data
      );

      if (res.status === "success") return res.result;
    } catch (error) {
      return null;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateData",
  async (data, { dispatch, getState }) => {
    const { user } = getState();

    try {
      const res = await axiosConfig.get(`/users/${user.data.user_id}`);

      if (res.status === "error") return user;

      const newUser = _.merge({}, user, {
        data: { ...res.result },
      });
      return newUser;
    } catch (error) {}
  }
);

export const getUserShortcuts = createAsyncThunk(
  "user/getUserShortcuts",
  async (_, { dispatch, getState }) => {
    const { user } = getState();
    const navigation = selectNavigationAll(getState());

    try {
      const res = await axiosConfig.get("/shortcuts");

      if (res.status === "error") return [];

      const shortcuts =
        res.result.length > 0 &&
        res.result?.map((item) => {
          const findNavi = navigation.find(
            (n) => n.menu_item_id == item.menu_item_id
          );

          return findNavi.id;
        });

      return shortcuts;
    } catch (error) {
      return [];
    }
  }
);

export const updateUserShortcuts = createAsyncThunk(
  "user/updateShortucts",
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };
    return newUser;
  }
);

export const setUserShortcuts = createAsyncThunk(
  "user/setUserShortcuts",
  async (data, { dispatch, getState }) => {
    const { user } = getState();
    const { id, navigation } = data;

    const findNavi = navigation.find((n) => n.id == id);

    try {
      if (findNavi) {
        const res = await axiosConfig.post(
          `/shortcuts/${user.data.workspace.id}/${findNavi.menu_item_id}`
        );

        if (res.status === "error") throw new Error(res);

        return res;
      }
    } catch (error) {}
  }
);

export const removeUserShortcuts = createAsyncThunk(
  "user/removeUserShortcuts",
  async (data, { dispatch, getState }) => {
    const { user } = getState();
    const { id, navigation } = data;

    const findNavi = navigation.find((n) => n.id == id);

    try {
      if (findNavi) {
        const res = await axiosConfig.delete(
          `/shortcuts/${user.data.workspace.id}/${findNavi.menu_item_id}`
        );

        if (res.status === "error") throw new Error(res);

        return res;
      }
    } catch (error) {}
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user.role || user.role?.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: "/",
  });

  dispatch(clearClientData());

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role?.length === 0) {
    // is guest
    return;
  }
};

const initialState = {
  role: [], // guest
  data: {
    displayName: "X",
    photoURL: "",
    email: "info@codemaker.pt",
    shortcuts: [],
    access_level: {
      name: "",
    },
  },
  routes: [
    "/dashboard",
    "/",
    "*",
    "/loading",
    "/maintenance",
    "/404",
    "/forgot-password",
    "/reset-password",
    "/sign-up",
    "/sign-out",
    "/sign-in",
    "/secretcode",
    "/webkey",
  ],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
    setUserState: (state, action) => action.payload,
  },
  extraReducers: {
    [updateUserSettings.fulfilled]: (state, action) => action.payload,
    [updateUserProfile.fulfilled]: (state, action) => action.payload,
    [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => action.payload,
  },
});

export const { userLoggedOut, setUserState } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export default userSlice.reducer;
