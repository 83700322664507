import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class GroupService {
  addGroup = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`menu`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  editGroup = (data, group) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`menu/${group.menu_id}`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  deleteMenu = (group) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`menu/${group.menu_id}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}
const groupService = new GroupService();
export default groupService;
