import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class MenuService {
  editMenu = (node, data, icon, titleLang, subtitleLang, languages) => {
    var body = {};

    for (let index = 0; index < languages.length; index++) {
      const element = languages[index].code;
      const title_lang = `title_lang[${element}]`;
      const subtitle_lang = `subtitle_lang[${element}]`;
      body = {
        ...body,
        [title_lang]: titleLang[element] || "",
        [subtitle_lang]: subtitleLang[element] || "",
      };
    }

    body = {
      ...body,
      ...data,
      icon,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`menu/${node.menu_id}/item/${node.menu_item_id}`, body)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  addMenu = (node, data, icon, titleLang, subtitleLang, languages, isNew) => {
    var body = {};

    for (let index = 0; index < languages.length; index++) {
      const element = languages[index].code;
      const title_lang = `title_lang[${element}]`;
      const subtitle_lang = `subtitle_lang[${element}]`;
      body = {
        ...body,
        [title_lang]: titleLang[element] || "",
        [subtitle_lang]: subtitleLang[element] || "",
      };
    }

    body = {
      menu_item_in: isNew ? 0 : node.menu_item_id,
      ...body,
      ...data,
      icon,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`menu/${node.menu_id}/item`, body)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  deleteMenu = (node) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`menu/${node.menu_id}/item/${node.menu_item_id}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  reorderItemsMenu = (prevTreeIndex, nextTreeIndex, nextParentNode, node) => {
    var body = {};

    if (prevTreeIndex !== nextTreeIndex) {
      if (nextParentNode) {
        body = {
          menu_item_in: nextParentNode.menu_item_id,
        };
      } else if (nextParentNode === undefined) {
        body = {
          menu_item_in: 0,
        };
      }
    } else {
      if (nextParentNode) {
        body = {
          menu_item_in: nextParentNode.menu_item_id,
        };
      } else if (nextParentNode === undefined) {
        body = {
          menu_item_in: 0,
        };
      }
    }

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/menu/${node.menu_id}/item/${node.menu_item_id}`, body)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  reorderMenu = (ids, node) => {
    const data = new FormData();

    for (let index = 0; index < ids.length; index++)
      data.append("ids[]", ids[index]);

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/menu/${node.menu_id}/reorder`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}
const menuService = new MenuService();
export default menuService;
