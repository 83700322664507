import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Modal,
  SpeedDial,
  SpeedDialAction,
  Switch,
  Typography,
} from "@mui/material";
import {
  Add,
  Delete,
  Edit,
  FormatListBulletedOutlined,
  PlayArrow,
  RefreshOutlined,
} from "@mui/icons-material";
import { t } from "app/store/labels/globalLabels";
import { setEditDrawer, setEditRow, setOpenDrawer } from "./store/drawerSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCronjobsList,
  removeCronjobs,
  selectCronjobs,
  selectStatus,
  updateCronjobs,
} from "./store/cronjobsSlice";
import { MaterialReactTable } from "material-react-table";
import { useMemo, useState } from "react";
import { selectLanguages, selectTableLanguage } from "app/store/i18nSlice";
import DrawerMenu from "./components/DrawerMenu";
import cronjobsService from "./service/CronjobsService";

const Cronjobs = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectStatus);
  const cronjobs = useSelector(selectCronjobs);
  const tableLang = useSelector(selectTableLanguage);
  const lang = useSelector(selectLanguages);

  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [_disable, _setDisable] = useState(false);

  const handleOpenValidation = () => {
    setOpenValidationDialog(true);
  };

  const handleNo = () => {
    setOpenValidationDialog(false);
  };

  const handleCronState = async (bool, row) => {
    const cron = {
      ...row.original,
      is_active: bool ? 1 : 0,
    };

    try {
      const res = await cronjobsService.editCronjob(
        cron,
        row.original.cronjob_id
      );

      dispatch(updateCronjobs({ value: cron, index: row.index }));
    } catch (error) {}
    handleNo();
  };

  const handleDeleteCronjob = async (selectedRow) => {
    const id = selectedRow.original.cronjob_id;

    try {
      const res = await cronjobsService.deleteCronjob(id);

      dispatch(removeCronjobs(selectedRow.index));
    } catch (error) {}

    handleNo();
  };

  const handleExecution = async (row) => {
    try {
      const id = row.original.cronjob_id;
      const res = await cronjobsService.executeCron(id);
      setInProgress(false);
      _setDisable(false);
    } catch (error) {}
  };

  const tasks = useMemo(() => {
    const staticTasks = [
      {
        accessorKey: "cronjob_id",
        header: dispatch(t("common.id")),
        size: 75,
      },
      {
        accessorKey: "is_active",
        header: dispatch(t("common.active")),
        size: 150,
        enableEditing: true,
        Cell: ({ row }) =>
          row?.original && (
            <Switch
              checked={Boolean(row.original.is_active)}
              onChange={(e) => {
                handleCronState(e.target.checked, row);
              }}
            />
          ),
      },
      {
        accessorKey: "name",
        header: dispatch(t("common.name")),
        size: 150,
      },
      {
        accessorKey: "cjr_data",
        header: dispatch(t("common.info")),
        size: 150,
      },
      {
        accessorKey: "cjroutine",
        header: dispatch(t("common.routine")),
        size: 150,
      },
      {
        accessorKey: "start_date_time",
        header: dispatch(t("common.start")),
        size: 150,
      },
      {
        accessorKey: "end_date_time",
        header: dispatch(t("common.end")),
        size: 150,
      },
      {
        accessorKey: "last_request",
        header: dispatch(t("common.last_request")),
        size: 150,
      },
      {
        accessorKey: "created",
        header: dispatch(t("common.created")),
        size: 150,
      },
    ];
    return [...staticTasks];
  }, [cronjobs, lang]);

  const renderConfirmDialog = () => {
    return (
      <Dialog maxWidth="xs" open={openValidationDialog}>
        <DialogTitle>{dispatch(t("common.delete"))}</DialogTitle>
        <DialogContent dividers>
          {dispatch(t("common.confirm_action"))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>{dispatch(t("common.no"))}</Button>

          <Button onClick={() => handleDeleteCronjob(selectedRow)}>
            {dispatch(t("common.yes"))}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {renderConfirmDialog()}
      <MaterialReactTable
        columns={tasks}
        enableStickyHeader
        localization={tableLang}
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 332px)" } }}
        data={cronjobs}
        enableRowActions
        state={{
          showProgressBars: inProgress,
          showSkeletons: loading == "pending" ? true : false,
        }}
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            key={1}
            disabled={_disable}
            onClick={() => {
              dispatch(setEditRow(row));
              dispatch(setEditDrawer(true));
              dispatch(setOpenDrawer(true));
              closeMenu();
            }}
          >
            <Edit />
            {dispatch(t("common.edit"))}
          </MenuItem>,
          <MenuItem
            key={2}
            color="error"
            disabled={_disable}
            onClick={() => {
              setSelectedRow(row);
              handleOpenValidation();
              closeMenu();
            }}
          >
            <Delete /> {dispatch(t("common.delete"))}
          </MenuItem>,
          <MenuItem
            key={3}
            disabled={_disable}
            onClick={() => {
              handleExecution(row);
              setInProgress(true);
              _setDisable(true);
              closeMenu();
            }}
          >
            <PlayArrow /> {dispatch(t("common.execute"))}
          </MenuItem>,
        ]}
      />
      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle={dispatch(t("common.add"))}
          tooltipOpen
          onClick={() => {
            dispatch(setOpenDrawer(true));
          }}
        />
        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh_table"))}
          tooltipOpen
          onClick={() => {
            dispatch(fetchCronjobsList());
          }}
        />
      </SpeedDial>
      <DrawerMenu />
    </>
  );
};

export default Cronjobs;
