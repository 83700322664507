import { useState, useEffect, useMemo } from "react";
import { useThemeMediaQuery } from "@fuse/hooks";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import TablePropertiesSidebar from "./components/sidebar/TablePropertiesSidebar";
import TablePropertiesHeader from "./TablePropertiesHeader";
import TableProperties from "./TableProperties";
import FusePageSimple from "@fuse/core/FusePageSimple";
import reducer from "./store";
import withReducer from "app/store/withReducer";

const TablePropertiesApp = () => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const routeParams = useParams();
  //const table_properties = dispatch(selectTableProperties)

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <FusePageSimple
      header={
        <TablePropertiesHeader
          leftSidebar={(e) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
        />
      }
      content={
        <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
          {useMemo(
            () => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <TableProperties />
                    </motion.div>
                  </div>
                </motion.div>
              );
            },
            [
              /*table_properties*/
            ]
          )}
        </div>
      }
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      leftSidebarContent={
        <TablePropertiesSidebar /*navigation={navigation}*/ />
      }
      scroll="content"
    />
  );
};

export default withReducer("table_properties", reducer)(TablePropertiesApp);
