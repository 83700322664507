import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import AccessLevelCardModel from "../components/model/AccessLevelCardModel";
import { addCard, removeCard, updateCard } from "./accessLevelSlice";

export const getAccessLevelList = createAsyncThunk(
  "access_level/list/getAccessLevelList",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/access_level`);

      if (res.status === "error") return [];

      const data = res.result.map((item) => AccessLevelCardModel(item));

      return data;
    } catch (error) {
      return [];
    }
  }
);

const cardsAdapter = createEntityAdapter({
  selectId: (access) => access.access_level_id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = cardsAdapter.getInitialState({ status: "pending" });

export const {
  selectAll: selectCards,
  selectIds: selectIds,
  selectById: selectCardsById,
} = cardsAdapter.getSelectors((state) => state.access_level.list);

const accessLevelsSlice = createSlice({
  name: "access_level/list",
  initialState,
  reducers: {
    setNavigation: cardsAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAccessLevelList.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getAccessLevelList.fulfilled, (state, action) => {
        cardsAdapter.upsertMany(state, action.payload);
        state.status = "fulfilled";
      })
      .addCase(getAccessLevelList.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(removeCard.fulfilled, (state, action) => {
        cardsAdapter.removeOne(state, action.payload.access_level_id);
      })
      .addCase(addCard.fulfilled, (state, action) => {
        cardsAdapter.addOne(state, action.payload);
      })
      .addCase(updateCard.fulfilled, (state, action) =>
        cardsAdapter.upsertOne(state, action.payload)
      );
  },
});

export const { resetNavigation } = accessLevelsSlice.actions;

export const selectAccessLevel = ({ access_level }) =>
  access_level.list.access_level;
export const selectStatus = ({ access_level }) => access_level.list.status;

export default accessLevelsSlice.reducer;
