import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import {
  addCard,
  getCard,
  newCard,
  selectCard,
  updateCard,
} from "../../store/accessLevelSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { t } from "app/store/labels/globalLabels";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import * as yup from "yup";
import _ from "lodash";

const AccessLevelFormCardItem = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const card = useSelector(selectCard);
  const navigate = useNavigate();

  const types = [
    {
      type: "master",
      label: "Master",
    },
    {
      type: "admin",
      label: dispatch(t("common.admin")),
    },
    {
      type: "user",
      label: dispatch(t("common.user")),
    },
  ];

  const schema = yup.object().shape({
    name: yup.string().required(dispatch(t("common.input_required"))),
    user_type: yup.string().required(dispatch(t("common.input_required"))),
    is_active: yup.number().notRequired().nullable(),
  });

  const { control, formState, handleSubmit, reset, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  useEffect(() => {
    if (routeParams.id === "new") {
      dispatch(newCard());
    } else {
      dispatch(getCard(routeParams.id));
    }
  }, [dispatch, routeParams]);

  useEffect(() => {
    reset({ ...card });
  }, [card, reset]);

  const onSubmit = (obj) => {
    try {
      if (routeParams.id === "new") {
        dispatch(addCard(obj)).then(({ payload }) => {
          if (payload) navigate("/system/access-level/cards");
        });
      } else {
        dispatch(updateCard(obj)).then(({ payload }) => {
          if (payload) navigate("/system/access-level/cards");
        });
      }
    } catch (error) {}
  };

  if (_.isEmpty(form)) return <FuseLoading />;

  return (
    <>
      <div className=" flex flex-1 flex-col h-full mt-64 px-48 sm:px-48">
        <div className="w-full">
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label={dispatch(t("common.name"))}
                placeholder={dispatch(t("common.name"))}
                id="name"
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            control={control}
            name="user_type"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="user_type"
                options={types}
                disabled={routeParams.id == "new" ? false : true}
                value={
                  value ? _.find(types, { type: value?.type || value }) : null
                }
                onChange={(event, newValue) => {
                  if (newValue != null && typeof newValue === "object")
                    return onChange(newValue.type);

                  return onChange(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  value && option.type == value?.type
                }
                getOptionLabel={(option) => option.label}
                renderOption={(_props, option) => (
                  <li {..._props}>{option.label}</li>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    className="mt-32 ml-2 grow"
                    {...params}
                    required
                    label={dispatch(t("common.user_type"))}
                    placeholder={dispatch(t("common.user_type"))}
                  />
                )}
              />
            )}
          />

          {routeParams.id !== "new" && (
            <Controller
              control={control}
              name="is_active"
              render={({ field: { value, onChange, ...field } }) => (
                <FormControlLabel
                  className="mt-16"
                  control={
                    <Checkbox
                      {...field}
                      name={"is_active"}
                      checked={Boolean(value)}
                      onChange={(e) => {
                        onChange(e.target.checked ? 1 : 0);
                      }}
                    />
                  }
                  label={dispatch(t("common.active"))}
                />
              )}
            />
          )}
        </div>
      </div>

      <Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t">
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </Button>
      </Box>
    </>
  );
};

export default AccessLevelFormCardItem;
