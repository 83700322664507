import { combineReducers } from "@reduxjs/toolkit";
import list from "./accessLevelsSlice";
import card from "./accessLevelSlice";
import endpoints from "./endpointsSlice";
import drawer from "./drawerSlice";

const reducer = combineReducers({
  list,
  card,
  endpoints,
  drawer,
});

export default reducer;
