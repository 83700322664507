import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import {
  createNavigation,
  resetNavigation,
} from "./navigationAccess_logsSlice";
import FuseUtils from "@fuse/utils/FuseUtils";
import MenuModal from "../modal/MenuModal";

export const fetchAccessMenu = createAsyncThunk(
  "access_logs/menu/fetchAccessMenu",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(resetNavigation());
      dispatch(setActiveAccess_logs(null));
      const res = await axiosConfig.get(`/request_logs`);

      if (res.status === "error") return [];

      const splitedRes = res.result.map((item) => item.split("T")[0]);

      const finalData = Array.from(new Set(splitedRes)).map((item) =>
        MenuModal(item)
      );

      if (finalData.length > 0) dispatch(createNavigation(finalData));

      return finalData;
    } catch (error) {
      return [];
    }
  }
);

export const filterAccess_logs = (text) => (dispatch, getState) => {
  const { access_logs } = getState().access_logs.menu;

  dispatch(resetNavigation());

  if (text.length === 0) return dispatch(createNavigation(access_logs));

  const filteredGroups = FuseUtils.filterArrayByString([...access_logs], text);

  return dispatch(createNavigation(filteredGroups));
};

const access_logsSlice = createSlice({
  name: "access_logs/menu",
  initialState: {
    access_logs: [],
    searchValues: [],
    activeAccess_logs: null,
    status: "",
  },

  reducers: {
    setAccess: (state, action) => {
      state.access_logs = action.payload;
    },
    setSearchValues: (state, action) => {
      state.searchValues = action.payload;
    },
    setActiveAccess_logs: (state, action) => {
      const id = action.payload;

      if (id === "none" || !id) state.activeAccess_logs = null;
      else state.activeAccess_logs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessMenu.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchAccessMenu.fulfilled, (state, action) => {
        state.access_logs = action.payload;
        state.status = "success";
      })
      .addCase(fetchAccessMenu.rejected, (state) => {
        state.access_logs = [];
        state.status = "reject";
      });
  },
});

export const { setAccess, setSearchValues, setActiveAccess_logs } =
  access_logsSlice.actions;

export const selectAccess = ({ access_logs }) => access_logs.menu.access_logs;
export const selectStatus = ({ access_logs }) => access_logs.menu.status;

export default access_logsSlice.reducer;
