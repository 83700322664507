const menu = [
  {
    id: "error_logs",
    title: "Error Logs",
    type: "group",
    hasActions: true,
    children: [
      {
        id: "none",
        title: "No Filters",
        type: "item",
        active: true,
      },
    ],
  },
];

export default menu;
