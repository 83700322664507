import axiosConfig from "src/app/auth/services/axios/axiosConfig";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LabelsModel, {
  LabelsNewModel,
  LabelsTagsModel,
} from "../model/LabelsModel";
import labelsService from "../service/labelsService";

export const getLabels = createAsyncThunk(
  "label/table/getLabels",
  async (data, { dispatch, getState }) => {
    try {
      const { systemLanguages } = await getState().i18n;

      const res = await axiosConfig.get("/labels");

      if (res.status === "error") throw new Error(res);

      const arrayTable = await res.result.map((i) =>
        LabelsModel(i, systemLanguages)
      );
      const arrayTags = LabelsTagsModel(res.result);

      const newModel = await LabelsNewModel(systemLanguages);

      return { table: arrayTable, tags: arrayTags, create: newModel };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addLabels = createAsyncThunk(
  "label/table/addLabels",
  async (data, { dispatch, getState }) => {
    try {
      const { systemLanguages } = await getState().i18n;

      const res = await labelsService.addLabel(data);

      if (res.status === "error") throw new Error(res);

      return { ...data, label_group_id: res.result.last_id };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateLabels = createAsyncThunk(
  "label/table/updateLabels",
  async (data, { dispatch, getState }) => {
    try {
      const res = await labelsService.updateLabel(data);

      if (res.status === "error") throw new Error(res);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const deleteLabels = createAsyncThunk(
  "label/table/deleteLabels",
  async (data, { dispatch, getState }) => {
    try {
      const res = await labelsService.deleteLabel(data);

      if (res.status === "error") throw new Error(res);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const tableSlice = createSlice({
  name: "label/table",
  initialState: {
    labels: [],
    tags: [],
    create: {},
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLabels.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getLabels.fulfilled, (state, action) => {
        const { table, tags, create } = action.payload;

        state.labels = table;
        state.tags = tags;
        state.create = create;
        state.status = "fulfilled";
      })
      .addCase(getLabels.rejected, (state) => {
        state.labels = [];
        state.tags = [];
        state.create = {};
        state.status = "rejected";
      })
      .addCase(addLabels.fulfilled, (state, action) => {
        const index = state.labels.find(
          (l) => l.label_group_tag == action.payload.label_group_tag
        );

        const data = {
          ...action.payload,
          is_system: index?.is_system || 0,
        };

        if (!index) {
          state.tags.push(data.label_group_tag);
        }

        state.labels.push(data);
      })
      .addCase(updateLabels.fulfilled, (state, action) => {
        const index = state.labels.findIndex(
          (l) =>
            l.label_group_tag == action.payload.label_group_tag &&
            l.label_tag == action.payload.label_tag
        );

        state.labels[index] = action.payload;
      })
      .addCase(deleteLabels.fulfilled, (state, action) => {
        const index = state.labels.findIndex(
          (l) =>
            l.label_group_tag == action.payload.label_group_tag &&
            l.label_tag == action.payload.label_tag
        );

        state.labels.splice(index, 1);
      });
  },
});

export const selectLabels = ({ label }) => label.table;

export default tableSlice.reducer;
