const icons = [
  "heroicons-outline:academic-cap",
  "heroicons-outline:annotation",
  "heroicons-outline:adjustments",
  "heroicons-outline:archive",
  "heroicons-outline:arrow-circle-down",
  "heroicons-outline:arrow-circle-left",
  "heroicons-outline:arrow-circle-right",
  "heroicons-outline:arrow-circle-up",
  "heroicons-outline:arrow-down",
  "heroicons-outline:arrow-left",
  "heroicons-outline:arrow-narrow-down",
  "heroicons-outline:arrow-narrow-left",
  "heroicons-outline:arrow-narrow-right",
  "heroicons-outline:arrow-narrow-up",
  "heroicons-outline:arrow-right",
  "heroicons-outline:arrow-sm-left",
  "heroicons-outline:arrow-sm-down",
  "heroicons-outline:arrow-sm-right",
  "heroicons-outline:arrow-sm-up",
  "heroicons-outline:arrow-up",
  "heroicons-outline:arrows-expand",
  "heroicons-outline:at-symbol",
  "heroicons-outline:badge-check",
  "heroicons-outline:backspace",
  "heroicons-outline:ban",
  "heroicons-outline:beaker",
  "heroicons-outline:bell",
  "heroicons-outline:book-open",
  "heroicons-outline:bookmark-alt",
  "heroicons-outline:bookmark",
  "heroicons-outline:briefcase",
  "heroicons-outline:cake",
  "heroicons-outline:calculator",
  "heroicons-outline:calendar",
  "heroicons-outline:camera",
  "heroicons-outline:cash",
  "heroicons-outline:chart-bar",
  "heroicons-outline:chart-pie",
  "heroicons-outline:chart-square-bar",
  "heroicons-outline:chat-alt-2",
  "heroicons-outline:chat-alt",
  "heroicons-outline:chat",
  "heroicons-outline:check-circle",
  "heroicons-outline:check",
  "heroicons-outline:chevron-double-down",
  "heroicons-outline:chevron-double-left",
  "heroicons-outline:chevron-double-up",
  "heroicons-outline:chevron-double-right",
  "heroicons-outline:chevron-down",
  "heroicons-outline:chevron-left",
  "heroicons-outline:chevron-right",
  "heroicons-outline:chevron-up",
  "heroicons-outline:chip",
  "heroicons-outline:clipboard-check",
  "heroicons-outline:clipboard-copy",
  "heroicons-outline:clipboard-list",
  "heroicons-outline:clipboard",
  "heroicons-outline:clock",
  "heroicons-outline:cloud-download",
  "heroicons-outline:cloud-upload",
  "heroicons-outline:cloud",
  "heroicons-outline:code",
  "heroicons-outline:cog",
  "heroicons-outline:collection",
  "heroicons-outline:credit-card",
  "heroicons-outline:color-swatch",
  "heroicons-outline:cube-transparent",
  "heroicons-outline:cube",
  "heroicons-outline:currency-bangladeshi",
  "heroicons-outline:currency-dollar",
  "heroicons-outline:currency-euro",
  "heroicons-outline:currency-pound",
  "heroicons-outline:currency-rupee",
  "heroicons-outline:currency-yen",
  "heroicons-outline:cursor-click",
  "heroicons-outline:database",
  "heroicons-outline:desktop-computer",
  "heroicons-outline:device-mobile",
  "heroicons-outline:device-tablet",
  "heroicons-outline:document-add",
  "heroicons-outline:document-download",
  "heroicons-outline:document-duplicate",
  "heroicons-outline:document-remove",
  "heroicons-outline:document-report",
  "heroicons-outline:document-search",
  "heroicons-outline:document-text",
  "heroicons-outline:document",
  "heroicons-outline:dots-circle-horizontal",
  "heroicons-outline:dots-horizontal",
  "heroicons-outline:dots-vertical",
  "heroicons-outline:download",
  "heroicons-outline:duplicate",
  "heroicons-outline:emoji-happy",
  "heroicons-outline:emoji-sad",
  "heroicons-outline:exclamation-circle",
  "heroicons-outline:exclamation",
  "heroicons-outline:external-link",
  "heroicons-outline:eye-off",
  "heroicons-outline:eye",
  "heroicons-outline:fast-forward",
  "heroicons-outline:film",
  "heroicons-outline:filter",
  "heroicons-outline:finger-print",
  "heroicons-outline:fire",
  "heroicons-outline:flag",
  "heroicons-outline:folder-add",
  "heroicons-outline:folder-download",
  "heroicons-outline:folder-open",
  "heroicons-outline:folder-remove",
  "heroicons-outline:folder",
  "heroicons-outline:gift",
  "heroicons-outline:globe-alt",
  "heroicons-outline:globe",
  "heroicons-outline:hand",
  "heroicons-outline:hashtag",
  "heroicons-outline:heart",
  "heroicons-outline:home",
  "heroicons-outline:identification",
  "heroicons-outline:inbox-in",
  "heroicons-outline:inbox",
  "heroicons-outline:information-circle",
  "heroicons-outline:key",
  "heroicons-outline:library",
  "heroicons-outline:light-bulb",
  "heroicons-outline:lightning-bolt",
  "heroicons-outline:link",
  "heroicons-outline:location-marker",
  "heroicons-outline:lock-closed",
  "heroicons-outline:lock-open",
  "heroicons-outline:login",
  "heroicons-outline:logout",
  "heroicons-outline:mail-open",
  "heroicons-outline:mail",
  "heroicons-outline:map",
  "heroicons-outline:menu-alt-1",
  "heroicons-outline:menu-alt-2",
  "heroicons-outline:menu-alt-3",
  "heroicons-outline:menu-alt-4",
  "heroicons-outline:menu",
  "heroicons-outline:microphone",
  "heroicons-outline:minus-circle",
  "heroicons-outline:minus-sm",
  "heroicons-outline:minus",
  "heroicons-outline:moon",
  "heroicons-outline:music-note",
  "heroicons-outline:newspaper",
  "heroicons-outline:office-building",
  "heroicons-outline:paper-airplane",
  "heroicons-outline:paper-clip",
  "heroicons-outline:pause",
  "heroicons-outline:pencil-alt",
  "heroicons-outline:pencil",
  "heroicons-outline:phone-incoming",
  "heroicons-outline:phone-missed-call",
  "heroicons-outline:phone-outgoing",
  "heroicons-outline:photograph",
  "heroicons-outline:phone",
  "heroicons-outline:play",
  "heroicons-outline:plus-circle",
  "heroicons-outline:plus-sm",
  "heroicons-outline:plus",
  "heroicons-outline:presentation-chart-bar",
  "heroicons-outline:presentation-chart-line",
  "heroicons-outline:printer",
  "heroicons-outline:qrcode",
  "heroicons-outline:puzzle",
  "heroicons-outline:question-mark-circle",
  "heroicons-outline:receipt-refund",
  "heroicons-outline:receipt-tax",
  "heroicons-outline:refresh",
  "heroicons-outline:reply",
  "heroicons-outline:rewind",
  "heroicons-outline:rss",
  "heroicons-outline:save-as",
  "heroicons-outline:save",
  "heroicons-outline:scale",
  "heroicons-outline:scissors",
  "heroicons-outline:search-circle",
  "heroicons-outline:search",
  "heroicons-outline:selector",
  "heroicons-outline:server",
  "heroicons-outline:share",
  "heroicons-outline:shield-check",
  "heroicons-outline:shield-exclamation",
  "heroicons-outline:shopping-bag",
  "heroicons-outline:shopping-cart",
  "heroicons-outline:sort-ascending",
  "heroicons-outline:sort-descending",
  "heroicons-outline:speakerphone",
  "heroicons-outline:sparkles",
  "heroicons-outline:star",
  "heroicons-outline:status-online",
  "heroicons-outline:status-offline",
  "heroicons-outline:stop",
  "heroicons-outline:sun",
  "heroicons-outline:support",
  "heroicons-outline:switch-vertical",
  "heroicons-outline:switch-horizontal",
  "heroicons-outline:table",
  "heroicons-outline:tag",
  "heroicons-outline:template",
  "heroicons-outline:terminal",
  "heroicons-outline:thumb-up",
  "heroicons-outline:thumb-down",
  "heroicons-outline:ticket",
  "heroicons-outline:translate",
  "heroicons-outline:trash",
  "heroicons-outline:trending-down",
  "heroicons-outline:trending-up",
  "heroicons-outline:truck",
  "heroicons-outline:upload",
  "heroicons-outline:user-add",
  "heroicons-outline:user-group",
  "heroicons-outline:user-circle",
  "heroicons-outline:user-remove",
  "heroicons-outline:user",
  "heroicons-outline:users",
  "heroicons-outline:variable",
  "heroicons-outline:video-camera",
  "heroicons-outline:view-boards",
  "heroicons-outline:view-grid-add",
  "heroicons-outline:view-grid",
  "heroicons-outline:view-list",
  "heroicons-outline:volume-up",
  "heroicons-outline:volume-off",
  "heroicons-outline:x-circle",
  "heroicons-outline:zoom-in",
  "heroicons-outline:wifi",
  "heroicons-outline:zoom-out",
  "heroicons-outline:x",
  "material-outline:10k",
  "material-outline:10mp",
  "material-outline:11mp",
  "material-outline:12mp",
  "material-outline:13mp",
  "material-outline:14mp",
  "material-outline:15mp",
  "material-outline:16mp",
  "material-outline:17mp",
  "material-outline:18mp",
  "material-outline:19mp",
  "material-outline:1k",
  "material-outline:1k_plus",
  "material-outline:1x_mobiledata",
  "material-outline:20mp",
  "material-outline:21mp",
  "material-outline:22mp",
  "material-outline:23mp",
  "material-outline:24mp",
  "material-outline:2k",
  "material-outline:2k_plus",
  "material-outline:2mp",
  "material-outline:30fps",
  "material-outline:30fps_select",
  "material-outline:360",
  "material-outline:3d_rotation",
  "material-outline:3g_mobiledata",
  "material-outline:3k",
  "material-outline:3k_plus",
  "material-outline:3mp",
  "material-outline:3p",
  "material-outline:4g_mobiledata",
  "material-outline:4g_plus_mobiledata",
  "material-outline:4k",
  "material-outline:4k_plus",
  "material-outline:4mp",
  "material-outline:5g",
  "material-outline:5k",
  "material-outline:5k_plus",
  "material-outline:5mp",
  "material-outline:60fps",
  "material-outline:60fps_select",
  "material-outline:6_ft_apart",
  "material-outline:6k",
  "material-outline:6k_plus",
  "material-outline:6mp",
  "material-outline:7k",
  "material-outline:7k_plus",
  "material-outline:7mp",
  "material-outline:8k",
  "material-outline:8k_plus",
  "material-outline:8mp",
  "material-outline:9k",
  "material-outline:9k_plus",
  "material-outline:9mp",
  "material-outline:ac_unit",
  "material-outline:access_alarm",
  "material-outline:access_alarms",
  "material-outline:access_time",
  "material-outline:access_time_filled",
  "material-outline:accessibility",
  "material-outline:accessibility_new",
  "material-outline:accessible",
  "material-outline:accessible_forward",
  "material-outline:account_balance",
  "material-outline:account_balance_wallet",
  "material-outline:account_box",
  "material-outline:account_circle",
  "material-outline:account_tree",
  "material-outline:ad_units",
  "material-outline:adb",
  "material-outline:add",
  "material-outline:add_a_photo",
  "material-outline:add_alarm",
  "material-outline:add_alert",
  "material-outline:add_box",
  "material-outline:add_business",
  "material-outline:add_chart",
  "material-outline:add_circle",
  "material-outline:add_circle_outline",
  "material-outline:add_comment",
  "material-outline:add_ic_call",
  "material-outline:add_link",
  "material-outline:add_location",
  "material-outline:add_location_alt",
  "material-outline:add_moderator",
  "material-outline:add_photo_alternate",
  "material-outline:add_reaction",
  "material-outline:add_road",
  "material-outline:add_shopping_cart",
  "material-outline:add_task",
  "material-outline:add_to_drive",
  "material-outline:add_to_home_screen",
  "material-outline:add_to_photos",
  "material-outline:add_to_queue",
  "material-outline:addchart",
  "material-outline:adjust",
  "material-outline:admin_panel_settings",
  "material-outline:ads_click",
  "material-outline:agriculture",
  "material-outline:air",
  "material-outline:airline_seat_flat",
  "material-outline:airline_seat_flat_angled",
  "material-outline:airline_seat_individual_suite",
  "material-outline:airline_seat_legroom_extra",
  "material-outline:airline_seat_legroom_normal",
  "material-outline:airline_seat_legroom_reduced",
  "material-outline:airline_seat_recline_extra",
  "material-outline:airline_seat_recline_normal",
  "material-outline:airplane_ticket",
  "material-outline:airplanemode_active",
  "material-outline:airplanemode_inactive",
  "material-outline:airplay",
  "material-outline:airport_shuttle",
  "material-outline:alarm",
  "material-outline:alarm_add",
  "material-outline:alarm_off",
  "material-outline:alarm_on",
  "material-outline:album",
  "material-outline:align_horizontal_center",
  "material-outline:align_horizontal_left",
  "material-outline:align_horizontal_right",
  "material-outline:align_vertical_bottom",
  "material-outline:align_vertical_center",
  "material-outline:align_vertical_top",
  "material-outline:all_inbox",
  "material-outline:all_inclusive",
  "material-outline:all_out",
  "material-outline:alt_route",
  "material-outline:alternate_email",
  "material-outline:analytics",
  "material-outline:anchor",
  "material-outline:android",
  "material-outline:animation",
  "material-outline:announcement",
  "material-outline:aod",
  "material-outline:apartment",
  "material-outline:api",
  "material-outline:app_blocking",
  "material-outline:app_registration",
  "material-outline:app_settings_alt",
  "material-outline:approval",
  "material-outline:apps",
  "material-outline:architecture",
  "material-outline:archive",
  "material-outline:area_chart",
  "material-outline:arrow_back",
  "material-outline:arrow_back_ios",
  "material-outline:arrow_back_ios_new",
  "material-outline:arrow_circle_down",
  "material-outline:arrow_circle_up",
  "material-outline:arrow_downward",
  "material-outline:arrow_drop_down",
  "material-outline:arrow_drop_down_circle",
  "material-outline:arrow_drop_up",
  "material-outline:arrow_forward",
  "material-outline:arrow_forward_ios",
  "material-outline:arrow_left",
  "material-outline:arrow_right",
  "material-outline:arrow_right_alt",
  "material-outline:arrow_upward",
  "material-outline:art_track",
  "material-outline:article",
  "material-outline:aspect_ratio",
  "material-outline:assessment",
  "material-outline:assignment",
  "material-outline:assignment_ind",
  "material-outline:assignment_late",
  "material-outline:assignment_return",
  "material-outline:assignment_returned",
  "material-outline:assignment_turned_in",
  "material-outline:assistant",
  "material-outline:assistant_direction",
  "material-outline:assistant_photo",
  "material-outline:atm",
  "material-outline:attach_email",
  "material-outline:attach_file",
  "material-outline:attach_money",
  "material-outline:attachment",
  "material-outline:attractions",
  "material-outline:attribution",
  "material-outline:audiotrack",
  "material-outline:auto_awesome",
  "material-outline:auto_awesome_mosaic",
  "material-outline:auto_awesome_motion",
  "material-outline:auto_delete",
  "material-outline:auto_fix_high",
  "material-outline:auto_fix_normal",
  "material-outline:auto_fix_off",
  "material-outline:auto_graph",
  "material-outline:auto_stories",
  "material-outline:autofps_select",
  "material-outline:autorenew",
  "material-outline:av_timer",
  "material-outline:baby_changing_station",
  "material-outline:back_hand",
  "material-outline:backpack",
  "material-outline:backspace",
  "material-outline:backup",
  "material-outline:backup_table",
  "material-outline:badge",
  "material-outline:bakery_dining",
  "material-outline:balcony",
  "material-outline:ballot",
  "material-outline:bar_chart",
  "material-outline:batch_prediction",
  "material-outline:bathroom",
  "material-outline:bathtub",
  "material-outline:battery_alert",
  "material-outline:battery_charging_full",
  "material-outline:battery_full",
  "material-outline:battery_saver",
  "material-outline:battery_std",
  "material-outline:battery_unknown",
  "material-outline:beach_access",
  "material-outline:bed",
  "material-outline:bedroom_baby",
  "material-outline:bedroom_child",
  "material-outline:bedroom_parent",
  "material-outline:bedtime",
  "material-outline:beenhere",
  "material-outline:bento",
  "material-outline:bike_scooter",
  "material-outline:biotech",
  "material-outline:blender",
  "material-outline:block",
  "material-outline:bloodtype",
  "material-outline:bluetooth",
  "material-outline:bluetooth_audio",
  "material-outline:bluetooth_connected",
  "material-outline:bluetooth_disabled",
  "material-outline:bluetooth_drive",
  "material-outline:bluetooth_searching",
  "material-outline:blur_circular",
  "material-outline:blur_linear",
  "material-outline:blur_off",
  "material-outline:blur_on",
  "material-outline:bolt",
  "material-outline:book",
  "material-outline:book_online",
  "material-outline:bookmark",
  "material-outline:bookmark_add",
  "material-outline:bookmark_added",
  "material-outline:bookmark_border",
  "material-outline:bookmark_remove",
  "material-outline:bookmarks",
  "material-outline:border_all",
  "material-outline:border_bottom",
  "material-outline:border_clear",
  "material-outline:border_color",
  "material-outline:border_horizontal",
  "material-outline:border_inner",
  "material-outline:border_left",
  "material-outline:border_outer",
  "material-outline:border_right",
  "material-outline:border_style",
  "material-outline:border_top",
  "material-outline:border_vertical",
  "material-outline:branding_watermark",
  "material-outline:breakfast_dining",
  "material-outline:brightness_1",
  "material-outline:brightness_2",
  "material-outline:brightness_3",
  "material-outline:brightness_4",
  "material-outline:brightness_5",
  "material-outline:brightness_6",
  "material-outline:brightness_7",
  "material-outline:brightness_auto",
  "material-outline:brightness_high",
  "material-outline:brightness_low",
  "material-outline:brightness_medium",
  "material-outline:broken_image",
  "material-outline:browser_not_supported",
  "material-outline:brunch_dining",
  "material-outline:brush",
  "material-outline:bubble_chart",
  "material-outline:bug_report",
  "material-outline:build",
  "material-outline:build_circle",
  "material-outline:bungalow",
  "material-outline:burst_mode",
  "material-outline:bus_alert",
  "material-outline:business",
  "material-outline:business_center",
  "material-outline:cabin",
  "material-outline:cable",
  "material-outline:cached",
  "material-outline:cake",
  "material-outline:calculate",
  "material-outline:calendar_today",
  "material-outline:calendar_view_day",
  "material-outline:calendar_view_month",
  "material-outline:calendar_view_week",
  "material-outline:call",
  "material-outline:call_end",
  "material-outline:call_made",
  "material-outline:call_merge",
  "material-outline:call_missed",
  "material-outline:call_missed_outgoing",
  "material-outline:call_received",
  "material-outline:call_split",
  "material-outline:call_to_action",
  "material-outline:camera",
  "material-outline:camera_alt",
  "material-outline:camera_enhance",
  "material-outline:camera_front",
  "material-outline:camera_indoor",
  "material-outline:camera_outdoor",
  "material-outline:camera_rear",
  "material-outline:camera_roll",
  "material-outline:cameraswitch",
  "material-outline:campaign",
  "material-outline:cancel",
  "material-outline:cancel_presentation",
  "material-outline:cancel_schedule_send",
  "material-outline:car_rental",
  "material-outline:car_repair",
  "material-outline:card_giftcard",
  "material-outline:card_membership",
  "material-outline:card_travel",
  "material-outline:carpenter",
  "material-outline:cases",
  "material-outline:casino",
  "material-outline:cast",
  "material-outline:cast_connected",
  "material-outline:cast_for_education",
  "material-outline:catching_pokemon",
  "material-outline:category",
  "material-outline:celebration",
  "material-outline:cell_wifi",
  "material-outline:center_focus_strong",
  "material-outline:center_focus_weak",
  "material-outline:chair",
  "material-outline:chair_alt",
  "material-outline:chalet",
  "material-outline:change_circle",
  "material-outline:change_history",
  "material-outline:charging_station",
  "material-outline:chat",
  "material-outline:chat_bubble",
  "material-outline:chat_bubble_outline",
  "material-outline:check",
  "material-outline:check_box",
  "material-outline:check_box_outline_blank",
  "material-outline:check_circle",
  "material-outline:check_circle_outline",
  "material-outline:checklist",
  "material-outline:checklist_rtl",
  "material-outline:checkroom",
  "material-outline:chevron_left",
  "material-outline:chevron_right",
  "material-outline:child_care",
  "material-outline:child_friendly",
  "material-outline:chrome_reader_mode",
  "material-outline:circle",
  "material-outline:circle_notifications",
  "material-outline:class",
  "material-outline:clean_hands",
  "material-outline:cleaning_services",
  "material-outline:clear",
  "material-outline:clear_all",
  "material-outline:close",
  "material-outline:close_fullscreen",
  "material-outline:closed_caption",
  "material-outline:closed_caption_disabled",
  "material-outline:closed_caption_off",
  "material-outline:cloud",
  "material-outline:cloud_circle",
  "material-outline:cloud_done",
  "material-outline:cloud_download",
  "material-outline:cloud_off",
  "material-outline:cloud_queue",
  "material-outline:cloud_upload",
  "material-outline:code",
  "material-outline:code_off",
  "material-outline:coffee",
  "material-outline:coffee_maker",
  "material-outline:collections",
  "material-outline:collections_bookmark",
  "material-outline:color_lens",
  "material-outline:colorize",
  "material-outline:comment",
  "material-outline:comment_bank",
  "material-outline:commute",
  "material-outline:compare",
  "material-outline:compare_arrows",
  "material-outline:compass_calibration",
  "material-outline:compost",
  "material-outline:compress",
  "material-outline:computer",
  "material-outline:confirmation_number",
  "material-outline:connect_without_contact",
  "material-outline:connected_tv",
  "material-outline:construction",
  "material-outline:contact_mail",
  "material-outline:contact_page",
  "material-outline:contact_phone",
  "material-outline:contact_support",
  "material-outline:contactless",
  "material-outline:contacts",
  "material-outline:content_copy",
  "material-outline:content_cut",
  "material-outline:content_paste",
  "material-outline:content_paste_off",
  "material-outline:control_camera",
  "material-outline:control_point",
  "material-outline:control_point_duplicate",
  "material-outline:copy_all",
  "material-outline:copyright",
  "material-outline:coronavirus",
  "material-outline:corporate_fare",
  "material-outline:cottage",
  "material-outline:countertops",
  "material-outline:create",
  "material-outline:create_new_folder",
  "material-outline:credit_card",
  "material-outline:credit_card_off",
  "material-outline:credit_score",
  "material-outline:crib",
  "material-outline:crop",
  "material-outline:crop_16_9",
  "material-outline:crop_3_2",
  "material-outline:crop_5_4",
  "material-outline:crop_7_5",
  "material-outline:crop_din",
  "material-outline:crop_free",
  "material-outline:crop_landscape",
  "material-outline:crop_original",
  "material-outline:crop_portrait",
  "material-outline:crop_rotate",
  "material-outline:crop_square",
  "material-outline:cruelty_free",
  "material-outline:dangerous",
  "material-outline:dark_mode",
  "material-outline:dashboard",
  "material-outline:dashboard_customize",
  "material-outline:data_exploration",
  "material-outline:data_saver_off",
  "material-outline:data_saver_on",
  "material-outline:data_usage",
  "material-outline:date_range",
  "material-outline:deck",
  "material-outline:dehaze",
  "material-outline:delete",
  "material-outline:delete_forever",
  "material-outline:delete_outline",
  "material-outline:delete_sweep",
  "material-outline:delivery_dining",
  "material-outline:departure_board",
  "material-outline:description",
  "material-outline:design_services",
  "material-outline:desktop_access_disabled",
  "material-outline:desktop_mac",
  "material-outline:desktop_windows",
  "material-outline:details",
  "material-outline:developer_board",
  "material-outline:developer_board_off",
  "material-outline:developer_mode",
  "material-outline:device_hub",
  "material-outline:device_thermostat",
  "material-outline:device_unknown",
  "material-outline:devices",
  "material-outline:devices_other",
  "material-outline:dialer_sip",
  "material-outline:dialpad",
  "material-outline:dining",
  "material-outline:dinner_dining",
  "material-outline:directions",
  "material-outline:directions_bike",
  "material-outline:directions_boat",
  "material-outline:directions_boat_filled",
  "material-outline:directions_bus",
  "material-outline:directions_bus_filled",
  "material-outline:directions_car",
  "material-outline:directions_car_filled",
  "material-outline:directions_off",
  "material-outline:directions_railway",
  "material-outline:directions_railway_filled",
  "material-outline:directions_run",
  "material-outline:directions_subway",
  "material-outline:directions_subway_filled",
  "material-outline:directions_transit",
  "material-outline:directions_transit_filled",
  "material-outline:directions_walk",
  "material-outline:dirty_lens",
  "material-outline:disabled_by_default",
  "material-outline:disabled_visible",
  "material-outline:disc_full",
  "material-outline:dns",
  "material-outline:do_disturb",
  "material-outline:do_disturb_alt",
  "material-outline:do_disturb_off",
  "material-outline:do_disturb_on",
  "material-outline:do_not_disturb",
  "material-outline:do_not_disturb_alt",
  "material-outline:do_not_disturb_off",
  "material-outline:do_not_disturb_on",
  "material-outline:do_not_disturb_on_total_silence",
  "material-outline:do_not_step",
  "material-outline:do_not_touch",
  "material-outline:dock",
  "material-outline:document_scanner",
  "material-outline:domain",
  "material-outline:domain_disabled",
  "material-outline:domain_verification",
  "material-outline:done",
  "material-outline:done_all",
  "material-outline:done_outline",
  "material-outline:donut_large",
  "material-outline:donut_small",
  "material-outline:door_back",
  "material-outline:door_front",
  "material-outline:door_sliding",
  "material-outline:doorbell",
  "material-outline:double_arrow",
  "material-outline:downhill_skiing",
  "material-outline:download",
  "material-outline:download_done",
  "material-outline:download_for_offline",
  "material-outline:downloading",
  "material-outline:drafts",
  "material-outline:drag_handle",
  "material-outline:drag_indicator",
  "material-outline:draw",
  "material-outline:drive_eta",
  "material-outline:drive_file_move",
  "material-outline:drive_file_move_rtl",
  "material-outline:drive_file_rename_outline",
  "material-outline:drive_folder_upload",
  "material-outline:dry",
  "material-outline:dry_cleaning",
  "material-outline:duo",
  "material-outline:dvr",
  "material-outline:dynamic_feed",
  "material-outline:dynamic_form",
  "material-outline:e_mobiledata",
  "material-outline:earbuds",
  "material-outline:earbuds_battery",
  "material-outline:east",
  "material-outline:edgesensor_high",
  "material-outline:edgesensor_low",
  "material-outline:edit",
  "material-outline:edit_attributes",
  "material-outline:edit_calendar",
  "material-outline:edit_location",
  "material-outline:edit_location_alt",
  "material-outline:edit_note",
  "material-outline:edit_notifications",
  "material-outline:edit_off",
  "material-outline:edit_road",
  "material-outline:eject",
  "material-outline:elderly",
  "material-outline:electric_bike",
  "material-outline:electric_car",
  "material-outline:electric_moped",
  "material-outline:electric_rickshaw",
  "material-outline:electric_scooter",
  "material-outline:electrical_services",
  "material-outline:elevator",
  "material-outline:email",
  "material-outline:emergency",
  "material-outline:emoji_emotions",
  "material-outline:emoji_events",
  "material-outline:emoji_flags",
  "material-outline:emoji_food_beverage",
  "material-outline:emoji_nature",
  "material-outline:emoji_objects",
  "material-outline:emoji_people",
  "material-outline:emoji_symbols",
  "material-outline:emoji_transportation",
  "material-outline:engineering",
  "material-outline:enhanced_encryption",
  "material-outline:equalizer",
  "material-outline:error",
  "material-outline:error_outline",
  "material-outline:escalator",
  "material-outline:escalator_warning",
  "material-outline:euro",
  "material-outline:euro_symbol",
  "material-outline:ev_station",
  "material-outline:event",
  "material-outline:event_available",
  "material-outline:event_busy",
  "material-outline:event_note",
  "material-outline:event_seat",
  "material-outline:exit_to_app",
  "material-outline:expand",
  "material-outline:expand_less",
  "material-outline:expand_more",
  "material-outline:explicit",
  "material-outline:explore",
  "material-outline:explore_off",
  "material-outline:exposure",
  "material-outline:exposure_neg_1",
  "material-outline:exposure_neg_2",
  "material-outline:exposure_plus_1",
  "material-outline:exposure_plus_2",
  "material-outline:exposure_zero",
  "material-outline:extension",
  "material-outline:extension_off",
  "material-outline:face",
  "material-outline:face_retouching_natural",
  "material-outline:face_retouching_off",
  "material-outline:facebook",
  "material-outline:fact_check",
  "material-outline:family_restroom",
  "material-outline:fast_forward",
  "material-outline:fast_rewind",
  "material-outline:fastfood",
  "material-outline:favorite",
  "material-outline:favorite_border",
  "material-outline:featured_play_list",
  "material-outline:featured_video",
  "material-outline:feed",
  "material-outline:feedback",
  "material-outline:female",
  "material-outline:fence",
  "material-outline:festival",
  "material-outline:fiber_dvr",
  "material-outline:fiber_manual_record",
  "material-outline:fiber_new",
  "material-outline:fiber_pin",
  "material-outline:fiber_smart_record",
  "material-outline:file_copy",
  "material-outline:file_download",
  "material-outline:file_download_done",
  "material-outline:file_download_off",
  "material-outline:file_present",
  "material-outline:file_upload",
  "material-outline:filter",
  "material-outline:filter_1",
  "material-outline:filter_2",
  "material-outline:filter_3",
  "material-outline:filter_4",
  "material-outline:filter_5",
  "material-outline:filter_6",
  "material-outline:filter_7",
  "material-outline:filter_8",
  "material-outline:filter_9",
  "material-outline:filter_9_plus",
  "material-outline:filter_alt",
  "material-outline:filter_b_and_w",
  "material-outline:filter_center_focus",
  "material-outline:filter_drama",
  "material-outline:filter_frames",
  "material-outline:filter_hdr",
  "material-outline:filter_list",
  "material-outline:filter_none",
  "material-outline:filter_tilt_shift",
  "material-outline:filter_vintage",
  "material-outline:find_in_page",
  "material-outline:find_replace",
  "material-outline:fingerprint",
  "material-outline:fire_extinguisher",
  "material-outline:fireplace",
  "material-outline:first_page",
  "material-outline:fit_screen",
  "material-outline:fitness_center",
  "material-outline:flag",
  "material-outline:flaky",
  "material-outline:flare",
  "material-outline:flash_auto",
  "material-outline:flash_off",
  "material-outline:flash_on",
  "material-outline:flashlight_off",
  "material-outline:flashlight_on",
  "material-outline:flatware",
  "material-outline:flight",
  "material-outline:flight_land",
  "material-outline:flight_takeoff",
  "material-outline:flip",
  "material-outline:flip_camera_android",
  "material-outline:flip_camera_ios",
  "material-outline:flip_to_back",
  "material-outline:flip_to_front",
  "material-outline:flourescent",
  "material-outline:flutter_dash",
  "material-outline:fmd_bad",
  "material-outline:fmd_good",
  "material-outline:folder",
  "material-outline:folder_open",
  "material-outline:folder_shared",
  "material-outline:folder_special",
  "material-outline:follow_the_signs",
  "material-outline:font_download",
  "material-outline:font_download_off",
  "material-outline:food_bank",
  "material-outline:format_align_center",
  "material-outline:format_align_justify",
  "material-outline:format_align_left",
  "material-outline:format_align_right",
  "material-outline:format_bold",
  "material-outline:format_clear",
  "material-outline:format_color_fill",
  "material-outline:format_color_reset",
  "material-outline:format_color_text",
  "material-outline:format_indent_decrease",
  "material-outline:format_indent_increase",
  "material-outline:format_italic",
  "material-outline:format_line_spacing",
  "material-outline:format_list_bulleted",
  "material-outline:format_list_numbered",
  "material-outline:format_list_numbered_rtl",
  "material-outline:format_paint",
  "material-outline:format_quote",
  "material-outline:format_shapes",
  "material-outline:format_size",
  "material-outline:format_strikethrough",
  "material-outline:format_textdirection_l_to_r",
  "material-outline:format_textdirection_r_to_l",
  "material-outline:format_underlined",
  "material-outline:forum",
  "material-outline:forward",
  "material-outline:forward_10",
  "material-outline:forward_30",
  "material-outline:forward_5",
  "material-outline:forward_to_inbox",
  "material-outline:foundation",
  "material-outline:free_breakfast",
  "material-outline:free_cancellation",
  "material-outline:front_hand",
  "material-outline:fullscreen",
  "material-outline:fullscreen_exit",
  "material-outline:functions",
  "material-outline:g_mobiledata",
  "material-outline:g_translate",
  "material-outline:gamepad",
  "material-outline:games",
  "material-outline:garage",
  "material-outline:gavel",
  "material-outline:generating_tokens",
  "material-outline:gesture",
  "material-outline:get_app",
  "material-outline:gif",
  "material-outline:gite",
  "material-outline:golf_course",
  "material-outline:gpp_bad",
  "material-outline:gpp_good",
  "material-outline:gpp_maybe",
  "material-outline:gps_fixed",
  "material-outline:gps_not_fixed",
  "material-outline:gps_off",
  "material-outline:grade",
  "material-outline:gradient",
  "material-outline:grading",
  "material-outline:grain",
  "material-outline:graphic_eq",
  "material-outline:grass",
  "material-outline:grid_3x3",
  "material-outline:grid_4x4",
  "material-outline:grid_goldenratio",
  "material-outline:grid_off",
  "material-outline:grid_on",
  "material-outline:grid_view",
  "material-outline:group",
  "material-outline:group_add",
  "material-outline:group_off",
  "material-outline:group_work",
  "material-outline:groups",
  "material-outline:h_mobiledata",
  "material-outline:h_plus_mobiledata",
  "material-outline:hail",
  "material-outline:handyman",
  "material-outline:hardware",
  "material-outline:hd",
  "material-outline:hdr_auto",
  "material-outline:hdr_auto_select",
  "material-outline:hdr_enhanced_select",
  "material-outline:hdr_off",
  "material-outline:hdr_off_select",
  "material-outline:hdr_on",
  "material-outline:hdr_on_select",
  "material-outline:hdr_plus",
  "material-outline:hdr_strong",
  "material-outline:hdr_weak",
  "material-outline:headphones",
  "material-outline:headphones_battery",
  "material-outline:headset",
  "material-outline:headset_mic",
  "material-outline:headset_off",
  "material-outline:healing",
  "material-outline:health_and_safety",
  "material-outline:hearing",
  "material-outline:hearing_disabled",
  "material-outline:height",
  "material-outline:help",
  "material-outline:help_center",
  "material-outline:help_outline",
  "material-outline:hevc",
  "material-outline:hide_image",
  "material-outline:hide_source",
  "material-outline:high_quality",
  "material-outline:highlight",
  "material-outline:highlight_alt",
  "material-outline:highlight_off",
  "material-outline:hiking",
  "material-outline:history",
  "material-outline:history_edu",
  "material-outline:history_toggle_off",
  "material-outline:holiday_village",
  "material-outline:home",
  "material-outline:home_max",
  "material-outline:home_mini",
  "material-outline:home_repair_service",
  "material-outline:home_work",
  "material-outline:horizontal_distribute",
  "material-outline:horizontal_rule",
  "material-outline:horizontal_split",
  "material-outline:hot_tub",
  "material-outline:hotel",
  "material-outline:hotel_class",
  "material-outline:hourglass_bottom",
  "material-outline:hourglass_disabled",
  "material-outline:hourglass_empty",
  "material-outline:hourglass_full",
  "material-outline:hourglass_top",
  "material-outline:house",
  "material-outline:house_siding",
  "material-outline:houseboat",
  "material-outline:how_to_reg",
  "material-outline:how_to_vote",
  "material-outline:http",
  "material-outline:https",
  "material-outline:hvac",
  "material-outline:ice_skating",
  "material-outline:icecream",
  "material-outline:image",
  "material-outline:image_aspect_ratio",
  "material-outline:image_not_supported",
  "material-outline:image_search",
  "material-outline:imagesearch_roller",
  "material-outline:import_contacts",
  "material-outline:import_export",
  "material-outline:important_devices",
  "material-outline:inbox",
  "material-outline:incomplete_circle",
  "material-outline:indeterminate_check_box",
  "material-outline:info",
  "material-outline:input",
  "material-outline:insert_chart",
  "material-outline:insert_chart_outline",
  "material-outline:insert_comment",
  "material-outline:insert_drive_file",
  "material-outline:insert_emoticon",
  "material-outline:insert_invitation",
  "material-outline:insert_link",
  "material-outline:insert_photo",
  "material-outline:insights",
  "material-outline:integration_instructions",
  "material-outline:inventory",
  "material-outline:inventory_2",
  "material-outline:invert_colors",
  "material-outline:invert_colors_off",
  "material-outline:ios_share",
  "material-outline:iron",
  "material-outline:iso",
  "material-outline:kayaking",
  "material-outline:keyboard",
  "material-outline:keyboard_alt",
  "material-outline:keyboard_arrow_down",
  "material-outline:keyboard_arrow_left",
  "material-outline:keyboard_arrow_right",
  "material-outline:keyboard_arrow_up",
  "material-outline:keyboard_backspace",
  "material-outline:keyboard_capslock",
  "material-outline:keyboard_hide",
  "material-outline:keyboard_return",
  "material-outline:keyboard_tab",
  "material-outline:keyboard_voice",
  "material-outline:king_bed",
  "material-outline:kitchen",
  "material-outline:kitesurfing",
  "material-outline:label",
  "material-outline:label_important",
  "material-outline:label_off",
  "material-outline:landscape",
  "material-outline:language",
  "material-outline:laptop",
  "material-outline:laptop_chromebook",
  "material-outline:laptop_mac",
  "material-outline:laptop_windows",
  "material-outline:last_page",
  "material-outline:launch",
  "material-outline:layers",
  "material-outline:layers_clear",
  "material-outline:leaderboard",
  "material-outline:leak_add",
  "material-outline:leak_remove",
  "material-outline:legend_toggle",
  "material-outline:lens",
  "material-outline:lens_blur",
  "material-outline:library_add",
  "material-outline:library_add_check",
  "material-outline:library_books",
  "material-outline:library_music",
  "material-outline:light",
  "material-outline:light_mode",
  "material-outline:lightbulb",
  "material-outline:line_style",
  "material-outline:line_weight",
  "material-outline:linear_scale",
  "material-outline:link",
  "material-outline:link_off",
  "material-outline:linked_camera",
  "material-outline:liquor",
  "material-outline:list",
  "material-outline:list_alt",
  "material-outline:live_help",
  "material-outline:live_tv",
  "material-outline:living",
  "material-outline:local_activity",
  "material-outline:local_airport",
  "material-outline:local_atm",
  "material-outline:local_bar",
  "material-outline:local_cafe",
  "material-outline:local_car_wash",
  "material-outline:local_convenience_store",
  "material-outline:local_dining",
  "material-outline:local_drink",
  "material-outline:local_fire_department",
  "material-outline:local_florist",
  "material-outline:local_gas_station",
  "material-outline:local_grocery_store",
  "material-outline:local_hospital",
  "material-outline:local_hotel",
  "material-outline:local_laundry_service",
  "material-outline:local_library",
  "material-outline:local_mall",
  "material-outline:local_movies",
  "material-outline:local_offer",
  "material-outline:local_parking",
  "material-outline:local_pharmacy",
  "material-outline:local_phone",
  "material-outline:local_pizza",
  "material-outline:local_play",
  "material-outline:local_police",
  "material-outline:local_post_office",
  "material-outline:local_printshop",
  "material-outline:local_see",
  "material-outline:local_shipping",
  "material-outline:local_taxi",
  "material-outline:location_city",
  "material-outline:location_disabled",
  "material-outline:location_off",
  "material-outline:location_on",
  "material-outline:location_searching",
  "material-outline:lock",
  "material-outline:lock_clock",
  "material-outline:lock_open",
  "material-outline:login",
  "material-outline:logout",
  "material-outline:looks",
  "material-outline:looks_3",
  "material-outline:looks_4",
  "material-outline:looks_5",
  "material-outline:looks_6",
  "material-outline:looks_one",
  "material-outline:looks_two",
  "material-outline:loop",
  "material-outline:loupe",
  "material-outline:low_priority",
  "material-outline:loyalty",
  "material-outline:lte_mobiledata",
  "material-outline:lte_plus_mobiledata",
  "material-outline:luggage",
  "material-outline:lunch_dining",
  "material-outline:mail",
  "material-outline:mail_outline",
  "material-outline:male",
  "material-outline:manage_accounts",
  "material-outline:manage_search",
  "material-outline:map",
  "material-outline:maps_home_work",
  "material-outline:maps_ugc",
  "material-outline:margin",
  "material-outline:mark_as_unread",
  "material-outline:mark_chat_read",
  "material-outline:mark_chat_unread",
  "material-outline:mark_email_read",
  "material-outline:mark_email_unread",
  "material-outline:markunread",
  "material-outline:markunread_mailbox",
  "material-outline:masks",
  "material-outline:maximize",
  "material-outline:media_bluetooth_off",
  "material-outline:media_bluetooth_on",
  "material-outline:mediation",
  "material-outline:medical_services",
  "material-outline:medication",
  "material-outline:meeting_room",
  "material-outline:memory",
  "material-outline:menu",
  "material-outline:menu_book",
  "material-outline:menu_open",
  "material-outline:merge_type",
  "material-outline:message",
  "material-outline:mic",
  "material-outline:mic_external_off",
  "material-outline:mic_external_on",
  "material-outline:mic_none",
  "material-outline:mic_off",
  "material-outline:microwave",
  "material-outline:military_tech",
  "material-outline:minimize",
  "material-outline:miscellaneous_services",
  "material-outline:missed_video_call",
  "material-outline:mms",
  "material-outline:mobile_friendly",
  "material-outline:mobile_off",
  "material-outline:mobile_screen_share",
  "material-outline:mobiledata_off",
  "material-outline:mode",
  "material-outline:mode_comment",
  "material-outline:mode_edit",
  "material-outline:mode_edit_outline",
  "material-outline:mode_night",
  "material-outline:mode_standby",
  "material-outline:model_training",
  "material-outline:monetization_on",
  "material-outline:money",
  "material-outline:money_off",
  "material-outline:money_off_csred",
  "material-outline:monitor",
  "material-outline:monitor_weight",
  "material-outline:monochrome_photos",
  "material-outline:mood",
  "material-outline:mood_bad",
  "material-outline:moped",
  "material-outline:more",
  "material-outline:more_horiz",
  "material-outline:more_time",
  "material-outline:more_vert",
  "material-outline:motion_photos_auto",
  "material-outline:motion_photos_off",
  "material-outline:motion_photos_on",
  "material-outline:motion_photos_pause",
  "material-outline:motion_photos_paused",
  "material-outline:mouse",
  "material-outline:move_to_inbox",
  "material-outline:movie",
  "material-outline:movie_creation",
  "material-outline:movie_filter",
  "material-outline:moving",
  "material-outline:mp",
  "material-outline:multiline_chart",
  "material-outline:multiple_stop",
  "material-outline:museum",
  "material-outline:music_note",
  "material-outline:music_off",
  "material-outline:music_video",
  "material-outline:my_location",
  "material-outline:nat",
  "material-outline:nature",
  "material-outline:nature_people",
  "material-outline:navigate_before",
  "material-outline:navigate_next",
  "material-outline:navigation",
  "material-outline:near_me",
  "material-outline:near_me_disabled",
  "material-outline:nearby_error",
  "material-outline:nearby_off",
  "material-outline:network_cell",
  "material-outline:network_check",
  "material-outline:network_locked",
  "material-outline:network_wifi",
  "material-outline:new_label",
  "material-outline:new_releases",
  "material-outline:next_plan",
  "material-outline:next_week",
  "material-outline:nfc",
  "material-outline:night_shelter",
  "material-outline:nightlife",
  "material-outline:nightlight",
  "material-outline:nightlight_round",
  "material-outline:nights_stay",
  "material-outline:no_accounts",
  "material-outline:no_backpack",
  "material-outline:no_cell",
  "material-outline:no_drinks",
  "material-outline:no_encryption",
  "material-outline:no_encryption_gmailerrorred",
  "material-outline:no_flash",
  "material-outline:no_food",
  "material-outline:no_luggage",
  "material-outline:no_meals",
  "material-outline:no_meeting_room",
  "material-outline:no_photography",
  "material-outline:no_sim",
  "material-outline:no_stroller",
  "material-outline:no_transfer",
  "material-outline:nordic_walking",
  "material-outline:north",
  "material-outline:north_east",
  "material-outline:north_west",
  "material-outline:not_accessible",
  "material-outline:not_interested",
  "material-outline:not_listed_location",
  "material-outline:not_started",
  "material-outline:note",
  "material-outline:note_add",
  "material-outline:note_alt",
  "material-outline:notes",
  "material-outline:notification_add",
  "material-outline:notification_important",
  "material-outline:notifications",
  "material-outline:notifications_active",
  "material-outline:notifications_none",
  "material-outline:notifications_off",
  "material-outline:notifications_paused",
  "material-outline:offline_bolt",
  "material-outline:offline_pin",
  "material-outline:offline_share",
  "material-outline:ondemand_video",
  "material-outline:online_prediction",
  "material-outline:opacity",
  "material-outline:open_in_browser",
  "material-outline:open_in_full",
  "material-outline:open_in_new",
  "material-outline:open_in_new_off",
  "material-outline:open_with",
  "material-outline:other_houses",
  "material-outline:outbound",
  "material-outline:outbox",
  "material-outline:outdoor_grill",
  "material-outline:outlet",
  "material-outline:outline_flag",
  "material-outline:padding",
  "material-outline:pages",
  "material-outline:pageview",
  "material-outline:paid",
  "material-outline:palette",
  "material-outline:pan_tool",
  "material-outline:panorama",
  "material-outline:panorama_fish_eye",
  "material-outline:panorama_horizontal",
  "material-outline:panorama_horizontal_select",
  "material-outline:panorama_photosphere",
  "material-outline:panorama_photosphere_select",
  "material-outline:panorama_vertical",
  "material-outline:panorama_vertical_select",
  "material-outline:panorama_wide_angle",
  "material-outline:panorama_wide_angle_select",
  "material-outline:paragliding",
  "material-outline:park",
  "material-outline:party_mode",
  "material-outline:password",
  "material-outline:pattern",
  "material-outline:pause",
  "material-outline:pause_circle",
  "material-outline:pause_circle_filled",
  "material-outline:pause_circle_outline",
  "material-outline:pause_presentation",
  "material-outline:payment",
  "material-outline:payments",
  "material-outline:pedal_bike",
  "material-outline:pending",
  "material-outline:pending_actions",
  "material-outline:people",
  "material-outline:people_alt",
  "material-outline:people_outline",
  "material-outline:perm_camera_mic",
  "material-outline:perm_contact_calendar",
  "material-outline:perm_data_setting",
  "material-outline:perm_device_information",
  "material-outline:perm_identity",
  "material-outline:perm_media",
  "material-outline:perm_phone_msg",
  "material-outline:perm_scan_wifi",
  "material-outline:person",
  "material-outline:person_add",
  "material-outline:person_add_alt",
  "material-outline:person_add_alt_1",
  "material-outline:person_add_disabled",
  "material-outline:person_off",
  "material-outline:person_outline",
  "material-outline:person_pin",
  "material-outline:person_pin_circle",
  "material-outline:person_remove",
  "material-outline:person_remove_alt_1",
  "material-outline:person_search",
  "material-outline:personal_injury",
  "material-outline:personal_video",
  "material-outline:pest_control",
  "material-outline:pest_control_rodent",
  "material-outline:pets",
  "material-outline:phone",
  "material-outline:phone_android",
  "material-outline:phone_bluetooth_speaker",
  "material-outline:phone_callback",
  "material-outline:phone_disabled",
  "material-outline:phone_enabled",
  "material-outline:phone_forwarded",
  "material-outline:phone_in_talk",
  "material-outline:phone_iphone",
  "material-outline:phone_locked",
  "material-outline:phone_missed",
  "material-outline:phone_paused",
  "material-outline:phonelink",
  "material-outline:phonelink_erase",
  "material-outline:phonelink_lock",
  "material-outline:phonelink_off",
  "material-outline:phonelink_ring",
  "material-outline:phonelink_setup",
  "material-outline:photo",
  "material-outline:photo_album",
  "material-outline:photo_camera",
  "material-outline:photo_camera_back",
  "material-outline:photo_camera_front",
  "material-outline:photo_filter",
  "material-outline:photo_library",
  "material-outline:photo_size_select_actual",
  "material-outline:photo_size_select_large",
  "material-outline:photo_size_select_small",
  "material-outline:piano",
  "material-outline:piano_off",
  "material-outline:picture_as_pdf",
  "material-outline:picture_in_picture",
  "material-outline:picture_in_picture_alt",
  "material-outline:pie_chart",
  "material-outline:pie_chart_outline",
  "material-outline:pin",
  "material-outline:pin_drop",
  "material-outline:pin_end",
  "material-outline:pin_invoke",
  "material-outline:pivot_table_chart",
  "material-outline:place",
  "material-outline:plagiarism",
  "material-outline:play_arrow",
  "material-outline:play_circle",
  "material-outline:play_circle_filled",
  "material-outline:play_circle_outline",
  "material-outline:play_disabled",
  "material-outline:play_for_work",
  "material-outline:play_lesson",
  "material-outline:playlist_add",
  "material-outline:playlist_add_check",
  "material-outline:playlist_play",
  "material-outline:plumbing",
  "material-outline:plus_one",
  "material-outline:podcasts",
  "material-outline:point_of_sale",
  "material-outline:policy",
  "material-outline:poll",
  "material-outline:polymer",
  "material-outline:pool",
  "material-outline:portable_wifi_off",
  "material-outline:portrait",
  "material-outline:post_add",
  "material-outline:power",
  "material-outline:power_input",
  "material-outline:power_off",
  "material-outline:power_settings_new",
  "material-outline:precision_manufacturing",
  "material-outline:pregnant_woman",
  "material-outline:present_to_all",
  "material-outline:preview",
  "material-outline:price_change",
  "material-outline:price_check",
  "material-outline:print",
  "material-outline:print_disabled",
  "material-outline:priority_high",
  "material-outline:privacy_tip",
  "material-outline:private_connectivity",
  "material-outline:production_quantity_limits",
  "material-outline:psychology",
  "material-outline:public",
  "material-outline:public_off",
  "material-outline:publish",
  "material-outline:published_with_changes",
  "material-outline:push_pin",
  "material-outline:qr_code",
  "material-outline:qr_code_2",
  "material-outline:qr_code_scanner",
  "material-outline:query_builder",
  "material-outline:query_stats",
  "material-outline:question_answer",
  "material-outline:queue",
  "material-outline:queue_music",
  "material-outline:queue_play_next",
  "material-outline:quickreply",
  "material-outline:quiz",
  "material-outline:r_mobiledata",
  "material-outline:radar",
  "material-outline:radio",
  "material-outline:radio_button_checked",
  "material-outline:radio_button_unchecked",
  "material-outline:railway_alert",
  "material-outline:ramen_dining",
  "material-outline:rate_review",
  "material-outline:raw_off",
  "material-outline:raw_on",
  "material-outline:read_more",
  "material-outline:real_estate_agent",
  "material-outline:receipt",
  "material-outline:receipt_long",
  "material-outline:recent_actors",
  "material-outline:recommend",
  "material-outline:record_voice_over",
  "material-outline:recycling",
  "material-outline:redeem",
  "material-outline:redo",
  "material-outline:reduce_capacity",
  "material-outline:refresh",
  "material-outline:remember_me",
  "material-outline:remove",
  "material-outline:remove_circle",
  "material-outline:remove_circle_outline",
  "material-outline:remove_done",
  "material-outline:remove_from_queue",
  "material-outline:remove_moderator",
  "material-outline:remove_red_eye",
  "material-outline:remove_shopping_cart",
  "material-outline:reorder",
  "material-outline:repeat",
  "material-outline:repeat_on",
  "material-outline:repeat_one",
  "material-outline:repeat_one_on",
  "material-outline:replay",
  "material-outline:replay_10",
  "material-outline:replay_30",
  "material-outline:replay_5",
  "material-outline:replay_circle_filled",
  "material-outline:reply",
  "material-outline:reply_all",
  "material-outline:report",
  "material-outline:report_gmailerrorred",
  "material-outline:report_off",
  "material-outline:report_problem",
  "material-outline:request_page",
  "material-outline:request_quote",
  "material-outline:reset_tv",
  "material-outline:restart_alt",
  "material-outline:restaurant",
  "material-outline:restaurant_menu",
  "material-outline:restore",
  "material-outline:restore_from_trash",
  "material-outline:restore_page",
  "material-outline:reviews",
  "material-outline:rice_bowl",
  "material-outline:ring_volume",
  "material-outline:roofing",
  "material-outline:room",
  "material-outline:room_preferences",
  "material-outline:room_service",
  "material-outline:rotate_90_degrees_ccw",
  "material-outline:rotate_left",
  "material-outline:rotate_right",
  "material-outline:rounded_corner",
  "material-outline:router",
  "material-outline:rowing",
  "material-outline:rss_feed",
  "material-outline:rsvp",
  "material-outline:rtt",
  "material-outline:rule",
  "material-outline:rule_folder",
  "material-outline:run_circle",
  "material-outline:running_with_errors",
  "material-outline:rv_hookup",
  "material-outline:safety_divider",
  "material-outline:sailing",
  "material-outline:sanitizer",
  "material-outline:satellite",
  "material-outline:save",
  "material-outline:save_alt",
  "material-outline:saved_search",
  "material-outline:savings",
  "material-outline:scanner",
  "material-outline:scatter_plot",
  "material-outline:schedule",
  "material-outline:schedule_send",
  "material-outline:schema",
  "material-outline:school",
  "material-outline:science",
  "material-outline:score",
  "material-outline:screen_lock_landscape",
  "material-outline:screen_lock_portrait",
  "material-outline:screen_lock_rotation",
  "material-outline:screen_rotation",
  "material-outline:screen_search_desktop",
  "material-outline:screen_share",
  "material-outline:screenshot",
  "material-outline:sd",
  "material-outline:sd_card",
  "material-outline:sd_card_alert",
  "material-outline:sd_storage",
  "material-outline:search",
  "material-outline:search_off",
  "material-outline:security",
  "material-outline:security_update",
  "material-outline:security_update_good",
  "material-outline:security_update_warning",
  "material-outline:segment",
  "material-outline:select_all",
  "material-outline:self_improvement",
  "material-outline:sell",
  "material-outline:send",
  "material-outline:send_and_archive",
  "material-outline:send_to_mobile",
  "material-outline:sensor_door",
  "material-outline:sensor_window",
  "material-outline:sensors",
  "material-outline:sensors_off",
  "material-outline:sentiment_dissatisfied",
  "material-outline:sentiment_neutral",
  "material-outline:sentiment_satisfied",
  "material-outline:sentiment_satisfied_alt",
  "material-outline:sentiment_very_dissatisfied",
  "material-outline:sentiment_very_satisfied",
  "material-outline:set_meal",
  "material-outline:settings",
  "material-outline:settings_accessibility",
  "material-outline:settings_applications",
  "material-outline:settings_backup_restore",
  "material-outline:settings_bluetooth",
  "material-outline:settings_brightness",
  "material-outline:settings_cell",
  "material-outline:settings_ethernet",
  "material-outline:settings_input_antenna",
  "material-outline:settings_input_component",
  "material-outline:settings_input_composite",
  "material-outline:settings_input_hdmi",
  "material-outline:settings_input_svideo",
  "material-outline:settings_overscan",
  "material-outline:settings_phone",
  "material-outline:settings_power",
  "material-outline:settings_remote",
  "material-outline:settings_suggest",
  "material-outline:settings_system_daydream",
  "material-outline:settings_voice",
  "material-outline:share",
  "material-outline:share_location",
  "material-outline:shield",
  "material-outline:shop",
  "material-outline:shop_2",
  "material-outline:shop_two",
  "material-outline:shopping_bag",
  "material-outline:shopping_basket",
  "material-outline:shopping_cart",
  "material-outline:short_text",
  "material-outline:shortcut",
  "material-outline:show_chart",
  "material-outline:shower",
  "material-outline:shuffle",
  "material-outline:shuffle_on",
  "material-outline:shutter_speed",
  "material-outline:sick",
  "material-outline:signal_cellular_0_bar",
  "material-outline:signal_cellular_4_bar",
  "material-outline:signal_cellular_alt",
  "material-outline:signal_cellular_connected_no_internet_0_bar",
  "material-outline:signal_cellular_connected_no_internet_4_bar",
  "material-outline:signal_cellular_no_sim",
  "material-outline:signal_cellular_nodata",
  "material-outline:signal_cellular_null",
  "material-outline:signal_cellular_off",
  "material-outline:signal_wifi_0_bar",
  "material-outline:signal_wifi_4_bar",
  "material-outline:signal_wifi_4_bar_lock",
  "material-outline:signal_wifi_bad",
  "material-outline:signal_wifi_connected_no_internet_4",
  "material-outline:signal_wifi_off",
  "material-outline:signal_wifi_statusbar_4_bar",
  "material-outline:signal_wifi_statusbar_connected_no_internet_4",
  "material-outline:signal_wifi_statusbar_null",
  "material-outline:sim_card",
  "material-outline:sim_card_alert",
  "material-outline:sim_card_download",
  "material-outline:single_bed",
  "material-outline:sip",
  "material-outline:skateboarding",
  "material-outline:skip_next",
  "material-outline:skip_previous",
  "material-outline:sledding",
  "material-outline:slideshow",
  "material-outline:slow_motion_video",
  "material-outline:smart_button",
  "material-outline:smart_display",
  "material-outline:smart_screen",
  "material-outline:smart_toy",
  "material-outline:smartphone",
  "material-outline:smoke_free",
  "material-outline:smoking_rooms",
  "material-outline:sms",
  "material-outline:sms_failed",
  "material-outline:snippet_folder",
  "material-outline:snooze",
  "material-outline:snowboarding",
  "material-outline:snowmobile",
  "material-outline:snowshoeing",
  "material-outline:soap",
  "material-outline:social_distance",
  "material-outline:sort",
  "material-outline:sort_by_alpha",
  "material-outline:source",
  "material-outline:south",
  "material-outline:south_east",
  "material-outline:south_west",
  "material-outline:spa",
  "material-outline:space_bar",
  "material-outline:space_dashboard",
  "material-outline:speaker",
  "material-outline:speaker_group",
  "material-outline:speaker_notes",
  "material-outline:speaker_notes_off",
  "material-outline:speaker_phone",
  "material-outline:speed",
  "material-outline:spellcheck",
  "material-outline:splitscreen",
  "material-outline:sports",
  "material-outline:sports_bar",
  "material-outline:sports_baseball",
  "material-outline:sports_basketball",
  "material-outline:sports_cricket",
  "material-outline:sports_esports",
  "material-outline:sports_football",
  "material-outline:sports_golf",
  "material-outline:sports_handball",
  "material-outline:sports_hockey",
  "material-outline:sports_kabaddi",
  "material-outline:sports_mma",
  "material-outline:sports_motorsports",
  "material-outline:sports_rugby",
  "material-outline:sports_score",
  "material-outline:sports_soccer",
  "material-outline:sports_tennis",
  "material-outline:sports_volleyball",
  "material-outline:square_foot",
  "material-outline:stacked_bar_chart",
  "material-outline:stacked_line_chart",
  "material-outline:stairs",
  "material-outline:star",
  "material-outline:star_border",
  "material-outline:star_border_purple500",
  "material-outline:star_half",
  "material-outline:star_outline",
  "material-outline:star_purple500",
  "material-outline:star_rate",
  "material-outline:stars",
  "material-outline:stay_current_landscape",
  "material-outline:stay_current_portrait",
  "material-outline:stay_primary_landscape",
  "material-outline:stay_primary_portrait",
  "material-outline:sticky_note_2",
  "material-outline:stop",
  "material-outline:stop_circle",
  "material-outline:stop_screen_share",
  "material-outline:storage",
  "material-outline:store",
  "material-outline:store_mall_directory",
  "material-outline:storefront",
  "material-outline:storm",
  "material-outline:straighten",
  "material-outline:stream",
  "material-outline:streetview",
  "material-outline:strikethrough_s",
  "material-outline:stroller",
  "material-outline:style",
  "material-outline:subdirectory_arrow_left",
  "material-outline:subdirectory_arrow_right",
  "material-outline:subject",
  "material-outline:subscript",
  "material-outline:subscriptions",
  "material-outline:subtitles",
  "material-outline:subtitles_off",
  "material-outline:subway",
  "material-outline:summarize",
  "material-outline:superscript",
  "material-outline:supervised_user_circle",
  "material-outline:supervisor_account",
  "material-outline:support",
  "material-outline:support_agent",
  "material-outline:surfing",
  "material-outline:surround_sound",
  "material-outline:swap_calls",
  "material-outline:swap_horiz",
  "material-outline:swap_horizontal_circle",
  "material-outline:swap_vert",
  "material-outline:swap_vertical_circle",
  "material-outline:swipe",
  "material-outline:switch_account",
  "material-outline:switch_camera",
  "material-outline:switch_left",
  "material-outline:switch_right",
  "material-outline:switch_video",
  "material-outline:sync",
  "material-outline:sync_alt",
  "material-outline:sync_disabled",
  "material-outline:sync_problem",
  "material-outline:system_security_update",
  "material-outline:system_security_update_good",
  "material-outline:system_security_update_warning",
  "material-outline:system_update",
  "material-outline:system_update_alt",
  "material-outline:tab",
  "material-outline:tab_unselected",
  "material-outline:table_chart",
  "material-outline:table_rows",
  "material-outline:table_view",
  "material-outline:tablet",
  "material-outline:tablet_android",
  "material-outline:tablet_mac",
  "material-outline:tag",
  "material-outline:tag_faces",
  "material-outline:takeout_dining",
  "material-outline:tap_and_play",
  "material-outline:tapas",
  "material-outline:task",
  "material-outline:task_alt",
  "material-outline:taxi_alert",
  "material-outline:terrain",
  "material-outline:text_fields",
  "material-outline:text_format",
  "material-outline:text_rotate_up",
  "material-outline:text_rotate_vertical",
  "material-outline:text_rotation_angledown",
  "material-outline:text_rotation_angleup",
  "material-outline:text_rotation_down",
  "material-outline:text_rotation_none",
  "material-outline:text_snippet",
  "material-outline:textsms",
  "material-outline:texture",
  "material-outline:theater_comedy",
  "material-outline:theaters",
  "material-outline:thermostat",
  "material-outline:thermostat_auto",
  "material-outline:thumb_down",
  "material-outline:thumb_down_alt",
  "material-outline:thumb_down_off_alt",
  "material-outline:thumb_up",
  "material-outline:thumb_up_alt",
  "material-outline:thumb_up_off_alt",
  "material-outline:thumbs_up_down",
  "material-outline:time_to_leave",
  "material-outline:timelapse",
  "material-outline:timeline",
  "material-outline:timer",
  "material-outline:timer_10",
  "material-outline:timer_10_select",
  "material-outline:timer_3",
  "material-outline:timer_3_select",
  "material-outline:timer_off",
  "material-outline:tips_and_updates",
  "material-outline:title",
  "material-outline:toc",
  "material-outline:today",
  "material-outline:toggle_off",
  "material-outline:toggle_on",
  "material-outline:toll",
  "material-outline:tonality",
  "material-outline:topic",
  "material-outline:touch_app",
  "material-outline:tour",
  "material-outline:toys",
  "material-outline:track_changes",
  "material-outline:traffic",
  "material-outline:train",
  "material-outline:tram",
  "material-outline:transfer_within_a_station",
  "material-outline:transform",
  "material-outline:transgender",
  "material-outline:transit_enterexit",
  "material-outline:translate",
  "material-outline:travel_explore",
  "material-outline:trending_down",
  "material-outline:trending_flat",
  "material-outline:trending_up",
  "material-outline:trip_origin",
  "material-outline:try",
  "material-outline:tty",
  "material-outline:tune",
  "material-outline:tungsten",
  "material-outline:turned_in",
  "material-outline:turned_in_not",
  "material-outline:tv",
  "material-outline:tv_off",
  "material-outline:two_wheeler",
  "material-outline:umbrella",
  "material-outline:unarchive",
  "material-outline:undo",
  "material-outline:unfold_less",
  "material-outline:unfold_more",
  "material-outline:unpublished",
  "material-outline:unsubscribe",
  "material-outline:upcoming",
  "material-outline:update",
  "material-outline:update_disabled",
  "material-outline:upgrade",
  "material-outline:upload",
  "material-outline:upload_file",
  "material-outline:usb",
  "material-outline:usb_off",
  "material-outline:verified",
  "material-outline:verified_user",
  "material-outline:vertical_align_bottom",
  "material-outline:vertical_align_center",
  "material-outline:vertical_align_top",
  "material-outline:vertical_distribute",
  "material-outline:vertical_split",
  "material-outline:vibration",
  "material-outline:video_call",
  "material-outline:video_camera_back",
  "material-outline:video_camera_front",
  "material-outline:video_label",
  "material-outline:video_library",
  "material-outline:video_settings",
  "material-outline:video_stable",
  "material-outline:videocam",
  "material-outline:videocam_off",
  "material-outline:videogame_asset",
  "material-outline:videogame_asset_off",
  "material-outline:view_agenda",
  "material-outline:view_array",
  "material-outline:view_carousel",
  "material-outline:view_column",
  "material-outline:view_comfy",
  "material-outline:view_compact",
  "material-outline:view_day",
  "material-outline:view_headline",
  "material-outline:view_in_ar",
  "material-outline:view_list",
  "material-outline:view_module",
  "material-outline:view_quilt",
  "material-outline:view_sidebar",
  "material-outline:view_stream",
  "material-outline:view_week",
  "material-outline:vignette",
  "material-outline:villa",
  "material-outline:visibility",
  "material-outline:visibility_off",
  "material-outline:voice_chat",
  "material-outline:voice_over_off",
  "material-outline:voicemail",
  "material-outline:volume_down",
  "material-outline:volume_mute",
  "material-outline:volume_off",
  "material-outline:volume_up",
  "material-outline:volunteer_activism",
  "material-outline:vpn_key",
  "material-outline:vpn_lock",
  "material-outline:vrpano",
  "material-outline:wallpaper",
  "material-outline:warning",
  "material-outline:warning_amber",
  "material-outline:wash",
  "material-outline:watch",
  "material-outline:watch_later",
  "material-outline:water",
  "material-outline:water_damage",
  "material-outline:water_drop",
  "material-outline:waterfall_chart",
  "material-outline:waves",
  "material-outline:waving_hand",
  "material-outline:wb_auto",
  "material-outline:wb_cloudy",
  "material-outline:wb_incandescent",
  "material-outline:wb_iridescent",
  "material-outline:wb_shade",
  "material-outline:wb_sunny",
  "material-outline:wb_twilight",
  "material-outline:wc",
  "material-outline:web",
  "material-outline:web_asset",
  "material-outline:web_asset_off",
  "material-outline:weekend",
  "material-outline:west",
  "material-outline:whatshot",
  "material-outline:wheelchair_pickup",
  "material-outline:where_to_vote",
  "material-outline:widgets",
  "material-outline:wifi",
  "material-outline:wifi_calling",
  "material-outline:wifi_calling_3",
  "material-outline:wifi_lock",
  "material-outline:wifi_off",
  "material-outline:wifi_protected_setup",
  "material-outline:wifi_tethering",
  "material-outline:wifi_tethering_error_rounded",
  "material-outline:wifi_tethering_off",
  "material-outline:window",
  "material-outline:wine_bar",
  "material-outline:work",
  "material-outline:work_off",
  "material-outline:work_outline",
  "material-outline:workspaces",
  "material-outline:wrap_text",
  "material-outline:wrong_location",
  "material-outline:wysiwyg",
  "material-outline:yard",
  "material-outline:youtube_searched_for",
  "material-outline:zoom_in",
  "material-outline:zoom_out",
  "material-outline:zoom_out_map",
  "heroicons-solid:academic-cap",
  "heroicons-solid:annotation",
  "heroicons-solid:adjustments",
  "heroicons-solid:archive",
  "heroicons-solid:arrow-circle-down",
  "heroicons-solid:arrow-circle-left",
  "heroicons-solid:arrow-circle-right",
  "heroicons-solid:arrow-circle-up",
  "heroicons-solid:arrow-down",
  "heroicons-solid:arrow-left",
  "heroicons-solid:arrow-narrow-down",
  "heroicons-solid:arrow-narrow-left",
  "heroicons-solid:arrow-narrow-right",
  "heroicons-solid:arrow-narrow-up",
  "heroicons-solid:arrow-right",
  "heroicons-solid:arrow-sm-left",
  "heroicons-solid:arrow-sm-down",
  "heroicons-solid:arrow-sm-right",
  "heroicons-solid:arrow-sm-up",
  "heroicons-solid:arrow-up",
  "heroicons-solid:arrows-expand",
  "heroicons-solid:at-symbol",
  "heroicons-solid:badge-check",
  "heroicons-solid:backspace",
  "heroicons-solid:ban",
  "heroicons-solid:beaker",
  "heroicons-solid:bell",
  "heroicons-solid:book-open",
  "heroicons-solid:bookmark-alt",
  "heroicons-solid:bookmark",
  "heroicons-solid:briefcase",
  "heroicons-solid:cake",
  "heroicons-solid:calculator",
  "heroicons-solid:calendar",
  "heroicons-solid:camera",
  "heroicons-solid:cash",
  "heroicons-solid:chart-bar",
  "heroicons-solid:chart-pie",
  "heroicons-solid:chart-square-bar",
  "heroicons-solid:chat-alt-2",
  "heroicons-solid:chat-alt",
  "heroicons-solid:chat",
  "heroicons-solid:check-circle",
  "heroicons-solid:check",
  "heroicons-solid:chevron-double-down",
  "heroicons-solid:chevron-double-left",
  "heroicons-solid:chevron-double-up",
  "heroicons-solid:chevron-double-right",
  "heroicons-solid:chevron-down",
  "heroicons-solid:chevron-left",
  "heroicons-solid:chevron-right",
  "heroicons-solid:chevron-up",
  "heroicons-solid:chip",
  "heroicons-solid:clipboard-check",
  "heroicons-solid:clipboard-copy",
  "heroicons-solid:clipboard-list",
  "heroicons-solid:clipboard",
  "heroicons-solid:clock",
  "heroicons-solid:cloud-download",
  "heroicons-solid:cloud-upload",
  "heroicons-solid:cloud",
  "heroicons-solid:code",
  "heroicons-solid:cog",
  "heroicons-solid:collection",
  "heroicons-solid:credit-card",
  "heroicons-solid:color-swatch",
  "heroicons-solid:cube-transparent",
  "heroicons-solid:cube",
  "heroicons-solid:currency-bangladeshi",
  "heroicons-solid:currency-dollar",
  "heroicons-solid:currency-euro",
  "heroicons-solid:currency-pound",
  "heroicons-solid:currency-rupee",
  "heroicons-solid:currency-yen",
  "heroicons-solid:cursor-click",
  "heroicons-solid:database",
  "heroicons-solid:desktop-computer",
  "heroicons-solid:device-mobile",
  "heroicons-solid:device-tablet",
  "heroicons-solid:document-add",
  "heroicons-solid:document-download",
  "heroicons-solid:document-duplicate",
  "heroicons-solid:document-remove",
  "heroicons-solid:document-report",
  "heroicons-solid:document-search",
  "heroicons-solid:document-text",
  "heroicons-solid:document",
  "heroicons-solid:dots-circle-horizontal",
  "heroicons-solid:dots-horizontal",
  "heroicons-solid:dots-vertical",
  "heroicons-solid:download",
  "heroicons-solid:duplicate",
  "heroicons-solid:emoji-happy",
  "heroicons-solid:emoji-sad",
  "heroicons-solid:exclamation-circle",
  "heroicons-solid:exclamation",
  "heroicons-solid:external-link",
  "heroicons-solid:eye-off",
  "heroicons-solid:eye",
  "heroicons-solid:fast-forward",
  "heroicons-solid:film",
  "heroicons-solid:filter",
  "heroicons-solid:finger-print",
  "heroicons-solid:fire",
  "heroicons-solid:flag",
  "heroicons-solid:folder-add",
  "heroicons-solid:folder-download",
  "heroicons-solid:folder-open",
  "heroicons-solid:folder-remove",
  "heroicons-solid:folder",
  "heroicons-solid:gift",
  "heroicons-solid:globe-alt",
  "heroicons-solid:globe",
  "heroicons-solid:hand",
  "heroicons-solid:hashtag",
  "heroicons-solid:heart",
  "heroicons-solid:home",
  "heroicons-solid:identification",
  "heroicons-solid:inbox-in",
  "heroicons-solid:inbox",
  "heroicons-solid:information-circle",
  "heroicons-solid:key",
  "heroicons-solid:library",
  "heroicons-solid:light-bulb",
  "heroicons-solid:lightning-bolt",
  "heroicons-solid:link",
  "heroicons-solid:location-marker",
  "heroicons-solid:lock-closed",
  "heroicons-solid:lock-open",
  "heroicons-solid:login",
  "heroicons-solid:logout",
  "heroicons-solid:mail-open",
  "heroicons-solid:mail",
  "heroicons-solid:map",
  "heroicons-solid:menu-alt-1",
  "heroicons-solid:menu-alt-2",
  "heroicons-solid:menu-alt-3",
  "heroicons-solid:menu-alt-4",
  "heroicons-solid:menu",
  "heroicons-solid:microphone",
  "heroicons-solid:minus-circle",
  "heroicons-solid:minus-sm",
  "heroicons-solid:minus",
  "heroicons-solid:moon",
  "heroicons-solid:music-note",
  "heroicons-solid:newspaper",
  "heroicons-solid:office-building",
  "heroicons-solid:paper-airplane",
  "heroicons-solid:paper-clip",
  "heroicons-solid:pause",
  "heroicons-solid:pencil-alt",
  "heroicons-solid:pencil",
  "heroicons-solid:phone-incoming",
  "heroicons-solid:phone-missed-call",
  "heroicons-solid:phone-outgoing",
  "heroicons-solid:photograph",
  "heroicons-solid:phone",
  "heroicons-solid:play",
  "heroicons-solid:plus-circle",
  "heroicons-solid:plus-sm",
  "heroicons-solid:plus",
  "heroicons-solid:presentation-chart-bar",
  "heroicons-solid:presentation-chart-line",
  "heroicons-solid:printer",
  "heroicons-solid:qrcode",
  "heroicons-solid:puzzle",
  "heroicons-solid:question-mark-circle",
  "heroicons-solid:receipt-refund",
  "heroicons-solid:receipt-tax",
  "heroicons-solid:refresh",
  "heroicons-solid:reply",
  "heroicons-solid:rewind",
  "heroicons-solid:rss",
  "heroicons-solid:save-as",
  "heroicons-solid:save",
  "heroicons-solid:scale",
  "heroicons-solid:scissors",
  "heroicons-solid:search-circle",
  "heroicons-solid:search",
  "heroicons-solid:selector",
  "heroicons-solid:server",
  "heroicons-solid:share",
  "heroicons-solid:shield-check",
  "heroicons-solid:shield-exclamation",
  "heroicons-solid:shopping-bag",
  "heroicons-solid:shopping-cart",
  "heroicons-solid:sort-ascending",
  "heroicons-solid:sort-descending",
  "heroicons-solid:speakerphone",
  "heroicons-solid:sparkles",
  "heroicons-solid:star",
  "heroicons-solid:status-online",
  "heroicons-solid:status-offline",
  "heroicons-solid:stop",
  "heroicons-solid:sun",
  "heroicons-solid:support",
  "heroicons-solid:switch-vertical",
  "heroicons-solid:switch-horizontal",
  "heroicons-solid:table",
  "heroicons-solid:tag",
  "heroicons-solid:template",
  "heroicons-solid:terminal",
  "heroicons-solid:thumb-up",
  "heroicons-solid:thumb-down",
  "heroicons-solid:ticket",
  "heroicons-solid:translate",
  "heroicons-solid:trash",
  "heroicons-solid:trending-down",
  "heroicons-solid:trending-up",
  "heroicons-solid:truck",
  "heroicons-solid:upload",
  "heroicons-solid:user-add",
  "heroicons-solid:user-group",
  "heroicons-solid:user-circle",
  "heroicons-solid:user-remove",
  "heroicons-solid:user",
  "heroicons-solid:users",
  "heroicons-solid:variable",
  "heroicons-solid:video-camera",
  "heroicons-solid:view-boards",
  "heroicons-solid:view-grid-add",
  "heroicons-solid:view-grid",
  "heroicons-solid:view-list",
  "heroicons-solid:volume-up",
  "heroicons-solid:volume-off",
  "heroicons-solid:x-circle",
  "heroicons-solid:zoom-in",
  "heroicons-solid:wifi",
  "heroicons-solid:zoom-out",
  "heroicons-solid:x",
  "material-solid:10k",
  "material-solid:10mp",
  "material-solid:11mp",
  "material-solid:12mp",
  "material-solid:13mp",
  "material-solid:14mp",
  "material-solid:15mp",
  "material-solid:16mp",
  "material-solid:17mp",
  "material-solid:18mp",
  "material-solid:19mp",
  "material-solid:1k",
  "material-solid:1k_plus",
  "material-solid:1x_mobiledata",
  "material-solid:20mp",
  "material-solid:21mp",
  "material-solid:22mp",
  "material-solid:23mp",
  "material-solid:24mp",
  "material-solid:2k",
  "material-solid:2k_plus",
  "material-solid:2mp",
  "material-solid:30fps",
  "material-solid:30fps_select",
  "material-solid:360",
  "material-solid:3d_rotation",
  "material-solid:3g_mobiledata",
  "material-solid:3k",
  "material-solid:3k_plus",
  "material-solid:3mp",
  "material-solid:3p",
  "material-solid:4g_mobiledata",
  "material-solid:4g_plus_mobiledata",
  "material-solid:4k",
  "material-solid:4k_plus",
  "material-solid:4mp",
  "material-solid:5g",
  "material-solid:5k",
  "material-solid:5k_plus",
  "material-solid:5mp",
  "material-solid:60fps",
  "material-solid:60fps_select",
  "material-solid:6_ft_apart",
  "material-solid:6k",
  "material-solid:6k_plus",
  "material-solid:6mp",
  "material-solid:7k",
  "material-solid:7k_plus",
  "material-solid:7mp",
  "material-solid:8k",
  "material-solid:8k_plus",
  "material-solid:8mp",
  "material-solid:9k",
  "material-solid:9k_plus",
  "material-solid:9mp",
  "material-solid:ac_unit",
  "material-solid:access_alarm",
  "material-solid:access_alarms",
  "material-solid:access_time",
  "material-solid:access_time_filled",
  "material-solid:accessibility",
  "material-solid:accessibility_new",
  "material-solid:accessible",
  "material-solid:accessible_forward",
  "material-solid:account_balance",
  "material-solid:account_balance_wallet",
  "material-solid:account_box",
  "material-solid:account_circle",
  "material-solid:account_tree",
  "material-solid:ad_units",
  "material-solid:adb",
  "material-solid:add",
  "material-solid:add_a_photo",
  "material-solid:add_alarm",
  "material-solid:add_alert",
  "material-solid:add_box",
  "material-solid:add_business",
  "material-solid:add_chart",
  "material-solid:add_circle",
  "material-solid:add_circle_outline",
  "material-solid:add_comment",
  "material-solid:add_ic_call",
  "material-solid:add_link",
  "material-solid:add_location",
  "material-solid:add_location_alt",
  "material-solid:add_moderator",
  "material-solid:add_photo_alternate",
  "material-solid:add_reaction",
  "material-solid:add_road",
  "material-solid:add_shopping_cart",
  "material-solid:add_task",
  "material-solid:add_to_drive",
  "material-solid:add_to_home_screen",
  "material-solid:add_to_photos",
  "material-solid:add_to_queue",
  "material-solid:addchart",
  "material-solid:adjust",
  "material-solid:admin_panel_settings",
  "material-solid:ads_click",
  "material-solid:agriculture",
  "material-solid:air",
  "material-solid:airline_seat_flat",
  "material-solid:airline_seat_flat_angled",
  "material-solid:airline_seat_individual_suite",
  "material-solid:airline_seat_legroom_extra",
  "material-solid:airline_seat_legroom_normal",
  "material-solid:airline_seat_legroom_reduced",
  "material-solid:airline_seat_recline_extra",
  "material-solid:airline_seat_recline_normal",
  "material-solid:airplane_ticket",
  "material-solid:airplanemode_active",
  "material-solid:airplanemode_inactive",
  "material-solid:airplay",
  "material-solid:airport_shuttle",
  "material-solid:alarm",
  "material-solid:alarm_add",
  "material-solid:alarm_off",
  "material-solid:alarm_on",
  "material-solid:album",
  "material-solid:align_horizontal_center",
  "material-solid:align_horizontal_left",
  "material-solid:align_horizontal_right",
  "material-solid:align_vertical_bottom",
  "material-solid:align_vertical_center",
  "material-solid:align_vertical_top",
  "material-solid:all_inbox",
  "material-solid:all_inclusive",
  "material-solid:all_out",
  "material-solid:alt_route",
  "material-solid:alternate_email",
  "material-solid:analytics",
  "material-solid:anchor",
  "material-solid:android",
  "material-solid:animation",
  "material-solid:announcement",
  "material-solid:aod",
  "material-solid:apartment",
  "material-solid:api",
  "material-solid:app_blocking",
  "material-solid:app_registration",
  "material-solid:app_settings_alt",
  "material-solid:approval",
  "material-solid:apps",
  "material-solid:architecture",
  "material-solid:archive",
  "material-solid:area_chart",
  "material-solid:arrow_back",
  "material-solid:arrow_back_ios",
  "material-solid:arrow_back_ios_new",
  "material-solid:arrow_circle_down",
  "material-solid:arrow_circle_up",
  "material-solid:arrow_downward",
  "material-solid:arrow_drop_down",
  "material-solid:arrow_drop_down_circle",
  "material-solid:arrow_drop_up",
  "material-solid:arrow_forward",
  "material-solid:arrow_forward_ios",
  "material-solid:arrow_left",
  "material-solid:arrow_right",
  "material-solid:arrow_right_alt",
  "material-solid:arrow_upward",
  "material-solid:art_track",
  "material-solid:article",
  "material-solid:aspect_ratio",
  "material-solid:assessment",
  "material-solid:assignment",
  "material-solid:assignment_ind",
  "material-solid:assignment_late",
  "material-solid:assignment_return",
  "material-solid:assignment_returned",
  "material-solid:assignment_turned_in",
  "material-solid:assistant",
  "material-solid:assistant_direction",
  "material-solid:assistant_photo",
  "material-solid:atm",
  "material-solid:attach_email",
  "material-solid:attach_file",
  "material-solid:attach_money",
  "material-solid:attachment",
  "material-solid:attractions",
  "material-solid:attribution",
  "material-solid:audiotrack",
  "material-solid:auto_awesome",
  "material-solid:auto_awesome_mosaic",
  "material-solid:auto_awesome_motion",
  "material-solid:auto_delete",
  "material-solid:auto_fix_high",
  "material-solid:auto_fix_normal",
  "material-solid:auto_fix_off",
  "material-solid:auto_graph",
  "material-solid:auto_stories",
  "material-solid:autofps_select",
  "material-solid:autorenew",
  "material-solid:av_timer",
  "material-solid:baby_changing_station",
  "material-solid:back_hand",
  "material-solid:backpack",
  "material-solid:backspace",
  "material-solid:backup",
  "material-solid:backup_table",
  "material-solid:badge",
  "material-solid:bakery_dining",
  "material-solid:balcony",
  "material-solid:ballot",
  "material-solid:bar_chart",
  "material-solid:batch_prediction",
  "material-solid:bathroom",
  "material-solid:bathtub",
  "material-solid:battery_alert",
  "material-solid:battery_charging_full",
  "material-solid:battery_full",
  "material-solid:battery_saver",
  "material-solid:battery_std",
  "material-solid:battery_unknown",
  "material-solid:beach_access",
  "material-solid:bed",
  "material-solid:bedroom_baby",
  "material-solid:bedroom_child",
  "material-solid:bedroom_parent",
  "material-solid:bedtime",
  "material-solid:beenhere",
  "material-solid:bento",
  "material-solid:bike_scooter",
  "material-solid:biotech",
  "material-solid:blender",
  "material-solid:block",
  "material-solid:bloodtype",
  "material-solid:bluetooth",
  "material-solid:bluetooth_audio",
  "material-solid:bluetooth_connected",
  "material-solid:bluetooth_disabled",
  "material-solid:bluetooth_drive",
  "material-solid:bluetooth_searching",
  "material-solid:blur_circular",
  "material-solid:blur_linear",
  "material-solid:blur_off",
  "material-solid:blur_on",
  "material-solid:bolt",
  "material-solid:book",
  "material-solid:book_online",
  "material-solid:bookmark",
  "material-solid:bookmark_add",
  "material-solid:bookmark_added",
  "material-solid:bookmark_border",
  "material-solid:bookmark_remove",
  "material-solid:bookmarks",
  "material-solid:border_all",
  "material-solid:border_bottom",
  "material-solid:border_clear",
  "material-solid:border_color",
  "material-solid:border_horizontal",
  "material-solid:border_inner",
  "material-solid:border_left",
  "material-solid:border_outer",
  "material-solid:border_right",
  "material-solid:border_style",
  "material-solid:border_top",
  "material-solid:border_vertical",
  "material-solid:branding_watermark",
  "material-solid:breakfast_dining",
  "material-solid:brightness_1",
  "material-solid:brightness_2",
  "material-solid:brightness_3",
  "material-solid:brightness_4",
  "material-solid:brightness_5",
  "material-solid:brightness_6",
  "material-solid:brightness_7",
  "material-solid:brightness_auto",
  "material-solid:brightness_high",
  "material-solid:brightness_low",
  "material-solid:brightness_medium",
  "material-solid:broken_image",
  "material-solid:browser_not_supported",
  "material-solid:brunch_dining",
  "material-solid:brush",
  "material-solid:bubble_chart",
  "material-solid:bug_report",
  "material-solid:build",
  "material-solid:build_circle",
  "material-solid:bungalow",
  "material-solid:burst_mode",
  "material-solid:bus_alert",
  "material-solid:business",
  "material-solid:business_center",
  "material-solid:cabin",
  "material-solid:cable",
  "material-solid:cached",
  "material-solid:cake",
  "material-solid:calculate",
  "material-solid:calendar_today",
  "material-solid:calendar_view_day",
  "material-solid:calendar_view_month",
  "material-solid:calendar_view_week",
  "material-solid:call",
  "material-solid:call_end",
  "material-solid:call_made",
  "material-solid:call_merge",
  "material-solid:call_missed",
  "material-solid:call_missed_outgoing",
  "material-solid:call_received",
  "material-solid:call_split",
  "material-solid:call_to_action",
  "material-solid:camera",
  "material-solid:camera_alt",
  "material-solid:camera_enhance",
  "material-solid:camera_front",
  "material-solid:camera_indoor",
  "material-solid:camera_outdoor",
  "material-solid:camera_rear",
  "material-solid:camera_roll",
  "material-solid:cameraswitch",
  "material-solid:campaign",
  "material-solid:cancel",
  "material-solid:cancel_presentation",
  "material-solid:cancel_schedule_send",
  "material-solid:car_rental",
  "material-solid:car_repair",
  "material-solid:card_giftcard",
  "material-solid:card_membership",
  "material-solid:card_travel",
  "material-solid:carpenter",
  "material-solid:cases",
  "material-solid:casino",
  "material-solid:cast",
  "material-solid:cast_connected",
  "material-solid:cast_for_education",
  "material-solid:catching_pokemon",
  "material-solid:category",
  "material-solid:celebration",
  "material-solid:cell_wifi",
  "material-solid:center_focus_strong",
  "material-solid:center_focus_weak",
  "material-solid:chair",
  "material-solid:chair_alt",
  "material-solid:chalet",
  "material-solid:change_circle",
  "material-solid:change_history",
  "material-solid:charging_station",
  "material-solid:chat",
  "material-solid:chat_bubble",
  "material-solid:chat_bubble_outline",
  "material-solid:check",
  "material-solid:check_box",
  "material-solid:check_box_outline_blank",
  "material-solid:check_circle",
  "material-solid:check_circle_outline",
  "material-solid:checklist",
  "material-solid:checklist_rtl",
  "material-solid:checkroom",
  "material-solid:chevron_left",
  "material-solid:chevron_right",
  "material-solid:child_care",
  "material-solid:child_friendly",
  "material-solid:chrome_reader_mode",
  "material-solid:circle",
  "material-solid:circle_notifications",
  "material-solid:class",
  "material-solid:clean_hands",
  "material-solid:cleaning_services",
  "material-solid:clear",
  "material-solid:clear_all",
  "material-solid:close",
  "material-solid:close_fullscreen",
  "material-solid:closed_caption",
  "material-solid:closed_caption_disabled",
  "material-solid:closed_caption_off",
  "material-solid:cloud",
  "material-solid:cloud_circle",
  "material-solid:cloud_done",
  "material-solid:cloud_download",
  "material-solid:cloud_off",
  "material-solid:cloud_queue",
  "material-solid:cloud_upload",
  "material-solid:code",
  "material-solid:code_off",
  "material-solid:coffee",
  "material-solid:coffee_maker",
  "material-solid:collections",
  "material-solid:collections_bookmark",
  "material-solid:color_lens",
  "material-solid:colorize",
  "material-solid:comment",
  "material-solid:comment_bank",
  "material-solid:commute",
  "material-solid:compare",
  "material-solid:compare_arrows",
  "material-solid:compass_calibration",
  "material-solid:compost",
  "material-solid:compress",
  "material-solid:computer",
  "material-solid:confirmation_number",
  "material-solid:connect_without_contact",
  "material-solid:connected_tv",
  "material-solid:construction",
  "material-solid:contact_mail",
  "material-solid:contact_page",
  "material-solid:contact_phone",
  "material-solid:contact_support",
  "material-solid:contactless",
  "material-solid:contacts",
  "material-solid:content_copy",
  "material-solid:content_cut",
  "material-solid:content_paste",
  "material-solid:content_paste_off",
  "material-solid:control_camera",
  "material-solid:control_point",
  "material-solid:control_point_duplicate",
  "material-solid:copy_all",
  "material-solid:copyright",
  "material-solid:coronavirus",
  "material-solid:corporate_fare",
  "material-solid:cottage",
  "material-solid:countertops",
  "material-solid:create",
  "material-solid:create_new_folder",
  "material-solid:credit_card",
  "material-solid:credit_card_off",
  "material-solid:credit_score",
  "material-solid:crib",
  "material-solid:crop",
  "material-solid:crop_16_9",
  "material-solid:crop_3_2",
  "material-solid:crop_5_4",
  "material-solid:crop_7_5",
  "material-solid:crop_din",
  "material-solid:crop_free",
  "material-solid:crop_landscape",
  "material-solid:crop_original",
  "material-solid:crop_portrait",
  "material-solid:crop_rotate",
  "material-solid:crop_square",
  "material-solid:cruelty_free",
  "material-solid:dangerous",
  "material-solid:dark_mode",
  "material-solid:dashboard",
  "material-solid:dashboard_customize",
  "material-solid:data_exploration",
  "material-solid:data_saver_off",
  "material-solid:data_saver_on",
  "material-solid:data_usage",
  "material-solid:date_range",
  "material-solid:deck",
  "material-solid:dehaze",
  "material-solid:delete",
  "material-solid:delete_forever",
  "material-solid:delete_outline",
  "material-solid:delete_sweep",
  "material-solid:delivery_dining",
  "material-solid:departure_board",
  "material-solid:description",
  "material-solid:design_services",
  "material-solid:desktop_access_disabled",
  "material-solid:desktop_mac",
  "material-solid:desktop_windows",
  "material-solid:details",
  "material-solid:developer_board",
  "material-solid:developer_board_off",
  "material-solid:developer_mode",
  "material-solid:device_hub",
  "material-solid:device_thermostat",
  "material-solid:device_unknown",
  "material-solid:devices",
  "material-solid:devices_other",
  "material-solid:dialer_sip",
  "material-solid:dialpad",
  "material-solid:dining",
  "material-solid:dinner_dining",
  "material-solid:directions",
  "material-solid:directions_bike",
  "material-solid:directions_boat",
  "material-solid:directions_boat_filled",
  "material-solid:directions_bus",
  "material-solid:directions_bus_filled",
  "material-solid:directions_car",
  "material-solid:directions_car_filled",
  "material-solid:directions_off",
  "material-solid:directions_railway",
  "material-solid:directions_railway_filled",
  "material-solid:directions_run",
  "material-solid:directions_subway",
  "material-solid:directions_subway_filled",
  "material-solid:directions_transit",
  "material-solid:directions_transit_filled",
  "material-solid:directions_walk",
  "material-solid:dirty_lens",
  "material-solid:disabled_by_default",
  "material-solid:disabled_visible",
  "material-solid:disc_full",
  "material-solid:dns",
  "material-solid:do_disturb",
  "material-solid:do_disturb_alt",
  "material-solid:do_disturb_off",
  "material-solid:do_disturb_on",
  "material-solid:do_not_disturb",
  "material-solid:do_not_disturb_alt",
  "material-solid:do_not_disturb_off",
  "material-solid:do_not_disturb_on",
  "material-solid:do_not_disturb_on_total_silence",
  "material-solid:do_not_step",
  "material-solid:do_not_touch",
  "material-solid:dock",
  "material-solid:document_scanner",
  "material-solid:domain",
  "material-solid:domain_disabled",
  "material-solid:domain_verification",
  "material-solid:done",
  "material-solid:done_all",
  "material-solid:done_outline",
  "material-solid:donut_large",
  "material-solid:donut_small",
  "material-solid:door_back",
  "material-solid:door_front",
  "material-solid:door_sliding",
  "material-solid:doorbell",
  "material-solid:double_arrow",
  "material-solid:downhill_skiing",
  "material-solid:download",
  "material-solid:download_done",
  "material-solid:download_for_offline",
  "material-solid:downloading",
  "material-solid:drafts",
  "material-solid:drag_handle",
  "material-solid:drag_indicator",
  "material-solid:draw",
  "material-solid:drive_eta",
  "material-solid:drive_file_move",
  "material-solid:drive_file_move_rtl",
  "material-solid:drive_file_rename_outline",
  "material-solid:drive_folder_upload",
  "material-solid:dry",
  "material-solid:dry_cleaning",
  "material-solid:duo",
  "material-solid:dvr",
  "material-solid:dynamic_feed",
  "material-solid:dynamic_form",
  "material-solid:e_mobiledata",
  "material-solid:earbuds",
  "material-solid:earbuds_battery",
  "material-solid:east",
  "material-solid:edgesensor_high",
  "material-solid:edgesensor_low",
  "material-solid:edit",
  "material-solid:edit_attributes",
  "material-solid:edit_calendar",
  "material-solid:edit_location",
  "material-solid:edit_location_alt",
  "material-solid:edit_note",
  "material-solid:edit_notifications",
  "material-solid:edit_off",
  "material-solid:edit_road",
  "material-solid:eject",
  "material-solid:elderly",
  "material-solid:electric_bike",
  "material-solid:electric_car",
  "material-solid:electric_moped",
  "material-solid:electric_rickshaw",
  "material-solid:electric_scooter",
  "material-solid:electrical_services",
  "material-solid:elevator",
  "material-solid:email",
  "material-solid:emergency",
  "material-solid:emoji_emotions",
  "material-solid:emoji_events",
  "material-solid:emoji_flags",
  "material-solid:emoji_food_beverage",
  "material-solid:emoji_nature",
  "material-solid:emoji_objects",
  "material-solid:emoji_people",
  "material-solid:emoji_symbols",
  "material-solid:emoji_transportation",
  "material-solid:engineering",
  "material-solid:enhanced_encryption",
  "material-solid:equalizer",
  "material-solid:error",
  "material-solid:error_outline",
  "material-solid:escalator",
  "material-solid:escalator_warning",
  "material-solid:euro",
  "material-solid:euro_symbol",
  "material-solid:ev_station",
  "material-solid:event",
  "material-solid:event_available",
  "material-solid:event_busy",
  "material-solid:event_note",
  "material-solid:event_seat",
  "material-solid:exit_to_app",
  "material-solid:expand",
  "material-solid:expand_less",
  "material-solid:expand_more",
  "material-solid:explicit",
  "material-solid:explore",
  "material-solid:explore_off",
  "material-solid:exposure",
  "material-solid:exposure_neg_1",
  "material-solid:exposure_neg_2",
  "material-solid:exposure_plus_1",
  "material-solid:exposure_plus_2",
  "material-solid:exposure_zero",
  "material-solid:extension",
  "material-solid:extension_off",
  "material-solid:face",
  "material-solid:face_retouching_natural",
  "material-solid:face_retouching_off",
  "material-solid:facebook",
  "material-solid:fact_check",
  "material-solid:family_restroom",
  "material-solid:fast_forward",
  "material-solid:fast_rewind",
  "material-solid:fastfood",
  "material-solid:favorite",
  "material-solid:favorite_border",
  "material-solid:featured_play_list",
  "material-solid:featured_video",
  "material-solid:feed",
  "material-solid:feedback",
  "material-solid:female",
  "material-solid:fence",
  "material-solid:festival",
  "material-solid:fiber_dvr",
  "material-solid:fiber_manual_record",
  "material-solid:fiber_new",
  "material-solid:fiber_pin",
  "material-solid:fiber_smart_record",
  "material-solid:file_copy",
  "material-solid:file_download",
  "material-solid:file_download_done",
  "material-solid:file_download_off",
  "material-solid:file_present",
  "material-solid:file_upload",
  "material-solid:filter",
  "material-solid:filter_1",
  "material-solid:filter_2",
  "material-solid:filter_3",
  "material-solid:filter_4",
  "material-solid:filter_5",
  "material-solid:filter_6",
  "material-solid:filter_7",
  "material-solid:filter_8",
  "material-solid:filter_9",
  "material-solid:filter_9_plus",
  "material-solid:filter_alt",
  "material-solid:filter_b_and_w",
  "material-solid:filter_center_focus",
  "material-solid:filter_drama",
  "material-solid:filter_frames",
  "material-solid:filter_hdr",
  "material-solid:filter_list",
  "material-solid:filter_none",
  "material-solid:filter_tilt_shift",
  "material-solid:filter_vintage",
  "material-solid:find_in_page",
  "material-solid:find_replace",
  "material-solid:fingerprint",
  "material-solid:fire_extinguisher",
  "material-solid:fireplace",
  "material-solid:first_page",
  "material-solid:fit_screen",
  "material-solid:fitness_center",
  "material-solid:flag",
  "material-solid:flaky",
  "material-solid:flare",
  "material-solid:flash_auto",
  "material-solid:flash_off",
  "material-solid:flash_on",
  "material-solid:flashlight_off",
  "material-solid:flashlight_on",
  "material-solid:flatware",
  "material-solid:flight",
  "material-solid:flight_land",
  "material-solid:flight_takeoff",
  "material-solid:flip",
  "material-solid:flip_camera_android",
  "material-solid:flip_camera_ios",
  "material-solid:flip_to_back",
  "material-solid:flip_to_front",
  "material-solid:flourescent",
  "material-solid:flutter_dash",
  "material-solid:fmd_bad",
  "material-solid:fmd_good",
  "material-solid:folder",
  "material-solid:folder_open",
  "material-solid:folder_shared",
  "material-solid:folder_special",
  "material-solid:follow_the_signs",
  "material-solid:font_download",
  "material-solid:font_download_off",
  "material-solid:food_bank",
  "material-solid:format_align_center",
  "material-solid:format_align_justify",
  "material-solid:format_align_left",
  "material-solid:format_align_right",
  "material-solid:format_bold",
  "material-solid:format_clear",
  "material-solid:format_color_fill",
  "material-solid:format_color_reset",
  "material-solid:format_color_text",
  "material-solid:format_indent_decrease",
  "material-solid:format_indent_increase",
  "material-solid:format_italic",
  "material-solid:format_line_spacing",
  "material-solid:format_list_bulleted",
  "material-solid:format_list_numbered",
  "material-solid:format_list_numbered_rtl",
  "material-solid:format_paint",
  "material-solid:format_quote",
  "material-solid:format_shapes",
  "material-solid:format_size",
  "material-solid:format_strikethrough",
  "material-solid:format_textdirection_l_to_r",
  "material-solid:format_textdirection_r_to_l",
  "material-solid:format_underlined",
  "material-solid:forum",
  "material-solid:forward",
  "material-solid:forward_10",
  "material-solid:forward_30",
  "material-solid:forward_5",
  "material-solid:forward_to_inbox",
  "material-solid:foundation",
  "material-solid:free_breakfast",
  "material-solid:free_cancellation",
  "material-solid:front_hand",
  "material-solid:fullscreen",
  "material-solid:fullscreen_exit",
  "material-solid:functions",
  "material-solid:g_mobiledata",
  "material-solid:g_translate",
  "material-solid:gamepad",
  "material-solid:games",
  "material-solid:garage",
  "material-solid:gavel",
  "material-solid:generating_tokens",
  "material-solid:gesture",
  "material-solid:get_app",
  "material-solid:gif",
  "material-solid:gite",
  "material-solid:golf_course",
  "material-solid:gpp_bad",
  "material-solid:gpp_good",
  "material-solid:gpp_maybe",
  "material-solid:gps_fixed",
  "material-solid:gps_not_fixed",
  "material-solid:gps_off",
  "material-solid:grade",
  "material-solid:gradient",
  "material-solid:grading",
  "material-solid:grain",
  "material-solid:graphic_eq",
  "material-solid:grass",
  "material-solid:grid_3x3",
  "material-solid:grid_4x4",
  "material-solid:grid_goldenratio",
  "material-solid:grid_off",
  "material-solid:grid_on",
  "material-solid:grid_view",
  "material-solid:group",
  "material-solid:group_add",
  "material-solid:group_off",
  "material-solid:group_work",
  "material-solid:groups",
  "material-solid:h_mobiledata",
  "material-solid:h_plus_mobiledata",
  "material-solid:hail",
  "material-solid:handyman",
  "material-solid:hardware",
  "material-solid:hd",
  "material-solid:hdr_auto",
  "material-solid:hdr_auto_select",
  "material-solid:hdr_enhanced_select",
  "material-solid:hdr_off",
  "material-solid:hdr_off_select",
  "material-solid:hdr_on",
  "material-solid:hdr_on_select",
  "material-solid:hdr_plus",
  "material-solid:hdr_strong",
  "material-solid:hdr_weak",
  "material-solid:headphones",
  "material-solid:headphones_battery",
  "material-solid:headset",
  "material-solid:headset_mic",
  "material-solid:headset_off",
  "material-solid:healing",
  "material-solid:health_and_safety",
  "material-solid:hearing",
  "material-solid:hearing_disabled",
  "material-solid:height",
  "material-solid:help",
  "material-solid:help_center",
  "material-solid:help_outline",
  "material-solid:hevc",
  "material-solid:hide_image",
  "material-solid:hide_source",
  "material-solid:high_quality",
  "material-solid:highlight",
  "material-solid:highlight_alt",
  "material-solid:highlight_off",
  "material-solid:hiking",
  "material-solid:history",
  "material-solid:history_edu",
  "material-solid:history_toggle_off",
  "material-solid:holiday_village",
  "material-solid:home",
  "material-solid:home_max",
  "material-solid:home_mini",
  "material-solid:home_repair_service",
  "material-solid:home_work",
  "material-solid:horizontal_distribute",
  "material-solid:horizontal_rule",
  "material-solid:horizontal_split",
  "material-solid:hot_tub",
  "material-solid:hotel",
  "material-solid:hotel_class",
  "material-solid:hourglass_bottom",
  "material-solid:hourglass_disabled",
  "material-solid:hourglass_empty",
  "material-solid:hourglass_full",
  "material-solid:hourglass_top",
  "material-solid:house",
  "material-solid:house_siding",
  "material-solid:houseboat",
  "material-solid:how_to_reg",
  "material-solid:how_to_vote",
  "material-solid:http",
  "material-solid:https",
  "material-solid:hvac",
  "material-solid:ice_skating",
  "material-solid:icecream",
  "material-solid:image",
  "material-solid:image_aspect_ratio",
  "material-solid:image_not_supported",
  "material-solid:image_search",
  "material-solid:imagesearch_roller",
  "material-solid:import_contacts",
  "material-solid:import_export",
  "material-solid:important_devices",
  "material-solid:inbox",
  "material-solid:incomplete_circle",
  "material-solid:indeterminate_check_box",
  "material-solid:info",
  "material-solid:input",
  "material-solid:insert_chart",
  "material-solid:insert_chart_outline",
  "material-solid:insert_comment",
  "material-solid:insert_drive_file",
  "material-solid:insert_emoticon",
  "material-solid:insert_invitation",
  "material-solid:insert_link",
  "material-solid:insert_photo",
  "material-solid:insights",
  "material-solid:integration_instructions",
  "material-solid:inventory",
  "material-solid:inventory_2",
  "material-solid:invert_colors",
  "material-solid:invert_colors_off",
  "material-solid:ios_share",
  "material-solid:iron",
  "material-solid:iso",
  "material-solid:kayaking",
  "material-solid:keyboard",
  "material-solid:keyboard_alt",
  "material-solid:keyboard_arrow_down",
  "material-solid:keyboard_arrow_left",
  "material-solid:keyboard_arrow_right",
  "material-solid:keyboard_arrow_up",
  "material-solid:keyboard_backspace",
  "material-solid:keyboard_capslock",
  "material-solid:keyboard_hide",
  "material-solid:keyboard_return",
  "material-solid:keyboard_tab",
  "material-solid:keyboard_voice",
  "material-solid:king_bed",
  "material-solid:kitchen",
  "material-solid:kitesurfing",
  "material-solid:label",
  "material-solid:label_important",
  "material-solid:label_off",
  "material-solid:landscape",
  "material-solid:language",
  "material-solid:laptop",
  "material-solid:laptop_chromebook",
  "material-solid:laptop_mac",
  "material-solid:laptop_windows",
  "material-solid:last_page",
  "material-solid:launch",
  "material-solid:layers",
  "material-solid:layers_clear",
  "material-solid:leaderboard",
  "material-solid:leak_add",
  "material-solid:leak_remove",
  "material-solid:legend_toggle",
  "material-solid:lens",
  "material-solid:lens_blur",
  "material-solid:library_add",
  "material-solid:library_add_check",
  "material-solid:library_books",
  "material-solid:library_music",
  "material-solid:light",
  "material-solid:light_mode",
  "material-solid:lightbulb",
  "material-solid:line_style",
  "material-solid:line_weight",
  "material-solid:linear_scale",
  "material-solid:link",
  "material-solid:link_off",
  "material-solid:linked_camera",
  "material-solid:liquor",
  "material-solid:list",
  "material-solid:list_alt",
  "material-solid:live_help",
  "material-solid:live_tv",
  "material-solid:living",
  "material-solid:local_activity",
  "material-solid:local_airport",
  "material-solid:local_atm",
  "material-solid:local_bar",
  "material-solid:local_cafe",
  "material-solid:local_car_wash",
  "material-solid:local_convenience_store",
  "material-solid:local_dining",
  "material-solid:local_drink",
  "material-solid:local_fire_department",
  "material-solid:local_florist",
  "material-solid:local_gas_station",
  "material-solid:local_grocery_store",
  "material-solid:local_hospital",
  "material-solid:local_hotel",
  "material-solid:local_laundry_service",
  "material-solid:local_library",
  "material-solid:local_mall",
  "material-solid:local_movies",
  "material-solid:local_offer",
  "material-solid:local_parking",
  "material-solid:local_pharmacy",
  "material-solid:local_phone",
  "material-solid:local_pizza",
  "material-solid:local_play",
  "material-solid:local_police",
  "material-solid:local_post_office",
  "material-solid:local_printshop",
  "material-solid:local_see",
  "material-solid:local_shipping",
  "material-solid:local_taxi",
  "material-solid:location_city",
  "material-solid:location_disabled",
  "material-solid:location_off",
  "material-solid:location_on",
  "material-solid:location_searching",
  "material-solid:lock",
  "material-solid:lock_clock",
  "material-solid:lock_open",
  "material-solid:login",
  "material-solid:logout",
  "material-solid:looks",
  "material-solid:looks_3",
  "material-solid:looks_4",
  "material-solid:looks_5",
  "material-solid:looks_6",
  "material-solid:looks_one",
  "material-solid:looks_two",
  "material-solid:loop",
  "material-solid:loupe",
  "material-solid:low_priority",
  "material-solid:loyalty",
  "material-solid:lte_mobiledata",
  "material-solid:lte_plus_mobiledata",
  "material-solid:luggage",
  "material-solid:lunch_dining",
  "material-solid:mail",
  "material-solid:mail_outline",
  "material-solid:male",
  "material-solid:manage_accounts",
  "material-solid:manage_search",
  "material-solid:map",
  "material-solid:maps_home_work",
  "material-solid:maps_ugc",
  "material-solid:margin",
  "material-solid:mark_as_unread",
  "material-solid:mark_chat_read",
  "material-solid:mark_chat_unread",
  "material-solid:mark_email_read",
  "material-solid:mark_email_unread",
  "material-solid:markunread",
  "material-solid:markunread_mailbox",
  "material-solid:masks",
  "material-solid:maximize",
  "material-solid:media_bluetooth_off",
  "material-solid:media_bluetooth_on",
  "material-solid:mediation",
  "material-solid:medical_services",
  "material-solid:medication",
  "material-solid:meeting_room",
  "material-solid:memory",
  "material-solid:menu",
  "material-solid:menu_book",
  "material-solid:menu_open",
  "material-solid:merge_type",
  "material-solid:message",
  "material-solid:mic",
  "material-solid:mic_external_off",
  "material-solid:mic_external_on",
  "material-solid:mic_none",
  "material-solid:mic_off",
  "material-solid:microwave",
  "material-solid:military_tech",
  "material-solid:minimize",
  "material-solid:miscellaneous_services",
  "material-solid:missed_video_call",
  "material-solid:mms",
  "material-solid:mobile_friendly",
  "material-solid:mobile_off",
  "material-solid:mobile_screen_share",
  "material-solid:mobiledata_off",
  "material-solid:mode",
  "material-solid:mode_comment",
  "material-solid:mode_edit",
  "material-solid:mode_edit_outline",
  "material-solid:mode_night",
  "material-solid:mode_standby",
  "material-solid:model_training",
  "material-solid:monetization_on",
  "material-solid:money",
  "material-solid:money_off",
  "material-solid:money_off_csred",
  "material-solid:monitor",
  "material-solid:monitor_weight",
  "material-solid:monochrome_photos",
  "material-solid:mood",
  "material-solid:mood_bad",
  "material-solid:moped",
  "material-solid:more",
  "material-solid:more_horiz",
  "material-solid:more_time",
  "material-solid:more_vert",
  "material-solid:motion_photos_auto",
  "material-solid:motion_photos_off",
  "material-solid:motion_photos_on",
  "material-solid:motion_photos_pause",
  "material-solid:motion_photos_paused",
  "material-solid:mouse",
  "material-solid:move_to_inbox",
  "material-solid:movie",
  "material-solid:movie_creation",
  "material-solid:movie_filter",
  "material-solid:moving",
  "material-solid:mp",
  "material-solid:multiline_chart",
  "material-solid:multiple_stop",
  "material-solid:museum",
  "material-solid:music_note",
  "material-solid:music_off",
  "material-solid:music_video",
  "material-solid:my_location",
  "material-solid:nat",
  "material-solid:nature",
  "material-solid:nature_people",
  "material-solid:navigate_before",
  "material-solid:navigate_next",
  "material-solid:navigation",
  "material-solid:near_me",
  "material-solid:near_me_disabled",
  "material-solid:nearby_error",
  "material-solid:nearby_off",
  "material-solid:network_cell",
  "material-solid:network_check",
  "material-solid:network_locked",
  "material-solid:network_wifi",
  "material-solid:new_label",
  "material-solid:new_releases",
  "material-solid:next_plan",
  "material-solid:next_week",
  "material-solid:nfc",
  "material-solid:night_shelter",
  "material-solid:nightlife",
  "material-solid:nightlight",
  "material-solid:nightlight_round",
  "material-solid:nights_stay",
  "material-solid:no_accounts",
  "material-solid:no_backpack",
  "material-solid:no_cell",
  "material-solid:no_drinks",
  "material-solid:no_encryption",
  "material-solid:no_encryption_gmailerrorred",
  "material-solid:no_flash",
  "material-solid:no_food",
  "material-solid:no_luggage",
  "material-solid:no_meals",
  "material-solid:no_meeting_room",
  "material-solid:no_photography",
  "material-solid:no_sim",
  "material-solid:no_stroller",
  "material-solid:no_transfer",
  "material-solid:nordic_walking",
  "material-solid:north",
  "material-solid:north_east",
  "material-solid:north_west",
  "material-solid:not_accessible",
  "material-solid:not_interested",
  "material-solid:not_listed_location",
  "material-solid:not_started",
  "material-solid:note",
  "material-solid:note_add",
  "material-solid:note_alt",
  "material-solid:notes",
  "material-solid:notification_add",
  "material-solid:notification_important",
  "material-solid:notifications",
  "material-solid:notifications_active",
  "material-solid:notifications_none",
  "material-solid:notifications_off",
  "material-solid:notifications_paused",
  "material-solid:offline_bolt",
  "material-solid:offline_pin",
  "material-solid:offline_share",
  "material-solid:ondemand_video",
  "material-solid:online_prediction",
  "material-solid:opacity",
  "material-solid:open_in_browser",
  "material-solid:open_in_full",
  "material-solid:open_in_new",
  "material-solid:open_in_new_off",
  "material-solid:open_with",
  "material-solid:other_houses",
  "material-solid:outbound",
  "material-solid:outbox",
  "material-solid:outdoor_grill",
  "material-solid:outlet",
  "material-solid:outline_flag",
  "material-solid:padding",
  "material-solid:pages",
  "material-solid:pageview",
  "material-solid:paid",
  "material-solid:palette",
  "material-solid:pan_tool",
  "material-solid:panorama",
  "material-solid:panorama_fish_eye",
  "material-solid:panorama_horizontal",
  "material-solid:panorama_horizontal_select",
  "material-solid:panorama_photosphere",
  "material-solid:panorama_photosphere_select",
  "material-solid:panorama_vertical",
  "material-solid:panorama_vertical_select",
  "material-solid:panorama_wide_angle",
  "material-solid:panorama_wide_angle_select",
  "material-solid:paragliding",
  "material-solid:park",
  "material-solid:party_mode",
  "material-solid:password",
  "material-solid:pattern",
  "material-solid:pause",
  "material-solid:pause_circle",
  "material-solid:pause_circle_filled",
  "material-solid:pause_circle_outline",
  "material-solid:pause_presentation",
  "material-solid:payment",
  "material-solid:payments",
  "material-solid:pedal_bike",
  "material-solid:pending",
  "material-solid:pending_actions",
  "material-solid:people",
  "material-solid:people_alt",
  "material-solid:people_outline",
  "material-solid:perm_camera_mic",
  "material-solid:perm_contact_calendar",
  "material-solid:perm_data_setting",
  "material-solid:perm_device_information",
  "material-solid:perm_identity",
  "material-solid:perm_media",
  "material-solid:perm_phone_msg",
  "material-solid:perm_scan_wifi",
  "material-solid:person",
  "material-solid:person_add",
  "material-solid:person_add_alt",
  "material-solid:person_add_alt_1",
  "material-solid:person_add_disabled",
  "material-solid:person_off",
  "material-solid:person_outline",
  "material-solid:person_pin",
  "material-solid:person_pin_circle",
  "material-solid:person_remove",
  "material-solid:person_remove_alt_1",
  "material-solid:person_search",
  "material-solid:personal_injury",
  "material-solid:personal_video",
  "material-solid:pest_control",
  "material-solid:pest_control_rodent",
  "material-solid:pets",
  "material-solid:phone",
  "material-solid:phone_android",
  "material-solid:phone_bluetooth_speaker",
  "material-solid:phone_callback",
  "material-solid:phone_disabled",
  "material-solid:phone_enabled",
  "material-solid:phone_forwarded",
  "material-solid:phone_in_talk",
  "material-solid:phone_iphone",
  "material-solid:phone_locked",
  "material-solid:phone_missed",
  "material-solid:phone_paused",
  "material-solid:phonelink",
  "material-solid:phonelink_erase",
  "material-solid:phonelink_lock",
  "material-solid:phonelink_off",
  "material-solid:phonelink_ring",
  "material-solid:phonelink_setup",
  "material-solid:photo",
  "material-solid:photo_album",
  "material-solid:photo_camera",
  "material-solid:photo_camera_back",
  "material-solid:photo_camera_front",
  "material-solid:photo_filter",
  "material-solid:photo_library",
  "material-solid:photo_size_select_actual",
  "material-solid:photo_size_select_large",
  "material-solid:photo_size_select_small",
  "material-solid:piano",
  "material-solid:piano_off",
  "material-solid:picture_as_pdf",
  "material-solid:picture_in_picture",
  "material-solid:picture_in_picture_alt",
  "material-solid:pie_chart",
  "material-solid:pie_chart_outline",
  "material-solid:pin",
  "material-solid:pin_drop",
  "material-solid:pin_end",
  "material-solid:pin_invoke",
  "material-solid:pivot_table_chart",
  "material-solid:place",
  "material-solid:plagiarism",
  "material-solid:play_arrow",
  "material-solid:play_circle",
  "material-solid:play_circle_filled",
  "material-solid:play_circle_outline",
  "material-solid:play_disabled",
  "material-solid:play_for_work",
  "material-solid:play_lesson",
  "material-solid:playlist_add",
  "material-solid:playlist_add_check",
  "material-solid:playlist_play",
  "material-solid:plumbing",
  "material-solid:plus_one",
  "material-solid:podcasts",
  "material-solid:point_of_sale",
  "material-solid:policy",
  "material-solid:poll",
  "material-solid:polymer",
  "material-solid:pool",
  "material-solid:portable_wifi_off",
  "material-solid:portrait",
  "material-solid:post_add",
  "material-solid:power",
  "material-solid:power_input",
  "material-solid:power_off",
  "material-solid:power_settings_new",
  "material-solid:precision_manufacturing",
  "material-solid:pregnant_woman",
  "material-solid:present_to_all",
  "material-solid:preview",
  "material-solid:price_change",
  "material-solid:price_check",
  "material-solid:print",
  "material-solid:print_disabled",
  "material-solid:priority_high",
  "material-solid:privacy_tip",
  "material-solid:private_connectivity",
  "material-solid:production_quantity_limits",
  "material-solid:psychology",
  "material-solid:public",
  "material-solid:public_off",
  "material-solid:publish",
  "material-solid:published_with_changes",
  "material-solid:push_pin",
  "material-solid:qr_code",
  "material-solid:qr_code_2",
  "material-solid:qr_code_scanner",
  "material-solid:query_builder",
  "material-solid:query_stats",
  "material-solid:question_answer",
  "material-solid:queue",
  "material-solid:queue_music",
  "material-solid:queue_play_next",
  "material-solid:quickreply",
  "material-solid:quiz",
  "material-solid:r_mobiledata",
  "material-solid:radar",
  "material-solid:radio",
  "material-solid:radio_button_checked",
  "material-solid:radio_button_unchecked",
  "material-solid:railway_alert",
  "material-solid:ramen_dining",
  "material-solid:rate_review",
  "material-solid:raw_off",
  "material-solid:raw_on",
  "material-solid:read_more",
  "material-solid:real_estate_agent",
  "material-solid:receipt",
  "material-solid:receipt_long",
  "material-solid:recent_actors",
  "material-solid:recommend",
  "material-solid:record_voice_over",
  "material-solid:recycling",
  "material-solid:redeem",
  "material-solid:redo",
  "material-solid:reduce_capacity",
  "material-solid:refresh",
  "material-solid:remember_me",
  "material-solid:remove",
  "material-solid:remove_circle",
  "material-solid:remove_circle_outline",
  "material-solid:remove_done",
  "material-solid:remove_from_queue",
  "material-solid:remove_moderator",
  "material-solid:remove_red_eye",
  "material-solid:remove_shopping_cart",
  "material-solid:reorder",
  "material-solid:repeat",
  "material-solid:repeat_on",
  "material-solid:repeat_one",
  "material-solid:repeat_one_on",
  "material-solid:replay",
  "material-solid:replay_10",
  "material-solid:replay_30",
  "material-solid:replay_5",
  "material-solid:replay_circle_filled",
  "material-solid:reply",
  "material-solid:reply_all",
  "material-solid:report",
  "material-solid:report_gmailerrorred",
  "material-solid:report_off",
  "material-solid:report_problem",
  "material-solid:request_page",
  "material-solid:request_quote",
  "material-solid:reset_tv",
  "material-solid:restart_alt",
  "material-solid:restaurant",
  "material-solid:restaurant_menu",
  "material-solid:restore",
  "material-solid:restore_from_trash",
  "material-solid:restore_page",
  "material-solid:reviews",
  "material-solid:rice_bowl",
  "material-solid:ring_volume",
  "material-solid:roofing",
  "material-solid:room",
  "material-solid:room_preferences",
  "material-solid:room_service",
  "material-solid:rotate_90_degrees_ccw",
  "material-solid:rotate_left",
  "material-solid:rotate_right",
  "material-solid:rounded_corner",
  "material-solid:router",
  "material-solid:rowing",
  "material-solid:rss_feed",
  "material-solid:rsvp",
  "material-solid:rtt",
  "material-solid:rule",
  "material-solid:rule_folder",
  "material-solid:run_circle",
  "material-solid:running_with_errors",
  "material-solid:rv_hookup",
  "material-solid:safety_divider",
  "material-solid:sailing",
  "material-solid:sanitizer",
  "material-solid:satellite",
  "material-solid:save",
  "material-solid:save_alt",
  "material-solid:saved_search",
  "material-solid:savings",
  "material-solid:scanner",
  "material-solid:scatter_plot",
  "material-solid:schedule",
  "material-solid:schedule_send",
  "material-solid:schema",
  "material-solid:school",
  "material-solid:science",
  "material-solid:score",
  "material-solid:screen_lock_landscape",
  "material-solid:screen_lock_portrait",
  "material-solid:screen_lock_rotation",
  "material-solid:screen_rotation",
  "material-solid:screen_search_desktop",
  "material-solid:screen_share",
  "material-solid:screenshot",
  "material-solid:sd",
  "material-solid:sd_card",
  "material-solid:sd_card_alert",
  "material-solid:sd_storage",
  "material-solid:search",
  "material-solid:search_off",
  "material-solid:security",
  "material-solid:security_update",
  "material-solid:security_update_good",
  "material-solid:security_update_warning",
  "material-solid:segment",
  "material-solid:select_all",
  "material-solid:self_improvement",
  "material-solid:sell",
  "material-solid:send",
  "material-solid:send_and_archive",
  "material-solid:send_to_mobile",
  "material-solid:sensor_door",
  "material-solid:sensor_window",
  "material-solid:sensors",
  "material-solid:sensors_off",
  "material-solid:sentiment_dissatisfied",
  "material-solid:sentiment_neutral",
  "material-solid:sentiment_satisfied",
  "material-solid:sentiment_satisfied_alt",
  "material-solid:sentiment_very_dissatisfied",
  "material-solid:sentiment_very_satisfied",
  "material-solid:set_meal",
  "material-solid:settings",
  "material-solid:settings_accessibility",
  "material-solid:settings_applications",
  "material-solid:settings_backup_restore",
  "material-solid:settings_bluetooth",
  "material-solid:settings_brightness",
  "material-solid:settings_cell",
  "material-solid:settings_ethernet",
  "material-solid:settings_input_antenna",
  "material-solid:settings_input_component",
  "material-solid:settings_input_composite",
  "material-solid:settings_input_hdmi",
  "material-solid:settings_input_svideo",
  "material-solid:settings_overscan",
  "material-solid:settings_phone",
  "material-solid:settings_power",
  "material-solid:settings_remote",
  "material-solid:settings_suggest",
  "material-solid:settings_system_daydream",
  "material-solid:settings_voice",
  "material-solid:share",
  "material-solid:share_location",
  "material-solid:shield",
  "material-solid:shop",
  "material-solid:shop_2",
  "material-solid:shop_two",
  "material-solid:shopping_bag",
  "material-solid:shopping_basket",
  "material-solid:shopping_cart",
  "material-solid:short_text",
  "material-solid:shortcut",
  "material-solid:show_chart",
  "material-solid:shower",
  "material-solid:shuffle",
  "material-solid:shuffle_on",
  "material-solid:shutter_speed",
  "material-solid:sick",
  "material-solid:signal_cellular_0_bar",
  "material-solid:signal_cellular_4_bar",
  "material-solid:signal_cellular_alt",
  "material-solid:signal_cellular_connected_no_internet_0_bar",
  "material-solid:signal_cellular_connected_no_internet_4_bar",
  "material-solid:signal_cellular_no_sim",
  "material-solid:signal_cellular_nodata",
  "material-solid:signal_cellular_null",
  "material-solid:signal_cellular_off",
  "material-solid:signal_wifi_0_bar",
  "material-solid:signal_wifi_4_bar",
  "material-solid:signal_wifi_4_bar_lock",
  "material-solid:signal_wifi_bad",
  "material-solid:signal_wifi_connected_no_internet_4",
  "material-solid:signal_wifi_off",
  "material-solid:signal_wifi_statusbar_4_bar",
  "material-solid:signal_wifi_statusbar_connected_no_internet_4",
  "material-solid:signal_wifi_statusbar_null",
  "material-solid:sim_card",
  "material-solid:sim_card_alert",
  "material-solid:sim_card_download",
  "material-solid:single_bed",
  "material-solid:sip",
  "material-solid:skateboarding",
  "material-solid:skip_next",
  "material-solid:skip_previous",
  "material-solid:sledding",
  "material-solid:slideshow",
  "material-solid:slow_motion_video",
  "material-solid:smart_button",
  "material-solid:smart_display",
  "material-solid:smart_screen",
  "material-solid:smart_toy",
  "material-solid:smartphone",
  "material-solid:smoke_free",
  "material-solid:smoking_rooms",
  "material-solid:sms",
  "material-solid:sms_failed",
  "material-solid:snippet_folder",
  "material-solid:snooze",
  "material-solid:snowboarding",
  "material-solid:snowmobile",
  "material-solid:snowshoeing",
  "material-solid:soap",
  "material-solid:social_distance",
  "material-solid:sort",
  "material-solid:sort_by_alpha",
  "material-solid:source",
  "material-solid:south",
  "material-solid:south_east",
  "material-solid:south_west",
  "material-solid:spa",
  "material-solid:space_bar",
  "material-solid:space_dashboard",
  "material-solid:speaker",
  "material-solid:speaker_group",
  "material-solid:speaker_notes",
  "material-solid:speaker_notes_off",
  "material-solid:speaker_phone",
  "material-solid:speed",
  "material-solid:spellcheck",
  "material-solid:splitscreen",
  "material-solid:sports",
  "material-solid:sports_bar",
  "material-solid:sports_baseball",
  "material-solid:sports_basketball",
  "material-solid:sports_cricket",
  "material-solid:sports_esports",
  "material-solid:sports_football",
  "material-solid:sports_golf",
  "material-solid:sports_handball",
  "material-solid:sports_hockey",
  "material-solid:sports_kabaddi",
  "material-solid:sports_mma",
  "material-solid:sports_motorsports",
  "material-solid:sports_rugby",
  "material-solid:sports_score",
  "material-solid:sports_soccer",
  "material-solid:sports_tennis",
  "material-solid:sports_volleyball",
  "material-solid:square_foot",
  "material-solid:stacked_bar_chart",
  "material-solid:stacked_line_chart",
  "material-solid:stairs",
  "material-solid:star",
  "material-solid:star_border",
  "material-solid:star_border_purple500",
  "material-solid:star_half",
  "material-solid:star_outline",
  "material-solid:star_purple500",
  "material-solid:star_rate",
  "material-solid:stars",
  "material-solid:stay_current_landscape",
  "material-solid:stay_current_portrait",
  "material-solid:stay_primary_landscape",
  "material-solid:stay_primary_portrait",
  "material-solid:sticky_note_2",
  "material-solid:stop",
  "material-solid:stop_circle",
  "material-solid:stop_screen_share",
  "material-solid:storage",
  "material-solid:store",
  "material-solid:store_mall_directory",
  "material-solid:storefront",
  "material-solid:storm",
  "material-solid:straighten",
  "material-solid:stream",
  "material-solid:streetview",
  "material-solid:strikethrough_s",
  "material-solid:stroller",
  "material-solid:style",
  "material-solid:subdirectory_arrow_left",
  "material-solid:subdirectory_arrow_right",
  "material-solid:subject",
  "material-solid:subscript",
  "material-solid:subscriptions",
  "material-solid:subtitles",
  "material-solid:subtitles_off",
  "material-solid:subway",
  "material-solid:summarize",
  "material-solid:superscript",
  "material-solid:supervised_user_circle",
  "material-solid:supervisor_account",
  "material-solid:support",
  "material-solid:support_agent",
  "material-solid:surfing",
  "material-solid:surround_sound",
  "material-solid:swap_calls",
  "material-solid:swap_horiz",
  "material-solid:swap_horizontal_circle",
  "material-solid:swap_vert",
  "material-solid:swap_vertical_circle",
  "material-solid:swipe",
  "material-solid:switch_account",
  "material-solid:switch_camera",
  "material-solid:switch_left",
  "material-solid:switch_right",
  "material-solid:switch_video",
  "material-solid:sync",
  "material-solid:sync_alt",
  "material-solid:sync_disabled",
  "material-solid:sync_problem",
  "material-solid:system_security_update",
  "material-solid:system_security_update_good",
  "material-solid:system_security_update_warning",
  "material-solid:system_update",
  "material-solid:system_update_alt",
  "material-solid:tab",
  "material-solid:tab_unselected",
  "material-solid:table_chart",
  "material-solid:table_rows",
  "material-solid:table_view",
  "material-solid:tablet",
  "material-solid:tablet_android",
  "material-solid:tablet_mac",
  "material-solid:tag",
  "material-solid:tag_faces",
  "material-solid:takeout_dining",
  "material-solid:tap_and_play",
  "material-solid:tapas",
  "material-solid:task",
  "material-solid:task_alt",
  "material-solid:taxi_alert",
  "material-solid:terrain",
  "material-solid:text_fields",
  "material-solid:text_format",
  "material-solid:text_rotate_up",
  "material-solid:text_rotate_vertical",
  "material-solid:text_rotation_angledown",
  "material-solid:text_rotation_angleup",
  "material-solid:text_rotation_down",
  "material-solid:text_rotation_none",
  "material-solid:text_snippet",
  "material-solid:textsms",
  "material-solid:texture",
  "material-solid:theater_comedy",
  "material-solid:theaters",
  "material-solid:thermostat",
  "material-solid:thermostat_auto",
  "material-solid:thumb_down",
  "material-solid:thumb_down_alt",
  "material-solid:thumb_down_off_alt",
  "material-solid:thumb_up",
  "material-solid:thumb_up_alt",
  "material-solid:thumb_up_off_alt",
  "material-solid:thumbs_up_down",
  "material-solid:time_to_leave",
  "material-solid:timelapse",
  "material-solid:timeline",
  "material-solid:timer",
  "material-solid:timer_10",
  "material-solid:timer_10_select",
  "material-solid:timer_3",
  "material-solid:timer_3_select",
  "material-solid:timer_off",
  "material-solid:tips_and_updates",
  "material-solid:title",
  "material-solid:toc",
  "material-solid:today",
  "material-solid:toggle_off",
  "material-solid:toggle_on",
  "material-solid:toll",
  "material-solid:tonality",
  "material-solid:topic",
  "material-solid:touch_app",
  "material-solid:tour",
  "material-solid:toys",
  "material-solid:track_changes",
  "material-solid:traffic",
  "material-solid:train",
  "material-solid:tram",
  "material-solid:transfer_within_a_station",
  "material-solid:transform",
  "material-solid:transgender",
  "material-solid:transit_enterexit",
  "material-solid:translate",
  "material-solid:travel_explore",
  "material-solid:trending_down",
  "material-solid:trending_flat",
  "material-solid:trending_up",
  "material-solid:trip_origin",
  "material-solid:try",
  "material-solid:tty",
  "material-solid:tune",
  "material-solid:tungsten",
  "material-solid:turned_in",
  "material-solid:turned_in_not",
  "material-solid:tv",
  "material-solid:tv_off",
  "material-solid:two_wheeler",
  "material-solid:umbrella",
  "material-solid:unarchive",
  "material-solid:undo",
  "material-solid:unfold_less",
  "material-solid:unfold_more",
  "material-solid:unpublished",
  "material-solid:unsubscribe",
  "material-solid:upcoming",
  "material-solid:update",
  "material-solid:update_disabled",
  "material-solid:upgrade",
  "material-solid:upload",
  "material-solid:upload_file",
  "material-solid:usb",
  "material-solid:usb_off",
  "material-solid:verified",
  "material-solid:verified_user",
  "material-solid:vertical_align_bottom",
  "material-solid:vertical_align_center",
  "material-solid:vertical_align_top",
  "material-solid:vertical_distribute",
  "material-solid:vertical_split",
  "material-solid:vibration",
  "material-solid:video_call",
  "material-solid:video_camera_back",
  "material-solid:video_camera_front",
  "material-solid:video_label",
  "material-solid:video_library",
  "material-solid:video_settings",
  "material-solid:video_stable",
  "material-solid:videocam",
  "material-solid:videocam_off",
  "material-solid:videogame_asset",
  "material-solid:videogame_asset_off",
  "material-solid:view_agenda",
  "material-solid:view_array",
  "material-solid:view_carousel",
  "material-solid:view_column",
  "material-solid:view_comfy",
  "material-solid:view_compact",
  "material-solid:view_day",
  "material-solid:view_headline",
  "material-solid:view_in_ar",
  "material-solid:view_list",
  "material-solid:view_module",
  "material-solid:view_quilt",
  "material-solid:view_sidebar",
  "material-solid:view_stream",
  "material-solid:view_week",
  "material-solid:vignette",
  "material-solid:villa",
  "material-solid:visibility",
  "material-solid:visibility_off",
  "material-solid:voice_chat",
  "material-solid:voice_over_off",
  "material-solid:voicemail",
  "material-solid:volume_down",
  "material-solid:volume_mute",
  "material-solid:volume_off",
  "material-solid:volume_up",
  "material-solid:volunteer_activism",
  "material-solid:vpn_key",
  "material-solid:vpn_lock",
  "material-solid:vrpano",
  "material-solid:wallpaper",
  "material-solid:warning",
  "material-solid:warning_amber",
  "material-solid:wash",
  "material-solid:watch",
  "material-solid:watch_later",
  "material-solid:water",
  "material-solid:water_damage",
  "material-solid:water_drop",
  "material-solid:waterfall_chart",
  "material-solid:waves",
  "material-solid:waving_hand",
  "material-solid:wb_auto",
  "material-solid:wb_cloudy",
  "material-solid:wb_incandescent",
  "material-solid:wb_iridescent",
  "material-solid:wb_shade",
  "material-solid:wb_sunny",
  "material-solid:wb_twilight",
  "material-solid:wc",
  "material-solid:web",
  "material-solid:web_asset",
  "material-solid:web_asset_off",
  "material-solid:weekend",
  "material-solid:west",
  "material-solid:whatshot",
  "material-solid:wheelchair_pickup",
  "material-solid:where_to_vote",
  "material-solid:widgets",
  "material-solid:wifi",
  "material-solid:wifi_calling",
  "material-solid:wifi_calling_3",
  "material-solid:wifi_lock",
  "material-solid:wifi_off",
  "material-solid:wifi_protected_setup",
  "material-solid:wifi_tethering",
  "material-solid:wifi_tethering_error_rounded",
  "material-solid:wifi_tethering_off",
  "material-solid:window",
  "material-solid:wine_bar",
  "material-solid:work",
  "material-solid:work_off",
  "material-solid:work_outline",
  "material-solid:workspaces",
  "material-solid:wrap_text",
  "material-solid:wrong_location",
  "material-solid:wysiwyg",
  "material-solid:yard",
  "material-solid:youtube_searched_for",
  "material-solid:zoom_in",
  "material-solid:zoom_out",
  "material-solid:zoom_out_map",
  "feather:activity",
  "feather:airplay",
  "feather:alert-circle",
  "feather:alert-octagon",
  "feather:alert-triangle",
  "feather:align-center",
  "feather:align-justify",
  "feather:align-left",
  "feather:align-right",
  "feather:anchor",
  "feather:aperture",
  "feather:archive",
  "feather:arrow-down-circle",
  "feather:arrow-down-left",
  "feather:arrow-down-right",
  "feather:arrow-down",
  "feather:arrow-left-circle",
  "feather:arrow-left",
  "feather:arrow-right-circle",
  "feather:arrow-right",
  "feather:arrow-up-circle",
  "feather:arrow-up-left",
  "feather:arrow-up-right",
  "feather:arrow-up",
  "feather:at-sign",
  "feather:award",
  "feather:bar-chart-2",
  "feather:bar-chart",
  "feather:battery-charging",
  "feather:battery",
  "feather:bell-off",
  "feather:bell",
  "feather:bluetooth",
  "feather:bold",
  "feather:book-open",
  "feather:book",
  "feather:bookmark",
  "feather:box",
  "feather:briefcase",
  "feather:calendar",
  "feather:camera-off",
  "feather:camera",
  "feather:cast",
  "feather:check-circle",
  "feather:check-square",
  "feather:check",
  "feather:chevron-down",
  "feather:chevron-left",
  "feather:chevron-right",
  "feather:chevron-up",
  "feather:chevrons-down",
  "feather:chevrons-left",
  "feather:chevrons-right",
  "feather:chevrons-up",
  "feather:chrome",
  "feather:circle",
  "feather:clipboard",
  "feather:clock",
  "feather:cloud-drizzle",
  "feather:cloud-lightning",
  "feather:cloud-off",
  "feather:cloud-rain",
  "feather:cloud-snow",
  "feather:cloud",
  "feather:code",
  "feather:codepen",
  "feather:codesandbox",
  "feather:coffee",
  "feather:columns",
  "feather:command",
  "feather:compass",
  "feather:copy",
  "feather:corner-down-left",
  "feather:corner-down-right",
  "feather:corner-left-down",
  "feather:corner-left-up",
  "feather:corner-right-down",
  "feather:corner-right-up",
  "feather:corner-up-left",
  "feather:corner-up-right",
  "feather:cpu",
  "feather:credit-card",
  "feather:crop",
  "feather:crosshair",
  "feather:database",
  "feather:delete",
  "feather:disc",
  "feather:dollar-sign",
  "feather:download-cloud",
  "feather:download",
  "feather:droplet",
  "feather:edit-2",
  "feather:edit-3",
  "feather:edit",
  "feather:external-link",
  "feather:eye-off",
  "feather:eye",
  "feather:facebook",
  "feather:fast-forward",
  "feather:feather",
  "feather:figma",
  "feather:file-minus",
  "feather:file-plus",
  "feather:file-text",
  "feather:file",
  "feather:film",
  "feather:filter",
  "feather:flag",
  "feather:folder-minus",
  "feather:folder-plus",
  "feather:folder",
  "feather:framer",
  "feather:frown",
  "feather:gift",
  "feather:git-branch",
  "feather:git-commit",
  "feather:git-merge",
  "feather:git-pull-request",
  "feather:github",
  "feather:gitlab",
  "feather:globe",
  "feather:grid",
  "feather:hard-drive",
  "feather:hash",
  "feather:headphones",
  "feather:heart",
  "feather:help-circle",
  "feather:hexagon",
  "feather:home",
  "feather:image",
  "feather:inbox",
  "feather:info",
  "feather:instagram",
  "feather:italic",
  "feather:key",
  "feather:layers",
  "feather:layout",
  "feather:life-buoy",
  "feather:link-2",
  "feather:link",
  "feather:linkedin",
  "feather:list",
  "feather:loader",
  "feather:lock",
  "feather:log-in",
  "feather:log-out",
  "feather:mail",
  "feather:map-pin",
  "feather:map",
  "feather:maximize-2",
  "feather:maximize",
  "feather:meh",
  "feather:menu",
  "feather:message-circle",
  "feather:message-square",
  "feather:mic-off",
  "feather:mic",
  "feather:minimize-2",
  "feather:minimize",
  "feather:minus-circle",
  "feather:minus-square",
  "feather:minus",
  "feather:monitor",
  "feather:moon",
  "feather:more-horizontal",
  "feather:more-vertical",
  "feather:mouse-pointer",
  "feather:move",
  "feather:music",
  "feather:navigation-2",
  "feather:navigation",
  "feather:octagon",
  "feather:package",
  "feather:paperclip",
  "feather:pause-circle",
  "feather:pause",
  "feather:pen-tool",
  "feather:percent",
  "feather:phone-call",
  "feather:phone-forwarded",
  "feather:phone-incoming",
  "feather:phone-missed",
  "feather:phone-off",
  "feather:phone-outgoing",
  "feather:phone",
  "feather:pie-chart",
  "feather:play-circle",
  "feather:play",
  "feather:plus-circle",
  "feather:plus-square",
  "feather:plus",
  "feather:pocket",
  "feather:power",
  "feather:printer",
  "feather:radio",
  "feather:refresh-ccw",
  "feather:refresh-cw",
  "feather:repeat",
  "feather:rewind",
  "feather:rotate-ccw",
  "feather:rotate-cw",
  "feather:rss",
  "feather:save",
  "feather:scissors",
  "feather:search",
  "feather:send",
  "feather:server",
  "feather:settings",
  "feather:share-2",
  "feather:share",
  "feather:shield-off",
  "feather:shield",
  "feather:shopping-bag",
  "feather:shopping-cart",
  "feather:shuffle",
  "feather:sidebar",
  "feather:skip-back",
  "feather:skip-forward",
  "feather:slack",
  "feather:slash",
  "feather:sliders",
  "feather:smartphone",
  "feather:smile",
  "feather:speaker",
  "feather:square",
  "feather:star",
  "feather:stop-circle",
  "feather:sun",
  "feather:sunrise",
  "feather:sunset",
  "feather:tablet",
  "feather:tag",
  "feather:target",
  "feather:terminal",
  "feather:thermometer",
  "feather:thumbs-down",
  "feather:thumbs-up",
  "feather:toggle-left",
  "feather:toggle-right",
  "feather:tool",
  "feather:trash-2",
  "feather:trash",
  "feather:trello",
  "feather:trending-down",
  "feather:trending-up",
  "feather:triangle",
  "feather:truck",
  "feather:tv",
  "feather:twitch",
  "feather:twitter",
  "feather:type",
  "feather:umbrella",
  "feather:underline",
  "feather:unlock",
  "feather:upload-cloud",
  "feather:upload",
  "feather:user-check",
  "feather:user-minus",
  "feather:user-plus",
  "feather:user-x",
  "feather:user",
  "feather:users",
  "feather:video-off",
  "feather:video",
  "feather:voicemail",
  "feather:volume-1",
  "feather:volume-2",
  "feather:volume-x",
  "feather:volume",
  "feather:watch",
  "feather:wifi-off",
  "feather:wifi",
  "feather:wind",
  "feather:x-circle",
  "feather:x-octagon",
  "feather:x-square",
  "feather:x",
  "feather:youtube",
  "feather:zap-off",
  "feather:zap",
  "feather:zoom-in",
  "feather:zoom-out",
];

export default icons;
