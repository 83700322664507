import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class Error_logsService {
  removeFile = (obj) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/error_logs`, { data: { file: obj } })
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
  // removeFile = (obj) => {
  //   return new Promise((resolve, reject) => {
  //     axiosConfig
  //       .delete(`/error_logs?file=${obj}`)
  //       .then((res) => {
  //         if (res.status === "error") return reject(res);

  //         return resolve(res);
  //       })
  //       .catch((err) => {
  //         return reject(err);
  //       });
  //   });
  // };
}

const errorsService = new Error_logsService();
export default errorsService;
