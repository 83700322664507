import { createSlice } from "@reduxjs/toolkit";
import TablePropertiesModel from "../model/TablePropertiesModel";
import KeyModel from "../model/KeyModel";

const drawerSlice = createSlice({
  name: "table_properties/drawer",
  initialState: {
    drawerSelector: "",
    propertyDrawerState: false,
    property: {},
    keyDrawerState: false,
    key: {},
  },
  reducers: {
    //this state is used to determine which drawer it will be open
    setDrawerSelector: (state, action) => {
      state.drawerSelector = action.payload;
    },
    setNewTableProperty: (state) => {
      state.property = TablePropertiesModel();
      state.propertyDrawerState = true;
    },
    setPropertyDrawerState: (state, action) => {
      state.propertyDrawerState = action.payload;
    },
    setNewKey: (state) => {
      state.key = KeyModel();
      state.keyDrawerState = true;
    },
    setKeyDrawerState: (state, action) => {
      state.keyDrawerState = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setDrawerSelector,
  setNewTableProperty,
  setPropertyDrawerState,
  setNewKey,
  setKeyDrawerState,
} = drawerSlice.actions;

export const selectDrawerSlice = ({ table_properties }) =>
  table_properties.drawer;

export default drawerSlice.reducer;
