import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class CentralNotificationsService {
  addCentralNotification = (obj) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/notifications/preset`, obj)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  deleteCentralNotification = (id) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/notifications/preset/${id}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  editCentralNotification = (id, obj) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/notifications/preset/${id}`, obj)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const centralNotificationService = new CentralNotificationsService();
export default centralNotificationService;
