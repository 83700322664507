import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class WorkspaceService {
  checkItem = (path, id) => {
    let str = "";
    for (let index = 0; index < path.length; index++) {
      if (index + 1 == path.length) str = str.concat(path[index]);
      else str = str.concat(path[index], "-");
    }

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/workspaces/${id}/menu_item/${str}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(res);
        });
    });
  };

  uncheckItem = (path, id) => {
    let str = "";
    for (let index = 0; index < path.length; index++) {
      if (index + 1 == path.length) str = str.concat(path[index]);
      else str = str.concat(path[index], "-");
    }

    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/workspaces/${id}/menu_item/${str}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(res);
        });
    });
  };

  addWorkspace = (data, nameLang, languages) => {
    var body = {};

    for (let index = 0; index < languages.length; index++) {
      const element = languages[index].code;
      const name_lang = `name_lang[${element}]`;
      body = {
        ...body,
        [name_lang]: nameLang[element] || "",
      };
    }

    body = {
      ...body,
      ...data,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/workspaces`, body)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(res);
        });
    });
  };

  editWorkspace = (workspaceInfo, data, nameLang, languages) => {
    var body = {};

    for (let index = 0; index < languages.length; index++) {
      const element = languages[index].code;
      const name_lang = `name_lang[${element}]`;
      body = {
        ...body,
        [name_lang]: nameLang[element] || "",
      };
    }

    body = {
      ...body,
      ...data,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/workspaces/${workspaceInfo.workspace_id}`, body)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(res);
        });
    });
  };

  deleteWorkspace = (workspace) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/workspaces/${workspace.workspace_id}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}
const workspaceService = new WorkspaceService();
export default workspaceService;
