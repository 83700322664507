import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLabels } from "./tableSlice";

const drawerSlice = createSlice({
  name: "label/drawer",
  initialState: {
    open: false,
    data: {},
    create: {},
  },
  reducers: {
    setOpen: (state, action) => {
      const { open, data } = action.payload;

      state.data = data;
      state.open = open;
    },
    setEdit: (state) => {
      state.data = state.create;
      state.open = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLabels.fulfilled, (state, action) => {
      const { create } = action.payload;

      state.create = create;
    });
  },
});
export const { setOpen, setEdit } = drawerSlice.actions;

export const selectDrawer = ({ label }) => label.drawer;

export default drawerSlice.reducer;
