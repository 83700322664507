import React, { useState, useEffect } from "react";
import * as Muicon from "@mui/icons-material";
import Button from '@mui/material/Button';

const ButtonIcon = ({
  ariaLabel, 
  text, 
  style, 
  color,
  variant, 
  typeIcon, 
  iconPosition, 
  handleClick,
  hasTimer, 
  timer}) => {
    const Icon = Muicon[typeIcon];

    const [counter, setCounter] = useState(timer ? timer : 60);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
      let intervalId;
      if (counter > 0) {
        intervalId = setInterval(() => {
          setCounter(counter - 1);
        }, 1000);
      } else {
        setDisabled(false);
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId);
    }, [counter]);
  
    const onHandleClick = (e) => {
      setDisabled(true);
      setCounter(timer ? timer : 60);
      handleClick(e);
    };

    return (
        <Button
          className={style}
          color={color}
          variant={variant}
          aria-label={ariaLabel}
          endIcon={iconPosition === 1 && <Icon />}
          startIcon={iconPosition === 0 && <Icon />}
          type="submit"
          onClick={hasTimer ? onHandleClick : handleClick}
          disabled={hasTimer ? disabled : false}
        >
          {text} {hasTimer && disabled && " " + counter}
        </Button>
    );
};

export default ButtonIcon;
