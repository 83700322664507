import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import axiosService from "src/app/auth/services/axios/axiosService";
import history from "@history";

import PageComplement from "app/shared-components/PageComplement";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen/FuseSplashScreen";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";
import { useSnackbar } from "notistack";
import {
  selectDark,
  selectLight,
  selectLogos,
} from "app/store/labels/logosSlice";
import { Typography } from "@mui/material";

const ResetPassword = () => {
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [search, setSearch] = useSearchParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const labels = useSelector(selectGlobalLabels);
  const d = useDispatch();
  const logos = useSelector(selectLight);
  const logosDark = useSelector(selectDark);
  const theme = axiosService.getTheme();

  const schema = yup.object().shape({
    passwordOne: yup
      .string()
      .required(d(t("SYSTEM.signin_fields_password_helper")))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.!@#\$%\^&\*])(?=.{8,})/,
        d(t("SYSTEM.signin_fields_password_helper_info"))
      ),
    passwordTwo: yup
      .string()
      .oneOf(
        [yup.ref("passwordOne"), null],
        d(t("SYSTEM.signin_fields_confirm_password_helper"))
      ),
  });

  const defaultValues = {
    passwordOne: "",
    passwordTwo: "",
  };

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    axiosService
      .resetPassword(data.passwordOne, data.passwordTwo, search.get("tk"))
      .then((res) => {
        history.push("/sign-in");
      })
      .catch((err) => {
        history.push("/sign-in");
      });
  };

  useEffect(() => {
    const validateToken = async () => {
      await axiosService
        .validateTokenResetPass(search.get("tk"))
        .then((res) => {
          setTokenIsValid(true);
        })
        .catch((err) => {});
    };

    validateToken();
  }, []);

  return tokenIsValid ? (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="w-[70%]"
            src={`${process.env.REACT_APP_API_ASSETS}/${
              theme === "defaultDark"
                ? logosDark?.horizontal_logo
                : logos?.horizontal_logo
            }`}
            alt=""
          />
          <p className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            {d(t("SYSTEM.signin_title_reset_password"))}
          </p>
          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="passwordOne"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label={d(t("SYSTEM.signin_fields_password_label"))}
                  autoFocus
                  type="password"
                  error={!!errors.passwordOne}
                  helperText={errors?.passwordOne?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
            <Controller
              name="passwordTwo"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label={d(
                    t("SYSTEM.signin_fields_confirm_password_label")
                  )}
                  type="password"
                  error={!!errors.passwordTwo}
                  helperText={errors?.passwordTwo?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-16"
              aria-label="Reset"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              {d(t("SYSTEM.signin_buttons_reset_password_request"))}
            </Button>
          </form>
          <div className="mt-10">
            <Typography className="text-sm">
              Powered and Design by{" "}
              <a target="_blank" href="https://codemaker.pt">
                Codemaker.pt
              </a>
            </Typography>
            <Typography className="text-sm">
              APP Version {parseFloat(process.env.REACT_APP_VERSION)}
            </Typography>
          </div>
        </div>
      </Paper>

      <PageComplement />
    </div>
  ) : (
    <FuseSplashScreen />
  );
};

export default ResetPassword;
