import { Input } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import {
  filterMenu,
  setActiveGroup,
  setFilterGroup,
} from "../store/gatewaysMenuSlice";
import { updateNavigationItem } from "../store/navigationGatewaysSlice";
import FuseNavigation from "@fuse/core/FuseNavigation/FuseNavigation";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import { setFilters } from "../store/gatewaysSlice";

const GatewaysSidebar = ({ navigation }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");

  const handleChange = (e) => {
    setText(e.target.value);
    dispatch(filterMenu(e.target.value));
  };

  const handleClick = async (item) => {
    if (item.type === "item") {
      const active = await navigation[0].children.find((i) =>
        Boolean(i.active)
      );
      if (active.id !== item.id) {
        dispatch(
          updateNavigationItem(active.id, {
            active: false,
          })
        );
        dispatch(
          updateNavigationItem(item.id, {
            active: true,
          })
        );
        dispatch(setActiveGroup(item.id));
      }
      dispatch(setFilters(item.id));
    }
  };

  return (
    <div className="px-12  h-full">
      <div className="mt-20">
        <div className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-full shadow-none">
          <FuseSvgIcon color="action" size={20}>
            heroicons-solid:search
          </FuseSvgIcon>

          <Input
            placeholder={dispatch(t("common.search"))}
            className="flex flex-1 px-8"
            disableUnderline
            fullWidth
            value={text}
            inputProps={{
              "aria-label": "Search",
            }}
            onChange={handleChange}
          />
        </div>

        <FuseNavigation
          onItemClick={handleClick}
          navigation={navigation}
          className="px-0"
          hasAction={{
            active: true,
          }}
        />
      </div>
    </div>
  );
};

export default GatewaysSidebar;
