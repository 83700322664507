import React from "react";
import { useSelector } from "react-redux";
import {
  drawerState,
  selectEdit,
  setDrawer,
  setEdit,
} from "../../store/drawerSlice";
import { Drawer } from "rsuite";
import { useDispatch } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import { selectGroups } from "../../store/gatewaysMenuSlice";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { fetchGateways } from "../../store/gatewaysSlice";
import * as yup from "yup";
import _ from "@lodash";
import gatewayService from "../../service/GatewaysService";
import FuseLoading from "@fuse/core/FuseLoading";

const VoyagerGatewaysLocalization = () => {
  const dispatch = useDispatch();
  const open = useSelector(drawerState);
  const groups = useSelector(selectGroups);
  const gateway = useSelector(selectEdit);

  const shema = yup.object().shape({
    gateway_name: yup.string(),
    place_group_id: yup.string().nullable(),
  });

  const { control, formState, handleSubmit, reset, watch, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(shema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleClose = () => {
    dispatch(setDrawer(false));
    reset({});
    dispatch(setEdit({}));
  };

  const submit = async (obj) => {
    const _obj = {
      ...gateway,
      ...obj,
    };

    try {
      const res = await gatewayService.editGroup(_obj);

      dispatch(fetchGateways());
    } catch (error) {}

    handleClose();
  };

  useEffect(() => {
    reset({ ...gateway });
  }, [gateway, reset]);

  if (_.isEmpty(gateway) || _.isEmpty(form)) {
    return (
      <Drawer backdrop={true} open={open} onClose={handleClose}>
        <Drawer.Header>
          <Drawer.Title>{dispatch(t("common.location"))}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <FuseLoading />
        </Drawer.Body>
      </Drawer>
    );
  }

  return (
    <>
      <Drawer backdrop={true} open={open} onClose={handleClose}>
        <Drawer.Header>
          <Drawer.Title>{dispatch(t("common.location"))}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <form
            name="localizationForm"
            noValidate
            onSubmit={handleSubmit(submit)}
          >
            <Typography className="flex-1 text-center text-3xl font-semibold tracking-tight leading-10 truncate mt-10">
              {dispatch(t("common.group"))}
            </Typography>

            <Controller
              name="gateway_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={dispatch(t("common.name"))}
                  type="string"
                  error={!!errors.gateway_name}
                  helperText={errors?.gateway_name?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />

            <Controller
              name="place_group_id"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Autocomplete
                  {...field}
                  id="place_group_id"
                  className="mt-32"
                  options={groups}
                  value={
                    value
                      ? _.find(groups, {
                          id: value?.place_group_id || value,
                        })
                      : null
                  }
                  onChange={(event, newValue) => {
                    if (newValue != null && typeof newValue === "object")
                      return onChange(newValue?.id);

                    return onChange(newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value && option.id == value?.place_group_id
                  }
                  getOptionLabel={(option) => `${option.name}`}
                  renderOption={(_props, option) => (
                    <li {..._props}>
                      {option.name} - {option.address}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.place_group_id}
                      helperText={errors?.place_group_id?.message}
                      label={dispatch(t("common.group"))}
                      placeholder={dispatch(t("common.group"))}
                    />
                  )}
                />
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className="mt-20 mb-10"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              onKeyPress={handleKeyPress}
              type="submit"
              fullWidth
            >
              {dispatch(t("common.save"))}
            </Button>
          </form>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default VoyagerGatewaysLocalization;
