import { MaterialReactTable } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguages, selectTableLanguage } from "app/store/i18nSlice";
import { t } from "app/store/labels/globalLabels";
import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  Typography,
} from "@mui/material";
import {
  FormatListBulletedOutlined,
  PersonSearch,
  RefreshOutlined,
} from "@mui/icons-material";
import {
  selectAccessTable,
  selectIsLoading,
  selectUser,
  setUser,
  showUser,
} from "./store/access_logTableSlice";
import { fetchAccessMenu, selectStatus } from "./store/access_logsSlice";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";

const Access_logs = () => {
  const dispatch = useDispatch();
  const access_logs = useSelector(selectAccessTable);
  const loading = useSelector(selectStatus);
  const tableLang = useSelector(selectTableLanguage);
  const lang = useSelector(selectLanguages);
  const isLoading = useSelector(selectIsLoading);
  const user = useSelector(selectUser);

  const [dialogState, setDialogState] = useState(false);
  const [selectRow, setSelectRow] = useState();
  const [_disable, setDisable] = useState(false);

  const handleClose = () => {
    setDialogState(false);
    dispatch(setUser({}));
  };

  const getUser = (token) => {
    dispatch(showUser(token));
  };

  const columns = useMemo(() => {
    const Access_logsInfo = [
      {
        header: "Ip",
        accessorKey: "ip",
        size: 100,
      },
      {
        header: dispatch(t("common.date")),
        accessorKey: "date_time",
        size: 150,
      },
      {
        header: dispatch(t("common.method")),
        accessorKey: "method",
        size: 135,
      },
      {
        header: "Url",
        accessorKey: "url",
        Cell: ({ row }) => {
          return (
            <Typography className="break-words whitespace-normal">
              {row.original.url}
            </Typography>
          );
        },
      },
      {
        header: dispatch(t("common.data")),
        accessorKey: "data",
        Cell: ({ row }) => {
          return (
            <Typography className="break-words whitespace-normal">
              {row.original.data}
            </Typography>
          );
        },
      },
    ];
    return [...Access_logsInfo];
  }, [access_logs, lang]);

  const renderDialog = () => {
    return (
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        onClose={() => setDialogState(true)}
        open={dialogState}
      >
        <DialogTitle>{dispatch(t("common.more_info"))}</DialogTitle>
        <DialogContent dividers>
          <div className="text-center">
            <Typography>{selectRow?.original.headers["user-agent"]}</Typography>
          </div>

          {user.name && (
            <div className="text-center mt-10">
              <Typography>Id: {user.user_id}</Typography>
              <Typography>
                {dispatch(t("common.user"))}: {user.name}
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions className="flex justify-between">
          <Button
            onClick={() => {
              handleClose(), setDisable(false);
            }}
          >
            {dispatch(t("common.close"))}
          </Button>
          {selectRow?.original.headers["session-token"] && (
            <Button
              disabled={_disable}
              onClick={() => {
                getUser(selectRow?.original.headers["session-token"]);
                setDisable(true);
              }}
            >
              {dispatch(t("common.consult_user"))}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {renderDialog()}
      <MaterialReactTable
        data={access_logs}
        columns={columns}
        localization={tableLang}
        enableColumnOrdering
        enableGrouping
        enablePinning
        enableColumnResizing
        enableStickyHeader
        enableFullScreenToggle={false}
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 332px)" } }}
        initialState={{
          sorting: [{ id: "date_time", desc: true }],
          pagination: { pageIndex: 0, pageSize: 15 },
        }}
        state={{
          // showProgressBars: inProgress,
          showSkeletons: loading !== "success" || isLoading ? true : false,
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        defaultColumn={{ minSize: 20 }}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              p: "4px",
              marginLeft: "auto",
              marginRight: "0",
            }}
          >
            <IconButton>
              <FuseSvgIcon className="text-48" size={24} color="action">
                material-outline:pivot_table_chart
              </FuseSvgIcon>
            </IconButton>
          </Box>
        )}
        enableRowActions
        // displayColumnDefOptions={{
        //   "mrt-row-actions": { size: 30 },
        // }}
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            key={1}
            onClick={() => {
              setSelectRow(row);
              setDialogState(true);
              closeMenu();
            }}
          >
            <PersonSearch />
            <Typography className="ml-8">
              {dispatch(t("common.more_info"))}
            </Typography>
          </MenuItem>,
        ]}
      />

      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh_table"))}
          tooltipOpen
          onClick={() => dispatch(fetchAccessMenu())}
        />
      </SpeedDial>
    </>
  );
};

export default Access_logs;
