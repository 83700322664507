import _ from "@lodash";

const Model = (obj) =>
  _.defaults(obj || {}, {
    server_name: "",
    host: "",
    username: "",
    password: "",
    port: "",
    SMTPSecure: "",
    default_name: "",
  });

export default Model;
