import React from "react";
import { forwardRef } from "react";
import clsx from "clsx";
import AddressInput from "./AddressInput";
import { Button, Typography } from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import EntityModel, { EntityModelMerge } from "../../model/EntityModel";
import { useDispatch } from "react-redux";
import { t } from "app/store/labels/globalLabels";

const AddressSelector = forwardRef(({ value, onChange, className }, ref) => {
  const dispatch = useDispatch();

  const handleAddAddress = () => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      // Special onChange behavior for null or empty string value
      onChange([EntityModelMerge().addresses[0]]);
    } else {
      onChange([...value, EntityModel().addresses[0]]);
    }
  };

  return (
    <div className={clsx("w-full", className)} ref={ref}>
      {value?.map((item, index) => (
        <AddressInput
          value={item}
          key={index}
          onChange={(val) => {
            onChange(
              value.map((_item, _index) => (index === _index ? val : _item))
            );
          }}
          onRemove={(val) => {
            onChange(value.filter((_item, _index) => index !== _index));
          }}
          onChecked={(val) => {
            const newItems = value.map((obj, _index) => {
              if (index === _index) return { ...obj, is_main: val };
              return { ...obj, is_main: false };
            });

            onChange(newItems);
          }}
          hideRemove={value.length === 0}
        />
      ))}
      <Button
        className="group inline-flex items-center mt-2 ml-44 py-2 px-4 rounded cursor-pointer"
        onClick={handleAddAddress}
      >
        <FuseSvgIcon size={20}>heroicons-solid:plus-circle</FuseSvgIcon>

        <span className="ml-8 font-medium text-secondary group-hover:underline">
          {dispatch(t("common.add"))} {""} {dispatch(t("common.address"))}
        </span>
      </Button>
    </div>
  );
});

export default AddressSelector;
