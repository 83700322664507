import { lazy } from "react";
import EntityView from "./contact/EntityView";
import EntityForm from "./contact/EntityForm";
import EntityNewForm from "./contact/EntityNewForm";
import { Navigate } from "react-router-dom";

const EntitiesApp = lazy(() => import("./EntitiesApp"));

const EntitiesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/system/entities",
      // element: <EntitiesApp />,
      children: [
        {
          path: "",
          element: <Navigate to="/system/entities/customer" />,
        },
        {
          path: ":type",
          element: <EntitiesApp />,
          children: [
            {
              path: ":id",
              element: <EntityView />,
            },
            {
              path: ":id/new",
              element: <EntityNewForm />,
            },
            {
              path: ":id/edit",
              element: <EntityForm />,
            },
          ],
        },
      ],
    },
  ],
};

export default EntitiesAppConfig;
