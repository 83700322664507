import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class ServiceFunctions {
  exportTable = (body, columns) => {
    return new Promise((resolve, reject) => {
      axiosConfig.post(`/export`, { body, columns }),
        then((r) => {
          if (r.status == "error") return reject(r);

          return resolve(r);
        }).catch((e) => reject(e));
    });
  };
}

const serviceFunctions = new ServiceFunctions();
export default serviceFunctions;
