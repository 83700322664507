import _ from "@lodash";

const LabelsModel = (_l, systemLangs) => {
  if (!_l.label_lang) return { ..._l };

  const langJson = JSON.parse(_l.label_lang);

  const sLangs = {};
  systemLangs.map((_s) => {
    sLangs[_s.code] = "";
  });

  return {
    ..._l,
    ...sLangs,
    ...langJson,
  };
};

export const LabelsTagsModel = (data) => {
  const tagArray = data.map((_l, _index) => {
    const { label_group_tag } = _l;

    return label_group_tag;
  });

  const uniqueSortedTags = [...new Set(tagArray)].sort();

  return uniqueSortedTags;
};

export const LabelsNewModel = (systemLangs) => {
  const sLangs = {};

  systemLangs.map((_s) => {
    sLangs[_s.code] = "";
  });

  return {
    label_group_tag: "",
    label_tag: "",
    ...sLangs,
  };
};

export default LabelsModel;
