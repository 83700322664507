import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "@lodash";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getConfigs = createAsyncThunk(
  "configurations/clientConfigs/getConfigs",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/APIconfig`);

      if (res.status === "error") throw new Error(res);

      return res.result.corporate;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateConfigs = createAsyncThunk(
  "configurations/clientConfigs/updateConfigs",
  async (data, { dispatch, getState }) => {
    try {
      const newValues = await correctObj(data);

      const res = await axiosConfig.post(`/APIconfig`, newValues);

      if (res.status === "error") throw new Error(res);

      return res.result.corporate;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const correctObj = (obj) => {
  if (typeof obj === "string") {
    return obj;
  }

  if (obj == null) {
    return "null";
  }

  if (obj instanceof File) {
    return obj;
  }

  if (obj instanceof Blob) {
    return null;
  }

  const result = {};
  for (const key in obj) {
    result[key] = correctObj(obj[key]);
  }

  return result;
};

const clientConfigsSlice = createSlice({
  name: "configurations/clientConfigs",
  initialState: {
    corporate: {
      name: "",
      long_name: "",
      favicon: null,
      logos: {
        dark_theme: {
          vertical_logo: null,
          horizontal_logo: null,
        },
        light_theme: {
          vertical_logo: null,
          horizontal_logo: null,
        },
      },
    },
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfigs.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getConfigs.fulfilled, (state, action) => {
        const { social_media, ...rest } = action.payload;

        state.corporate = {
          ...rest,
          favicon:
            rest.favicon != "null"
              ? `${process.env.REACT_APP_API_ASSETS_FAVICON}/${rest.favicon}`
              : null,
          logos: {
            dark_theme: {
              vertical_logo:
                rest.logos.dark_theme.vertical_logo != "null"
                  ? `${process.env.REACT_APP_API_ASSETS}/${rest.logos.dark_theme.vertical_logo}`
                  : null,
              horizontal_logo:
                rest.logos.dark_theme.horizontal_logo != "null"
                  ? `${process.env.REACT_APP_API_ASSETS}/${rest.logos.dark_theme.horizontal_logo}`
                  : null,
            },
            light_theme: {
              vertical_logo:
                rest.logos.light_theme.vertical_logo != "null"
                  ? `${process.env.REACT_APP_API_ASSETS}/${rest.logos.light_theme.vertical_logo}`
                  : null,
              horizontal_logo:
                rest.logos.light_theme.horizontal_logo != "null"
                  ? `${process.env.REACT_APP_API_ASSETS}/${rest.logos.light_theme.horizontal_logo}`
                  : null,
            },
          },
        };
        state.status = "fulfilled";
      })
      .addCase(getConfigs.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const selectClientConfigs = ({ configurations }) =>
  configurations.clientConfigs;

export default clientConfigsSlice.reducer;
