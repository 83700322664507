import { MaterialReactTable } from "material-react-table";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchSmtp_ServersList,
  removeSmtp_server,
  selectSmtp,
  selectStatus,
  setSmtp_servers,
  updateSmtp_server,
} from "./store/smtp_serversSlice";
import { t } from "app/store/labels/globalLabels";
import { selectLanguages, selectTableLanguage } from "app/store/i18nSlice";
import { MenuItem, SpeedDial, SpeedDialAction, Switch } from "@mui/material";
import {
  Add,
  Delete,
  Edit,
  FormatListBulletedOutlined,
  PlayArrow,
  RefreshOutlined,
} from "@mui/icons-material";
import { setDrawerState, setEditSmtp, setEmptySmtp } from "./store/drawerSlice";
import DrawerMenu from "./components/DrawerMenu";
import ConfirmationDialog from "../../components/confirmationDialog/ConfirmationDialog";
import { useState } from "react";
import smtp_serverService from "./service/Smtp_serverService";

const Smtp_Servers = () => {
  const dispatch = useDispatch();
  const smtp_server = useSelector(selectSmtp);
  const lang = useSelector(selectLanguages);
  const loading = useSelector(selectStatus);
  const tableLang = useSelector(selectTableLanguage);

  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [smtp, setSmtp] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [_disable, _setDisable] = useState(false);

  const handleOpenValidation = () => {
    setOpenValidationDialog(true);
  };

  const handleNo = () => {
    setOpenValidationDialog(false);
  };

  const handleDelete = async () => {
    try {
      const res = await smtp_serverService.deleteSmtp_server(
        smtp.original.server_id
      );

      dispatch(removeSmtp_server(smtp.index));
    } catch (error) {}
    handleNo();
  };

  const handleExecution = async (row) => {
    try {
      const id = row.original.server_id;
      const res = await smtp_serverService.executeSmtp_server(id);
    } catch (error) {}
    setInProgress(false);
    _setDisable(false);
  };

  const handleSwitchState = async (bool, row) => {
    const smtp_serverCopy = JSON.parse(JSON.stringify(smtp_server));

    const main = smtp_serverCopy.filter((item) => item?.is_main != 0);

    if (row.original?.server_id != main[0]?.server_id && main[0]?.is_main) {
      main[0].is_main = 0;

      dispatch(setSmtp_servers(smtp_serverCopy));
    }

    const obj = {
      is_main: bool ? 1 : 0,
    };

    try {
      const res = await smtp_serverService.editSmtp_server(
        row.original.server_id,
        obj
      );

      const _obj = {
        ...row.original,
        is_main: obj.is_main,
      };

      dispatch(
        updateSmtp_server({
          index: row.index,
          value: _obj,
        })
      );
    } catch (error) {}
  };

  const columns = useMemo(() => {
    const Smtp_ServersInfo = [
      {
        header: dispatch(t("common.server_name")),
        accessorKey: "server_name",
        size: 220,
      },
      {
        header: dispatch(t("common.main")),
        accessorKey: "is_main",
        maxSize: 150,
        Cell: ({ row }) => (
          <Switch
            disabled={row.original.is_main ? true : false || _disable}
            checked={Boolean(row.original.is_main)}
            onChange={(e) => {
              _setDisable(true);
              handleSwitchState(e.target.checked, row);
              _setDisable(false);
            }}
          />
        ),
      },
      {
        header: "Host",
        accessorKey: "host",
        size: 220,
      },
      {
        header: dispatch(t("common.user")),
        accessorKey: "username",
        size: 150,
      },
      {
        header: dispatch(t("common.port")),
        accessorKey: "port",
        maxSize: 100,
      },
      {
        header: dispatch(t("common.protocol")),
        accessorKey: "SMTPSecure",
        maxSize: 150,
      },
      {
        header: dispatch(t("common.default_name")),
        accessorKey: "default_name",
        size: 220,
      },
    ];
    return [...Smtp_ServersInfo];
  }, [smtp_server, lang]);

  return (
    <>
      <MaterialReactTable
        data={smtp_server}
        columns={columns}
        localization={tableLang}
        enableColumnOrdering
        enableGrouping
        enablePinning
        enableColumnResizing
        enableStickyHeader
        enableFullScreenToggle={false}
        displayColumnDefOptions={{ "mrt-row-actions": { size: 55 } }}
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 332px)" } }}
        initialState={{
          pagination: { pageIndex: 0, pageSize: 15 },
        }}
        state={{
          showProgressBars: inProgress,
          showSkeletons: loading == "pending" ? true : false,
        }}
        enableRowActions
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            key={1}
            disabled={_disable}
            onClick={() => {
              setSmtp(row);
              dispatch(setDrawerState(true));
              dispatch(setEditSmtp(row.original));
              closeMenu();
            }}
          >
            <Edit />
            {dispatch(t("common.edit"))}
          </MenuItem>,
          <MenuItem
            key={2}
            disabled={_disable}
            color="error"
            onClick={() => {
              setSmtp(row);
              handleOpenValidation(row);
              closeMenu();
            }}
          >
            <Delete /> {dispatch(t("common.delete"))}
          </MenuItem>,
          <MenuItem
            key={3}
            disabled={_disable}
            onClick={() => {
              handleExecution(row);
              setInProgress(true);
              _setDisable(true);
              closeMenu();
            }}
          >
            <PlayArrow /> {dispatch(t("common.execute"))}
          </MenuItem>,
        ]}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        defaultColumn={{ minSize: 20 }}
      />
      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle={dispatch(t("common.add"))}
          tooltipOpen
          onClick={() => dispatch(setEmptySmtp())}
        />

        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh_table"))}
          tooltipOpen
          onClick={() => dispatch(fetchSmtp_ServersList())}
        />
      </SpeedDial>
      <DrawerMenu smtp={smtp} />
      <ConfirmationDialog
        open={openValidationDialog}
        onClose={handleNo}
        onSubmit={() => handleDelete(smtp)}
        title="common.delete"
      />
    </>
  );
};

export default Smtp_Servers;
