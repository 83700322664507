import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import { Add, Delete, Edit, InfoOutlined } from "@mui/icons-material";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { t } from "app/store/labels/globalLabels";
import { useDispatch } from "react-redux";
import _ from "@lodash";
import { FormatDate, isObjectEmpty } from "app/shared-components/Functions";

const MenuActions = ({ className, onEdit, onAdd, onDelete, options, info }) => {
  const definedProps = [onEdit, onAdd, onDelete].filter(
    (prop) => prop !== undefined && prop !== null
  );

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreMenuEl, setMoreMenuEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMoreMenuClick = async (event) => {
    setMoreMenuEl(event.currentTarget);
  };

  const handleMoreMenuClose = (event) => {
    setMoreMenuEl(null);
  };

  const handleEdit = (opt) => {
    handleMenuClose();
    onEdit(opt);
  };

  const handleAdd = (opt) => {
    handleMenuClose();
    onAdd(opt);
  };

  const handleDelete = (opt) => {
    handleMenuClose();
    onDelete(opt);
  };

  return (
    <>
      {definedProps.length == 1 && (
        <>
          {onEdit && (
            <IconButton
              className={`p-0 ${className}`}
              onClick={() => handleEdit(options)}
            >
              <FuseSvgIcon size={20}>material-solid:edit</FuseSvgIcon>
            </IconButton>
          )}

          {onAdd && (
            <IconButton
              className={`p-0 ${className}`}
              onClick={() => handleAdd(options)}
            >
              <FuseSvgIcon size={20}>material-outline:add</FuseSvgIcon>
            </IconButton>
          )}

          {onDelete && (
            <IconButton
              className={`p-0 ${className}`}
              onClick={() => handleDelete(options)}
            >
              <FuseSvgIcon size={20}>material-solid:delete</FuseSvgIcon>
            </IconButton>
          )}
        </>
      )}

      {definedProps.length > 1 && (
        <IconButton className={`p-0 ${className}`} onClick={handleMenuOpen}>
          <FuseSvgIcon size={20}>heroicons-outline:dots-vertical</FuseSvgIcon>
        </IconButton>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {onEdit && (
          <MenuItem onClick={() => handleEdit(options)}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary={dispatch(t("common.edit"))} />
          </MenuItem>
        )}

        {onAdd && (
          <MenuItem onClick={() => handleAdd(options)}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary={dispatch(t("common.add"))} />
          </MenuItem>
        )}

        {onDelete && (
          <MenuItem onClick={() => handleDelete(options)}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary={dispatch(t("common.delete"))} />
          </MenuItem>
        )}

        {!isObjectEmpty(info) && (
          <>
            <MenuItem
              className={className}
              onClick={handleMoreMenuClick}
              aria-owns={moreMenuEl ? "more-menu-info" : null}
              aria-haspopup="true"
            >
              <ListItemIcon>
                <InfoOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>{dispatch(t("common.info"))}</ListItemText>
            </MenuItem>
            <Menu
              id="more-menu-info"
              anchorEl={moreMenuEl}
              open={Boolean(moreMenuEl)}
              onClose={handleMoreMenuClose}
              autoFocus={false}
              disableAutoFocus
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {info.created && (
                <MenuItem disabled>
                  <ListItemText>
                    {`${dispatch(t("common.created"))}: ${FormatDate(
                      info.created,
                      true
                    )}`}
                  </ListItemText>
                </MenuItem>
              )}
              {info.created_by_name && (
                <MenuItem disabled>
                  <ListItemText>
                    {`${dispatch(t("common.created"))}: ${
                      info.created_by_name
                    }`}
                  </ListItemText>
                </MenuItem>
              )}
              {info.updated && (
                <MenuItem disabled>
                  <ListItemText>
                    {`${dispatch(t("common.updated"))}: ${FormatDate(
                      info.updated,
                      true
                    )}`}
                  </ListItemText>
                </MenuItem>
              )}
              {info.updated_by_name && (
                <MenuItem disabled>
                  <ListItemText>
                    {`${dispatch(t("common.updated"))}: ${
                      info.updated_by_name
                    }`}
                  </ListItemText>
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </Popover>
    </>
  );
};

export default MenuActions;
