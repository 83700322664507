import { useDispatch, useSelector } from "react-redux";
import { fetchErrorsList, selectErrors } from "./store/errorsSlice";
import { useEffect } from "react";
import { useThemeMediaQuery } from "@fuse/hooks";
import { useMemo } from "react";
import { motion } from "framer-motion";
import { selectNavigation } from "./store/navigationError_logsSlice";
import { useState } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import reducer from "./store";
import withReducer from "app/store/withReducer";
import Error_logAppHeader from "./Errors_logAppHeader";
import Error_logs from "./Error_logs";
import Error_logsSideBar from "./components/Error_logsSideBar";

const Error_logsApp = () => {
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  const errors = useSelector(selectErrors);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);

  useEffect(() => {
    dispatch(fetchErrorsList());
  }, [dispatch]);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <FusePageSimple
        header={
          <Error_logAppHeader
            leftSidebar={(e) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
          />
        }
        content={
          <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
            {useMemo(() => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <Error_logs />
                    </motion.div>
                  </div>
                </motion.div>
              );
            }, [errors])}
          </div>
        }
        leftSidebarOpen={leftSidebarOpen}
        leftSidebarOnClose={() => {
          setLeftSidebarOpen(false);
        }}
        leftSidebarContent={<Error_logsSideBar navigation={navigation} />}
        scroll="content"
      />
    </>
  );
};

export default withReducer("error_logs", reducer)(Error_logsApp);
