import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class SupportService {
  addTicket = async (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/ticket`, data)
        .then((res) => {
          if (res.status === "success") return resolve(res);
          return reject(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const supportService = new SupportService();
export default supportService;
