import FuseUtils from "@fuse/utils/FuseUtils";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import menu from "../config/groupsMenu";
import { setActiveGroup } from "./groupsSlice";
import { changeWorkspace, setFilterMenu } from "./menuSlice";
import { t } from "app/store/labels/globalLabels";

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(emptyInitialState, menu);

export const changeLanguage = () => (dispatch, getState) => {
  dispatch(
    updateNavigationItem("groups", {
      title: dispatch(t("common.groups")),
    })
  );
};

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

const prependNavigationItem = (item, parentId) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(
    setNavigation(FuseUtils.appendNavItem(navigation, item, parentId))
  );
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const {
  selectAll: selectNavigationAll,
  selectIds: selectNavigationIds,
  selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors((state) => state.menu.navigationGroups);

const navigationGroupsSlice = createSlice({
  name: "menu/navigationGroups",
  initialState,
  reducers: {
    setNavigation: navigationAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },
  extraReducers: {},
});

export const createNavigation = (items) => async (dispatch, getState) => {
  const { activeWorkspace } = getState().menu.workspaces;
  items.map((i, index) => {
    dispatch(
      prependNavigationItem(
        {
          ...i,
          id: `${i.menu_id}`,
          title: i.name,
          type: "item",
          hasActions: true,
          active: index === 0 ? true : false,
        },
        "groups"
      )
    );
    if (index === 0) {
      dispatch(setActiveGroup(i.menu_id));
      dispatch(setFilterMenu(i.menu_id));
      if (activeWorkspace) dispatch(changeWorkspace(activeWorkspace));
    }
  });
};

export const { setNavigation, resetNavigation } = navigationGroupsSlice.actions;

export const selectNavigation = createSelector(
  [selectNavigationAll],
  (navigation) => {
    return navigation;
  }
);

export default navigationGroupsSlice.reducer;
