import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import _ from "@lodash";
import {
  addTemplate,
  getTemplate,
  newTemplate,
  selectTemplate,
  updateTemplate,
} from "../store/templatesSlice";
import { useNavigate, useParams } from "react-router-dom";
import FuseLoading from "@fuse/core/FuseLoading";
import Translate from "src/app/main/components/inputLanguageSelector/Translate";
import InputTranslate from "src/app/main/components/textTranslate/InputTranslate";

const TemplateForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const template = useSelector(selectTemplate);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (routeParams.form === "new") {
      setTitle(dispatch(t("common.add")));
      dispatch(newTemplate());
    } else {
      setTitle(dispatch(t("common.edit")));
      dispatch(getTemplate(routeParams.template));
    }
  }, [dispatch, routeParams]);

  const schema = yup.object().shape({
    name_tag: yup
      .string()
      .matches(/^[a-zA-Z0-9_-]+$/, dispatch(t("common.invalid_chars")))
      .required(dispatch(t("common.input_required"))),
  });

  const { control, setValue, watch, reset, formState, handleSubmit } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({ ...template });
  }, [template]);

  const { dirtyFields, isValid, errors } = formState;

  const form = watch();

  const onSubmit = async (data) => {
    //TODO finalizar
    try {
      if (routeParams.form === "new") {
        dispatch(addTemplate(data)).then(({ payload }) => {
          navigate(`/system/templates/${payload.id}`);
        });
      } else {
        dispatch(updateTemplate(data)).then(({ payload }) => {
          navigate(`/system/templates/${routeParams.template}`);
        });
      }
    } catch (error) {}
  };

  if (_.isEmpty(form) || !template) {
    return <FuseLoading />;
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex justify-between items-center mt-10 px-24">
        <Typography>{title}</Typography>
        <IconButton
          className=""
          sx={{ color: "background.primary" }}
          component={NavLinkAdapter}
          to={-1}
          size="large"
        >
          <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
        </IconButton>
      </div>
      <div className="w-full px-24 flex-1">
        <Controller
          control={control}
          name={"name_tag"}
          render={({ field: { onChange, ...field } }) => (
            <TextField
              {...field}
              className="mt-32"
              label={dispatch(t("common.tag"))}
              placeholder={dispatch(t("common.tag"))}
              disabled={routeParams.form !== "new"}
              onChange={(e) => {
                setValue("title", e.target.value);
                onChange(e.target.value);
              }}
              id="name_tag"
              error={!!errors.name_tag}
              helperText={errors?.name_tag?.message}
              variant="outlined"
              required
              fullWidth
            />
          )}
        />

        <Controller
          control={control}
          name={"name_lang"}
          render={({ field }) => (
            <InputTranslate {...field} className={"mt-32"} />
          )}
        />
      </div>

      <Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t">
        <Button
          className="ml-auto"
          component={NavLinkAdapter}
          to={`/system/templates/${routeParams.template}`}
        >
          {dispatch(t("common.cancel"))}
        </Button>
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </Button>
      </Box>
    </div>
  );
};

export default TemplateForm;
