import axiosConfig from "../auth/services/axios/axiosConfig";

class UserService {
  updateUser = (data, user) => {
    var body = {};

    for (const key in data) {
      const element = `attributes[${key}]`;
      if (key != "email") {
        body = { ...body, [element]: data[key] };
      }
    }

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/user_data/${user.data.user_id}`, body)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const userService = new UserService();
export default userService;
