import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import clsx from "clsx";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTheme,
  selectFuseCurrentLayoutConfig,
  selectMainTheme,
  selectToolbarTheme,
} from "app/store/fuse/settingsSlice";
import { selectFuseNavbar } from "app/store/fuse/navbarSlice";
import LanguageSwitcher from "../../shared-components/LanguageSwitcher";
import NotificationPanelToggleButton from "../../shared-components/notificationPanel/NotificationPanelToggleButton";
import NavbarToggleButton from "../../shared-components/NavbarToggleButton";
import UserMenu from "../../shared-components/UserMenu";
import { selectClient, logoutClient } from "../../../store/clientSlice";
import Typography from "@mui/material/Typography";
import { clearDevices } from "src/modules/pages/user/devices/voyager/store/devicesSlice";
import axiosService from "src/app/auth/services/axios/axiosService";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";
import { getUserShortcuts, selectUser } from "app/store/userSlice";
import NavigationSearch from "app/theme-layouts/shared-components/NavigationSearch";
import NavigationShortcuts from "app/theme-layouts/shared-components/NavigationShortcuts";
import { IconButton } from "@mui/material";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";

function ToolbarLayout1(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const mainTheme = useSelector(selectMainTheme);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const client = useSelector(selectClient);
  const labels = useSelector(selectGlobalLabels);
  const user = useSelector(selectUser);
  const clientAccess = axiosService.getClientAccess();
  const accessAs = axiosService.getAccessAs();

  const dispatch = useDispatch();

  const clienteMenuClick = () => {
    dispatch(logoutClient());
    dispatch(clearDevices());
  };

  const handleTheme = (value) => {
    axiosService.setTheme(value);
    dispatch(changeTheme(value));
  };

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx("flex relative z-20 shadow-md", props.className)}
        color="default"
        sx={{
          background: (theme) =>
            theme.palette.mode === "light"
              ? toolbarTheme.palette.background.default
              : toolbarTheme.palette.background.default,
        }}
        position="static"
      >
        <Toolbar
          // disableGutters
          className={`p-0 min-h-48 md:min-h-64`}
        >
          <div className="flex pl-16">
            {config.navbar.display && config.navbar.position === "left" && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === "style-3" ||
                    config.navbar.style === "style-3-dense") && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === "style-1" && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            <Hidden lgDown>
              <NavigationShortcuts />
            </Hidden>
          </div>

          <div className="flex flex-1 justify-start items-center px-8 h-full overflow-x-auto"></div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
            <NavigationSearch />
           

            {mainTheme.palette.mode === "light" ? (
              <IconButton onClick={() => handleTheme("defaultDark")}>
                <DarkModeOutlined />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleTheme("default")}>
                <LightModeOutlined />
              </IconButton>
            )}

            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === "right" && (
            <>
              <Hidden lgDown>
                {!navbar.open && (
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                )}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
