import _ from "@lodash";

const NotificationsModel = (obj) =>
  _.defaults(obj || {}, {
    name: "",
    user: [],
    emails: [],
  });

export const Notifications = (data) => {
  const createArray = (items) => {
    const { user, emails } = JSON.parse(items.recipients);

    const newUser = user.split(",").map((i) => `${i}`);

    return {
      user: newUser,
      emails: emails.split(","),
    };
  };

  const recipients = createArray(data);

  return {
    ...data,
    user: recipients.user,
    emails: recipients.emails,
  };
};

export default NotificationsModel;
