import { Drawer } from "rsuite";
import * as yup from "yup";
import { selectOpenState, setOpenDrawer } from "../store/drawerSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import languageService from "../service/languageService";
import { useState } from "react";
import {
  addLanguage,
  selectAllLang,
  selectSystemLang,
} from "../store/tableSlice";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";
import { selectLanguages } from "app/store/i18nSlice";
import { useSnackbar } from "notistack";
import { selectActiveLang } from "../store/tableSlice";
import { fetchLanguageList } from "../store/tableSlice";

const filter = createFilterOptions();

const LanguageAddMenu = () => {
  const dispatch = useDispatch();
  const labels = useSelector(selectGlobalLabels);
  const open = useSelector(selectOpenState);
  const allLang = useSelector(selectAllLang);
  const systemLang = useSelector(selectSystemLang);
  const activeLang = useSelector(selectActiveLang);

  const [disabledBtn, setDisabledBtn] = useState(false);

  const schema = yup.object().shape({
    lang: yup.string(),
  });

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      lang: null,
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;

  const handleClose = () => {
    reset();
    setDisabledBtn(false);
    dispatch(setOpenDrawer(false));
  };

  const onSubmit = async (data) => {
    setDisabledBtn(true);

    try {
      const res = await languageService.addRow(data, systemLang);

      dispatch(
        addLanguage({
          name: data.lang.split(".")[0],
          code: data.lang.split(".")[1],
          active: false,
        })
      );
    } catch (error) {}
    setDisabledBtn(false);
    handleClose();
    dispatch(fetchLanguageList());
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      <Drawer backdrop={true} open={open} onClose={handleClose}>
        <Drawer.Header>
          <Drawer.Title>{dispatch(t("common.add"))}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="lang"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <Autocomplete
                  id="lang"
                  isOptionEqualToValue={(value) => value}
                  className="mb-16"
                  value={value}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      onChange("");
                    } else if (newValue === null) {
                      onChange(null);
                    } else {
                      onChange(`${newValue.name}.${newValue.code}`);
                    }
                  }}
                  selectOnFocus
                  clearOnBlur
                  options={allLang}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return `${option.name} - ${option.code}`;
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={`${option.code}.${option.name}`}>
                      {option.name} - {option.code}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={dispatch(t("common.search"))}
                      variant="outlined"
                      error={!!errors.lang}
                      helperText={errors?.lang?.message}
                      required
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                />
              )}
            />

            <Button
              color="secondary"
              type="submit"
              disabled={_.isEmpty(dirtyFields) || !isValid || disabledBtn}
              onKeyPress={handleKeyPress}
              variant="contained"
              fullWidth
            >
              {dispatch(t("common.save"))}
            </Button>
          </form>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default LanguageAddMenu;
