import axiosConfig, {
  axiosInitial,
} from "src/app/auth/services/axios/axiosConfig";
import {
  createSelector,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

export const fetchGlobalLabels = createAsyncThunk(
  "labelsApp/global/fetchGlobalLabels",
  async (lang, { dispatch, getState }) => {
    const { user } = getState();

    try {
      const data = await axiosInitial.get(
        `/labels?formated=1&lang=${lang || user.data.session_lang}`
      );

      if (data?.status === "error") return null;

      return data.result;
    } catch (error) {}
  }
);

export const t = (tag, options) => (dispatch, getState) => {
  const { elements: labels } = getState().labelsApp.globalLabels;
  var returned = "";
  const search = tag && labels ? tag : "";

  if (!search) return `[${tag}]`;

  if (!labels[search]) return `[${tag}]`;

  returned = labels[search];

  for (const key in options) {
    returned = returned.replace(`{{${key}}}`, options[key]);
  }

  return returned;
};

export const snackLabelsValidation =
  (tag, title) => async (dispatch, getState) => {
    const { elements } = getState().labelsApp.globalLabels;

    if (elements[tag]) {
      if (typeof elements[tag] === "object") {
        return title
          ? elements[tag].title
          : elements[tag].content
          ? elements[tag].content
          : `[${tag}.content]`;
      } else if (typeof elements[tag] === "string") {
        return elements[tag];
      }
    }

    return `[${tag}]`;
  };

const globalLabels = createSlice({
  name: "labelsApp/globalLabels",
  initialState: {
    elements: null,
    status: "idle",
    pageTitle: "",
    pageSubtitle: "",
  },
  reducers: {
    setGlobalLabels: (state, action) => {
      state.elements = action.payload;
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    setPageSubTitle: (state, action) => {
      state.pageSubtitle = action.payload;
    },
  },
  extraReducers: {
    [fetchGlobalLabels.pending]: (state, action) => {
      state.status = "pending";
    },
    [fetchGlobalLabels.fulfilled]: (state, action) => {
      state.status = "fulfilled";
      state.elements = action.payload;
    },
    [fetchGlobalLabels.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

export const { setGlobalLabels, setPageTitle, setPageSubTitle } =
  globalLabels.actions;

export const selectPageTitle = ({ labelsApp }) =>
  labelsApp.globalLabels.pageTitle;
export const selectPageSubTitle = ({ labelsApp }) =>
  labelsApp.globalLabels.pageSubtitle;
export const selectGlobalLabels = ({ labelsApp }) =>
  labelsApp.globalLabels.elements;
export const selectGlobalLabelsStatus = ({ labelsApp }) =>
  labelsApp.globalLabels.status;

export default globalLabels.reducer;
