import FuseUtils from "@fuse/utils/FuseUtils";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import menu from "../config/navigationConfig";

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(emptyInitialState, menu);

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

const prependNavigationItem = (item, parentId) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(
    setNavigation(FuseUtils.appendNavItem(navigation, item, parentId))
  );
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const {
  selectAll: selectNavigationAll,
  selectIds: selectNavigationIds,
  selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors(
  (state) => state.gateways.navigationGateways
);

const navigationGatewaysSlice = createSlice({
  name: "gateways/navigationGateways",
  initialState,
  reducers: {
    setNavigation: navigationAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },
  extraReducers: {},
});

export const createNavigation = (items) => async (dispatch, getState) => {
  items.map((gateways) => {
    dispatch(
      prependNavigationItem(
        {
          ...gateways,
        },
        "gateways"
      )
    );
  });
};

export const { setNavigation, resetNavigation } =
  navigationGatewaysSlice.actions;

export const selectNavigation = createSelector(
  [selectNavigationAll],
  (navigation) => {
    return navigation;
  }
);

export default navigationGatewaysSlice.reducer;
