import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class PushNotificationsService {
  addPushNotification = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/pushnotif/templates/`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  removePushNotification = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/pushnotif/templates/${data.original.pushnotif_template_id}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  editPushNotification = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/pushnotif/templates/${data.pushnotif_template_id}`, {
          is_active: Number(data.is_active),
        })
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const pushNotificationsService = new PushNotificationsService();
export default pushNotificationsService;
