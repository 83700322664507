import ConfigurationsConfig from "./configuration/ConfigurationsConfig";
import CronjobsConfig from "./cronjobs/CronjobsConfig";
import EntitiesAppConfig from "./entities/EntitiesAppConfig";
import Error_logsAppConfig from "./error_logs/Error_logsAppConfig";
import TableLabelsConfig from "./labels/TableLabelsConfig";
import LanguagesConfig from "./languages/LanguagesConfig";
import MenuConfig from "./menu/MenuConfig";
import SectionsConfig from "./sections/SectionsConfig";
import TemplatesConfig from "./templates/TemplatesConfig";
import UsersAppConfig from "./users/UsersAppConfig";
import Access_logsAppConfig from "./access_logs/Access_logsAppConfig";
import Smtp_ServersConfig from "./smtp/Smtp_ServersConfig";
import HelpCenterAppConfig from "./help_center/HelpCenterAppConfig";
import FaqsAppConfig from "./faqs/FaqsAppConfig";
import GuidesAppConfig from "./guides/GuidesAppConfig";
import ContactSupportAppConfig from "./contact_support/ContactSupportAppConfig";
import CentralNotificationsConfig from "./central_notifications/CentralNotificationsConfig";
import AccessLevelConfig from "./access_level/AccessLevelConfig";
import PushNotificationsConfig from "./push_notifications/PushNotificationsConfig";
import TablePropertiesConfig from "./table_properties/TablePropertiesConfig";

const systemConfig = [
  TableLabelsConfig,
  LanguagesConfig,
  CronjobsConfig,
  MenuConfig,
  Error_logsAppConfig,
  EntitiesAppConfig,
  UsersAppConfig,
  ConfigurationsConfig,
  TemplatesConfig,
  SectionsConfig,
  Access_logsAppConfig,
  Smtp_ServersConfig,
  HelpCenterAppConfig,
  FaqsAppConfig,
  GuidesAppConfig,
  ContactSupportAppConfig,
  CentralNotificationsConfig,
  AccessLevelConfig,
  PushNotificationsConfig,
  TablePropertiesConfig,
];

export default systemConfig;
