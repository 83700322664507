import { t } from "app/store/labels/globalLabels";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "rsuite";
import { selectProfile, setOpen } from "./store/profileSlice";
import { selectUser } from "app/store/userSlice";
import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import {
  getCountryByIso,
  getLanguageByCode,
} from "app/shared-components/Functions";
import format from "date-fns/format";
import { selectLanguages } from "app/store/i18nSlice";
import { useState } from "react";
import ProfileEdit from "./ProfileEdit";

const Profile = () => {
  const user = useSelector(selectUser);
  const { open } = useSelector(selectProfile);
  const languages = useSelector(selectLanguages);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const handleClose = () => {
    dispatch(setOpen(false));
    setEdit(false);
  };

  return (
    <Drawer
      backdrop={true}
      open={open}
      onClose={handleClose}
      className="w-[550px]"
    >
      <Drawer.Header>
        <Drawer.Title>{dispatch(t("common.myprofile"))}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="p-0">
        {!edit ? (
          <>
            <Box
              className="relative w-full h-136 sm:h-120 px-32 sm:px-48"
              sx={{
                backgroundColor: "background.paper",
              }}
            />
            <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
              <div className="w-full max-w-3xl">
                <div className="flex flex-auto items-end -mt-64">
                  <Avatar
                    sx={{
                      borderWidth: 4,
                      borderStyle: "solid",
                      borderColor: "background.default",
                      backgroundColor: "background.paper",
                      color: "text.secondary",
                    }}
                    className="w-128 h-128 text-64 font-bold"
                    src={user.data.picture_src}
                    alt={user.data.name}
                  >
                    {user.data.name?.charAt(0)}
                  </Avatar>
                  <div className="flex items-center ml-auto mb-4">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setEdit(true)}
                    >
                      <FuseSvgIcon size={20}>
                        heroicons-outline:pencil-alt
                      </FuseSvgIcon>
                      <span className="mx-8">{dispatch(t("common.edit"))}</span>
                    </Button>
                  </div>
                </div>

                <Typography className="mt-12 text-4xl font-bold truncate">
                  {user.data.name}
                </Typography>

                <Divider className="mt-16 mb-24" />

                <div className="flex flex-col space-y-20">
                  {user.data?.email && (
                    <div className="flex items-start">
                      <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
                      <div>
                        <Typography className="ml-24 leading-6 font-600">
                          {dispatch(t("common.email"))}
                        </Typography>
                        <div className="ml-24 leading-6">
                          <a
                            className="hover:underline text-primary-500"
                            href={`mailto: ${user.data.email}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {user.data.email}
                          </a>
                        </div>
                      </div>
                    </div>
                  )}

                  {user.data?.phone_number && (
                    <div className="flex items-start">
                      <FuseSvgIcon>heroicons-outline:phone</FuseSvgIcon>
                      <div>
                        <Typography className="ml-24 leading-6 font-600">
                          {dispatch(t("common.phone"))}
                        </Typography>
                        <div className="min-w-0 ml-24 space-y-4">
                          <div className="flex items-center leading-6">
                            <Box
                              className="hidden sm:flex w-24 h-16 overflow-hidden"
                              sx={{
                                background:
                                  "url('/assets/images/flags/flags.png') no-repeat 0 0",
                                backgroundSize: "24px 3876px",
                                backgroundPosition: getCountryByIso(
                                  user.data.phone_country_code
                                )?.flagImagePos,
                              }}
                            />

                            <div className="sm:ml-12 font-mono">
                              {
                                getCountryByIso(user.data.phone_country_code)
                                  ?.code
                              }
                            </div>

                            <div className="ml-10 font-mono">
                              {user.data.phone_number}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {user.data?.lang && (
                    <div className="flex items-start">
                      <FuseSvgIcon>heroicons-outline:globe</FuseSvgIcon>
                      <div>
                        <Typography className="ml-24 leading-6 font-600">
                          {dispatch(t("common.language"))}
                        </Typography>
                        <div className="min-w-0 ml-24 space-y-4">
                          <div className="flex items-center leading-6">
                            {getLanguageByCode(user.data.lang, languages)
                              ?.flag && (
                              <img
                                className="min-w-20 object-contain w-20 h-20"
                                src={
                                  getLanguageByCode(user.data.lang, languages)
                                    ?.flag
                                }
                                alt={
                                  getLanguageByCode(user.data.lang, languages)
                                    ?.flag
                                }
                              />
                            )}

                            <div className="ml-10 leading-6">
                              {
                                getLanguageByCode(user.data.lang, languages)
                                  ?.title
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {user.data?.workspace_name && (
                    <div className="flex items-start">
                      <FuseSvgIcon>material-outline:work_outline</FuseSvgIcon>
                      <div>
                        <Typography className="ml-24 leading-6 font-600">
                          {dispatch(t("common.workspace"))}
                        </Typography>
                        <div className="ml-24 leading-6">
                          {user.data.workspace_name}
                        </div>
                      </div>
                    </div>
                  )}

                  {user.data.access_level && (
                    <div className="flex items-start">
                      <FuseSvgIcon>material-outline:account_box</FuseSvgIcon>
                      <div>
                        <Typography className="ml-24 leading-6 font-600">
                          {dispatch(t("common.access_level"))}
                        </Typography>
                        <div className="ml-24 leading-6">
                          {user.data.access_level?.name}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex items-start">
                    <FuseSvgIcon>material-outline:security</FuseSvgIcon>
                    <div>
                      <Typography className="ml-24 leading-6 font-600">
                        {dispatch(t("common.2fa"))}
                      </Typography>
                      <div className="ml-24 leading-6">
                        {Boolean(user.data.signin_token_required)
                          ? dispatch(t("common.active"))
                          : dispatch(t("common.inactive"))}
                      </div>
                    </div>
                  </div>

                  {user.data.birthdate && (
                    <div className="flex items-start">
                      <FuseSvgIcon>heroicons-outline:cake</FuseSvgIcon>
                      <div>
                        <Typography className="ml-24 leading-6 font-600">
                          {dispatch(t("common.birthday"))}
                        </Typography>
                        <div className="ml-24 leading-6">
                          {format(new Date(user.data.birthdate), "MMMM d, y")}
                        </div>
                      </div>
                    </div>
                  )}

                  {user.data.email_signature && (
                    <div className="flex w-full">
                      <FuseSvgIcon>heroicons-outline:menu-alt-2</FuseSvgIcon>
                      <div>
                        <Typography className="ml-24 leading-6 font-600">
                          {dispatch(t("common.signature"))}
                        </Typography>
                        <div className="max-w-none w-full ml-24 prose dark:prose-invert whitespace-pre-wrap">
                          {user.data.email_signature}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <ProfileEdit isEdit={setEdit} />
        )}
      </Drawer.Body>
    </Drawer>
  );
};

export default Profile;
