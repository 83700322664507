const components = {};

export const registerComponent = (name, Component) => {
  components[name] = Component;
};

const ItemComponent = (props) => {
  const C = components[props.type];
  return C ? <C {...props} /> : null;
};

export default ItemComponent;
