import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Skeleton,
  Typography,
} from "@mui/material";
import { lighten } from "@mui/system";
import VoyagerGatewaysLocalization from "./VoyagerGatewaysLocalization";

const SkeletonVoyagerGateways = () => {
  return (
    <>
      <Card className="flex flex-col h-256 shadow">
        <CardHeader
          title={
            <Typography className="text-16 font-medium">
              <Skeleton variant="text" sx={{ width: 100 }} />
            </Typography>
          }
          action={
            <Skeleton
              className="mr-5"
              variant="rounded"
              sx={{ width: 10, height: 35, marginRigth: 5 }}
            />
          }
        />
        <CardContent className="flex flex-col flex-auto p-24">
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Skeleton variant="rounded" sx={{ width: 110, height: 96 }} />

            <Box
              sx={{ display: "flex", flexDirection: "column", marginLeft: 2 }}
            >
              <Typography
                className={`flex items-center space-x-6 text-13 break-words mt-10`}
              >
                <span className="flex border-solid border-8 rounded-full"></span>
                <span className="leading-none ml-2">
                  <Skeleton
                    variant="rounded"
                    sx={{ width: 41, fontSize: "1.3rem" }}
                  />
                </span>
              </Typography>
            </Box>
          </Box>
        </CardContent>

        <CardActions
          className="items-center justify-between py-16 px-24"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? lighten(theme.palette.background.paper, 0.4)
                : lighten(theme.palette.background.paper, 0.03),
          }}
        >
          <Typography className="flex flex-row items-center justify-center">
            <Skeleton variant="text" sx={{ fontSize: "1.5rem", width: 100 }} />
          </Typography>
          <Typography className="px-16 min-w-128">
            <Skeleton variant="text" sx={{ fontSize: "1.5rem", width: 100 }} />
          </Typography>
        </CardActions>
      </Card>
      <VoyagerGatewaysLocalization />
    </>
  );
};

export default SkeletonVoyagerGateways;
