import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { selectNavigationAll } from "app/store/fuse/navigationSlice";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getVersions = createAsyncThunk(
  "configurations/versions/getGroupsByNavigation",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get("/APIconfig");

      if (res.status === "error") throw new Error();

      return res.result.version;
    } catch (error) {
      return {};
    }
  }
);

export const setWebVersion = createAsyncThunk(
  "configurations/versions/setWebVersion",
  async (data, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.post(`/SET_VERSION/WEB_APP/save`, data);

      return { version: res };
    } catch (error) {}
  }
);

export const setAndroidVersion = createAsyncThunk(
  "configurations/versions/setAndroidVersion",
  async (data, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.post(`/SET_VERSION/ANDROID_APP/save`, data);

      return { version: res };
    } catch (error) {}
  }
);

export const setIosVersion = createAsyncThunk(
  "configurations/versions/setIosVersion",
  async (data, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.post(`/SET_VERSION/IOS_APP/save`, data);

      return { version: res };
    } catch (error) {}
  }
);

const versionsSlice = createSlice({
  name: "configurations/versions",
  initialState: {
    web: {},
    android: {},
    ios: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVersions.fulfilled, (state, action) => {
        state.web = action.payload.WEB_APP;
        state.android = action.payload.ANDROID_APP;
        state.ios = action.payload.IOS_APP;
      })
      .addCase(getVersions.rejected, (state) => {
        state.web = { version: null, updated: null };
        state.android = { version: null, updated: null };
        state.ios = { version: null, updated: null };
      })
      .addCase(setWebVersion.fulfilled, (state, action) => {
        state.web = { ...action.payload };
      })
      .addCase(setAndroidVersion.fulfilled, (state, action) => {
        state.android = { ...action.payload };
      })
      .addCase(setIosVersion.fulfilled, (state, action) => {
        state.ios = { ...action.payload };
      });
  },
});

export const selectVersions = ({ configurations }) => configurations.versions;

export default versionsSlice.reducer;
