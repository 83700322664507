import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const fetchSmtp_ServersList = createAsyncThunk(
  "smtp_servers/table/fetchSmtp_ServersList",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get("/smtp_servers");

      if (res.status === "error") throw new Error(res);

      return res.result;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const smtp_serversSlice = createSlice({
  name: "smtp_servers/table",
  initialState: {
    smtp_servers: [],
    status: "pending",
  },

  reducers: {
    setSmtp_servers: (state, action) => {
      state.smtp_servers = action.payload;
    },
    newSmtp_server: (state, action) => {
      state.smtp_servers.push(action.payload);
    },
    removeSmtp_server: (state, action) => {
      const index = action.payload;
      state.smtp_servers.splice(index, 1);
    },
    updateSmtp_server: (state, action) => {
      const { index, value } = action.payload;

      state.smtp_servers[index] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSmtp_ServersList.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(fetchSmtp_ServersList.fulfilled, (state, action) => {
        state.smtp_servers = action.payload;
        state.status = "success";
      }),
      builder.addCase(fetchSmtp_ServersList.rejected, (state, action) => {
        state.smtp_servers = [];
        state.status = "rejected";
      });
  },
});

export const {
  setSmtp_servers,
  newSmtp_server,
  removeSmtp_server,
  updateSmtp_server,
} = smtp_serversSlice.actions;

export const selectSmtp = ({ smtp_servers }) => smtp_servers.table.smtp_servers;
export const selectStatus = ({ smtp_servers }) => smtp_servers.table.status;

export default smtp_serversSlice.reducer;
