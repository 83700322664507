import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const versionSlice = createSlice({
  name: "version",
  initialState: {
    version: 0,
    versionAPI: 0,
    showBagde: false,
  },
  reducers: {
    setVersion: (state, action) => {
      state.version = action.payload;
    },
    setVersionAPI: (state, action) => {
      state.versionAPI = action.payload;
    },
    setBagde: (state, action) => {
      state.showBagde = action.payload;
    },
  },
  extraReducers: {},
});

export const selectVersion = ({ version }) => version.version;
export const selectVersionAPI = ({ version }) => version.versionAPI;
export const selectBagde = ({ version }) => version.showBagde;

export const { setVersion, setVersionAPI, setBagde } = versionSlice.actions;
export default versionSlice.reducer;
