import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectNavigation } from "./store/navigationAccess_logsSlice";
import { fetchAccessMenu, selectAccess } from "./store/access_logsSlice";
import { useThemeMediaQuery } from "@fuse/hooks";
import { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import Error_logAppHeader from "../error_logs/Errors_logAppHeader";
import Access_logs from "./Access_logs";
import reducer from "./store";
import withReducer from "app/store/withReducer";
import Access_logsSideBar from "./components/Access_logsSideBar";
import { useParams } from "react-router-dom";
import {
  fetchAccessTableList,
  selectAccessTable,
} from "./store/access_logTableSlice";
import Access_logsAppHeader from "./Access_logsAppHeader";

const Access_logsApp = () => {
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  const access_logs = useSelector(selectAccessTable);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const routeParams = useParams();

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);

  useEffect(() => {
    dispatch(fetchAccessMenu());
  }, [dispatch]);

  useEffect(() => {
    if (routeParams.id) {
      dispatch(fetchAccessTableList(routeParams.id));
    }
  }, [routeParams]);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <FusePageSimple
        header={
          <Access_logsAppHeader
            leftSidebar={(e) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
          />
        }
        content={
          <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
            {useMemo(() => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <Access_logs />
                    </motion.div>
                  </div>
                </motion.div>
              );
            }, [access_logs])}
          </div>
        }
        leftSidebarOpen={leftSidebarOpen}
        leftSidebarOnClose={() => {
          setLeftSidebarOpen(false);
        }}
        leftSidebarContent={<Access_logsSideBar navigation={navigation} />}
        scroll="content"
      />
    </>
  );
};

export default withReducer("access_logs", reducer)(Access_logsApp);
