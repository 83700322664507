import { combineReducers } from "@reduxjs/toolkit";
import globalLabels from "./globalLabels";
import logos from "./logosSlice";

const reducer = combineReducers({
  globalLabels,
  logos,
});

export default reducer;
