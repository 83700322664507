import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import MenuAppHeader from "./MenuAppHeader";
import _ from "@lodash";
import { motion } from "framer-motion";
import { useMemo } from "react";
import Menu from "./Menu";
import withReducer from "app/store/withReducer";
import reducer from "./store";
import { useDispatch, useSelector } from "react-redux";
import Groups from "./components/sidebars/Groups";
import { useThemeMediaQuery } from "@fuse/hooks";
import { useState } from "react";
import Workspaces from "./components/sidebars/Workspaces";
import { useEffect } from "react";
import { fetchGroups, selectGroups } from "./store/groupsSlice";
import { fetchWorkspaces } from "./store/workspacesSlice";
import {
  selectCurrentLanguage,
  selectCurrentLanguageId,
} from "app/store/i18nSlice";
import { fetchMenu, setLangMenu } from "./store/menuSlice";
import { selectNavigation as naviGroup } from "./store/navigationGroupsSlice";
import { selectNavigation as naviWork } from "./store/navigationWorkspacesSlices";

const MenuApp = () => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(!isMobile);
  const naviGroups = useSelector(naviGroup);
  const naviWorkspaces = useSelector(naviWork);
  const lang = useSelector(selectCurrentLanguageId);
  const langCurrent = useSelector(selectCurrentLanguage);

  useEffect(() => {
    Promise.all([
      dispatch(fetchMenu()),
      dispatch(setLangMenu(langCurrent)),
    ]).then((r) => {
      dispatch(fetchGroups());
      dispatch(fetchWorkspaces());
    });
  }, [dispatch, lang]);

  return (
    <FusePageSimple
      header={
        <MenuAppHeader
          leftSidebarToggle={(ev) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
          rightSidebarToggle={(ev) => {
            setRightSidebarOpen(!rightSidebarOpen);
          }}
        />
      }
      content={
        <div className="w-full h-full px-24 md:px-32 pb-24">
          {useMemo(() => {
            const container = {
              show: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            };

            const item = {
              hidden: { opacity: 0, y: 20 },
              show: { opacity: 1, y: 0 },
            };

            return (
              <>
                <motion.div
                  className="h-full w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <motion.div variants={item}>
                    <Menu />
                  </motion.div>
                </motion.div>
              </>
            );
          }, [])}
        </div>
      }
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarContent={<Groups navigation={naviGroups} />}
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      rightSidebarOpen={rightSidebarOpen}
      rightSidebarContent={<Workspaces navigation={naviWorkspaces} />}
      rightSidebarOnClose={() => {
        setRightSidebarOpen(false);
      }}
      scroll="content"
    />
  );
};

export default withReducer("menu", reducer)(MenuApp);
