import React, { useContext } from "react";
import { OnlineStatusContext } from "../context/OnlineStatusContext";
import { Button } from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

const MessageConnection = () => {
  const { snackbarMessage, isOnline } = useContext(OnlineStatusContext);

  return (
    <>
      {!isOnline && (
        <>
        <div className="snackbar w-full m-auto absolute   bg-white	 z-[999999] flex h-screen justify-center items-center">
          <div className="text-center	font-['Poppins']"><h3>OFFLINE</h3>Oops! It seems you've lost your internet connection.</div>
        </div> 
      </>
      )}
          </>

  );
};

export default MessageConnection;
