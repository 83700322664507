import _ from "@lodash";

const NewEndpointsModel = (obj) =>
  _.defaults(obj || {}, {
    name: "",
    url_tabs: "",
    description: "",
    max_url_tabs: 0,
    folder_path: "",
    is_active: false,
    open_access: false,
    must_have_session: false,
  });

export const EndpointsModelMerge = (data) =>
  _.mergeWith(
    {
      description: "",
      folder_path: "",
    },
    data,
    (objValue, srcValue, key) => {
      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export default NewEndpointsModel;
