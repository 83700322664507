import Typography from "@mui/material/Typography";
import {
  selectPageSubTitle,
  selectPageTitle,
} from "app/store/labels/globalLabels";
import { useDispatch, useSelector } from "react-redux";

const Smtp_ServersAppHeader = () => {
  const pageTitle = useSelector(selectPageTitle);
  const pageSubtitle = useSelector(selectPageSubTitle);

  return (
    <div className="flex w-full container">
      <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-24 md:p-32 pb-0 md:pb-0">
        <div className="flex flex-col mt-16">
          <Typography
            component="h2"
            className="flex-1 text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate mx-10"
          >
            {pageTitle}
          </Typography>
          <Typography
            component="h4"
            className=" flex-1 font-medium tracking-tight pl-10"
            color="text.secondary"
          >
            {pageSubtitle}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Smtp_ServersAppHeader;
