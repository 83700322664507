import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getEntitieType = createAsyncThunk(
  "entities/type/getEntitieType",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get("/entities/types");

      if (res.status === "error") return [];

      const obj = res.result.filter((item) => item.type_tag != "worker");

      return obj;
    } catch (error) {}
  }
);

const entittiesTypeSlice = createSlice({
  name: "entities/type",
  initialState: {
    types: [],
    status: "",
  },

  reducers: {
    setTypes: (state, action) => {
      state.types = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEntitieType.pending, (state) => {
      state.status = "pending";
    }),
      builder.addCase(getEntitieType.fulfilled, (state, action) => {
        state.types = action.payload;
        state.status = "success";
      }),
      builder.addCase(getEntitieType.rejected, (state) => {
        state.types = [];
        state.status = "rejected";
      });
  },
});

export const selectTypes = ({ entities }) => entities.type.types;

export default entittiesTypeSlice.reducer;
