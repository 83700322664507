import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createNavigation, resetNavigation } from "./navigationGatewaysSlice";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import MenuModal from "../modal/MenuModal";
import FuseUtils from "@fuse/utils/FuseUtils";

export const fetchGatewaysMenu = createAsyncThunk(
  "gateways/menu/fetchGatewaysMenu",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(resetNavigation());
      dispatch(setActiveGroup(null));
      const { user } = getState();

      const res = await axiosConfig.get(
        `/devices/${user.data.user_id}/place_groups`
      );

      if (res.status === "errors") return [];

      const finalMenu = res.result.map((item) => MenuModal(item));

      if (finalMenu.length > 0) dispatch(createNavigation(finalMenu));

      return finalMenu;
    } catch (error) {
      return [];
    }
  }
);

export const filterMenu = (text) => (dispatch, getState) => {
  const { groups } = getState().gateways.menu;

  dispatch(resetNavigation());

  if (text.length === 0) return dispatch(createNavigation(groups));

  const filteredGroups = FuseUtils.filterArrayByString([...groups], text);

  return dispatch(createNavigation(filteredGroups));
};

const gatewaysMenuSlice = createSlice({
  name: "gateways/menu",
  initialState: {
    groups: [],
    searchValue: [],
    filterGroup: [],
    activeGroup: null,
    status: "",
  },

  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setSearchValues: (state, action) => {
      state.searchValue = action.payload;
    },
    setActiveGroup: (state, action) => {
      const id = action.payload;

      if (id === "none" || !id) {
        state.activeGroup = null;
      } else {
        state.activeGroup = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGatewaysMenu.pending, (state) => {
      state.status = "pending";
    }),
      builder.addCase(fetchGatewaysMenu.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.status = "success";
      }),
      builder.addCase(fetchGatewaysMenu.rejected, (state) => {
        state.groups = [];
        state.status = "reject";
      });
  },
});

export const { setGroups, setActiveGroup, setSearchValues } =
  gatewaysMenuSlice.actions;

export const selectGroups = ({ gateways }) => gateways.menu.groups;
export const selectStatus = ({ gateways }) => gateways.menu.status;

export default gatewaysMenuSlice.reducer;
