import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import { Notifications } from "../model/NotificationsModel";

export const fetchNotificationsList = createAsyncThunk(
  "notifications/list/fetchNotificationsList",
  async (_, { dispatch, getSate }) => {
    try {
      const res = await axiosConfig.get("/notifications/preset/");

      if (res.state === "error") return [];

      const obj = res.result.map((item) => Notifications(item));

      return obj;
    } catch (error) {
      return [];
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications/list",
  initialState: {
    notifications: [],
    status: "idle",
  },

  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    newNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    deleteNotification: (state, action) => {
      const index = action.payload;

      state.notifications.splice(index, 1);
    },
    updateNotification: (state, action) => {

      const { index, value } = action.payload;

      state.notifications[index] = value;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchNotificationsList.pending, (state) => {
      state.status = "pending";
    }),
      builder.addCase(fetchNotificationsList.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.status = "fulfilled";
      }),
      builder.addCase(fetchNotificationsList.rejected, (state) => {
        state.notifications = [];
        state.status = "rejected";
      });
  },
});

export const {
  setNotifications,
  newNotification,
  deleteNotification,
  updateNotification,
} = notificationsSlice.actions;

export const selectNotifications = ({ notifications }) =>
  notifications.list.notifications;
export const selectStatus = ({ notifications }) => notifications.list.status;

export default notificationsSlice.reducer;
