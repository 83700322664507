const menu = [
  {
    id: "templates",
    title: "Template",
    type: "group",
    active: false,
    hasActions: true,
    children: [],
  },
];

export default menu;
