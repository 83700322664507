import React, { useEffect } from "react";
import VersionControl from "./VersionControl";
import { useParams } from "react-router-dom";
import ThemeChange from "./ThemeChange";
import PageSettings from "./PageSettings";
import Security from "./Security";
import CycleSettings from "./CycleSettings";
import { useDispatch } from "react-redux";
import SocialMedia from "./SocialMedia";
import ClientConfigs from "./ClientConfigs";

const SidebarRedirect = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  return (
    <div>
      {routeParams.id === "version" && <VersionControl />}
      {routeParams.id === "theme" && <ThemeChange />}
      {routeParams.id === "page-config" && <PageSettings />}
      {routeParams.id === "security" && <Security />}
      {routeParams.id === "cycle-settings" && <CycleSettings />}
      {routeParams.id === "social-network" && <SocialMedia />}
      {routeParams.id === "client-image" && <ClientConfigs />}
    </div>
  );
};

export default SidebarRedirect;
