import TablePropertiesApp from "./TablePropertiesApp";

const TablePropertiesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "system/table-properties",
      element: <TablePropertiesApp />,
      children: [
        {
          path: ":id",
          element: <TablePropertiesApp />,
        },
      ],
    },
  ],
};

export default TablePropertiesConfig;
