import _ from "@lodash";

const SocialMediaModel = (data) =>
  _.defaults(data || {}, {
    short: "",
    name: "",
    url: "",
  });

export default SocialMediaModel;
