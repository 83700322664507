import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "rsuite";
import { t } from "app/store/labels/globalLabels";
import {
  selectDrawerSlice,
  setDrawerSelector,
  setPropertyDrawerState,
} from "../../store/drawerSlice";

const DrawerForm = () => {
  const dispatch = useDispatch();
  const { propertyDrawerState, keyDrawerState, drawerSelector } =
    useSelector(selectDrawerSlice);

  const handleClose = () => {
    dispatch(setPropertyDrawerState(false));
    dispatch(setDrawerSelector(""));
  };

  return (
    <Drawer
      backdrop={true}
      open={drawerSelector === true ? propertyDrawerState : keyDrawerState}
      onClose={handleClose}
    >
      <Drawer.Header>
        <Drawer.Title>{dispatch(t("common.add"))}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {drawerSelector === true ? "propertyDrawerState" : "keyDrawerState"}
      </Drawer.Body>
    </Drawer>
  );
};

export default DrawerForm;
