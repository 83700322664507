import { authRoles } from "src/app/auth";
import MenuApp from "./MenuApp";

const MenuConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "system/menu",
      element: <MenuApp />,
    },
  ],
};

export default MenuConfig;
