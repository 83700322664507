import { combineReducers } from "@reduxjs/toolkit";
import navigationGateways from "./navigationGatewaysSlice";
import menu from "./gatewaysMenuSlice";
import cards from "./gatewaysSlice";
import drawer from "./drawerSlice";

const reducer = combineReducers({
  menu,
  navigationGateways,
  cards,
  drawer,
});

export default reducer;
