import _ from "@lodash";

const TablePropertiesModel = (obj) => {
  _.defaults(obj || {}, {
    property_tag: "",
    is_required: 0,
    input_type: "",
    preset_value: "",
    must_select_from_preset: 0,
    icon: "",
  });
};

export default TablePropertiesModel;
