import FuseUtils from "@fuse/utils/FuseUtils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import postsService from "../service/postsService";
import PostModel, {
  NewPost,
  PostGroupList,
  PostList,
  PostYup,
  PostYupDateTime,
  PostYupFile,
  PostYupItems,
  PostYupItemsCheck,
  PostYupNumber,
  PostYupText,
} from "../model/PostModel";
import _ from "@lodash";

export const getPosts = createAsyncThunk(
  "section/posts/getPosts",
  async (id, { dispatch, getState }) => {
    try {
      const { systemLanguages } = getState().i18n;
      const res = await axiosConfig.get(`/posts/sections/${id}/content`);

      if (res.status === "error") return [];
      const newItems = res.result.map((i) => PostList(i, systemLanguages));
      return newItems;
    } catch (error) {
      return [];
    }
  }
);

export const getPostsItems = createAsyncThunk(
  "section/posts/getPostsItems",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const { section } = getState().section.sections;
      const { systemLanguages } = getState().i18n;

      if (!section.post_template_id) return Promise.reject();

      const res = await axiosConfig.get(
        `/posts/templates/${section.post_template_id}/blocks/`
      );

      if (res.status === "error") throw new Error(res);

      const newItems = await res.result
        .map((i) => PostModel(i))
        .map((_i) => {
          if (!_i.features) return _i;

          if (_i.features.type == "image" || _i.features.type == "file")
            return PostYupFile(_i);

          if (_i.features.type == "url") return PostYupText(_i);

          if (_i.features.type == "textSimple") return PostYupText(_i);

          if (_i.features.type == "select") return PostYupItems(_i);

          if (_i.features.type == "radio") return PostYupItems(_i);

          if (_i.features.type == "checkbox") return PostYupItemsCheck(_i);

          if (_i.features.type == "datetime") return PostYupDateTime(_i);

          if (_i.features.type == "integer") return PostYupNumber(_i);

          return PostYup(_i, systemLanguages);
        });

      return newItems;
    } catch (error) {
      throw error;
    }
  }
);

export const createNewPost = createAsyncThunk(
  "section/posts/createNewPost",
  async (_, { dispatch, getState }) => {
    try {
      const { items } = getState().section.posts;
      const { systemLanguages } = getState().i18n;

      const values = await NewPost({}, systemLanguages);

      const updatedItems = await items.map((i) => {
        if (!i.features) return i;

        return {
          ...i,
          values: { ...i.values },
        };
      });

      return { items: updatedItems, post: values };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getPost = createAsyncThunk(
  "section/posts/getPost",
  async ({ section, post }, { dispatch, getState }) => {
    try {
      const { items } = getState().section.posts;
      const { systemLanguages } = getState().i18n;

      const res = await axiosConfig.get(
        `/posts/sections/${section}/content/${post}`
      );

      if (res.status === "error") throw new Error();

      const values = await PostList(res.result, systemLanguages);

      const updatedItems = await items.map((i) => {
        if (!i.features) return i;

        const type = i.features.type;
        const { name_tag } = i;

        if (type == "image" || type == "file")
          return {
            ...i,
            values: {
              [name_tag]: values.content[name_tag]?.src || null,
            },
          };

        if (type == "url")
          return {
            ...i,
            values: {
              [name_tag]: values.content[name_tag]?.value || "",
            },
          };

        if (type == "textSimple")
          return {
            ...i,
            values: {
              [name_tag]: values.content[name_tag]?.value || "",
            },
          };

        if (type == "select")
          return {
            ...i,
            values: {
              [name_tag]: values.content[name_tag]?.value || "",
            },
          };

        if (type == "radio")
          return {
            ...i,
            values: {
              [name_tag]: values.content[name_tag]?.value || "",
            },
          };

        if (type == "datetime")
          return {
            ...i,
            values: {
              [name_tag]: values.content[name_tag]?.value || null,
            },
          };

        if (type == "integer")
          return {
            ...i,
            values: {
              [name_tag]: values.content[name_tag]?.value || 0,
            },
          };

        const { features, ...rest } = values.content[name_tag]
          ? values.content[name_tag]
          : i.values[name_tag];

        if (type == "checkbox")
          return {
            ...i,
            values: {
              [name_tag]: { ...rest },
            },
          };

        return {
          ...i,
          values: {
            [name_tag]: { ...rest },
          },
        };
      });

      return { items: updatedItems, post: values };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addPost = createAsyncThunk(
  "section/posts/addPost",
  async ({ title_lang, data }, { dispatch, getState }) => {
    try {
      const {
        sections: { section },
        posts: { items },
      } = getState().section;

      const firstPost = await getPostsWithoutFile(data, items);

      const res = await postsService.addPost(section, firstPost, title_lang);

      if (res.status === "error") throw new Error(res);

      const filePosts = await getPostsWithFile(data, items);

      if (!_.isEmpty(filePosts)) {
        const resfiles = await postsService.addFiles(
          section,
          res.result.last_id,
          filePosts
        );

        if (resfiles.status === "error") throw new Error("error");

        const key = Object.keys(resfiles.result)[0];

        if (key === "error") throw new Error("error");

        const finalPosts = await getFinalPost(
          items,
          resfiles.result.success,
          firstPost
        );

        const resContent = await postsService.updatePost(
          section,
          res.result.last_id,
          finalPosts
        );

        if (resContent.status === "error") throw new Error(res);

        return finalPosts;
      } else {
        return filePosts;
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
);

const getPostsWithoutFile = (values, items) =>
  Object.keys(values)
    .map((v) => {
      const objPost = items.find((p) => p.name_tag == v);

      // objPost.features.type == "checkbox" && console.log(objPost, v, values);

      if (objPost.features.type == "image" || objPost.features.type == "file")
        return null;

      if (objPost.features.type == "url")
        return {
          [v]: {
            value: values[v],
            features: objPost.features,
          },
        };

      if (objPost.features.type == "textSimple")
        return {
          [v]: {
            value: values[v],
            features: objPost.features,
          },
        };

      if (objPost.features.type == "select")
        return {
          [v]: {
            value: values[v],
            features: objPost.features,
          },
        };

      if (objPost.features.type == "radio")
        return {
          [v]: {
            value: values[v],
            features: objPost.features,
          },
        };

      if (objPost.features.type == "datetime")
        return {
          [v]: {
            value: values[v],
            features: objPost.features,
          },
        };

      if (objPost.features.type == "integer")
        return {
          [v]: {
            value: values[v],
            features: objPost.features,
          },
        };

      return {
        [v]: {
          ...values[v],
          features: objPost.features,
        },
      };
    })
    .reduce((result, obj) => {
      return { ...result, ...obj };
    }, {});

const getPostsWithFile = (values, items) => {
  const result = {};

  Object.keys(values).forEach((key) => {
    const objPost = items.find((p) => p.name_tag === key);

    if (
      (objPost.features.type === "image" || objPost.features.type === "file") &&
      values[key] !== undefined &&
      values[key] !== null &&
      values[key] instanceof File
    ) {
      result[key] = values[key];
    }
  });
  return result;
};

const controlFiles = async (values, items, section, post) => {
  const result = {};

  for (const key of Object.keys(values)) {
    const handleFiles = async () => {
      const objPost = items.find((p) => p.name_tag === key);

      if (
        (objPost.features.type === "image" ||
          objPost.features.type === "file") &&
        values[key] !== undefined &&
        values[key] !== null
      ) {
        if (values[key] instanceof File) {
          if (objPost.values[key]) {
            const res = await postsService.deleteSingleFile(
              section,
              post,
              objPost.values[key].substring(
                objPost.values[key].lastIndexOf("/") + 1
              )
            );
            if (res.status === "error") return null;

            const resFile = await postsService.addSingleFile(section, post, {
              [key]: values[key],
            });

            const keyRes = Object.keys(resFile.result)[0];

            if (keyRes === "error") return null;

            result[key] = {
              src: resFile.result.success[0].src,
              features: objPost.features,
            };
            return;
          } else {
            const resFile = await postsService.addSingleFile(section, post, {
              [key]: values[key],
            });

            const keyRes = Object.keys(resFile.result)[0];

            if (keyRes === "error") return null;

            result[key] = {
              src: resFile.result.success[0].src,
              features: objPost.features,
            };
            return;
          }
        } else {
          result[key] = {
            src: objPost.values[key],
            features: objPost.features,
          };
        }
      } else if (
        (objPost.features.type === "image" ||
          objPost.features.type === "file") &&
        objPost.values[key]
      ) {
        const res = await postsService.deleteSingleFile(
          section,
          post,
          objPost.values[key].substring(
            objPost.values[key].lastIndexOf("/") + 1
          )
        );
      }
    };

    await handleFiles();
  }

  return result;
};

const getFinalPost = (items, response, firstPost) => {
  const values = response
    .map((src) => {
      const objPost = items.find((p) => p.name_tag == src.key);

      return {
        [src.key]: {
          src: src.src,
          features: objPost.features,
        },
      };
    })
    .reduce((result, obj) => {
      return { ...result, ...obj };
    }, {});

  return { ...firstPost, ...values };
};

export const updatePost = createAsyncThunk(
  "section/posts/updatePost",
  async ({ title_lang, data }, { dispatch, getState }) => {
    try {
      const {
        sections: { section },
        posts: { items, post },
      } = getState().section;

      const firstPost = await getPostsWithoutFile(data, items);

      const filePosts = await controlFiles(data, items, section, post);

      const res = await postsService.updatePost(
        section,
        post.post_content_id,
        {
          ...firstPost,
          ...filePosts,
        },
        title_lang
      );

      if (res.status === "error") throw new Error(res);

      return res;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
);

export const removePost = createAsyncThunk(
  "section/posts/removePost",
  async (data, { dispatch, getState }) => {
    try {
      const {
        sections: { section },
        posts: { post },
      } = getState().section;
      const res = await postsService.deletePost(section, post);
      return post;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const reorderPosts = createAsyncThunk(
  "section/posts/reorderPosts",
  async ({ sourceIndex, destinationIndex }, { dispatch, getState }) => {
    try {
      const {
        sections: { section },
        posts: { posts },
      } = getState().section;

      const updatedList = [...posts];
      const [removed] = updatedList.splice(sourceIndex, 1);
      updatedList.splice(destinationIndex, 0, removed);
      dispatch(setPosts(updatedList));

      const res = await postsService.reorderPosts(section, updatedList);

      return updatedList;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const changeActive = createAsyncThunk(
  "section/posts/changeActive",
  async (data, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.post(
        `/posts/sections/${data.post_section_id}/content/${data.post_content_id}`,
        { is_active: data.is_active }
      );

      if (res.status === "error") throw new Error();

      return data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
);

export const filterPosts = createAsyncThunk(
  "section/posts/filterPosts",
  async (text, { dispatch, getState }) => {
    const { posts, postsFilter } = getState().section.posts;

    if (text.length === 0) return { posts: postsFilter, text: "" };

    const filteredGroups = FuseUtils.filterArrayByString(
      [...postsFilter],
      text
    );

    return { posts: filteredGroups, text: text };
  }
);

export const getGroup = createAsyncThunk(
  "section/posts/getGroup",
  async ({ section, post }, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(
        `/posts/sections/${section}/content/${post}`
      );

      return PostGroupList(res.result);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
);

export const addGroup = createAsyncThunk(
  "section/posts/addGroup",
  async (data, { dispatch, getState }) => {
    try {
      const {
        sections: { section },
        posts: { items },
      } = getState().section;

      const res = await postsService.addGroup(section, data);

      return data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
);

export const updateGroup = createAsyncThunk(
  "section/posts/updateGroup",
  async (data, { dispatch, getState }) => {
    try {
      const {
        sections: { section },
        posts: { items, post },
      } = getState().section;

      const res = await postsService.updateGroup(section, post, data);

      return data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
);

const postsSlice = createSlice({
  name: "section/posts",
  initialState: {
    posts: [],
    post: {},
    items: [],
    schemas: {},
    values: {},
    status: "pending",
    statusPosts: "pending",
    postsFilter: [],
    postsText: "",
    error: null,
  },
  reducers: {
    newPost: (state, action) => {
      state.post = {};
      state.values = {};
      state.error = null;
      state.status = "fulfilled";
    },
    resetPost: (state) => {
      state.post = {};
      state.values = {};
      state.schemas = {};
      state.error = null;
      state.status = "idle";
      state.postsText = "";
    },
    resetPosts: (state) => {
      state.posts = [];
      state.error = null;
    },
    setPosts: (state, action) => {
      state.posts = action.payload;
      state.postsFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPosts.pending, (state) => {
        state.statusPosts = "pending";
      })
      .addCase(getPosts.fulfilled, (state, action) => {
        state.error = null;
        state.posts = action.payload;
        state.postsFilter = action.payload;
        state.statusPosts = "fulfilled";
      })
      .addCase(getPosts.rejected, (state, action) => {
        state.posts = [];
        state.statusPosts = "rejected";
      })
      .addCase(getPost.fulfilled, (state, action) => {
        // console.log(action.payload);
        const { items, post } = action.payload;
        state.error = null;

        state.values = items.reduce((result, obj) => {
          return { ...result, ...obj.values };
        }, {});

        state.items = items;
        state.post = post;
        state.status = "fulfilled";
      })
      .addCase(createNewPost.fulfilled, (state, action) => {
        // console.log(action.payload);
        const { items, post } = action.payload;
        state.error = null;

        state.values = items.reduce((result, obj) => {
          return { ...result, ...obj.values };
        }, {});

        state.items = items;
        state.post = post;
        state.status = "fulfilled";
      })
      .addCase(getPostsItems.fulfilled, (state, action) => {
        state.error = null;
        state.schemas = action.payload.reduce((result, obj) => {
          return { ...result, ...obj.schema };
        }, {});

        state.values = action.payload.reduce((result, obj) => {
          return { ...result, ...obj.values };
        }, {});

        state.items = action.payload;
      })
      .addCase(getPostsItems.rejected, (state, action) => {
        state.schemas = {};
        state.values = {};
        state.items = [];
        state.error = action.error.message;
      })
      .addCase(changeActive.fulfilled, (state, action) => {
        const index = state.posts.findIndex(
          (p) => p.post_content_id == action.payload.post_content_id
        );

        state.posts[index] = action.payload;
        state.postsFilter[index] = action.payload;
      })
      .addCase(filterPosts.fulfilled, (state, action) => {
        const { text, posts } = action.payload;
        state.postsText = text;
        state.posts = posts;
      })
      .addCase(removePost.fulfilled, (state, action) => {
        const index = state.posts.findIndex(
          (p) => p.post_content_id == action.payload.post_content_id
        );

        state.posts.splice(index, 1);
        state.postsFilter.splice(index, 1);
      })
      .addCase(getGroup.fulfilled, (state, action) => {
        state.post = action.payload;
        state.status = "fulfilled";
      });
  },
});

export const { newPost, resetPost, resetPosts, setPosts } = postsSlice.actions;

export const selectPosts = ({ section }) => section.posts.posts;
export const selectPost = ({ section }) => section.posts.post;
export const selectItems = ({ section }) => section.posts.items;
export const selectSchemas = ({ section }) => section.posts.schemas;
export const selectValues = ({ section }) => section.posts.values;
export const selectPostStatus = ({ section }) => section.posts.status;
export const selectPostError = ({ section }) => section.posts.error;
export const selectPostsText = ({ section }) => section.posts.postsText;

export const selectAll = ({ section }) => section.posts;

export default postsSlice.reducer;
