import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Button from "@mui/material/Button";
import { forwardRef } from "react";
import clsx from "clsx";
import MediaInput from "./MediaInput";
import SocialMediaModel from "./model/SocialMediaModel";
import { Typography } from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import { useDispatch } from "react-redux";

const Selector = forwardRef(({ value, onChange, className }, ref) => {
  const dispatch = useDispatch();


  return (
    <div className={clsx("w-full", className)} ref={ref}>
      {value?.map((item, index) => (
        <MediaInput
          value={item}
          key={index}
          onChange={(val) => {
            onChange(
              value.map((_item, _index) => (index === _index ? val : _item))
            );
          }}
          onRemove={(val) => {
            onChange(value.filter((_item, _index) => index !== _index));
          }}
        />
      ))}
      <div className="w-full flex justify-start items-center">
        <Button
          className="whitespace-nowrap mt-24"
          onClick={() => onChange([...value, SocialMediaModel()])}
        >
          <FuseSvgIcon size={20}>heroicons-solid:plus-circle</FuseSvgIcon>
          <Typography className="mx-8">{dispatch(t("common.add"))}</Typography>
        </Button>
      </div>
    </div>
  );
});

export default Selector;
