import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "menu/drawer",
  initialState: {
    drawerMenu: false,
    drawerGroup: false,
    drawerWorkspace: false,
  },
  reducers: {
    setOpenDrawer: (state, action) => {
      state.drawerMenu = action.payload;
    },
    setDrawerGroup: (state, action) => {
      state.drawerGroup = action.payload;
    },
    setDrawerWorkspace: (state, action) => {
      state.drawerWorkspace = action.payload;
    },
  },
  extraReducers: {},
});

export const { setOpenDrawer, setDrawerGroup, setDrawerWorkspace } =
  drawerSlice.actions;

export const selectOpenMenu = ({ menu }) => menu.drawer.drawerMenu;
export const selectOpenGroup = ({ menu }) => menu.drawer.drawerGroup;
export const selectOpenWorkspace = ({ menu }) => menu.drawer.drawerWorkspace;

export default drawerSlice.reducer;
