const menu = [
  {
    id: "access_logs",
    title: "Access Logs",
    type: "group",
    hasActions: true,
    children: [],
  },
];

export default menu;
