import FuseUtils from "@fuse/utils/FuseUtils";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import menu from "../config/workspaceMenu";
import { t } from "app/store/labels/globalLabels";

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(emptyInitialState, menu);

export const changeLanguage = () => (dispatch, getState) => {
  dispatch(
    updateNavigationItem("none", {
      title: dispatch(t("common.all")),
    })
  );
};

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

const prependNavigationItem = (item, parentId) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(
    setNavigation(FuseUtils.appendNavItem(navigation, item, parentId))
  );
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const {
  selectAll: selectNavigationAll,
  selectIds: selectNavigationIds,
  selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors((state) => state.menu.navigationWorkspaces);

const navigationWorkspacesSlice = createSlice({
  name: "menu/navigationWorkspaces",
  initialState,
  reducers: {
    setNavigation: navigationAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },
  extraReducers: {},
});

export const createNavigation = (items) => async (dispatch, getState) => {
  items.map((i, index) => {
    dispatch(
      prependNavigationItem(
        {
          ...i,
          id: `${i.workspace_id}`,
          title: i.name,
          type: "item",
          hasActions: true,
          badge: {
            title: i.total_users,
            classes: "h-20 text-white rounded-full justify-center",
          },
        },
        "workspaces"
      )
    );
  });
};

export const { setNavigation, resetNavigation } =
  navigationWorkspacesSlice.actions;

export const selectNavigation = createSelector(
  [selectNavigationAll],
  (navigation) => {
    return navigation;
  }
);

export default navigationWorkspacesSlice.reducer;
