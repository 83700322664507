import PushNotificationsApp from "./PushNotificationsApp";

const PushNotificationsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "system/push-notifications",
      element: <PushNotificationsApp />,
    },
  ],
};

export default PushNotificationsConfig;
