import _ from "@lodash";

const BlockNewModel = (data) =>
  _.defaults(data || {}, {
    post_template_id: null,
    post_template_in: null,
    name: "",
    name_tag: "",
    name_lang: "",
    features: "",
  });

export const BlockModel = (data) =>
  _.defaults(data || {}, {
    post_template_id: data.last_id,
    post_template_in: data.post_template_id,
    name: "",
    name_tag: "",
    name_lang: "",
    features: "",
  });

export const BlockModelMerge = (data) =>
  _.mergeWith(
    {
      features: "",
      name_lang: "",
    },
    data,
    (objValue, srcValue, key) => {
      if (key == "features" || key == "name_lang")
        return srcValue ? JSON.parse(srcValue) : null;

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export const BlockModelDemerge = (data) =>
  _.mergeWith(
    {
      features: "",
    },
    data,
    (objValue, srcValue, key) => {
      if (key == "features") return JSON.stringify(srcValue);

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export default BlockNewModel;
