import { lazy } from "react";
import { Navigate } from "react-router-dom";
import SectionForm from "./components/SectionForm";
import SelectItem from "./components/SelectItem";

import PostForm from "./components/posts/PostForm";
import GroupForm from "./components/groups/GroupForm";

const SectionsApp = lazy(() => import("./SectionsApp"));

const SectionsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/system/sections",
      children: [
        {
          path: "",
          element: <Navigate to="/system/sections/none" />,
        },
        {
          path: ":section",
          element: <SectionsApp />,
          children: [
            {
              path: "",
              element: <SelectItem />,
            },
            {
              path: ":post/post",
              element: <PostForm />,
            },
            {
              path: ":post/group",
              element: <GroupForm />,
            },
            {
              path: ":form/form",
              element: <SectionForm />,
            },
          ],
        },
      ],
    },
  ],
};

export default SectionsConfig;
