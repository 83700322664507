import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  EntityEmail,
  removeEntityEmail,
  selectEntity,
} from "../../store/entitySlice";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

const EmailInput = (props) => {
  const { value, hideRemove } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const entity = useSelector(selectEntity);
  const routeParams = useParams();

  const schema = yup.object().shape({
    email_address: yup.string().email(dispatch(t("common.set_valid_email"))),
    label: yup.string().nullable(),
  });

  const defaultValues = {
    email_address: "",
    label: "",
  };

  const { control, formState, handleSubmit, reset, setValue, getValues } =
    useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    reset(value);
  }, [reset, value]);

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = (data) => {
    const obj = {
      ...data,
      entity_id: entity.entity_id,
      is_main: data.is_main ? 1 : 0,
    };

    props.onChange(obj);

    dispatch(EntityEmail(obj)).then(({ payload }) => {
      if (payload === "success") {
        navigate(
          `/system/entities/${routeParams.type}/${entity.entity_id}/edit`
        );
      }
    });
  };

  const handleDelete = () => {
    if (value.email_address != "") {
      dispatch(removeEntityEmail(value)).then(({ payload }) => {
        if (payload === "success") props.onRemove(value);
        navigate(
          `/system/entities/${routeParams.type}/${entity.entity_id}/edit`
        );
      });
    } else {
      props.onRemove();
    }
  };

  return (
    <>
      <form
        onClick={() => handleSubmit(onSubmit)}
        className="w-full flex space-x-5 h-full px-24 sm:px-48"
      >
        <Controller
          name="is_main"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <Tooltip
              title={
                value
                  ? dispatch(t("common.main"))
                  : dispatch(t("common.set_main"))
              }
            >
              <Radio
                {...field}
                className="mt-44"
                name="email-address-entities"
                checked={Boolean(value)}
                onChange={(e) => {
                  onChange(e.target.checked);
                  props.onChecked(e.target.checked);
                }}
              />
            </Tooltip>
          )}
        />

        <Controller
          control={control}
          name="email_address"
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32 w-1/2"
              label="Email"
              placeholder="Email"
              id="email_address"
              error={!!errors.email_address}
              helperText={errors?.email_address?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-solid:mail</FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="label"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              value={field.value || ""}
              id="label"
              label={dispatch(t("common.label"))}
              placeholder={dispatch(t("common.label"))}
              variant="outlined"
              error={!!errors.label}
              helperText={errors?.label?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-solid:tag</FuseSvgIcon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <div className="flex flex-row">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      disabled={_.isEmpty(dirtyFields) || !isValid}
                      onClick={handleSubmit(onSubmit)}
                    >
                      <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                    </IconButton>

                    {!hideRemove && (
                      <IconButton
                        className="flex flex-col"
                        onClick={handleDelete}
                      >
                        <FuseSvgIcon size={20}>
                          heroicons-solid:trash
                        </FuseSvgIcon>
                      </IconButton>
                    )}
                  </div>
                ),
              }}
            />
          )}
        />
      </form>
    </>
  );
};

export default EmailInput;
