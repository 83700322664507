import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class AccessLevelService {
  createEndpoint = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/APIendpoints/`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  deleteEndpoint = (id) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/APIendpoints/${id}`)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  editEndpoint = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/APIendpoints/${data.APIendpoint_id}`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  stateEndpoint = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/APIendpoints/${data.endpoint.APIendpoint_id}`, {
          is_active: data.is_active,
        })
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  activeRelation = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/access_level/${data.access_level_id}/APIendpoint/${data.endpoint.APIendpoint_id}`,
          {
            is_active: data.is_active,
          }
        )
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  deactivateRelation = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/access_level/${data.access_level_id}/APIendpoint/${data.endpoint.APIendpoint_id}`,
          {
            is_active: data.is_active,
          }
        )
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  removeRelation = (obj) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(
          `/access_level/${obj.access_level_id}/APIendpoint/${obj.endpoint.APIendpoint_id}`
        )
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  editRelation = (data) => {
    const _data = {
      ...data,
      description: data.obj.description,
      request_exceptions: data.obj.request_exceptions,
      request_method_exceptions: data.obj.request_method_exceptions,
      request_permissions: data.obj.request_permissions,
      response_exceptions: data.obj.response_exceptions,
    };

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(
          `/access_level/${data.access_level_id}/APIendpoint/${data.obj.APIendpoint_id}`,
          _data
        )
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const accessLevelService = new AccessLevelService();
export default accessLevelService;
