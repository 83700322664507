import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getWorkspace = createAsyncThunk(
  "usersApp/workspace/getWorkspace",
  async (params, { getState }) => {
    try {
      const res = await axiosConfig.get(`/workspaces`);

      if (res.status === "error") return [];

      const data = await res.result.map((a) => {
        return {
          ...a,
          id: a.workspace_id,
        };
      });

      return data;
    } catch (error) {}
  }
);

const workspaceAdapter = createEntityAdapter({});

export const { selectAll: selectWorkspace, selectById: selectWorkspaceById } =
  workspaceAdapter.getSelectors((state) => state.usersApp.workspace);

const workspaceSlice = createSlice({
  name: "usersApp/workspace",
  initialState: workspaceAdapter.getInitialState([]),
  reducers: {},
  extraReducers: {
    [getWorkspace.fulfilled]: workspaceAdapter.setAll,
  },
});

export default workspaceSlice.reducer;
