import axiosConfig from "src/app/auth/services/axios/axiosConfig";

class TemplatesService {
  addTemplate = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/posts/templates`, data)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  updateTemplate = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/posts/templates/${data.id}`, data)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };

  deleteTemplate = (data) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .delete(`/posts/templates/${data.id}`)
        .then((r) => {
          if (r.status === "error") return reject(r);

          return resolve(r);
        })
        .catch((err) => reject(err));
    });
  };
}

const templatesService = new TemplatesService();
export default templatesService;
