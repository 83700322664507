import { Drawer } from "rsuite";
import { useSelector } from "react-redux";
import {
  selectDrawerState,
  selectEditSmtp,
  setDrawerState,
  setEditSmtp,
} from "../store/drawerSlice";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "app/store/labels/globalLabels";
import { Controller, useForm } from "react-hook-form";
import { newSmtp_server, updateSmtp_server } from "../store/smtp_serversSlice";
import { Button, TextField } from "@mui/material";
import { useEffect } from "react";
import * as yup from "yup";
import smtp_serverService from "../service/Smtp_serverService";
import _ from "@lodash";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";

const DrawerMenu = (props) => {
  const dispatch = useDispatch();
  const open = useSelector(selectDrawerState);
  const smtp = useSelector(selectEditSmtp);
  const index = props.smtp.index;

  const schema = yup.object().shape({
    server_name: yup.string().required(dispatch(t("common.input_required"))),
    host: yup.string().required(dispatch(t("common.input_required"))),
    username: yup.string().required(dispatch(t("common.input_required"))),
    port: yup.string().required(dispatch(t("common.input_required"))),
    SMTPSecure: yup.string().required(dispatch(t("common.input_required"))),
    default_name: yup.string().required(dispatch(t("common.input_required"))),
  });

  const { control, formState, handleSubmit, reset, setValue, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  useEffect(() => {
    reset({ ...smtp });
  }, [smtp, reset]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleClose = () => {
    reset();
    dispatch(setDrawerState(false));
    dispatch(setEditSmtp(""));
  };

  const submit = async (obj) => {
    try {
      const res = await smtp_serverService.addSmtp_server(obj);

      const _obj = {
        ...obj,
        server_id: res.result.last_id,
        is_main: false,
      };

      dispatch(newSmtp_server(_obj));
    } catch (error) {}

    handleClose();
  };

  const edit = async (obj) => {
    const id = obj.server_id;
    try {
      const res = await smtp_serverService.editSmtp_server(id, obj);

      dispatch(updateSmtp_server({ index: index, value: obj }));
    } catch (error) {}

    handleClose();
  };

  return (
    <>
      <Drawer backdrop={true} open={open} onClose={handleClose}>
        <Drawer.Header>
          <Drawer.Title>
            {smtp?.server_id
              ? dispatch(t("common.edit"))
              : dispatch(t("common.add"))}
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          {_.isEmpty(form) || !smtp ? (
            <FuseLoading />
          ) : (
            <form
              name="smtpForm"
              className="mt-20"
              noValidate
              onSubmit={
                smtp?.server_id ? handleSubmit(edit) : handleSubmit(submit)
              }
            >
              <Controller
                name="server_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label={dispatch(t("common.server_name"))}
                    type="string"
                    error={!!errors.server_name}
                    helperText={errors?.server_name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                name="host"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Host"
                    type="string"
                    error={!!errors.host}
                    helperText={errors?.host?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label={dispatch(t("common.user"))}
                    type="string"
                    error={!!errors.username}
                    helperText={errors?.username?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label={dispatch(t("common.password"))}
                    type="password"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
              <Controller
                name="port"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label={dispatch(t("common.port"))}
                    type="string"
                    error={!!errors.port}
                    helperText={errors?.port?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
              <Controller
                name="SMTPSecure"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label={dispatch(t("common.protocol"))}
                    type="string"
                    error={!!errors.SMTP_secure}
                    helperText={errors?.SMTP_secure?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
              <Controller
                name="default_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label={dispatch(t("common.default_name"))}
                    type="string"
                    error={!!errors.default_name}
                    helperText={errors?.default_name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
              <Button
                variant="contained"
                color="secondary"
                className="mt-20 mb-10"
                disabled={_.isEmpty(dirtyFields) || !isValid}
                onKeyPress={handleKeyPress}
                type="submit"
                fullWidth
              >
                {smtp?.server_id
                  ? dispatch(t("common.edit"))
                  : dispatch(t("common.add"))}
              </Button>
            </form>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default DrawerMenu;
