import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getAccess = createAsyncThunk(
  "usersApp/access/getAccess",
  async (params, { getState }) => {
    try {
      const res = await axiosConfig.get(`/access_level`);

      if (res.status === "error") return [];

      const data = await res.result.map((a) => {
        return {
          ...a,
          id: a.access_level_id,
        };
      });

      return data;
    } catch (error) {}
  }
);

const accessAdapter = createEntityAdapter({});

export const { selectAll: selectAccess, selectById: selectAccessById } =
  accessAdapter.getSelectors((state) => state.usersApp.access);

const accessSlice = createSlice({
  name: "usersApp/access",
  initialState: accessAdapter.getInitialState([]),
  reducers: {},
  extraReducers: {
    [getAccess.fulfilled]: accessAdapter.setAll,
  },
});

export default accessSlice.reducer;
