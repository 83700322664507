import _ from 'lodash';

const FaqsModel = data =>
  _.mergeWith(
    {
      title_lang: '',
      content: '',
    },
    data,
    (objValue, srcValue, key) => {
      if (key == 'content' || key == 'title_lang' || key == 'name_lang')
        return JSON.parse(srcValue);

      if (srcValue == null || srcValue == undefined) {
        return objValue;
      }
    },
  );

export default FaqsModel;
