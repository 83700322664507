import FuseScrollbars from "@fuse/core/FuseScrollbars";
import FuseSettings from "@fuse/core/FuseSettings";
import FuseSettingsViewerDialog from "app/theme-layouts/shared-components/FuseSettingsViewerDialog";
import React from "react";
import { useDispatch } from "react-redux";

const PageSettings = () => {
  const dispatch = useDispatch();

  return (
    <FuseScrollbars className="p-16 sm:p-32">
      <FuseSettings />
      <FuseSettingsViewerDialog className="mt-32" />
    </FuseScrollbars>
  );
};

export default PageSettings;
