import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Typography from "@mui/material/Typography";
import FaqList from "./FaqList";
import { getFaqs, selectItems } from "./store/faqsSlice";
import { t } from "app/store/labels/globalLabels";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import _ from "@lodash";

function Faqs() {
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const lang = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    dispatch(getFaqs());
  }, [dispatch]);

  return (
    <div className="flex flex-col items-center p-24 sm:p-40">
      <div className="flex flex-col w-full max-w-4xl">
        <div className="sm:mt-32">
          <Button
            component={Link}
            to={"/system/help_center"}
            color="secondary"
            startIcon={
              <FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>
            }
          >
            {dispatch(t("common.back_to"))} {dispatch(t("common.helpcenter"))}
          </Button>
        </div>
        <div className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
          {dispatch(t("SYSTEM.helpcenter_frequently_asked"))}
        </div>

        {items.map(
          (i) =>
            !_.isEmpty(i.subitems) && (
              <div key={i.post_section_id}>
                <Typography className="mt-48 sm:mt-64 text-3xl font-bold leading-tight tracking-tight">
                  {i.name_lang[lang]}
                </Typography>
                <FaqList className="w-full mt-32" list={i.subitems} />
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default Faqs;
