import VoyagerDevicesConfig from "./voyager/VoyagerDevicesConfig";

const devicesConfig = [VoyagerDevicesConfig];

export default devicesConfig;

// import { lazy } from "react";

// const DevicesApp = lazy(() => import("./DevicesApp"));

// const DevicesConfig = {
//   settings: {
//     layout: {},
//   },
//   routes: [
//     {
//       path: "user/devices",
//       element: <DevicesApp />,
//     },
//   ],
// };

// export default DevicesConfig;
