import { createContext, useState } from "react"

export const UserContext = createContext()

export const UserContextProvider = ({children}) => {
    const [userEmail, setUserEmail] = useState(null)
    const [userPassword, setUserPassword] = useState(null)
    
  return (
    <UserContext.Provider value={{userEmail, setUserEmail, userPassword, setUserPassword}}>
        {children}
    </UserContext.Provider>
  )
}