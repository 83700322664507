import { useThemeMediaQuery } from "@fuse/hooks";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { selectNavigation } from "./store/navigationGatewaysSlice";
import { fetchGatewaysMenu } from "./store/gatewaysMenuSlice";
import { fetchGateways, selectGateways } from "./store/gatewaysSlice";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import GatewaysHeader from "./GatewaysHeader";
import Gateways from "./Gateways";
import GatewaysSidebar from "./components/GatewaysSidebar";
import withReducer from "app/store/withReducer";
import reducer from "./store";

const GatewaysApp = () => {
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const gateways = useSelector(selectGateways);

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);

  useEffect(() => {
    Promise.all([dispatch(fetchGatewaysMenu())]).then(() =>
      dispatch(fetchGateways())
    );
  }, [dispatch]);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <FusePageSimple
        header={
          <GatewaysHeader
            leftSidebar={(e) => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
          />
        }
        content={
          <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
            {useMemo(() => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full ">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <Gateways />
                    </motion.div>
                  </div>
                </motion.div>
              );
            }, [gateways])}
          </div>
        }
        leftSidebarOpen={leftSidebarOpen}
        leftSidebarOnClose={() => {
          setLeftSidebarOpen(false);
        }}
        leftSidebarContent={<GatewaysSidebar navigation={navigation} />}
      />
    </>
  );
};

export default withReducer("gateways", reducer)(GatewaysApp);
