import Guides from "./Guides";
import GuidesApp from "./GuidesApp";

const GuidesAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "system/guides",
      element: <GuidesApp />,
      children: [
        {
          path: "",
          element: <Guides />,
        },
      ],
    },
  ],
};

export default GuidesAppConfig;
