import axios from "axios";
import axiosService from "./axiosService";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import { t } from "app/store/labels/globalLabels";
import history from "@history";
import { setBagde, setVersion, setVersionAPI } from "app/store/versionSlice";
import { logoutUser, selectUser } from "app/store/userSlice";

const BASE_URL =
  process.env.REACT_APP_API_URL === "live"
    ? process.env.REACT_APP_API_LIVE_URL
    : process.env.REACT_APP_API_DEV_URL;

const API_KEY = process.env.REACT_APP_API_KEY;

const axiosConfig = axios.create({
  baseURL: BASE_URL,
  headers: {
    // "Content-Type": "multipart/form-data",
    "api-key": API_KEY,
  },
});
// const axiosConfig = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     "Content-Type": "multipart/form-data",
//     "api-key": API_KEY,
//   },
// });

export const axiosInitial = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "api-key": API_KEY,
  },
});

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    /**
     * Validate response from /APIlabels with HEADERS
     * if the response is not valid or labels has no result it throws an error
     * @callback from const cValidateObj
     */
    const validateObjEmpty = (res) => {
      if (typeof res.data !== "object" || !res.data) {
        throw new Error();
      }

      return res;
    };

    /**
     * Validate response from /APIlabels with HEADERS
     * if the response is not valid or labels has no result it throws an error
     */
    const cValidateObjEmpty = axiosConfig.interceptors.response.use(
      (res) => {
        try {
          const result = validateObjEmpty(res);

          return result;
        } catch (error) {
          handleObjectInvalid();
          return Promise.reject(error);
        }
      },
      (error) => {
        handleObjectInvalid();
        return Promise.reject(error);
      }
    );

    const cValidateObjEmptyInitial = axiosInitial.interceptors.response.use(
      (res) => {
        try {
          const result = validateObjEmpty(res);

          return result;
        } catch (error) {
          handleObjectInvalid();
          return Promise.reject(error);
        }
      },
      (error) => {
        handleObjectInvalid();
        return Promise.reject(error);
      }
    );

    /**
     * Validate MAINTENANCE
     */
    const isMaintenance = (res) => {
      if (
        user.role.length > 0 &&
        !Boolean(user?.data?.access_level?.is_master) &&
        Boolean(res?.data?.API_under_maintenance) &&
        !res.config.url.includes("/signout")
      ) {
        throw new Error(JSON.stringify(res.data));
      }

      return res;
    };

    /**
     * Validate MAINTENANCE
     */
    const cIsMaintenance = axiosConfig.interceptors.response.use(
      (res) => {
        try {
          const result = isMaintenance(res);

          return result;
        } catch (error) {
          handleMaintenance(error.message);
          return Promise.reject(error);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    /**
     * Validate response from /APIlabels with HEADERS
     * if the response is not valid or labels has no result it throws an error
     * @callback from const cValidateObj
     */
    const validateObj = (res) => {
      if (res.config.url.split("?")[0] === "/labels") {
        if (typeof res.data !== "object" || !res.data.result) {
          // setTimeout(() => {
          //   navigate.navigate('Maintenance');
          // }, 200);
          throw new Error();
        }
      }

      return res;
    };

    /**
     * Validate response from /APIlabels with HEADERS
     * if the response is not valid or labels has no result it throws an error
     */
    const cValidateObj = axiosConfig.interceptors.response.use(
      (res) => {
        try {
          const result = validateObj(res);

          return result;
        } catch (error) {
          handleError(error);
          throw new Error();
        }
      },
      (error) => {
        throw new Error();
      }
    );

    /**
     * Validate response from TOKEN with HEADERS
     * if the response has an element "message" == invalid_session_token it throws an error
     * @callback from const cTokenIsValid
     */
    const tokenIsValid = (res) => {
      if (res.data?.message && res.data?.message === "invalid_session_token") {
        handleTokenInvalid(res);
        throw new Error(res);
      }

      return res;
    };

    /**
     * Validate response from TOKEN with HEADERS
     * if the response has an element "message" == invalid_session_token it throws an error
     */
    const cTokenIsValid = axiosConfig.interceptors.response.use(
      (res) => {
        try {
          const result = tokenIsValid(res);

          return result;
        } catch (error) {
          handleTokenInvalid(error);
          throw new Error();
        }
      },
      (error) => {
        throw new Error();
      }
    );

    /**
     * Validate response from /APIlabels without HEADERS
     * if the response is not valid or labels has no result it throws an error
     * @callback from const cValidateInitialObj
     */
    const validateInitialObj = (res) => {
      if (res.config.url.split("?")[0] === "/labels") {
        if (typeof res.data !== "object" || !res.data.result) {
          // setTimeout(() => {
          //   navigate.navigate('Maintenance');
          // }, 200);
          throw new Error();
        }
      }

      return res;
    };

    /**
     * Validate response from /APIlabels without HEADERS
     * if the response is not valid or labels has no result it throws an error
     */
    const cValidateInitialObj = axiosInitial.interceptors.response.use(
      (res) => {
        try {
          const result = validateInitialObj(res);

          return result;
        } catch (error) {
          handleError(error);
          throw new Error();
        }
      },
      (error) => {
        throw new Error();
      }
    );

    const handleResponseVersion = (res) => {
      if (
        res.data?.version?.WEB_APP?.version > 0 &&
        parseFloat(process.env.REACT_APP_VERSION) > 0 &&
        res.data?.version?.WEB_APP?.version >
          parseFloat(process.env.REACT_APP_VERSION)
      ) {
        dispatch(setBagde(true));
        dispatch(setVersionAPI(parseFloat(res.data.version.WEB_APP.version)));
        dispatch(setVersion(parseFloat(process.env.REACT_APP_VERSION)));
        return res;
      }

      return res;
    };

    const versionInterceptor = axiosConfig.interceptors.response.use(
      (res) => {
        try {
          const result = handleResponseVersion(res);
          return result;
        } catch (error) {
          handleError(error);
          throw new Error();
        }
      },
      (error) => {
        throw new Error();
      }
    );

    const resMessage = (res) => {
      if (res.data.dialog) {
        const snackbar = Object.keys(res.data.dialog).map((item) => {
          const element = res.data.dialog[item];

          if (typeof element === "string") {
            return {
              variant: item,
              title: element,
              description: element,
            };
          } else {
            return {
              variant: item,
              title: element.title,
              description: element.content,
              icon: element.icon,
              time: element.time,
              data: element.data,
            };
          }
        });

        if (snackbar[0]) {
          dispatch(
            showMessage({
              message: dispatch(t(snackbar[0].title)),
              variant: snackbar[0].variant,
              autoHideDuration: snackbar[0].time || 5000,
            })
          );
        }

        return { ...res.data, snackbar };
      }

      return res.data;
    };

    const generateMessage = axiosConfig.interceptors.response.use(
      (res) => {
        try {
          const result = resMessage(res);

          return result;
        } catch (error) {
          handleError(error);
          throw new Error();
        }
      },
      (error) => {
        throw new Error();
      }
    );

    const generateInitialMessage = axiosInitial.interceptors.response.use(
      (res) => {
        try {
          const result = resMessage(res);

          return result;
        } catch (error) {
          handleError(error);
          throw new Error();
        }
      },
      (error) => {
        throw new Error();
      }
    );

    const handleObjectInvalid = (error) => {
      dispatch(
        showMessage({
          message: "Server / Network Error",
          variant: "error",
          autoHideDuration: 5000,
        })
      );
    };

    const handleMaintenance = (error) => {
      const err = JSON.parse(error);

      dispatch(
        showMessage({
          message: err?.dialog?.error
            ? dispatch(t(err?.dialog?.error))
            : "Maintenance",
          variant: "error",
          autoHideDuration: 5000,
        })
      );
      history.push("/maintenance");
    };

    const handleError = (error) => {
      dispatch(
        showMessage({
          message: "Server / Network Error",
          variant: "error",
          autoHideDuration: 5000,
        })
      );
      history.push("/maintenance");
    };

    const handleTokenInvalid = (error) => {
      dispatch(
        showMessage({
          message: dispatch(t("API.invalid_token")),
          variant: "error",
          autoHideDuration: 5000,
        })
      );
      axiosService.setSessionToken(null);
      axiosService.setBRSTR(null);
      axiosService.setAccessAs(null);
      dispatch(logoutUser());
      // history.push("/sign-in");
    };

    return () => {
      /**
       * Axios with HEADERS config in each request
       */
      axiosConfig.interceptors.response.eject(cValidateObjEmpty);
      axiosConfig.interceptors.response.eject(cIsMaintenance);
      axiosConfig.interceptors.response.eject(cValidateObj);
      axiosConfig.interceptors.response.eject(cTokenIsValid);
      axiosConfig.interceptors.response.eject(generateMessage);
      axiosConfig.interceptors.response.eject(versionInterceptor);

      /**
       * Axios without HEADERS
       */
      axiosInitial.interceptors.response.eject(cValidateObjEmptyInitial);
      axiosInitial.interceptors.response.eject(cValidateInitialObj);
      axiosInitial.interceptors.response.eject(generateInitialMessage);
    };
  }, [axiosConfig, axiosInitial, user]);

  return children;
};

axiosConfig.interceptors.request.use(function (config) {
  const sessionToken = axiosService.getSessionToken();
  const brstr = axiosService.getBRSTR();
  const admin = axiosService.getAdminDebug();
  const accessAs = axiosService.getAccessAs();

  if (admin && Boolean(admin)) {
    const url = config.url.split("?");

    config.url =
      url.length === 1
        ? url.concat("?&debug=1").join("")
        : url.join("?debug=1&");
  }

  if (sessionToken && brstr) {
    config.headers["session-token"] = accessAs || sessionToken;
    config.headers["brs-tr"] = brstr;
  }

  //FIXME
  if (config.method == "delete")
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
  else config.headers["Content-Type"] = "multipart/form-data";

  return config;
});

export default axiosConfig;
export { AxiosInterceptor };
