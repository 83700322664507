import { useEffect, useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  selectCurrentLanguage,
  selectSystemLanguages,
} from "app/store/i18nSlice";
import { useSelector } from "react-redux";
import { InputAdornment, Button, TextField, Box } from "@mui/material";
import { useRef } from "react";

const Translate = ({ id, placeholder, lang, onChangeText, className }) => {
  const language = useSelector(selectCurrentLanguage);
  const systemLang = useSelector(selectSystemLanguages);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [menu, setMenu] = useState(null);
  const [langTrad, setLangTrad] = useState("");
  const [text, setText] = useState("");

  const translateRef = useRef(null);

  useEffect(() => {
    if (lang) {
      const json = JSON.parse(lang);
      setLangTrad(json);
      setText(json[currentLanguage.id]);
    }
  }, [lang]);

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };

  const handleLanguageChange = (lng) => {
    const lang = {
      id: lng.code,
      flag: lng.flag_src,
      title: lng.name,
    };

    if (!langTrad || !langTrad[lang?.id]) setText("");
    else setText(langTrad[lang?.id]);

    setCurrentLanguage(lang);
    langMenuClose();
  };

  const handleChangeText = (value) => {
    const updatedLangTrad = { ...langTrad, [currentLanguage.id]: value };
    setLangTrad(updatedLangTrad);
    setText(value);
    onChangeText(updatedLangTrad);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }} ref={translateRef}>
      <TextField
        id={id}
        className={`mb-24 ${className}`}
        label={placeholder}
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        value={text}
        onChange={(e) => handleChangeText(e.target.value)}
        InputProps={{
          startAdornment: (
            <Button className="w-20 h-20" onClick={langMenuClick}>
              {currentLanguage?.flag && (
                <img
                  className="object-contain w-20 h-20"
                  src={currentLanguage?.flag}
                  alt={currentLanguage?.id}
                />
              )}
              <Typography
                className="mx-4 font-semibold uppercase"
                color="text.secondary"
              >
                {currentLanguage?.id}
              </Typography>
            </Button>
          ),
        }}
      />
      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {systemLang?.map((lng) => (
          <MenuItem key={lng.code} onClick={() => handleLanguageChange(lng)}>
            <ListItemIcon className="w-auto">
              {lng?.flag_src && (
                <img
                  className="object-contain w-20 h-20"
                  src={lng.flag_src}
                  alt={lng.code}
                />
              )}
            </ListItemIcon>
            <ListItemText primary={lng.name} />
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};
export default Translate;
