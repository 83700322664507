import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Drawer } from "rsuite";
import {
  selectDrawerState,
  selectEditRow,
  setDrawerState,
  setEditRow,
} from "../store/drawerSlice";
import * as yup from "yup";
import _ from "lodash";
import { useSelector } from "react-redux";
import centralNotificationService from "../service/CentralNotificationsService";
import {
  newNotification,
  updateNotification,
} from "../store/notificationsSlice";
import { useEffect } from "react";
import { selectUsers } from "../store/usersSlice";

const DrawerMenu = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectDrawerState);
  const users = useSelector(selectUsers);
  const row = useSelector(selectEditRow);

  const schema = yup
    .object()
    .shape({
      name: yup.string().required(dispatch(t("common.input_required"))),
      user: yup.array().nullable(),
      emails: yup
        .array()
        .of(yup.string().email(dispatch(t("common.set_valid_email"))))
        .nullable(),
    })
    .test("oneOf", null, function (obj) {
      const { user, emails } = obj;
      if (!user?.length && !emails?.length) {
        return this.createError({
          message: "must fill on of two camps",
        });
      }
      return true;
    });

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    reset({ ...row });
  }, [row, reset]);

  const handleClose = () => {
    reset();
    dispatch(setDrawerState(false));
  };

  const submit = async (obj) => {
    const recipient = `{"emails":"${obj.emails}","user":"${obj.user}"}`;

    const finalObj = {
      name: obj.name,
      recipients: recipient,
    };

    try {
      const res = await centralNotificationService.addCentralNotification(
        finalObj
      );

      const _obj = {
        ...obj,
        notifications_preset_id: res.result.last_id,
        is_push: 0,
      };

      dispatch(newNotification(_obj));
    } catch (error) {}

    handleClose();
  };

  const edit = async (obj) => {
    const id = row.notifications_preset_id;

    const recipient = `{"emails":"${obj.emails}","user":"${obj.user} ${obj.email}"}`;

    const finalObj = {
      name: obj.name,
      recipients: recipient,
    };

    try {
      const res = await centralNotificationService.editCentralNotification(
        id,
        finalObj
      );

      dispatch(updateNotification({ index: row.index, value: obj }));
    } catch (error) {}

    handleClose();
  };

  return (
    <Drawer backdrop={true} open={open} onClose={handleClose}>
      <Drawer.Header>
        <Drawer.Title>
          {row.notifications_preset_id
            ? dispatch(t("common.edit"))
            : dispatch(t("common.add"))}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <form
          name="endpointForm"
          className="mt-20"
          onSubmit={
            row.notifications_preset_id
              ? handleSubmit(edit)
              : handleSubmit(submit)
          }
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label={dispatch(t("common.name"))}
                type="string"
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="user"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                id="user"
                options={users}
                value={value}
                onChange={(event, newValue) => {
                  {
                    const items = newValue
                      .map((n) => n?.user_id || n)
                      .map((i) => `${i}`);
                    onChange([...items]);
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  typeof value == "object"
                    ? option.user_id == Number(value.user_id)
                    : option.user_id == Number(value)
                }
                getOptionLabel={(option) =>
                  `${option.name} ${option.email} ${option.user_id}`
                }
                renderOption={(_props, option) => (
                  <li {..._props}>
                    {option.name} ({option.email})
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const findedUser = _.find(users, {
                      user_id: Number(option),
                    });
                    return (
                      <Chip
                        key={findedUser.user_id}
                        label={findedUser?.name || findedUser.email}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mb-24"
                    label={dispatch(t("common.users"))}
                    type="string"
                    error={!!errors?.user}
                    helperText={errors?.user?.message}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            )}
          />

          <Controller
            name="emails"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                id="emails"
                freeSolo
                options={[]}
                value={value}
                onChange={(event, newValue) => {
                  onChange([...newValue]);
                }}
                getOptionLabel={(option) => option}
                renderOption={(_props, option) => <li {..._props}>{option}</li>}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mb-24"
                    label={dispatch(t("common.emails"))}
                    type="email"
                    error={!!errors?.emails}
                    helperText={
                      !_.isEmpty(errors?.emails)
                        ? errors?.emails[0]?.message
                        : ""
                    }
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            )}
          />

          <Button
            variant="contained"
            color="secondary"
            className="mt-20 mb-10"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            type="submit"
            fullWidth
          >
            {row.notifications_preset_id
              ? dispatch(t("common.edit"))
              : dispatch(t("common.add"))}
          </Button>
        </form>
      </Drawer.Body>
    </Drawer>
  );
};

export default DrawerMenu;
