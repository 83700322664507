import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import _ from "@lodash";
import { useEffect } from "react";
import { forwardRef } from "react";
import clsx from "clsx";
import ItemComponent from "../Components";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { useParams } from "react-router-dom";

const BlockFormFeatures = forwardRef(({ value, onChange, className }, ref) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const [options, setOptions] = useState([
    {
      label: dispatch(t("common.heading")),
      value: "heading",
      icon: "material-outline:title",
    },
    {
      label: dispatch(t("common.text")),
      value: "text",
      icon: "material-outline:text_fields",
    },
    {
      label: dispatch(t("common.number")),
      value: "integer",
      icon: "material-outline:looks_one",
    },
    {
      label: dispatch(t("common.date")),
      value: "datetime",
      icon: "material-outline:calendar_today",
    },
    {
      label: dispatch(t("common.text_area")),
      value: "textArea",
      icon: "material-outline:text_snippet",
    },
    {
      label: dispatch(t("common.text_simple")),
      value: "textSimple",
      icon: "material-outline:text_snippet",
    },
    {
      label: dispatch(t("common.select")),
      value: "select",
      icon: "material-outline:arrow_drop_down_circle",
    },
    {
      label: dispatch(t("common.radio")),
      value: "radio",
      icon: "material-outline:radio_button_checked",
    },
    {
      label: dispatch(t("common.checkbox")),
      value: "checkbox",
      icon: "material-outline:check_box",
    },
    {
      label: dispatch(t("common.image")),
      value: "image",
      icon: "material-outline:image",
    },
    {
      label: dispatch(t("common.file")),
      value: "file",
      icon: "material-outline:attach_file",
    },
    {
      label: "URL",
      value: "url",
      icon: "material-outline:link",
    },
  ]);

  const schema = yup.object().shape({
    type: yup.string().required(dispatch(t("common.input_required"))),
  });

  const defaultValues = {
    type: "",
    required: false,
    active: true,
  };

  const {
    control,
    setValue,
    watch,
    reset,
    formState,
    handleSubmit,
    getFieldState,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  useEffect(() => {
    if (value) reset({ ...value });
    else reset({ ...defaultValues });
  }, [value, reset]);

  const onSubmit = () => {
    onChange(getValues());
  };

  const handleChange = (data) => {
    setValue(data.name, data.value);
    onSubmit();
  };

  return (
    <div ref={ref} className={clsx("w-full", className)}>
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value, ...field } }) => (
          <Autocomplete
            {...field}
            id="type"
            className="mt-32"
            options={options}
            disabled={routeParams.block !== "new"}
            value={
              value ? _.find(options, { value: value?.value || value }) : null
            }
            onChange={(event, newValue) => {
              if (newValue != null && typeof newValue == "object") {
                setValue("type_icon", newValue.icon);
                onChange(newValue.value);
              } else onChange(newValue);

              onSubmit();
            }}
            isOptionEqualToValue={(option, value) =>
              value && option.value === value?.value
            }
            getOptionLabel={(option) => option.label}
            renderOption={(_props, option) => (
              <Box
                {..._props}
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              >
                <FuseSvgIcon className={"mr-5"}>{option.icon}</FuseSvgIcon>
                {option.label}
              </Box>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={dispatch(t("common.block_type"))}
                placeholder={dispatch(t("common.block_type"))}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Controller
                      control={control}
                      name="type_icon"
                      render={({ field: { value, ...field } }) => (
                        <InputAdornment {...field} position="start">
                          {value && <FuseSvgIcon>{value}</FuseSvgIcon>}
                        </InputAdornment>
                      )}
                    />
                  ),
                }}
              />
            )}
          />
        )}
      />

      {getValues().type && <ItemComponent {...value} onChange={handleChange} />}
    </div>
  );
});

export default BlockFormFeatures;
