const menu = [
  {
    id: "groups",
    title: "Groups",
    type: "group",
    active: false,
    hasActions: true,
    children: [],
  },
];

export default menu;
