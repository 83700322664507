import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Button from "@mui/material/Button";
import { forwardRef } from "react";
import clsx from "clsx";
import PhoneNumberInput from "./PhoneNumberInput";
import EntityModel, { EntityModelMerge } from "../../model/EntityModel";
import { useDispatch } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import { Typography } from "@mui/material";

const PhoneNumberSelector = forwardRef(
  ({ value, onChange, className }, ref) => {
    const dispatch = useDispatch();

    const handleAddPhone = () => {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        // Special onChange behavior for null or empty string value
        onChange([EntityModelMerge().phone_numbers[0]]);
      } else {
        onChange([...value, EntityModel().phone_numbers[0]]);
      }
    };

    return (
      <div className={clsx("w-full", className)} ref={ref}>
        {value?.map((item, index) => (
          <PhoneNumberInput
            value={item}
            key={index}
            onChange={(val) => {
              onChange(
                value.map((_item, _index) => (index === _index ? val : _item))
              );
            }}
            onRemove={() => {
              onChange(value.filter((_item, _index) => index !== _index));
            }}
            onChecked={(val) => {
              const newItems = value.map((obj, _index) => {
                if (index == _index) return { ...obj, is_main: val };

                return { ...obj, is_main: false };
              });

              onChange(newItems);
            }}
            hideRemove={value.length === 0}
          />
        ))}
        <Button
          className="group inline-flex items-center mt-2 ml-44 py-2 px-4 rounded cursor-pointer"
          onClick={handleAddPhone}
        >
          <FuseSvgIcon size={20}>heroicons-solid:plus-circle</FuseSvgIcon>

          <span className="ml-8 font-medium text-secondary group-hover:underline">
            {`${dispatch(t("common.add"))} ${dispatch(t("common.contact"))}`}
          </span>
        </Button>
      </div>
    );
  }
);

export default PhoneNumberSelector;
