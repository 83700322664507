import {
  createSelector,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import GuidesModel from "../model/GuidesModel";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getGuidesTickets = createAsyncThunk(
  "supportApp/guidesTickets/getGuidesTickets",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/posts/templates/1?formated=1`);

      if (res.status === "error") throw new Error(res);

      const items = parsePosts(res.result.subitems);

      return { items };
    } catch (error) {
      throw new Error(res);
    }
  }
);

const parsePosts = (data) => {
  const arrayNew = [];

  for (let i = 0; i < data.length; i++) {
    const element = data[i];

    arrayNew.push(GuidesModel(element));

    if (element.subitems) arrayNew[i].subitems = parsePosts(element.subitems);
  }
  return arrayNew;
};

const guidesTicketsSlice = createSlice({
  name: "supportApp/guidesTickets",
  initialState: {
    items: [],
    guidesTickets: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGuidesTickets.fulfilled, (state, action) => {
        const { items } = action.payload;

        state.items = items;
        state.status = "fulfilled";
      })
      .addCase(getGuidesTickets.rejected, (state) => {
        state.items = [];
        state.status = "rejected";
      });
  },
});

export const selectItems = ({ supportApp }) => supportApp.guidesTickets.items;
export const selectGuides = ({ supportApp }) =>
  supportApp.guidesTickets.guidesTickets;
export const selectStatus = ({ supportApp }) => supportApp.guidesTickets.status;

export default guidesTicketsSlice.reducer;
