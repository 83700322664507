import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useMemo } from "react";
import { motion } from "framer-motion";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import reducer from "./store";
import withReducer from "app/store/withReducer";
import PushNotificationsHeader from "./PushNotificationsHeader";
import PushNotifications from "./PushNotifications";
import {
  getPushNotifications,
  getScreens,
  getUserList,
  selectPushNotifications,
} from "./store/pushNotificationsSlice";

const PushNotificationsApp = () => {
  const dispatch = useDispatch();
  const push_notifications = useSelector(selectPushNotifications);

  useEffect(() => {
    dispatch(getPushNotifications());
    dispatch(getUserList());
    dispatch(getScreens());
  }, [dispatch]);

  return (
    <>
      <FusePageSimple
        header={<PushNotificationsHeader />}
        content={
          <div className="w-full px-24 md:px-32 xs:mb-68 pb-24">
            {useMemo(() => {
              const container = {
                show: {
                  transition: {
                    staggerChildren: 0.06,
                  },
                },
              };

              const item = {
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 },
              };

              return (
                <motion.div
                  className="w-full"
                  variants={container}
                  initial="hidden"
                  animate="show"
                >
                  <div className="w-full">
                    <motion.div
                      variants={item}
                      className="xl:col-span-2 flex flex-col flex-auto"
                    >
                      <PushNotifications />
                    </motion.div>
                  </div>
                </motion.div>
              );
            }, [push_notifications])}
          </div>
        }
      />
    </>
  );
};

export default withReducer("push_notifications", reducer)(PushNotificationsApp);
