import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import { t } from "app/store/labels/globalLabels";
import { useDispatch } from "react-redux";

const MediaInput = (props) => {
  const { value } = props;
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    short: yup.string(),
    name: yup.string(),
    url: yup.string(),
  });

  const defaultValues = {
    short: "",
    name: "",
    url: "",
  };

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(value);
  }, [reset, value]);

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = (data) => {
    props.onChange(data);
  };

  return (
    <form className="w-full flex flex-col" onChange={handleSubmit(onSubmit)}>
      <div className="w-full flex flex-row items-center justify-between">
        <Controller
          control={control}
          name={"short"}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32 w-[45%]"
              label={dispatch(t("common.short_name"))}
              placeholder={dispatch(t("common.short_name"))}
              error={!!errors.short}
              helperText={errors.short?.message}
              variant="outlined"
            />
          )}
        />

        <Controller
          control={control}
          name={"name"}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32 w-[45%]"
              label={dispatch(t("common.name"))}
              placeholder={dispatch(t("common.name"))}
              error={!!errors.name}
              helperText={errors.name?.message}
              variant="outlined"
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name={"url"}
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-32"
            label={dispatch(t("common.url"))}
            placeholder={dispatch(t("common.url"))}
            error={!!errors.url}
            helperText={errors.url?.message}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FuseSvgIcon size={20}>feather:link</FuseSvgIcon>
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton onClick={props.onRemove}>
                  <FuseSvgIcon size={20}>heroicons-solid:trash</FuseSvgIcon>
                </IconButton>
              ),
            }}
          />
        )}
      />
    </form>
  );
};

export default MediaInput;
