import FuseScrollbars from "@fuse/core/FuseScrollbars";
import { alpha, styled, useTheme } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import clsx from "clsx";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FuseNavigation from "@fuse/core/FuseNavigation";
import { navbarCloseMobile } from "app/store/fuse/navbarSlice";
import { selectContrastMainTheme } from "app/store/fuse/settingsSlice";
import { Link, useLocation } from "react-router-dom";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { selectNavigation } from "app/store/fuse/navigationSlice";
import { selectDark, selectLogos } from "app/store/labels/logosSlice";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import _ from "lodash";
import {
  selectPageTitle,
  setPageSubTitle,
  setPageTitle,
} from "app/store/labels/globalLabels";
import { Box } from "@mui/system";
import { ListItem, ListItemText } from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import FuseNavBadge from "@fuse/core/FuseNavigation/FuseNavBadge";
import { selectBagde, selectVersionAPI } from "app/store/versionSlice";
import FuseUtils from "@fuse/utils/FuseUtils";

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
}));

const RootItem = styled(Box)(({ theme }) => ({
  "& > .fuse-list-item": {
    minHeight: 100,
    height: 100,
    width: 100,
    borderRadius: 12,
    margin: "0 0 4px 0",
    color: alpha(theme.palette.text.primary, 0.7),
    cursor: "pointer",
    textDecoration: "none!important",
    padding: 0,
    "&.dense": {
      minHeight: 52,
      height: 52,
      width: 52,
    },
    "&.type-divider": {
      padding: 0,
      height: 2,
      minHeight: 2,
      margin: "12px 0",
      backgroundColor:
        theme.palette.mode === "light"
          ? "rgba(0, 0, 0, .05)!important"
          : "rgba(255, 255, 255, .1)!important",
      pointerEvents: "none",
    },
    "&:hover": {
      color: theme.palette.text.primary,
    },
    "&.active": {
      color: theme.palette.text.primary,
      backgroundColor:
        theme.palette.mode === "light"
          ? "rgba(0, 0, 0, .05)!important"
          : "rgba(255, 255, 255, .1)!important",
      // pointerEvents: 'none',
      transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
      "& .fuse-list-item-text-primary": {
        color: "inherit",
      },
      "& .fuse-list-item-icon": {
        color: "inherit",
      },
    },
    "& .fuse-list-item-icon": {
      color: "inherit",
    },
    "& .fuse-list-item-text": {},
  },
}));

const StyledPanel = styled(FuseScrollbars)(({ theme, opened }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  transition: theme.transitions.create(["opacity"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
  opacity: 0,
  pointerEvents: "none",
  ...(opened && {
    opacity: 1,
    pointerEvents: "initial",
  }),
}));

function needsToBeOpened(location, item) {
  return location && isUrlInChildren(item, location.pathname);
}

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }
    if (
      parent.children[i].url === url ||
      (parent.children[i].url !== "" && url.includes(parent.children[i].url))
    ) {
      return true;
    }
  }

  return false;
}

const NavbarStyle3Content = (props) => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const pageTitle = useSelector(selectPageTitle);
  const navigation = useSelector(selectNavigation);
  const [selectedNavigation, setSelectedNavigation] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const showBagde = useSelector(selectBagde);
  const versionAPI = useSelector(selectVersionAPI);
  const contrastTheme = useSelector(
    selectContrastMainTheme(theme.palette.primary.main)
  );
  const location = useLocation();
  const logos = useSelector(selectDark);

  useEffect(() => {
    navigation?.forEach((item) => {
      if (needsToBeOpened(location, item)) {
        setSelectedNavigation([item]);
        const naviFlat = FuseUtils.getFlatNavigation(navigation);

        const urlSplit = location.pathname.split("/");
        const urlNew = [urlSplit[0], urlSplit[1], urlSplit[2]].join("/");

        const titleChildren = naviFlat.find((c) => `/${c.url}` === urlNew);
        if (`/${titleChildren?.url}` === urlNew) {
          setSelectedNavigation([item]);
          dispatch(setPageTitle(`${titleChildren?.title}`));
          dispatch(setPageSubTitle(`${titleChildren?.subtitle || ""}`));
        }
      } else if (`/${item.url}` === location.pathname) {
        dispatch(setPageTitle(`${item?.title}`));
        dispatch(setPageSubTitle(`${item?.subtitle ? item?.subtitle : ""}`));
        !isMobile && setSelectedNavigation([item]);
      }
    });
  }, [navigation, location]);

  function handleParentItemClick(selected) {


    const hasurl = selected?.url.slice(0, 4);
    if(hasurl=='http' && selected?.url){
        window.open(selected?.url, "_blank");
        return false;
    };
    /** if there is no child item do not set/open panel
     */
    if (!selected.children) {
      setSelectedNavigation(selected);
      dispatch(setPageTitle(selected.title));
      setPanelOpen(false);
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
      return;
    }

    /**
     * If navigation already selected toggle panel visibility
     */
    if (selectedNavigation[0]?.id === selected.id) {
      setPanelOpen(!panelOpen);
    } else {
      /**
       * Set navigation and open panel
       */
      setSelectedNavigation([selected]);
      setPanelOpen(true);
    }
  }

  function handleChildItemClick(selected) {
    if (selected.type === "item") {
      dispatch(setPageTitle(`${selected.title}`));
      dispatch(setPageSubTitle(`${selected?.subtitle}`));
    }
    setPanelOpen(false);
    if (isMobile) {
      dispatch(navbarCloseMobile());
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setPanelOpen(false)}>
      <Root className={clsx("flex flex-auto h-full", props.className)}>
        <div
          id="fuse-navbar-side-panel"
          className="flex shrink-0 flex-col items-center"
        >
          <Link
            className="flex justify-center w-full items-center overflow-y-auto overflow-x-hidden"
            to={"/"}
            role="button"
          >
            <img
              className="w-3/4 m-10"
              src={`${process.env.REACT_APP_API_ASSETS}/${
                logos?.vertical_logo
              }`}
              alt="logo"
            />
          </Link>

          <FuseScrollbars
            className="flex flex-1 min-h-0 justify-center w-full overflow-y-auto overflow-x-hidden"
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <FuseNavigation
              className={clsx("navigation")}
              navigation={navigation}
              layout="vertical-2"
              onItemClick={handleParentItemClick}
              firstLevel
              selectedId={
                _.isArray(selectedNavigation)
                  ? selectedNavigation[0]?.id
                  : selectedNavigation.id
              }
              dense={props.dense}
            />
          </FuseScrollbars>

          {showBagde && (
            <RootItem>
              <ListItem
                role="button"
                onClick={() => window.location.reload(false)}
                component={"" && NavLinkAdapter}
                to={""}
                end={""}
                className={clsx(
                  `type-item`,
                  "fuse-list-item flex flex-col items-center justify-center p-12"
                )}
              >
                <div className="w-32 h-32 min-h-32 flex items-center justify-center relative mb-8">
                  <FuseSvgIcon
                    size={32}
                    className={clsx("fuse-list-item-icon")}
                    color="action"
                  >
                    heroicons-outline:refresh
                  </FuseSvgIcon>
                </div>

                <ListItemText
                  className="fuse-list-item-text grow-0 w-full bg-red-500 rounded-lg px-10 "
                  primary={`Version: ${versionAPI}`}
                  classes={{
                    primary:
                      "text-12 font-medium text-white fuse-list-item-text-primary truncate text-center truncate",
                  }}
                />
              </ListItem>
            </RootItem>
          )}
        </div>

        {selectedNavigation.length > 0 && (
          <StyledPanel
            id="fuse-navbar-panel"
            opened={panelOpen}
            className={clsx("shadow-5 overflow-y-auto overflow-x-hidden")}
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <FuseNavigation
              className={clsx("navigation")}
              navigation={selectedNavigation}
              layout="vertical"
              onItemClick={handleChildItemClick}
            />
          </StyledPanel>
        )}
      </Root>
    </ClickAwayListener>
  );
};

export default memo(NavbarStyle3Content);
