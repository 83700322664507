import SignInConfig from "./sign-in/SignInConfig";
import SignOutConfig from "./sign-out/SignOutConfig";
import SignUpConfig from "./sign-up/SignUpConfig";
import ForgotPasswordConfig from "./forgotPassword/ForgotPasswordConfig";
import ResetPasswordConfig from "./resetPassword/ResetPasswordConfig";
import SecretTokenConfig from "./secretToken/SecretTokenConfig";

const loginConfig = [
  SignInConfig,
  SignOutConfig,
  SignUpConfig,
  ForgotPasswordConfig,
  ResetPasswordConfig,
  SecretTokenConfig,
];

export default loginConfig;
