import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeSwitchState,
  deletePushNotification,
  getPushNotifications,
  getUserPushLiist,
  selectPush,
  selectPushNotifications,
  selectStatus,
} from "./store/pushNotificationsSlice";
import { useSelector } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import {
  Chip,
  ImageList,
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  Switch,
  Typography,
} from "@mui/material";
import {
  selectCurrentLanguageId,
  selectTableLanguage,
} from "app/store/i18nSlice";
import {
  Add,
  Delete,
  FormatListBulletedOutlined,
  RefreshOutlined,
  PeopleOutline,
  PeopleAlt,
  CompressOutlined,
} from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";
import DrawerForm from "./components/DrawerForm";
import {
  setDrawerUserState,
  setEmptyPushNotification,
  setUserDrawer,
} from "./store/drawerSlice";
import ConfirmationDialog from "../../components/confirmationDialog/ConfirmationDialog";
import UserList from "./components/UserList";

const PushNotifications = () => {
  const dispatch = useDispatch();
  const push_notifications = useSelector(selectPushNotifications);
  const lang = useSelector(selectCurrentLanguageId);
  const tableLang = useSelector(selectTableLanguage);
  const loading = useSelector(selectStatus);

  const [open, setOpen] = useState(false);
  const [_push, _setPush] = useState();
  const [_disable, _setDisable] = useState(false);

  const handleNo = () => {
    setOpen(false);
  };

  const handleDelete = (row) => {
    try {
      dispatch(deletePushNotification(row)).then((res) => {
        if (res.error) return;
      });
    } catch (error) {}
    handleNo();
  };

  const handleSwitch = (data) => {
    try {
      dispatch(changeSwitchState(data)).then((res) => {
        if (res.error) return;
      });
    } catch (error) {}
  };

  const columns = useMemo(() => {
    const push_notificationsInfo = [
      {
        header: dispatch(t("common.message")),
        accessorKey: `message_lang`,
        size: 220,
      },
      {
        header: dispatch(t("common.title")),
        accessorKey: "title_lang",
        size: 220,
      },
      {
        header: dispatch(t("common.device_type")),
        accessorKey: "device_type",
        size: 250,
        Cell: ({ row }) =>
          row.original.device_type.length !== 0
            ? row.original.device_type.map((u, _index) => (
                <Chip label={u} key={_index} />
              ))
            : dispatch(t("common.all")),
      },
      {
        header: dispatch(t("common.data")),
        accessorKey: "data",
        size: 400,
        Cell: ({ row }) => {
          return (
            <Typography className="break-words whitespace-normal">
              {row.original.data}
            </Typography>
          );
        },
      },
      {
        header: dispatch(t("common.recipients")),
        accessorKey: "recipients",
        size: 200,
        Cell: ({ row }) =>
          row.original.recipients !== null
            ? row.original.recipients
            : dispatch(t("common.all")),
      },
      {
        header: dispatch(t("SYSTEM.automatic")),
        accessorKey: "is_auto",
        size: 220,
        Cell: ({ row }) =>
          row.original.is_auto === 1 &&
          `${dispatch(t("common.no"))} ${dispatch(t("SYSTEM.automatic"))}`,
      },
      {
        header: dispatch(t("common.picture")),
        accessorKey: "image_src",
        size: 180,
        Cell: ({ row }) => (
          <div>
            {
              <ImageList className="cursor-pointer w-120 ml-8">
                <img
                  src={
                    row.original.image_src !== null
                      ? row.original.image_src
                      : row.original.image_url
                  }
                />
              </ImageList>
            }
          </div>
        ),
      },
      {
        header: dispatch(t("common.is_active")),
        accessorKey: "is_active",
        size: 150,
        Cell: ({ row }) => (
          <Switch
            checked={Boolean(row.original.is_active)}
            onChange={(e) => {
              const obj = {
                ...row.original,
                index: row.index,
                is_active: e.target.checked,
              };
              handleSwitch(obj);
            }}
          />
        ),
      },
      {
        header: dispatch(t("common.total_users")),
        accessorKey: "total_users",
        size: 230,
      },
      {
        header: dispatch(t("common.processed")),
        accessorKey: "total_pushnotif",
        size: 180,
      },
      {
        header: dispatch(t("common.sended")),
        accessorKey: "total_sended",
        size: 150,
      },
      {
        header: dispatch(t("common.opened")),
        accessorKey: "total_opened",
        size: 130,
      },
      {
        header: dispatch(t("common.start_date")),
        accessorKey: "send_date_time",
        size: 180,
      },
      {
        header: dispatch(t("common.end_date")),
        accessorKey: "finished",
        size: 180,
      },
      {
        header: dispatch(t("common.created_by")),
        accessorKey: "created_by_name",
        size: 170,
      },
    ];
    return [...push_notificationsInfo];
  }, [push_notifications, lang]);

  return (
    <>
      <MaterialReactTable
        data={push_notifications}
        columns={columns}
        localization={tableLang}
        enableColumnOrdering
        enableGrouping
        enablePinning
        enableColumnResizing
        enableStickyHeader
        enableFullScreenToggle={false}
        displayColumnDefOptions={{ "mrt-row-actions": { size: 55 } }}
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 332px)" } }}
        initialState={{
          pagination: { pageIndex: 0, pageSize: 15 },
        }}
        state={{
          showSkeletons: loading == "pending" ? true : false,
        }}
        enableRowActions
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            key={1}
            onClick={() => {
              dispatch(getUserPushLiist(row.original.pushnotif_template_id));
              dispatch(setDrawerUserState(true));
              closeMenu();
            }}
          >
            <PeopleAlt />
            <Typography className="ml-5">
              {dispatch(t("common.list"))}
            </Typography>
          </MenuItem>,
          <MenuItem
            key={2}
            onClick={() => {
              setOpen(true);
              _setPush(row);
              closeMenu();
            }}
          >
            <Delete />
            <Typography className="ml-5">
              {dispatch(t("common.delete"))}
            </Typography>
          </MenuItem>,
        ]}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        defaultColumn={{ minSize: 20 }}
      />
      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle={dispatch(t("common.add"))}
          tooltipOpen
          onClick={() => dispatch(setEmptyPushNotification())}
        />

        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh_table"))}
          tooltipOpen
          onClick={() => dispatch(getPushNotifications())}
        />
      </SpeedDial>
      <DrawerForm />
      <UserList />
      <ConfirmationDialog
        onClose={handleNo}
        onSubmit={() => handleDelete(_push)}
        open={open}
        title={"common.delete"}
      />
    </>
  );
};

export default PushNotifications;
