import { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import { useSelector } from "react-redux";
import {
  selectCurrentLanguage,
  selectCurrentLanguageId,
  selectSystemLanguages,
} from "app/store/i18nSlice";
import _ from "@lodash";
import { KeyboardArrowDown } from "@mui/icons-material";

const createSchema = (languages) => {
  const schema = {};

  languages.map((lang) => {
    schema[lang.code] = yup.string();
  });

  return yup.object().shape(schema);
};

const InputTranslate = forwardRef(
  ({ value, onChange, className, multiline, placeholder, required }, ref) => {
    const dispatch = useDispatch();
    const systemLangs = useSelector(selectSystemLanguages);
    const currentLang = useSelector(selectCurrentLanguageId);
    const [currentLanguage, setCurrentLanguage] = useState(
      _.find(systemLangs, { code: currentLang })
    );
    const [langText, setLangText] = useState(currentLang);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const schema = createSchema(systemLangs);
    const defaultValues = systemLangs.reduce((acc, lang) => {
      acc[lang.code] = "";
      return acc;
    }, {});

    const {
      control,
      formState,
      handleSubmit,
      reset,
      watch,
      setValue,
      getValues,
      getFieldState,
    } = useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      if (value) {
        reset({ ...value });
      } else {
        reset({ ...defaultValues });
      }
    }, [reset, value]);

    const { isDirty, dirtyFields, errors } = formState;

    const onSubmit = (data) => {
      onChange(data);
    };

    const handleTextChange = (e) => {
      setValue(langText, e.target.value);
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div ref={ref} className={clsx(className)}>
        <form onChange={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name={langText}
            render={({ field: { onChange, value, ...field } }) => (
              <TextField
                {...field}
                required={required}
                className="w-full"
                label={placeholder || dispatch(t("common.name"))}
                placeholder={placeholder || dispatch(t("common.name"))}
                fullWidth
                multiline={multiline}
                minRows={1}
                maxRows={10}
                value={getValues(langText)}
                onChange={handleTextChange}
                InputProps={{
                  className: `max-h-min h-min ${
                    multiline ? "items-start" : "items-center"
                  } `,
                  startAdornment: (
                    <>
                      <Button
                        className={`flex px-12 mr-10 items-center`}
                        id="lang-input-button"
                        aria-controls={open ? "lang-input-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDown />}
                        disableRipple
                      >
                        {currentLanguage?.flag_src && (
                          <img
                            className="object-contain w-20 h-20"
                            src={currentLanguage?.flag_src}
                            alt={currentLanguage?.code}
                          />
                        )}
                        <Typography
                          className="mx-4 font-semibold uppercase"
                          color="text.secondary"
                        >
                          {currentLanguage?.code}
                        </Typography>
                      </Button>
                      <Menu
                        id="lang-input-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        {systemLangs.map((item) => (
                          <MenuItem
                            onClick={() => {
                              setCurrentLanguage(item);
                              setLangText(item.code);
                              handleClose();
                            }}
                            disableRipple
                            key={item.code}
                          >
                            <ListItemIcon className="w-auto">
                              {item?.flag_src && (
                                <img
                                  className="object-contain w-20 h-20"
                                  src={item.flag_src}
                                  alt={item.code}
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  ),
                }}
              />
            )}
          />
        </form>
      </div>
    );
  }
);

export default InputTranslate;
