import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Box from "@mui/system/Box";
import _ from "@lodash";
import { selectCountries } from "../store/countriesSlice";

import { getEntity, selectEntity } from "../store/entitySlice";
import { t } from "app/store/labels/globalLabels";

const ContactView = () => {
  const entity = useSelector(selectEntity);
  const countries = useSelector(selectCountries);
  const routeParams = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([dispatch(getEntity(routeParams.id))]);
  }, [dispatch, routeParams]);

  function getCountryByIso(iso) {
    return countries.find((country) => country.iso === iso);
  }

  if (!entity) {
    return <FuseLoading />;
  }

  return (
    <>
      <Box
        className="relative w-full h-136 sm:h-120 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.paper",
        }}
      ></Box>
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
        <div className="w-full max-w-3xl">
          <div className="flex flex-auto items-end -mt-64">
            <Avatar
              sx={{
                borderWidth: 4,
                borderStyle: "solid",
                borderColor: "background.default",
                backgroundColor: "background.paper",
                color: "text.secondary",
              }}
              className="w-128 h-128 text-64 font-bold"
              src={entity.picture_src}
              alt={entity.name}
            >
              {entity.name.charAt(0)}
            </Avatar>
            <div className="flex items-center ml-auto mb-4">
              <Button
                variant="contained"
                color="secondary"
                component={NavLinkAdapter}
                to="edit"
              >
                <FuseSvgIcon size={20}>
                  heroicons-outline:pencil-alt
                </FuseSvgIcon>
                <span className="mx-8">{dispatch(t("common.edit"))}</span>
              </Button>
            </div>
          </div>

          <div className="flex items-center ml-auto mb-4">
            <Typography className="mt-12 text-4xl font-bold truncate">
              {entity.name}
            </Typography>
          </div>

          <Divider className="mt-16 mb-24" />

          <div className="flex flex-col space-y-32">
            <div className="flex items-start">
              <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
              <div>
                <Typography className="ml-24 leading-6 font-600">
                  {dispatch(t("common.email"))}
                </Typography>

                <div className="min-w-0 ml-24 space-y-4">
                  {entity.email_addresses ? (
                    entity.email_addresses.map((item) =>
                      item.email_address !== "" ? (
                        <div
                          className="flex items-center leading-6"
                          key={item.email_address}
                        >
                          <a
                            className="hover:underline text-primary-500"
                            href={`mailto:${item.email_address}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.email_address}
                          </a>
                          {item.label && (
                            <>
                              <Typography
                                className="text-md truncate"
                                color="text.secondary"
                              >
                                <span className="mx-8">&bull;</span>
                                <span className="font-medium">
                                  {item.label}
                                </span>
                              </Typography>
                            </>
                          )}
                        </div>
                      ) : null
                    )
                  ) : (
                    <>
                      <Typography
                        className="text-md truncate"
                        color="text.secondary"
                      >
                        {dispatch(t("common.no_records"))}
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-start">
              <FuseSvgIcon>heroicons-outline:phone</FuseSvgIcon>
              <div>
                <Typography className="ml-24 leading-6 font-600">
                  {dispatch(t("common.phone"))}
                </Typography>

                <div className="min-w-0 ml-24 space-y-4">
                  {entity.phone_numbers ? (
                    entity.phone_numbers.map((item, index) => (
                      <div className="flex items-center leading-6" key={index}>
                        <Box
                          className="hidden sm:flex w-24 h-16 overflow-hidden"
                          sx={{
                            background:
                              "url('/assets/images/contacts/flags.png') no-repeat 0 0",
                            backgroundSize: "24px 3876px",
                            backgroundPosition: getCountryByIso(
                              item.country_code
                            )?.flagImagePos,
                          }}
                        />

                        <div className="sm:ml-12 font-mono">
                          {getCountryByIso(item.country_code)?.code}
                        </div>

                        <div className="ml-10 font-mono">
                          {item.phone_number}
                        </div>

                        {item.label && (
                          <>
                            <Typography
                              className="text-md truncate"
                              color="text.secondary"
                            >
                              <span className="mx-8">&bull;</span>
                              <span className="font-medium">{item.label}</span>
                            </Typography>
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <>
                      <Typography
                        className="text-md truncate"
                        color="text.secondary"
                      >
                        {dispatch(t("common.no_records"))}
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-32">
              <div className="flex items-start">
                <FuseSvgIcon>heroicons-outline:receipt-tax0</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.vat"))}
                  </Typography>

                  <div className="min-w-0 ml-24 space-y-4">
                    {entity.vat ? (
                      <div>{<Typography>{entity.vat}</Typography>}</div>
                    ) : (
                      <>
                        <Typography
                          className="text-md truncate"
                          color="text.secondary"
                        >
                          {dispatch(t("common.no_records"))}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-32">
              <div className="flex items-start">
                <FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
                <div>
                  <Typography className="ml-24 leading-6 font-600">
                    {dispatch(t("common.addresses"))}
                  </Typography>

                  <div className="min-w-0 ml-24 space-y-4">
                    {entity.addresses ? (
                      entity.addresses.map((item) => [
                        <div
                          className="flex items-center leading-6"
                          key={item.street}
                        >
                          <div>
                            {item.street && (
                              <Typography>
                                {item.street} {item.city} {item.state}{" "}
                                {item.zip_code} {item.country}
                              </Typography>
                            )}
                          </div>
                          {item.label && (
                            <>
                              <Typography
                                className="text-md truncate"
                                color="text.secondary"
                              >
                                <span className="mx-8">&bull;</span>
                                <span className="font-medium">
                                  {item.label}
                                </span>
                              </Typography>
                            </>
                          )}
                        </div>,
                      ])
                    ) : (
                      <>
                        <Typography
                          className="text-md truncate"
                          color="text.secondary"
                        >
                          {dispatch(t("common.no_records"))}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-start">
              <FuseSvgIcon>heroicons-outline:menu-alt-2</FuseSvgIcon>
              <div>
                <Typography className="ml-24 leading-6 font-600">
                  {dispatch(t("common.notes"))}
                </Typography>

                {entity.notes ? (
                  <div
                    className="max-w-none ml-24 prose dark:prose-invert"
                    dangerouslySetInnerHTML={{ __html: entity.notes }}
                  />
                ) : (
                  <div className="max-w-none ml-24 prose dark:prose-invert">
                    <Typography
                      className="text-md truncate"
                      color="text.secondary"
                    >
                      {dispatch(t("common.no_records"))}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactView;
