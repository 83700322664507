import { useDispatch } from "react-redux";
import { selectDrawerState, setDrawerState } from "../store/drawerSlice";
import { t } from "app/store/labels/globalLabels";
import { useSelector } from "react-redux";
import { Drawer } from "rsuite";
import PushForm from "./PushForm";

const DrawerForm = () => {
  const dispatch = useDispatch();
  const state = useSelector(selectDrawerState);

  const handleClose = () => {
    dispatch(setDrawerState(false));
  };

  return (
    <>
      <Drawer backdrop={true} open={state} onClose={handleClose}>
        <Drawer.Header>
          <Drawer.Title>{dispatch(t("common.add"))}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>{state && <PushForm />}</Drawer.Body>
      </Drawer>
    </>
  );
};

export default DrawerForm;
