import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import history from "@history";
import ContactModel from "../model/ContactModel";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import axiosService from "src/app/auth/services/axios/axiosService";

export const getContact = createAsyncThunk(
  "contactsApp/task/getContact",
  async (id, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.get(`/users/${id}`).then((res) => {
        return {
          id: res.data.user_id,
          tags: [],
          emails: [],
          ...res.data,
        };
      });

      if (response.error) throw response.error.message;

      const data = await response;
      return data;
    } catch (error) {
      history.push({ pathname: `/apps/contacts` });
      return null;
    }
  }
);

export const addContact = createAsyncThunk(
  "contactsApp/contacts/addContact",
  async (contact, { dispatch, getState }) => {
    try {
      const dataContact = {
        "attributes[name]": contact.name,
        "attributes[email]": contact.emails,
      };

      const response = await axiosConfig.post("/users", dataContact);

      if (response.status === "error") return null;

      const data = response.result;

      const teste = {
        id: data.last_id,
        name: contact.name,
        email: contact.emails[0].email,
      };

      if (data?.error) throw data.error.message;

      return teste;
    } catch (error) {
      history.push({ pathname: `/customers` });
      return null;
    }
  }
);

export const updateContact = createAsyncThunk(
  "contactsApp/contacts/updateContact",
  async (contact, { dispatch, getState }) => {
    const response = await axios.put(`/api/contacts/${contact.id}`, contact);

    const data = await response.data;

    return data;
  }
);

export const removeContact = createAsyncThunk(
  "contactsApp/contacts/removeContact",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/api/contacts/${id}`);

    await response.data;

    return id;
  }
);

export const selectContact = ({ contactsApp }) => {
  return contactsApp.contact;
};

const contactSlice = createSlice({
  name: "contactsApp/contact",
  initialState: null,
  reducers: {
    newContact: (state, action) => ContactModel(),
    resetContact: () => null,
  },
  extraReducers: {
    [getContact.pending]: (state, action) => null,
    [getContact.fulfilled]: (state, action) => action.payload,
    [updateContact.fulfilled]: (state, action) => action.payload,
    [removeContact.fulfilled]: (state, action) => null,
  },
});

export const { resetContact, newContact } = contactSlice.actions;

export default contactSlice.reducer;
