import _ from "@lodash";

const UserModel = (data) =>
  _.defaults(data || {}, {
    user_id: null,
    picture: null,
    picture_src: null,
    background: null,
    name: "",
    email: "",
    lang: "pt",
    phone_number: "",
    phone_country_code: "pt",
    email_signature: "",
    birthdate: null,
    access_level_id: null,
    workspace_id: null,
    signin_token_required: false,
  });

export const UserModelMerge = (data) =>
  _.mergeWith(
    {
      phone_number: "",
      phone_country_code: "pt",
      email_signature: "",
      access_level_id: null,
      workspace_id: null,
      lang: "pt",
      signin_token_required: false,
    },
    data,
    (objValue, srcValue, key) => {
      // if (key == "signin_token_required")
      //   return Boolean(srcValue) ? true : false;

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export default UserModel;
