import moment from "moment";
import countries from "../main/components/countriesPhone";
import _ from "@lodash";

/**
 * @param {*} dateString data para formatar
 * @returns format date to DD/MM/YYYY HH:MM:SS
 */
export const FormatDate = (dateString, hasTime) => {
  const date = new Date(dateString);
  const formattedDate = !hasTime
    ? date.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
    : date.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
  return formattedDate.replace(",", "");
};

/**
 * @param {*} date data para formatar
 * @returns format date to YYYY-MM-DD
 */
export const FormatDateYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

/**
 * @param {*} date data para formatar
 * @returns format date to YYYY-MM-DD HH:MM:SS
 */
export const FormatDateSQL = (dateString, hasTime) => {
  if (dateString == null || dateString == undefined) return "";

  const newDate = new Date(dateString);

  return `${String(newDate.getFullYear())}-${(newDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${newDate.getDate().toString().padStart(2, "0")} ${
    hasTime
      ? `${newDate.getHours().toString().padStart(2, "0")}:${newDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${newDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`
      : ""
  }`;
};

export const getCountryByIso = (iso) => {
  return countries.find((country) => country.iso === iso);
};

export const getLanguageByCode = (id, languages) => {
  return languages.find((l) => l.id === id);
};

export const correctDate = (date, mili) => {
  const [datePart, timePart] = date.split(" ");
  const [day, month, year] = datePart.split("/");
  const [hours, minutes] = timePart.split(":");

  return mili
    ? new Date(year, month - 1, day, hours, minutes).getTime()
    : new Date(year, month - 1, day, hours, minutes);
};

/**
 * @param {*} date para ser o parametro da conta
 * @returns diff: diffInDays, expired: true or false,
 */
export const DateDiff = (dateStart, dateEnd) => {
  if (!dateStart || !dateEnd)
    return {
      diff: 0,
      begin: false,
      expired: false,
      isDay: false,
    };

  const start = moment(correctDate(FormatDate(dateStart, true), true));
  const end = moment(correctDate(FormatDate(dateEnd, true), true));
  const today = moment(new Date().getTime());

  const diff =
    today < start ? start.diff(today) : today > end ? today.diff(end) : 0;

  if (diff == 0)
    return {
      diff: 0,
      begin: false,
      expired: false,
      isDay: false,
    };

  const diffDuration = moment.duration(diff);
  const result =
    diff > 86400000
      ? diffDuration.asDays().toFixed()
      : `${diffDuration.hours()}:${diffDuration.minutes()}`;

  return {
    diff: result,
    begin: today < start,
    expired: today > end,
    isDay: diff < 86400000,
  };
};

export const isObjectEmpty = (obj) => {
  if (_.isEmpty(obj)) {
    return true;
  }

  return _.every(obj, (value) => {
    if (_.isObject(value)) {
      return isObjectEmpty(value);
    }
    return _.isEmpty(value);
  });
};

export const formatText = (value, formatChar, charsLength, charsLimit) => {
  const alphanumericValue = value.toUpperCase().replace(/[^A-Za-z0-9]/g, "");
  const formattedValue = alphanumericValue
    .match(new RegExp(`.{1,${charsLength}}`, "g"))
    .join(formatChar);

  const textSplit = formattedValue.slice(0, charsLimit);

  return textSplit;
};
