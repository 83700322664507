import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const fetchAccessTableList = createAsyncThunk(
  "access_logs/table/fetchAccessTableList",
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setIsLoading(true));
      const res = await axiosConfig.get(`/request_logs/${_}/requests`);

      if (res.status === "error") return [];

      const data = res.result.map((item) => {
        const parts = item.date_time.split(" ");
        const datePart = parts[0];

        const [year, month, day] = datePart.split("-");
        const formattedDate = `${day}/${month}/${year}`;

        return {
          ip: item.ip,
          date_time: `${formattedDate} ${parts[1]}`,
          method: item.method,
          url: item.url,
          data:
            item.data !== null &&
            typeof item.data === "object" &&
            Object.keys(item.data).length > 0
              ? JSON.stringify(item.data)
              : "",
          headers: item.headers,
        };
      });

      dispatch(setIsLoading(false));

      return data;
    } catch (error) {
      return [];
    }
  }
);

export const showUser = createAsyncThunk(
  "access_logs/table/showUser",
  async (token, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/access_tokens/${token}`);

      if (res.status === "error") return [];

      return res.result;
    } catch (error) {
      return [];
    }
  }
);

const access_logsSlice = createSlice({
  name: "access_logs/table",
  initialState: {
    access_logsTable: [],
    user: {},
    isLoading: false,
    status: "",
  },

  reducers: {
    setAccess_logsTable: (state, action) => {
      state.access_logsTable = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccessTableList.pending, (state) => {
      state.status = "pending";
    }),
      builder.addCase(fetchAccessTableList.fulfilled, (state, action) => {
        state.access_logsTable = action.payload;
        state.status = "success";
      }),
      builder
        .addCase(fetchAccessTableList.rejected, (state) => {
          state.access_logsTable = [];
          state.status = "reject";
        })
        .addCase(showUser.fulfilled, (state, action) => {
          state.user = action.payload;
          state.status = "success";
        });
  },
});

export const {
  setAccess,
  setSearchValues,
  setActiveAccess_logs,
  setIsLoading,
  setUser,
} = access_logsSlice.actions;

export const selectAccessTable = ({ access_logs }) =>
  access_logs.table.access_logsTable;
export const selectUser = ({ access_logs }) => access_logs.table.user;
export const selectStatus = ({ access_logs }) => access_logs.table.status;
export const selectIsLoading = ({ access_logs }) => access_logs.table.isLoading;

export default access_logsSlice.reducer;
