import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import Error404PageConfig from "../main/404/Error404PageConfig";
import maintenancePageConfig from "../main/maintenance/maintenancePageConfig";
// import Webkey from "src/modules/pages/user/devices/voyager/webkey/Webkey";

// SYSTEM
import systemConfig from "../main/system/SystemConfig";
import DashboardAppConfig from "../main/dashboards/DashboardAppConfig";
import loginConfig from "../main/login/LoginConfig";

// MODULES
import UserNotificationsConfig from "src/modules/pages/user/notifications/NotificationsConfig";
import devicesConfig from "src/modules/pages/user/devices/DevicesConfig";
import ReportConfig from "src/modules/pages/reports/ReportConfig";
import ContactsConfig from "src/modules/pages/user/contacts/ContactsConfig";
import WebkeyConfig from "src/modules/pages/user/devices/voyager/webkey/WebkeyConfig";
import GatewaysConfig from "src/modules/pages/user/gateways/GatewaysConfig";
import accessPlansConfig from "src/modules/pages/access_plans/AccessPlansConfig";
// import DevicesConfig from "src/modules/pages/user/devices/DevicesConfig";

const routeConfigs = [
  ...devicesConfig,
  ...systemConfig,
  ...loginConfig,
  ...accessPlansConfig,
  ReportConfig,
  DashboardAppConfig,
  UserNotificationsConfig,
  Error404PageConfig,
  ContactsConfig,
  WebkeyConfig,
  maintenancePageConfig,
  GatewaysConfig,
  // DevicesConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  // {
  //   path: "404",
  //   element: <Error404Page />,
  // },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
