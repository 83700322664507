import FusePageSimple from "@fuse/core/FusePageSimple";
import _ from "@lodash";
import { styled } from "@mui/material/styles";
import Notifications from "./Notifications";
import { Typography } from "@mui/material";
import { Timeline } from "@mui/lab";
import ActivityTimelineItem from "./ActivityTimelineItem";
// import exampleActivitiesData from "./exampleActivitiesData";
import { useThemeMediaQuery } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";
import sub from "date-fns/sub";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.default,
    boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
  },
}));

const NotificationsApp = (props) => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const labels = useSelector(selectGlobalLabels);

  const exampleActivitiesData = [
    {
      id: "493190c9-5b61-4912-afe5-78c21f1044d7",
      icon: "heroicons-solid:star",
      description: dispatch(t("NOTIFICATIONS.example_1_added_device")),
      date: sub(new Date(), { minutes: 25 }),
      extraContent: `<div class="font-bold">${dispatch(
        t("NOTIFICATIONS.example_2_added_device")
      )}</div><br>
    <div>${dispatch(t("NOTIFICATIONS.example_3_added_device"))}</div>`,
    },
    {
      id: "6e3e97e5-effc-4fb7-b730-52a151f0b641",
      image: "assets/images/avatars/male-04.jpg",
      description: `<strong>${dispatch(
        t("NOTIFICATIONS.example_4_added_device")
      )}</strong> ${dispatch(t("NOTIFICATIONS.example_5_added_device"))}`,
      date: sub(new Date(), { minutes: 50 }),
    },
    {
      id: "b91ccb58-b06c-413b-b389-87010e03a120",
      icon: "heroicons-solid:mail",
      description: dispatch(t("NOTIFICATIONS.example_6_added_device")),
      date: sub(new Date(), { hours: 3 }),
      linkedContent: dispatch(t("NOTIFICATIONS.example_7_added_device")),
    },
    {
      id: "541416c9-84a7-408a-8d74-27a43c38d797",
      icon: "heroicons-solid:refresh",
      description: `${dispatch(
        t("NOTIFICATIONS.example_8_added_device")
      )} <strong>${dispatch(
        t("NOTIFICATIONS.example_9_added_device")
      )}</strong>`,
      date: sub(new Date(), { hours: 5 }),
      linkedContent: dispatch(t("NOTIFICATIONS.example_10_added_device")),
      link: ".",
      useRouter: true,
    },
  ];

  return (
    <FusePageSimple
      content={
        <div className="flex flex-col flex-auto px-24 py-40 sm:px-64 sm:pt-72 sm:pb-80">
          <Typography className="text-4xl font-extrabold tracking-tight leading-none">
            {dispatch(t("NOTIFICATIONS.title"))}
          </Typography>
          <Typography className="mt-6 text-lg" color="text.secondary">
            {dispatch(t("NOTIFICATIONS.subtitle"))}
          </Typography>
          <Timeline
            className="py-48"
            position="right"
            sx={{
              "& .MuiTimelineItem-root:before": {
                display: "none",
              },
            }}
          >
            {exampleActivitiesData.map((item, index) => (
              <ActivityTimelineItem
                last={exampleActivitiesData.length === index + 1}
                item={item}
                key={item.id}
              />
            ))}
          </Timeline>
        </div>
      }
      scroll={isMobile ? "normal" : "page"}
    />
  );
};

export default NotificationsApp;
