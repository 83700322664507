import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import history from "@history";
import UserModel, { UserModelMerge } from "../model/UserModel";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const getUser = createAsyncThunk(
  "usersApp/task/getUser",
  async (id, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.get(
        `/users/${id}?session_data=1&menu_id=1`
      );

      const workspaceUser =
        response?.result?.workspaces_list &&
        response?.result?.workspaces_list.length > 0
          ? await response?.result?.workspaces_list.find((w) =>
              Boolean(w.is_user_workspace)
            )
          : null;

      const data = await {
        ...response.result,
        id: response.result.user_id,
        workspace_id: workspaceUser?.workspace_id,
        workspace_name: workspaceUser?.name,
      };

      return UserModelMerge(data);
    } catch (error) {
      history.push({ pathname: `/system/users` });

      return null;
    }
  }
);

export const addUser = createAsyncThunk(
  "usersApp/users/addUser",
  async (user, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/users`, user)
        .then((response) => {
          if (response.status === "error") return reject(null);

          const data = {
            ...user,
            id: response.result.last_id,
            user_id: response.result.last_id,
          };

          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }
);

export const updateUser = createAsyncThunk(
  "usersApp/users/updateUser",
  async (user, { dispatch, getState }) => {
    const response = await axiosConfig.post(`/users/${user.user_id}`, user);

    const updatedUser = await axiosConfig.get(`/users/${user.user_id}`);

    const data = await {
      ...updatedUser.result,
      id: updatedUser.result.user_id,
      phoneNumbers: [
        {
          country: updatedUser.result?.phone_country_code || "pt",
          phoneNumber: updatedUser.result?.phone_number || "",
        },
      ],
    };

    return data;
  }
);

export const removeUser = createAsyncThunk(
  "usersApp/users/removeUser",
  async (id, { dispatch, getState }) => {
    const response = await axiosConfig.delete(`/users/${id}`);

    await response.data;

    return id;
  }
);

export const selectUser = ({ usersApp }) => {
  return usersApp.user;
};

const userSlice = createSlice({
  name: "usersApp/user",
  initialState: null,
  reducers: {
    newUser: (state, action) => UserModel(),
    resetUser: () => null,
  },
  extraReducers: {
    [getUser.pending]: (state, action) => null,
    [getUser.fulfilled]: (state, action) => action.payload,
    [updateUser.fulfilled]: (state, action) => action.payload,
    [removeUser.fulfilled]: (state, action) => null,
  },
});

export const { resetUser, newUser } = userSlice.actions;

export default userSlice.reducer;
