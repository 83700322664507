import { MaterialReactTable } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchErrorsList,
  selectFilteredErrors,
  selectStatus,
} from "./store/errorsSlice";
import { selectLanguages, selectTableLanguage } from "app/store/i18nSlice";
import { t } from "app/store/labels/globalLabels";
import { useMemo } from "react";
import { SpeedDial, SpeedDialAction, Typography } from "@mui/material";
import {
  FormatListBulletedOutlined,
  RefreshOutlined,
} from "@mui/icons-material";

const Error_logs = () => {
  const dispatch = useDispatch();
  const errors = useSelector(selectFilteredErrors);
  const loading = useSelector(selectStatus);
  const tableLang = useSelector(selectTableLanguage);
  const lang = useSelector(selectLanguages);

  const columns = useMemo(() => {
    const errorsInfo = [
      {
        header: dispatch(t("common.file")),
        accessorKey: "file",
        size: 150,
      },
      {
        header: dispatch(t("common.error")),
        accessorKey: "error",
        size: 200,
        Cell: ({ row }) => {
          return (
            <Typography className="break-words whitespace-normal">
              {row.original.error}
            </Typography>
          );
        },
      },
      {
        header: dispatch(t("common.date")),
        accessorKey: "date_time",
        size: 130,
      },
      {
        header: dispatch(t("common.path")),
        accessorKey: "file_path",
        size: 200,
      },
      {
        header: dispatch(t("common.line")),
        accessorKey: "line",
        maxSize: 95,
      },
    ];
    return [...errorsInfo];
  }, [errors, lang]);

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={errors}
        localization={tableLang}
        enableStickyHeader
        enableColumnOrdering
        enableGrouping
        enablePinning
        enableColumnResizing
        muiTableContainerProps={{ sx: { maxHeight: "calc(100vh - 332px)" } }}
        initialState={{
          sorting: [{ id: "date_time", desc: true }],
          pagination: { pageIndex: 0, pageSize: 15 },
        }}
        state={{
          showSkeletons: loading !== "success" ? true : false,
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        defaultColumn={{ minSize: 20 }}
      />

      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh_table"))}
          tooltipOpen
          onClick={() => dispatch(fetchErrorsList())}
        />
      </SpeedDial>
    </div>
  );
};

export default Error_logs;
