import FuseUtils from "@fuse/utils/FuseUtils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import {
  createNavigation,
  removeNavigationItem,
  resetNavigation,
  updateNavigationItem,
} from "./navigationTemplatesSlice";
import templatesService from "../service/templatesService";
import TemplateModel, {
  TemplateNaviModel,
  TemplateNewModel,
  TemplateNewNaviModel,
} from "../model/TemplateModel";
import blocksService from "../service/blocksService";
import BlockNewModel, {
  BlockModelDemerge,
  BlockModelMerge,
  BlockParseModel,
} from "../model/BlockModel";

export const getBlocks = createAsyncThunk(
  "template/blocks/getBlocks",
  async (id, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/posts/templates/${id}/blocks`);

      if (res.status === "error") return [];

      const newItems = res.result.map((i) => BlockModelMerge(i));

      return newItems;
    } catch (error) {
      return [];
    }
  }
);

export const getBlock = createAsyncThunk(
  "template/blocks/getBlock",
  async ({ template, block }, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(
        `/posts/templates/${template}/blocks/${block}`
      );

      if (res.status === "error") return BlockNewModel();

      return BlockModelMerge(res.result);
    } catch (error) {
      return BlockNewModel();
    }
  }
);

export const addBlock = createAsyncThunk(
  "template/blocks/addBlock",
  async (data, { dispatch, getState }) => {
    try {
      const {
        templates: { template },
        blocks: { block },
      } = getState().template;
      const { language } = getState().i18n;

      const res = await blocksService.addBlock(
        template,
        BlockModelDemerge(data)
      );

      const newItem = {
        ...data,
        post_template_id: res.result.last_id,
        post_template_in: template.post_template_id,
        name: data.name_lang[language] || `[${data.name_tag}]`,
      };

      return newItem;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateBlock = createAsyncThunk(
  "template/blocks/updateBlock",
  async (data, { dispatch, getState }) => {
    try {
      const {
        templates: { template },
        blocks: { block },
      } = getState().template;
      const { language } = getState().i18n;
      const res = await blocksService.updateBlock(
        template,
        block,
        BlockModelDemerge(data)
      );

      const newItem = {
        ...data,
        name: data.name_lang[language] || `[${data.name_tag}]`,
      };

      return newItem;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const removeBlock = createAsyncThunk(
  "template/blocks/removeBlock",
  async (data, { dispatch, getState }) => {
    try {
      const {
        templates: { template },
        blocks: { block },
      } = getState().template;
      const res = await blocksService.deleteBlock(template, block);

      return block;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const reorderBlocks = createAsyncThunk(
  "template/blocks/reorderBlocks",
  async ({ sourceIndex, destinationIndex }, { dispatch, getState }) => {
    try {
      const {
        templates: { template },
        blocks: { blocks },
      } = getState().template;

      const updatedList = [...blocks];
      const [removed] = updatedList.splice(sourceIndex, 1);
      updatedList.splice(destinationIndex, 0, removed);
      dispatch(setBlocks(updatedList));

      const res = await blocksService.reorderBlocks(template, updatedList);

      return updatedList;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const blocksSlice = createSlice({
  name: "template/blocks",
  initialState: {
    blocks: [],
    block: {},
    status: "pending",
  },
  reducers: {
    newBlock: (state, action) => {
      state.block = BlockNewModel();
    },
    resetBlock: (state) => {
      state.block = {};
    },
    resetBlocks: (state) => {
      state.blocks = [];
    },
    setBlocks: (state, action) => {
      state.blocks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlocks.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getBlocks.fulfilled, (state, action) => {
        state.blocks = action.payload;
        state.status = "fulfilled";
      })
      .addCase(getBlocks.rejected, (state, action) => {
        state.blocks = [];
        state.status = "rejected";
      })
      .addCase(getBlock.fulfilled, (state, action) => {
        state.block = action.payload;
      })
      .addCase(addBlock.fulfilled, (state, action) => {
        state.blocks.push(action.payload);
      })
      .addCase(addBlock.rejected, (state, action) => {})
      .addCase(updateBlock.fulfilled, (state, action) => {
        const index = state.blocks.findIndex(
          (t) => t.post_template_id == action.payload.post_template_id
        );

        state.blocks[index] = action.payload;
      })
      .addCase(removeBlock.fulfilled, (state, action) => {
        const index = state.blocks.findIndex(
          (t) => t.post_template_id == action.payload.post_template_id
        );

        state.blocks = state.blocks.splice(index, 1);
      })
      .addCase(reorderBlocks.fulfilled, (state, action) => {
        state.blocks = action.payload;
      });
    // .addCase(getTemplate.fulfilled, (state, action) => {
    //   state.template = action.payload;
    // });
  },
});

export const { newBlock, resetBlock, resetBlocks, setBlocks } =
  blocksSlice.actions;

export const selectBlocks = ({ template }) => template.blocks;
export const selectBlock = ({ template }) => template.blocks.block;

export default blocksSlice.reducer;
