import { useSelector } from "react-redux";
import { selectFilterGateways, selectStatus } from "./store/gatewaysSlice";
import { motion } from "framer-motion";
import VoyagerGateways from "./types/voyager/VoyagerGateways";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { t } from "app/store/labels/globalLabels";
import SkeletonVoyagerGateways from "./types/voyager/SkeletonVoyagerGateways";
import NoResults from "src/app/main/components/noResults/NoResults";

const Gateways = () => {
  const dispatch = useDispatch();
  const gateways = useSelector(selectFilterGateways);
  const loading = useSelector(selectStatus);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div>
      {_.isEmpty(gateways) && loading == "success" ? (
        <div className="w-full flex items-center justify-center self-center">
          <NoResults className={"opacity-40"} />
        </div>
      ) : (
        <motion.div
          className="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-32"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {loading !== "success"
            ? Array.from({ length: 3 }, (value, index) => index).map(
                (_value, _index) => <SkeletonVoyagerGateways key={_index} />
              )
            : gateways?.map((gateways, _index) => (
                <motion.div variants={item} key={gateways.deviceId}>
                  <VoyagerGateways gateways={gateways} />
                </motion.div>
              ))}
        </motion.div>
      )}
    </div>
  );
};

export default Gateways;
