import { lazy } from "react";
import { Navigate } from "react-router-dom";
import TemplateForm from "./components/TemplateForm";
import TemplateSelectBlock from "./components/SelectItem";

import GroupForm from "./components/groups/GroupForm";
import BlockForm from "./components/blocks/BlockForm";

const TemplatesApp = lazy(() => import("./TemplatesApp"));

const TemplatesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/system/templates",
      children: [
        {
          path: "",
          element: <Navigate to="/system/templates/none" />,
        },
        {
          path: ":template",
          element: <TemplatesApp />,
          children: [
            {
              path: "",
              element: <TemplateSelectBlock />,
            },
            {
              path: ":block/block",
              element: <BlockForm />,
            },
            {
              path: ":block/group",
              element: <GroupForm />,
            },
            {
              path: ":form/form",
              element: <TemplateForm />,
            },
          ],
        },
      ],
    },
  ],
};

export default TemplatesConfig;
