import {
  createEntityAdapter,
  createSelector,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import FuseUtils from "@fuse/utils";
import i18next from "i18next";
import _ from "@lodash";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();

const navigationSlice = createSlice({
  name: "navigation",
  initialState: emptyInitialState,
  reducers: {
    setNavigation: navigationAdapter.setAll,
    resetNavigation: (state, action) => emptyInitialState,
  },
  extraReducers: {},
});

export const mountNavigation = (navigation) => {
  const arrayNew = [];

  for (let index = 0; index < navigation.length; index++) {
    const element = navigation[index];

    arrayNew.push({
      id: element.title_tag,
      element: element.element,
      url: element.url_tabs,
      icon: element.icon,
      type: element.type,
      title: element.title || `[${element.title_tag}]`,
      subtitle: element.subtitle,
      menu_item_id: element.menu_item_id,
    });

    if (element.subitems)
      arrayNew[index].children = mountNavigation(element.subitems);
  }

  return arrayNew;
};

// const initialState = navigationAdapter.upsertMany(emptyInitialState, fetchNavigation());

export const appendNavigationItem =
  (item, parentId) => (dispatch, getState) => {
    const navigation = selectNavigationAll(getState());

    return dispatch(
      setNavigation(FuseUtils.appendNavItem(navigation, item, parentId))
    );
  };

export const prependNavigationItem =
  (item, parentId) => (dispatch, getState) => {
    const navigation = selectNavigationAll(getState());

    return dispatch(
      setNavigation(FuseUtils.prependNavItem(navigation, item, parentId))
    );
  };

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const {
  selectAll: selectNavigationAll,
  selectIds: selectNavigationIds,
  selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors((state) => state.fuse.navigation);

export const { setNavigation, resetNavigation } = navigationSlice.actions;

const getUserRole = (state) => state.user.role;

export const selectNavigation = createSelector(
  [selectNavigationAll, ({ i18n }) => i18n.language, getUserRole],
  (navigation, language, userRole) => {
    function setTranslationValues(data) {
      // loop through every object in the array
      return data.map((item) => {
        if (item.translate && item.title) {
          item.title = i18next.t(`navigation:${item.translate}`);
        }

        // see if there is a children node
        if (item.children) {
          // run this function recursively on the children array
          item.children = setTranslationValues(item.children);
        }
        return item;
      });
    }

    return setTranslationValues(
      _.merge(
        [],
        filterRecursively(navigation, (item) =>
          FuseUtils.hasPermission(item.auth, userRole)
        )
      )
    );
  }
);

function filterRecursively(arr, predicate) {
  return arr.filter(predicate).map((item) => {
    item = { ...item };
    if (item.children) {
      item.children = filterRecursively(item.children, predicate);
    }
    return item;
  });
}

export const selectFlatNavigation = createSelector(
  [selectNavigation],
  (navigation) => FuseUtils.getFlatNavigation(navigation)
);

export const fetchNavigation = createAsyncThunk(
  "navigation/fetchNavigation",
  async (lang, { dispatch, getState }) => {
    const { user } = getState();

    const navMenu = await axiosConfig
      .get(`?menu_id=1&lang=${lang}`)
      .then((res) => {
        if (res?.status === "error") {
          return null;
        }
        return res?.result.workspace.menu;
      })
      .catch((err) => {});

    if (navMenu) {
      const newNavMenu = mountNavigation(navMenu);
      dispatch(setNavigation(newNavMenu));
      return newNavMenu;
    }

    return navMenu;
  }
);

export default navigationSlice.reducer;
