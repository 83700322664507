import { combineReducers } from "@reduxjs/toolkit";
import table from "./tableSlice";
import drawer from "./drawerSlice";

const reducer = combineReducers({
  table,
  drawer,
});

export default reducer;
