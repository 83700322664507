import GatewaysApp from "./GatewaysApp";

const GatewaysConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "user/gateways",
      element: <GatewaysApp />,
    },
  ],
};

export default GatewaysConfig;
