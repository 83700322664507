import FuseUtils from "@fuse/utils/FuseUtils";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import menu from "../config/menu";
import { t } from "app/store/labels/globalLabels";

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(emptyInitialState, menu);

export const changeLanguage = () => (dispatch, getState) => {
  dispatch(
    updateNavigationItem("none", {
      title: dispatch(t("common.all")),
    })
  );
};

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

const prependNavigationItem = (item, parentId) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(
    setNavigation(FuseUtils.appendNavItem(navigation, item, parentId))
  );
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState());

  return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const {
  selectAll: selectNavigationAll,
  selectIds: selectNavigationIds,
  selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors(
  (state) => state.error_logs.navigationError_logs
);

const navigationError_logsSlice = createSlice({
  name: "error_logs/navigationError_logs",
  initialState,
  reducers: {
    setNavigation: navigationAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },
  extraReducers: {},
});

export const createNavigation = (items) => async (dispatch, getState) => {
  items.map((error_logs) => {
    dispatch(
      prependNavigationItem(
        {
          ...[error_logs.file],
          id: `${error_logs.file}`,
          title: `${error_logs.file.split("API_server_root")[1]}`,
          type: "item",
          hasActions: true,
          // badge: {
          //   title: error_logs.data.length(),
          //   classes:
          //     "h-20 bg-deep-purple text-white rounded-full justify-center",
          // },
        },
        "error_logs"
      )
    );
  });
};

export const { setNavigation, resetNavigation } =
  navigationError_logsSlice.actions;

export const selectNavigation = createSelector(
  [selectNavigationAll],
  (navigation) => {
    return navigation;
  }
);

export default navigationError_logsSlice.reducer;
