import { element } from "prop-types";
import axiosConfig, {
  axiosInitial,
} from "../../../../auth/services/axios/axiosConfig";

class LanguageService {
  addRow = (values, languages) => {
    const data = new FormData();

    data.append("system_lang[]", values.lang.split(".")[1]);

    for (let index = 0; index < languages.length; index++) {
      const element = languages[index].code;
      data.append("system_lang[]", element);
    }

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/APIconfig`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  addFlag = (flag, row) => {
    const data = new FormData();

    data.append("code", row);
    data.append("file", flag);

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/assets_manager/flags`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })

        .catch((err) => {
          return reject(err);
        });
    });
  };

  updateOrder = (values) => {
    const lang = new FormData();
    const system_lang = new FormData();

    for (let index = 0; index < values.length; index++) {
      const element = values[index].code;
      if (values[index].active) lang.append("lang[]", element);
      system_lang.append("system_lang[]", element);
    }

    const langPush = [];
    const final = new FormData();
    langPush.push(...lang);
    langPush.push(...system_lang);
    langPush.forEach((arr) => final.append(arr[0], arr[1]));

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/APIconfig`, final)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  updateActiveLang = (values) => {
    const data = new FormData();

    values.map((item) => {
      Boolean(item.active) && data.append("lang[]", item.code);
    });

    const langValues = data.getAll("lang[]");

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/APIconfig`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  removeActiveRow = (values, languages) => {
    const data = new FormData();

    const selectedLang = values.original.code;

    const filteredLangs = languages.filter(
      (lang) => lang.code !== selectedLang
    );

    for (let index = 0; index < filteredLangs.length; index++) {
      const element = filteredLangs[index].code;
      data.append("lang[]", element);
    }

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/APIconfig`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };

  deleteRow = (code, languages, state) => {
    const data = new FormData();

    const filteredLangs = languages.filter((lang) => lang.code !== code);

    if (state === true) {
      for (let index = 0; index < filteredLangs.length; index++) {
        const element = filteredLangs[index].code;
        data.append("system_lang[]", element);
      }
      data.append("remove_labels", 1);
    } else {
      for (let index = 0; index < filteredLangs.length; index++) {
        const element = filteredLangs[index].code;
        data.append("system_lang[]", element);
      }
    }

    return new Promise((resolve, reject) => {
      axiosConfig
        .post(`/APIconfig`, data)
        .then((res) => {
          if (res.status === "error") return reject(res);

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

const languageService = new LanguageService();
export default languageService;
