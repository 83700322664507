import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import {
  changeLanguage,
  createNavigation,
  resetNavigation,
} from "./navigationWorkspacesSlices";
import FuseUtils from "@fuse/utils/FuseUtils";

export const fetchWorkspaces = createAsyncThunk(
  "menu/workspaces/fetchWorkspaces",
  async (id, { dispatch, getState }) => {
    const { language } = getState().i18n;
    dispatch(resetNavigation());
    dispatch(setActiveWorkspace(null));
    try {
      const res = await axiosConfig.get(`/workspaces?lang=${id || language}`);

      if (res.status === "error") return [];

      if (res.result.length > 0) dispatch(createNavigation(res.result));

      dispatch(changeLanguage());

      return res.result;
    } catch (error) {
      return [];
    }
  }
);

export const filterWorkspace = (text) => (dispatch, getState) => {
  const { workspaces } = getState().menu.workspaces;

  dispatch(resetNavigation());
  dispatch(setActiveWorkspace(null));
  if (text.length === 0) return dispatch(createNavigation(workspaces));

  const filteredGroups = FuseUtils.filterArrayByString([...workspaces], text);

  dispatch(setFilteredWorkspaces(filteredGroups));
  return dispatch(createNavigation(filteredGroups));
};

const workspacesSlice = createSlice({
  name: "menu/workspaces",
  initialState: {
    workspaces: [],
    workspacesFiltered: [],
    workspaceInfo: {},
    activeWorkspace: null,
    status: "idle",
  },
  reducers: {
    setActiveWorkspace: (state, action) => {
      const id = action.payload;

      if (id === "none" || !id) state.activeWorkspace = null;
      else state.activeWorkspace = action.payload;
    },
    setFilteredWorkspaces: (state, action) => {
      state.workspacesFiltered = action.payload;
    },
    resetWorkspaces: (state, action) => {
      state.workspacesFiltered = state.workspaces;
    },
    setWorkspaceInfo: (state, action) => {
      state.workspaceInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkspaces.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchWorkspaces.fulfilled, (state, action) => {
        state.workspaces = action.payload;
        state.status = "fulfilled";
      })
      .addCase(fetchWorkspaces.rejected, (state, action) => {
        state.workspaces = [];
        state.status = "rejected";
      });
  },
});

export const {
  setActiveWorkspace,
  setFilteredWorkspaces,
  resetWorkspaces,
  setWorkspaceInfo,
} = workspacesSlice.actions;

export const selectWorkspaces = ({ menu }) => menu.workspaces;

export default workspacesSlice.reducer;
