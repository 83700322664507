import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { selectNavigationAll } from "app/store/fuse/navigationSlice";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import history from "@history";

export const getSecurity = createAsyncThunk(
  "configurations/security/getSecurity",
  async (_, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.get(`/APIconfig`);

      if (res.status === "error") return Promise.reject(res);

      return res.result;
    } catch (error) {
      history.push({ pathname: `/system/configurations` });
      return Promise.reject(error);
    }
  }
);

export const updateSecurity = createAsyncThunk(
  "configurations/security/updateSecurity",
  async (data, { dispatch, getState }) => {
    try {
      const res = await axiosConfig.post(`/APIconfig`, data);

      if (res.status === "error") throw new Error(res);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const securitySlice = createSlice({
  name: "configurations/security",
  initialState: {
    apiConfig: {
      API_under_maintenance: 0,
      user: {
        signin_token_required: 0,
        can_signup: 0,
      },
    },
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSecurity.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getSecurity.fulfilled, (state, action) => {
        const {
          API_under_maintenance,
          user: { signin_token_required, can_signup },
        } = action.payload;

        state.apiConfig = {
          API_under_maintenance,
          user: {
            signin_token_required,
            can_signup,
          },
        };
        state.status = "fulfilled";
      })
      .addCase(getSecurity.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(updateSecurity.fulfilled, (state, action) => {
        state.apiConfig = action.payload;
      });
  },
});

export const selectSecurity = ({ configurations }) => configurations.security;

export default securitySlice.reducer;
