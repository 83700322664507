import { Cancel, CheckCircle, Close, Info, Warning } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import { SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalLabels, t } from "app/store/labels/globalLabels";

const SnackbarTemplate = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const labels = useSelector(selectGlobalLabels);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const bgColor = (type) => {
    if (type === "success") return "#43a047";
    if (type === "info") return "#2196f3";
    if (type === "error") return "#d32f2f";
    if (type === "warning") return "#ff9800";
  };

  const renderIcon = (type) => {
    if (type === "success") return <CheckCircle />;
    if (type === "info") return <Info />;
    if (type === "error") return <Cancel />;
    if (type === "warning") return <Warning />;
  };

  return (
    <SnackbarContent ref={ref}>
      <Card className="flex" style={{ backgroundColor: bgColor(props.type) }}>
        <Box className="flex items-center justify-center mx-10 opacity-85 text-white">
          {renderIcon(props.type)}
        </Box>
        <CardActions>
          <Typography className="flex text-white mr-5">
            {dispatch(t(props.message, { ...props.data }))}
          </Typography>
          <div className="flex justify-end items-center">
            <IconButton size="small" onClick={handleDismiss}>
              <Close className="text-white" fontSize="small" />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

export default SnackbarTemplate;
