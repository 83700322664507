import React, { createContext, useState, useEffect } from "react";

const OnlineStatusContext = createContext();

const OnlineStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const onlineHandler = () => {
      setIsOnline(true);
      setSnackbarMessage("You are online.");
    };

    const offlineHandler = () => {
      setIsOnline(false);
      setSnackbarMessage(
        "Oops! It seems you've lost your internet connection."
      );
    };

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider
      value={{ isOnline, snackbarMessage, setSnackbarMessage }}
    >
      {children}
    </OnlineStatusContext.Provider>
  );
};

export { OnlineStatusContext, OnlineStatusProvider };
