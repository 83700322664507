import _ from "@lodash";

const PushNotificationsModel = (obj) =>
  _.defaults(obj || {}, {
    users_list: [],
    recipients: "",
    send_date_time: "",
    title_lang: [],
    message_lang: [],
    device_type: [],
    image_url: "",
    picture: "",
    data: [
      {
        external_link: "",
        link_to: "",
      },
    ],
  });

export const PushNotificationsModelMerge = (obj) =>
  _.mergeWith(
    {
      users_list: [],
      recipients: "",
      send_date_time: "",
      title_lang: "",
      message_lang: "",
      device_type: [],
      picture: "",
      image: "",
      image_url: "",
      // data: [
      //   {
      //     key: "",
      //     value: "",
      //   },
      // ],
      url: "",
      screen: "",
    },
    obj,
    (objValue, srcValue, key) => {
      if (key === "message_lang" || key === "title_lang") {
        const json = JSON.parse(srcValue);
      }

      if (srcValue === null || srcValue === undefined) {
        return objValue;
      }
    }
  );

export const PushMerge = (obj, language) => {
  return _.mergeWith(
    {
      recipients: "",
      send_date_time: "",
      title_lang: [],
      message_lang: [],
      device_type: [],
      picture: "",
      image: "",
      image_url: "",
      image_src: "",
      data: {},
      total_pushnotif: 0,
      total_sended: 0,
      total_opened: 0,
    },
    obj,
    (objValue, srcValue, key) => {
      if (
        key === "device_type" ||
        key === "recipients_sent_to"
        // key === "data"
      ) {
        return srcValue ? JSON.parse(srcValue) : objValue;
      }

      if (key === "message_lang" || key === "title_lang") {
        const json = JSON.parse(srcValue);

        if (json?.all) {
          return json.all;
        } else {
          return json[language];
        }
      }

      if (srcValue === null || srcValue === undefined) {
        return undefined;
      }
    }
  );
};

export const ActionModel = (data) =>
  _.defaults(data || {}, {
    key: "",
    value: "",
  });

export default PushNotificationsModel;
