const menu = [
  {
    id: "workspaces",
    title: "Workspaces",
    type: "group",
    active: false,
    hasActions: true,
    children: [
      {
        id: "none",
        title: "No filter",
        type: "item",
        active: true,
      },
    ],
  },
];

export default menu;
