const menu = [
  {
    id: "gateways",
    title: "Gateways",
    type: "group",
    hasActions: true,
    children: [
      {
        id: "all_gateways",
        title: "All Gateways",
        type: "item",
        icon: "material-outline:layers",
        active: true,
      },
    ],
  },
];

export default menu;
