import { selectGlobalLabels } from "app/store/labels/globalLabels";
import { useSnackbar } from "notistack";
import { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import history from "@history";
import { useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { AccessTimeOutlined, LockClockOutlined } from "@mui/icons-material";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import style from "./Webkey.module.css";
import { FormatDate } from "src/app/shared-components/Functions";

const Webkey = () => {
  const [search, setSearch] = useSearchParams();
  const [webkeyParams, setWebkeyParams] = useState({});
  const [token, setToken] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chkButton, setChkButton] = useState(true);

  const labels = useSelector(selectGlobalLabels);

  useEffect(() => {
    const data = async () => {
      try {
        const body = {
          token: search.get("tk"),
        };

        const res = await axiosConfig.post("/VOYAGER_WEBKEY", body);

        if (res.status === "error") {
          setError(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        setToken(search.get("tk"));
        return setWebkeyParams(res.result);
      } catch (error) {
        setError(true);
        setLoading(false);
        return;
      }
    };

    data();
  }, []);

  const handleClick = async () => {
    try {
      const body = {
        token,
        action: "OPEN",
      };

      const res = await axiosConfig.post("/VOYAGER_WEBKEY", body);
    } catch (error) {}
  };

  return !loading ? (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          {error ? (
            <>
              <Typography className="text-3xl font-extrabold tracking-tight leading-tight text-center">
                Webkey not valid!
              </Typography>

              <Typography className="mt-32 text-2xl font-semibold tracking-tight leading-tight text-center">
                If the error persists, contact your administrator
              </Typography>
            </>
          ) : (
            <>
              <Typography className="text-3xl font-extrabold tracking-tight leading-tight text-center">
                {webkeyParams.key_name}!
              </Typography>

              <Typography className="mt-32 text-2xl font-semibold tracking-tight leading-tight text-center">
                Welcome to your virtual key, {webkeyParams.key_user_name}!
              </Typography>

              <div className="flex flex-row justify-between mt-12">
                <Typography className="text-12 font-bold tracking-tight leading-tight text-center text-grey-500">
                  <AccessTimeOutlined fontSize="small" />{" "}
                  {webkeyParams.access_start
                    ? FormatDate(webkeyParams.access_start)
                    : " No start date defined"}
                </Typography>
                <Typography className="text-12 font-bold tracking-tight leading-tight text-center text-grey-500">
                  <AccessTimeOutlined fontSize="small" />{" "}
                  {webkeyParams.access_end
                    ? FormatDate(webkeyParams.access_end)
                    : " No end date defined"}
                </Typography>
              </div>

              <div className="flex justify-center items-center mt-20">
                <input
                  id="inpLock"
                  type="checkbox"
                  className={style.inpLock}
                  onChange={() => {
                    setChkButton(false);
                    setTimeout(() => {
                      setChkButton(true);
                    }, 12000);
                  }}
                  checked={chkButton}
                />
                <label
                  htmlFor="inpLock"
                  className={`${style.btnlock} justify-center items-center`}
                  onClick={handleClick}
                >
                  <svg width="90%" height="90%" viewBox="0 0 36 40">
                    <path
                      className={style.lockb}
                      d="M27 27C27 34.1797 21.1797 40 14 40C6.8203 40 1 34.1797 1 27C1 19.8203 6.8203 14 14 14C21.1797 14 27 19.8203 27 27ZM15.6298 26.5191C16.4544 25.9845 17 25.056 17 24C17 22.3431 15.6569 21 14 21C12.3431 21 11 22.3431 11 24C11 25.056 11.5456 25.9845 12.3702 26.5191L11 32H17L15.6298 26.5191Z"
                    ></path>
                    <path
                      className={style.lock}
                      d="M6 21V10C6 5.58172 9.58172 2 14 2V2C18.4183 2 22 5.58172 22 10V21"
                    ></path>
                    <path className={style.bling} d="M29 20L31 22"></path>
                    <path className={style.bling} d="M31.5 15H34.5"></path>
                    <path className={style.bling} d="M29 10L31 8"></path>
                  </svg>
                </label>
              </div>
            </>
          )}
        </div>
      </Paper>
    </div>
  ) : (
    <FuseLoading />
  );
};

export default Webkey;
