import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  SpeedDial,
  SpeedDialAction,
  TextField,
  Typography,
} from "@mui/material";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
} from "@nosferatu500/react-sortable-tree";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkItems,
  fecthMenuChanges,
  fetchMenu,
  selectMenus,
  setLangMenu,
  setMenuInfo,
  setMenuSelected,
  uncheckItems,
} from "./store/menuSlice";
import { useEffect } from "react";
import {
  Add,
  Delete,
  Edit,
  FormatListBulletedOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { setOpenDrawer } from "./store/drawerSlice";
import DrawerMenu from "./components/DrawerMenu";
import menuService from "./service/menuService";
import { t } from "app/store/labels/globalLabels";
import { fetchGroups, selectGroups } from "./store/groupsSlice";
import { fetchWorkspaces, selectWorkspaces } from "./store/workspacesSlice";
import {
  selectCurrentLanguage,
  selectSystemLanguages,
} from "app/store/i18nSlice";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import workspaceService from "./service/workspaceService";
import DrawerGroup from "./components/DrawerGroup";
import DrawerWorkspace from "./components/DrawerWorkspace";
import MenuActions from "../../components/menuActions/MenuActions";
import { changeLanguage as changeLanguageGroup } from "./store/navigationGroupsSlice";
import { changeLanguage as changeLanguageWorkspace } from "./store/navigationWorkspacesSlices";

const Menu = () => {
  const dispatch = useDispatch();
  const { menuSelected, status } = useSelector(selectMenus);
  const lang = useSelector(selectCurrentLanguage);
  const systemLang = useSelector(selectSystemLanguages);
  const [menuLang, setMenuLang] = useState(lang);
  const [menu, setMenu] = useState(null);
  const { groupActive } = useSelector(selectGroups);
  const { activeWorkspace } = useSelector(selectWorkspaces);
  const [search, setSearch] = useState("");
  const [searchFocus, setSearchFocus] = useState(0);
  const [searchFocusCount, setSearchFocusCount] = useState(0);

  const [dataDelete, setDataDelete] = useState({});

  const [open, setOpen] = useState(false);

  const getNodeKey = ({ treeIndex, node }) => node.menu_item_id;
  // const getNodeKey = ({ treeIndex }) => treeIndex;

  const handleEdit = async (options) => {
    const { node, path, treeIndex } = options;
    const edit = true;
    Promise.all([
      dispatch(setMenuInfo({ node, path, changeNodeAtPath, getNodeKey, edit })),
      dispatch(setOpenDrawer(true)),
    ]);
  };

  const handleAdd = async (options) => {
    const { node, path, treeIndex } = options;
    const edit = false;
    Promise.all([
      dispatch(
        setMenuInfo({ node, path, addNodeUnderParent, getNodeKey, edit })
      ),
      dispatch(setOpenDrawer(true)),
    ]);
  };

  const handleChange = (data) => {
    dispatch(setMenuSelected(data));
  };

  const handleDelete = async (options) => {
    const { node, path, treeIndex } = options;
    setDataDelete({ node, path });
    setOpen(true);
  };

  const handleCancelDialog = () => {
    setOpen(false);
  };

  const handleSubmitDialog = async () => {
    const node = dataDelete.node;
    const path = dataDelete.path;

    try {
      const res = await menuService.deleteMenu(node);

      const newData = removeNodeAtPath({
        treeData: menuSelected,
        path,
        getNodeKey,
      });

      dispatch(setMenuSelected(newData));
    } catch (error) {}

    setOpen(false);
  };

  const handleMoveMenu = async (args) => {
    try {
      const {
        prevPath,
        prevTreeIndex,
        nextPath,
        nextParentNode,
        nextTreeIndex,
        node,
        treeData,
      } = args;

      const result = await menuService.reorderItemsMenu(
        prevTreeIndex,
        nextTreeIndex,
        nextParentNode,
        node
      );

      const newOrder = await menuIds(treeData);

      const res = await menuService.reorderMenu(newOrder, node);
    } catch (error) {}
  };

  const menuIds = (menu) => {
    const arrayNew = [];

    for (let index = 0; index < menu.length; index++) {
      const element = menu[index];

      arrayNew.push(element.menu_item_id);

      if (element.children) arrayNew.push(...menuIds(element.children));
    }

    return arrayNew;
  };

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };

  const handleLanguageChange = (lng) => {
    const language = {
      id: lng.code,
      flag: lng.flag_src,
      title: lng.name,
    };

    setMenuLang(language);
    dispatch(setLangMenu(language));
    langMenuClose();

    Promise.all([dispatch(fecthMenuChanges())]).then((r) => {
      // dispatch(fetchGroups());
      // dispatch(fetchWorkspaces(language.id));
    });
  };

  const validateChecked = (item) => {
    const result = item.workspaces.some(
      (i) => i.workspace_id == activeWorkspace
    );
    return Boolean(result);
  };

  const handleCheck = (node, path, e, treeIndex) => {
    if (e) handleCheckTrue(path);
    else {
      const id = [path.reverse()[0]];
      const res = idsUncheck(menuSelected, id);
      handleUncheck(res);
    }
  };

  const handleCheckTrue = async (path) => {
    try {
      const res = await workspaceService.checkItem(path, activeWorkspace);

      const checkResult = checkItems(menuSelected, path, activeWorkspace);
      dispatch(setMenuSelected(checkResult));
    } catch (error) {}
  };

  const handleUncheck = async (path) => {
    try {
      const res = await workspaceService.uncheckItem(path, activeWorkspace);

      const uncheckResult = uncheckItems(menuSelected, path, activeWorkspace);
      dispatch(setMenuSelected(uncheckResult));
    } catch (error) {}
  };

  const idsUncheck = (menu, ids) => {
    const arrayNew = [];

    for (let index = 0; index < menu.length; index++) {
      const element = menu[index];

      if (ids.includes(element.menu_item_id)) {
        arrayNew.push(element.menu_item_id);
      }

      if (ids.includes(element.menu_item_id) && element.children) {
        const idsChildren = element.children.map((c) => c.menu_item_id);
        arrayNew.push(...idsUncheck(element.children, idsChildren));
      } else if (element.children) {
        arrayNew.push(...idsUncheck(element.children, ids));
      }
    }

    return arrayNew;
  };

  const handleSearch = ({ node, searchQuery }) =>
    searchQuery &&
    node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

  const selectPrev = () =>
    setSearchFocus(
      searchFocus !== null
        ? (searchFocusCount + searchFocus - 1) % searchFocusCount
        : searchFocusCount - 1
    );

  const selectNext = () =>
    setSearchFocus(
      searchFocus !== null ? (searchFocus + 1) % searchFocusCount : 0
    );

  return (
    <div className="h-full w-full">
      <div className="flex ml-20 mt-20 mb-20">
        <div>
          <Button className="h-40 w-64" onClick={langMenuClick}>
            {menuLang?.flag && (
              <img
                className="mx-4 min-w-20 object-contain w-20 h-20"
                src={menuLang?.flag}
                alt={menuLang?.id}
              />
            )}

            <Typography
              className="mx-4 font-semibold uppercase"
              color="text.secondary"
            >
              {menuLang?.id}
            </Typography>
          </Button>

          <Popover
            open={Boolean(menu)}
            anchorEl={menu}
            onClose={langMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{
              paper: "py-8",
            }}
          >
            {systemLang?.map((lng) => (
              <MenuItem
                key={lng.code}
                onClick={() => handleLanguageChange(lng)}
              >
                <ListItemIcon className="min-w-40">
                  {lng?.flag_src && (
                    <img
                      className="min-w-20 object-contain w-20 h-20"
                      src={lng.flag_src}
                      alt={lng.code}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={lng.name} />
              </MenuItem>
            ))}
          </Popover>
        </div>
        <div>
          <Paper className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-full shadow-none">
            <FuseSvgIcon color="action" size={20}>
              heroicons-solid:search
            </FuseSvgIcon>

            <Input
              placeholder={dispatch(t("common.search"))}
              className="flex flex-1 px-8"
              disableUnderline
              fullWidth
              value={search}
              inputProps={{
                "aria-label": "Search",
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Paper>
        </div>
        <div className="flex">
          <Button
            disabled={!searchFocusCount}
            onClick={selectPrev}
            className="min-w-0"
          >
            <FuseSvgIcon color="secondary" size={20}>
              material-outline:chevron_left
            </FuseSvgIcon>
          </Button>
          <Button
            disabled={!searchFocusCount}
            onClick={selectNext}
            className="min-w-0"
          >
            <FuseSvgIcon color="secondary" size={20}>
              material-outline:chevron_right
            </FuseSvgIcon>
          </Button>
        </div>
      </div>

      <div className="h-full" style={{ height: "calc(100vh - 275px)" }}>
        <SortableTree
          treeData={menuSelected}
          onChange={handleChange}
          canDrag={() => {
            if (activeWorkspace) return false;
            else return true;
          }}
          getNodeKey={({ node }) => node.menu_item_id}
          onMoveNode={(args) => handleMoveMenu(args)}
          generateNodeProps={({ node, path, treeIndex }) => ({
            title: [
              <div className="flex flex-row " key={node.menu_item_id}>
                <FuseSvgIcon size={"18px"}>{node.icon}</FuseSvgIcon>
                <Typography className="ml-5">{node.title}</Typography>
              </div>,
            ],
            subtitle: [
              <div className="flex flex-row " key={node.menu_item_id}>
                {node.subtitle && (
                  <Typography className="ml-3 mt-12 text-xs	">
                    {node.subtitle}
                  </Typography>
                )}
              </div>,
            ],
            buttons: activeWorkspace
              ? [
                  <Checkbox
                    checked={Boolean(node.active)}
                    onChange={(e) =>
                      handleCheck(node, path, e.target.checked, treeIndex)
                    }
                  />,
                ]
              : [
                  <MenuActions
                    onEdit={handleEdit}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    options={{ node, path, treeIndex }}
                  />,
                ],
          })}
          searchMethod={handleSearch}
          searchQuery={search}
          searchFocusOffset={searchFocus}
          searchFinishCallback={(matches) => {
            setSearchFocusCount(matches.length);
            setSearchFocus(
              matches.length > 0 ? searchFocus % matches.length : 0
            );
          }}
          onlyExpandSearchedNodes
        />
      </div>
      <DrawerMenu />
      <DrawerGroup />
      <DrawerWorkspace />
      <SpeedDial
        ariaLabel="fab_system_table"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color={"primary"}
        icon={<FormatListBulletedOutlined />}
      >
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle={dispatch(t("common.add"))}
          tooltipOpen
          onClick={() => {
            Promise.all([
              dispatch(
                setMenuInfo({
                  node: { menu_id: groupActive },
                  path: {},
                  addNodeUnderParent,
                  getNodeKey,
                  edit: false,
                  isNew: true,
                })
              ),
              dispatch(setOpenDrawer(true)),
            ]);
          }}
        />
        <SpeedDialAction
          icon={<RefreshOutlined />}
          tooltipTitle={dispatch(t("common.refresh"))}
          tooltipOpen
          onClick={() => {
            setMenuLang(lang);
            Promise.all([dispatch(fetchMenu(lang.id))]).then((r) => {
              dispatch(fetchGroups());
              dispatch(fetchWorkspaces(lang.id));
            });
          }}
        />
      </SpeedDial>
      <ConfirmationDialog
        open={open}
        onClose={handleCancelDialog}
        onSubmit={handleSubmitDialog}
      />
    </div>
  );
};

const ConfirmationDialog = ({ open, onClose, onSubmit }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {dispatch(t("common.delete"))}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {dispatch(t("common.confirm_action"))}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onSubmit}>
          {dispatch(t("common.yes"))}
        </Button>
        <Button autoFocus onClick={onClose}>
          {dispatch(t("common.cancel"))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Menu;

{
  /* <>
                    <Button
                      aria-describedby={`node-${node.menu_item_id}`}
                      className="h-40 w-64"
                      onClick={handleMenuOpen}
                    >
                      <Typography
                        className="mx-4 font-semibold uppercase"
                        color="text.secondary"
                      >
                        asdasdasd
                      </Typography>
                    </Button>

                    <Popover
                      id={`node-${node.menu_item_id}`}
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      classes={{
                        paper: "py-8",
                      }}
                    >
                      <IconButton
                        onClick={() => handleEdit(node, path, treeIndex)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleAdd(node, path)}>
                        <Add />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(node, path)}>
                        <Delete />
                      </IconButton>
                    </Popover>
                    {/* <MenuList id="actions-menu">
                      <MenuItem>
                        <ListItemIcon className="min-w-40">
                          <FuseSvgIcon>heroicons-outline:trash</FuseSvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Remove Checklist" />
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon className="min-w-40">
                          <FuseSvgIcon>heroicons-outline:pencil</FuseSvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Rename Checklist" />
                      </MenuItem>
                    </MenuList>
                    <IconButton
                      onClick={() => handleEdit(node, path, treeIndex)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleAdd(node, path)}>
                      <Add />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(node, path)}>
                      <Delete />
                    </IconButton>
                  </>, */
}
