import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CountryCodeSelector from "./CountryCodeSelector";
import { t } from "app/store/labels/globalLabels";
import { useNavigate, useParams } from "react-router-dom";
import {
  EntityPhone,
  removeEntityPhone,
  selectEntity,
} from "../../store/entitySlice";
import { Button, Radio, Tooltip } from "@mui/material";

function PhoneNumberInput(props) {
  const { value, hideRemove } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const entity = useSelector(selectEntity);

  const schema = yup.object().shape({
    country_code: yup.string(),
    phone_number: yup
      .string()
      .matches(/^\d*$/, dispatch(t("common.numbers_only"))),
    label: yup.string(),
    is_main: "",
  });

  const defaultValues = {
    countryPhone_code: "pt",
    phone_number: "",
    label: "",
    is_main: "",
  };

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    reset(value);
  }, [reset, value]);

  const onSubmit = (data) => {
    const obj = {
      ...data,
      phone_number: data.phone_number,
      country_code: data.countryPhone_code,
      entity_id: entity.entity_id,
      is_main: data.is_main ? 1 : 0,
    };

    props.onChange(obj);

    dispatch(EntityPhone(obj)).then(({ payload }) => {
      if (payload === "success") {
        navigate(
          `/system/entities/${routeParams.type}/${entity.entity_id}/edit`
        );
      }
    });
  };

  const handleDelete = () => {
    if (value.phone_number != "") {
      dispatch(removeEntityPhone(value)).then(({ payload }) => {
        if (payload === "success") props.onRemove(value);
        navigate(
          `/system/entities/${routeParams.type}/${entity.entity_id}/edit`
        );
      });
    } else {
      props.onRemove();
    }
  };

  return (
    <form
      className="flex space-x-5 h-full px-24 sm:px-48"
      onClick={() => handleSubmit(onSubmit)}
    >
      <Controller
        name="is_main"
        control={control}
        render={({ field: { value, onChange, ...field } }) => (
          <Tooltip
            title={
              value
                ? dispatch(t("common.main"))
                : dispatch(t("common.set_main"))
            }
          >
            <Radio
              className="mt-32"
              {...field}
              name="phone-number-entities"
              checked={Boolean(value)}
              onChange={(e) => {
                onChange(e.target.checked);
                props.onChecked(e.target.checked);
              }}
            />
          </Tooltip>
        )}
      />

      <Controller
        control={control}
        name="phone_number"
        render={({ field }) => (
          <TextField
            className="mt-32 w-1/2"
            {...field}
            label={dispatch(t("common.contacts"))}
            placeholder={dispatch(t("common.contacts"))}
            id="phone_number"
            error={!!errors.phone_number}
            helperText={errors?.phone_number?.message}
            variant="outlined"
            // onChange={(e) => {
            //   onChange(e.target.value);
            //   props.onChange({
            //     ...value,
            //     phone_number: e.target.value,
            //   });
            // }}
            fullWidth
            InputProps={{
              startAdornment: (
                <Controller
                  control={control}
                  name="countryPhone_code"
                  render={({ field: _field }) => (
                    <InputAdornment position="start">
                      <CountryCodeSelector {..._field} />
                    </InputAdornment>
                  )}
                />
              ),
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="label"
        // render={({ field: { onChange, ...field } }) => (
        render={({ field }) => (
          <TextField
            {...field}
            className="mt-32 w-1/2"
            value={field.value || ""}
            label="Label"
            placeholder="Label"
            variant="outlined"
            fullWidth
            // onChange={(e) => {
            //   onChange(e.target.value);
            //   props.onChange({
            //     ...value,
            //     label: e.target.value,
            //   });
            // }}
            error={!!errors.label}
            helperText={errors?.label?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FuseSvgIcon size={20}>heroicons-solid:tag</FuseSvgIcon>
                </InputAdornment>
              ),
              endAdornment: (
                <div className="flex flex-row">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    onClick={handleSubmit(onSubmit)}
                  >
                    <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                  </IconButton>

                  {!hideRemove && (
                    <IconButton
                      className="flex flex-col"
                      onClick={handleDelete}
                    >
                      <FuseSvgIcon size={20}>heroicons-solid:trash</FuseSvgIcon>
                    </IconButton>
                  )}
                </div>
              ),
            }}
          />
        )}
      />
    </form>
  );
}

PhoneNumberInput.defaultProps = {
  hideRemove: false,
};

export default PhoneNumberInput;
