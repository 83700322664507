import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const fetchUserList = createAsyncThunk(
  "notifications/users/fetchUserList",
  async (_, { dispatch, getSate }) => {
    try {
      const res = await axiosConfig.get("/users/");

      if (res.state === "error") return [];

      const sortedUsers = res.result.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      return res.result;
    } catch (error) {
      return [];
    }
  }
);

const usersSlice = createSlice({
  name: "notifications/users",
  initialState: {
    users: [],
    status: "",
  },

  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUserList.pending, (state) => {
      state.status = "pending";
    }),
      builder.addCase(fetchUserList.fulfilled, (state, action) => {
        state.users = action.payload;
        state.status = "fulfilled";
      }),
      builder.addCase(fetchUserList.rejected, (state) => {
        state.users = [];
        state.status = "rejected";
      });
  },
});

export const { setUsers } = usersSlice.actions;

export const selectUsers = ({ notifications }) => notifications.users.users;
export const selectStatus = ({ notifications }) => notifications.users.status;

export default usersSlice.reducer;
