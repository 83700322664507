import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "rsuite";
import { t } from "app/store/labels/globalLabels";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import _ from "@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { selectOpenGroup, setDrawerGroup } from "../store/drawerSlice";
import { fetchGroups, selectGroups } from "../store/groupsSlice";
import groupService from "../service/groupService";

const DrawerGroup = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectOpenGroup);
  const { groupInfo } = useSelector(selectGroups);

  const [disabled, setDisabled] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required(dispatch(t("common.input_required"))),
  });

  const { control, formState, handleSubmit, setError, setValue, reset } =
    useForm({
      mode: "onChange",
      defaultValues: {
        name: "",
      },
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (groupInfo) {
      setValue("name", groupInfo.name || "", {
        shouldDirty: true,
        shouldValidate: groupInfo?.menu_id ? true : false,
      });
    }
  }, [groupInfo]);

  const handleClose = async () => {
    setDisabled(false);
    reset();
    dispatch(setDrawerGroup(false));
  };

  const onSubmit = async (data, e) => {
    setDisabled(true);
    e.preventDefault();
    try {
      if (groupInfo && groupInfo.menu_id) {
        const res = await groupService.editGroup(data, groupInfo);
      } else {
        const res = await groupService.addGroup(data);
      }

      dispatch(fetchGroups());
      handleClose();
    } catch (error) {}

    setDisabled(false);
  };

  return (
    <Drawer backdrop={true} open={open} onClose={handleClose}>
      <Drawer.Header>
        <Drawer.Title>
          {groupInfo?.menu_id
            ? dispatch(t("common.edit"))
            : dispatch(t("common.add"))}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <form
          name="menuForm"
          noValidate
          className="flex flex-col justify-center w-full mt-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label={dispatch(t("common.name"))}
                placeholder={dispatch(t("common.name"))}
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Button
            variant="contained"
            color="secondary"
            className="w-full mt-16"
            aria-label="Sign in"
            disabled={_.isEmpty(dirtyFields) || !isValid || disabled}
            type="submit"
            size="large"
          >
            {dispatch(t("common.save"))}
          </Button>
        </form>
      </Drawer.Body>
    </Drawer>
  );
};

export default DrawerGroup;
