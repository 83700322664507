import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import _ from "@lodash";
import {
  addSection,
  getSection,
  getSectionsTemplates,
  newSection,
  selectSection,
  selectSectionsTemplates,
  selectTemplateSection,
  updateSection,
} from "../store/sectionsSlice";
import { useNavigate, useParams } from "react-router-dom";
import FuseLoading from "@fuse/core/FuseLoading";
import InputTranslate from "src/app/main/components/textTranslate/InputTranslate";

const SectionForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const section = useSelector(selectSection);
  const templates = useSelector(selectSectionsTemplates);
  const templateDefault = useSelector(selectTemplateSection);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (routeParams.form === "new") {
      setTitle(dispatch(t("common.add")));
      dispatch(newSection(templateDefault));
    } else {
      setTitle(dispatch(t("common.edit")));
      dispatch(getSection(routeParams.section));
    }
  }, [dispatch, routeParams]);

  const schema = yup.object().shape({
    name_tag: yup
      .string()
      .matches(/^[a-zA-Z0-9_-]+$/, dispatch(t("common.invalid_chars")))
      .required(dispatch(t("common.input_required"))),
    post_template_id: yup
      .number()
      .required(dispatch(t("common.input_required"))),
    section_order: yup.number().notRequired(),
  });

  const { control, setValue, watch, reset, formState, handleSubmit } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({ ...section });
  }, [section]);

  const { dirtyFields, isValid, errors } = formState;

  const form = watch();

  const onSubmit = async (data) => {
    try {
      if (routeParams.form === "new") {
        dispatch(addSection(data)).then(({ payload }) => {
          navigate(`/system/sections/${payload.id}`);
        });
      } else {
        dispatch(updateSection(data)).then(({ payload }) => {
          navigate(`/system/sections/${routeParams.section}`);
        });
      }
    } catch (error) {}
  };

  if (_.isEmpty(form) || !section || !templates) {
    return <FuseLoading />;
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex justify-between items-center mt-10 px-24">
        <Typography>{title}</Typography>
        <IconButton
          className=""
          sx={{ color: "background.primary" }}
          component={NavLinkAdapter}
          to={`/system/sections/${routeParams.section}`}
          size="large"
        >
          <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
        </IconButton>
      </div>
      <div className="w-full px-24 flex-1">
        <Controller
          control={control}
          name={"name_tag"}
          render={({ field: { onChange, ...field } }) => (
            <TextField
              {...field}
              className="mt-32"
              label={dispatch(t("common.tag"))}
              placeholder={dispatch(t("common.tag"))}
              disabled={routeParams.form !== "new"}
              onChange={(e) => {
                setValue("title", e.target.value);
                onChange(e.target.value);
              }}
              id="name_tag"
              error={!!errors.name_tag}
              helperText={errors?.name_tag?.message}
              variant="outlined"
              required
              fullWidth
            />
          )}
        />

        <Controller
          control={control}
          name={"name_lang"}
          render={({ field }) => (
            <InputTranslate {...field} className={"mt-32"} />
          )}
        />

        <Controller
          control={control}
          name="post_template_id"
          render={({ field: { onChange, value, ...field } }) => (
            <Autocomplete
              {...field}
              id="post_template_id"
              className="mt-32"
              options={templates}
              disabled={routeParams.form !== "new"}
              value={
                value
                  ? _.find(templates, {
                      post_template_id: value?.post_template_id || value,
                    })
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue != null && typeof newValue == "object")
                  return onChange(newValue.post_template_id);

                return onChange(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                value && option.post_template_id === value?.post_template_id
              }
              getOptionLabel={(option) => option.name || `[${option.name_tag}]`}
              renderOption={(_props, option) => (
                <Box
                  {..._props}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                >
                  {option.name || `[${option.name_tag}]`}
                </Box>
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={dispatch(t("common.template"))}
                  placeholder={dispatch(t("common.template"))}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name={"section_order"}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              className="mt-32"
              label={dispatch(t("common.order"))}
              placeholder={dispatch(t("common.order"))}
              id="section_order"
              error={!!errors.section_order}
              helperText={errors?.section_order?.message}
              variant="outlined"
              required
              fullWidth
            />
          )}
        />

        <Controller
          control={control}
          name="is_public"
          render={({ field: { value, onChange, ...field } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  name="is_public"
                  checked={Boolean(value)}
                  onChange={(e) => {
                    onChange(e.target.checked ? 1 : 0);
                  }}
                />
              }
              label={dispatch(t("common.public"))}
            />
          )}
        />
      </div>

      <Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t">
        <Button
          className="ml-auto"
          component={NavLinkAdapter}
          to={`/system/sections/${routeParams.section}`}
        >
          {dispatch(t("common.cancel"))}
        </Button>
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {dispatch(t("common.save"))}
        </Button>
      </Box>
    </div>
  );
};

export default SectionForm;
