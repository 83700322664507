import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";
import {
  changeLanguage,
  createNavigation,
  resetNavigation,
} from "./navigationError_logsSlice";
import FuseUtils from "@fuse/utils/FuseUtils";

export const fetchErrorsList = createAsyncThunk(
  "error_logs/table/fetchErrorsList",
  async (_, { dispatch, getState }) => {
    dispatch(resetNavigation());
    dispatch(setActiveError_logs(null));
    try {
      const res = await axiosConfig.get("/error_logs");

      if (res.status === "error") return [];

      if (res.result.length > 0) dispatch(createNavigation(res.result));

      dispatch(changeLanguage());

      const data = await splitData(res.result);
      const items = await res.result.map((item) => {
        return {
          file: item.file,
          folder: item.folder,
        };
      });

      await dispatch(setSearchValues(items));

      return data;
    } catch (error) {
      return [];
    }
  }
);

const splitData = async (data) => {
  const result = await data.map((item) => {
    const final = item.data.map((_item) => {
      return {
        ..._item,
        file: item.file.split("API_server_root")[1],
        folder: item.folder,
        file_path: _item.file_path.split("API_server_root")[1],
      };
    });

    return final;
  });
  const finalResult = [].concat(...result);

  return finalResult;
};

export const fiterError_log = (text) => (dispatch, getState) => {
  const table = getState().error_logs.table.searchValues;

  dispatch(resetNavigation());

  if (text.length === 0) return dispatch(createNavigation(table));

  const filteredGroups = FuseUtils.filterArrayByString([...table], text);

  return dispatch(createNavigation(filteredGroups));
};

const errorsSlice = createSlice({
  name: "error_logs/table",
  initialState: {
    error_logs: [],
    filterError_log_table: [],
    activeError_logs: null,
    searchValues: [],
    status: "",
  },

  reducers: {
    setErrors: (state, action) => {
      state.error_logs = action.payload;
    },
    setFilterError_logs_table: (state, action) => {
      const index = action.payload;

      if (index === "0") {
        state.filterError_log_table = state.error_logs;
      } else {
        state.filterError_log_table = state.error_logs.filter((item) => {
          const check = item.file
            ? item.file === index.split("API_server_root")[1]
            : false;
          return check;
        });
      }
    },
    setActiveError_logs: (state, action) => {
      const id = action.payload;

      if (id === "none" || !id) state.activeError_logs = null;
      else state.activeError_logs = action.payload;
    },
    setSearchValues: (state, action) => {
      state.searchValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchErrorsList.pending, (state) => {
      state.status = "pending";
    }),
      builder.addCase(fetchErrorsList.fulfilled, (state, action) => {
        state.error_logs = action.payload;
        state.filterError_log_table = action.payload;
        state.status = "success";
      }),
      builder.addCase(fetchErrorsList.rejected, (state) => {
        state.error_logs = [];
        state.status = "reject";
      });
  },
});

export const {
  setErrors,
  setActiveError_logs,
  setFilterError_logs_menu,
  setFilterError_logs_table,
  setSearchValues,
} = errorsSlice.actions;

export const selectFilteredErrors = ({ error_logs }) =>
  error_logs.table.filterError_log_table;
export const selectErrors = ({ error_logs }) => error_logs.table.error_logs;
export const selectStatus = ({ error_logs }) => error_logs.table.status;

export default errorsSlice.reducer;
