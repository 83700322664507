// import createGenerateClassName from '@mui/styles/createGenerateClassName';
// import jssPreset from '@mui/styles/jssPreset';
// import { create } from 'jss';
// import jssExtend from 'jss-plugin-extend';
// import rtl from 'jss-rtl';
import Provider from "react-redux/es/components/Provider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StyledEngineProvider } from "@mui/material/styles";
import routes from "app/configs/routesConfig";
import store from "./store";
import AppContext from "./AppContext";
import { AxiosInterceptor } from "./auth/services/axios/axiosConfig";
import { OnlineStatusProvider } from "./context/OnlineStatusContext";
import MessageConnection from "./shared-components/MessageConnection";

const withAppProviders = (Component) => (props) => {
  const WrapperComponent = () => (
    <OnlineStatusProvider>
      <MessageConnection />
      <AppContext.Provider
        value={{
          routes,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Provider store={store}>
            <AxiosInterceptor>
              <StyledEngineProvider injectFirst>
                <Component {...props} />
              </StyledEngineProvider>
            </AxiosInterceptor>
          </Provider>
        </LocalizationProvider>
      </AppContext.Provider>
    </OnlineStatusProvider>
  );

  return WrapperComponent;
};

export default withAppProviders;
