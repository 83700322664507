import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useEffect } from "react";
import {
  Autocomplete,
  Button,
  IconButton,
  Radio,
  Tooltip,
} from "@mui/material";
import { t } from "app/store/labels/globalLabels";
import { useDispatch, useSelector } from "react-redux";
import { selectCountries } from "../../store/countriesSlice";
import {
  EntityAddress,
  removeEntityAddress,
  selectEntity,
} from "../../store/entitySlice";
import { useNavigate, useParams } from "react-router-dom";

const AddressInput = (props) => {
  const { value, hideRemove } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const entity = useSelector(selectEntity);
  const countries = useSelector(selectCountries);

  const schema = yup.object().shape({
    street: yup.string().nullable(),
    country_code: yup.string().nullable(),
    zip_code: yup
      .string()
      .matches(/^[\d-]*$/, dispatch(t("common.numbers_only")))
      .nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    label: yup.string().nullable(),
  });

  const defaultValues = {
    street: "",
    country_code: "",
    city: "",
    state: "",
    zip_code: "",
    // is_main: "",
    label: "",
  };

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(value);
  }, [reset, value]);

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = (data) => {
    const obj = {
      ...data,
      entity_id: entity.entity_id,
      // is_main: data.is_main ? 1 : 0,
    };

    props.onChange(obj);

    dispatch(EntityAddress(obj)).then(({ payload }) => {
      if (payload === "success") {
        navigate(
          `/system/entities/${routeParams.type}/${entity.entity_id}/edit`
        );
      }
    });
  };

  const handleDelete = () => {
    if (value.street != "") {
      dispatch(removeEntityAddress(value)).then(({ payload }) => {
        if (payload === "success") props.onRemove(value);
        navigate(
          `/system/entities/${routeParams.type}/${entity.entity_id}/edit`
        );
      });
    } else {
      props.onRemove();
    }
  };

  return (
    <form
      className="w-full px-24 sm:px-48"
      onClick={() => handleSubmit(onSubmit)}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between">
          <Controller
            name="is_main"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Tooltip
                title={
                  value
                    ? dispatch(t("common.main"))
                    : dispatch(t("common.set_main"))
                }
              >
                <Radio
                  className="mt-32"
                  {...field}
                  name="address-entities"
                  checked={Boolean(value)}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    props.onChecked(e.target.checked);
                  }}
                />
              </Tooltip>
            )}
          />

          <Controller
            control={control}
            name="label"
            // render={({ field: { onChange, ...field } }) => (
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ""}
                id="label"
                className="mt-32"
                label={dispatch(t("common.label"))}
                placeholder={dispatch(t("common.label"))}
                variant="outlined"
                // onChange={(e) => {
                //   onChange(e.target.value);
                //   props.onChange({
                //     ...value,
                //     label: e.target.value,
                //   });
                // }}
                error={!!errors.label}
                helperText={errors?.label?.message}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FuseSvgIcon size={20}>heroicons-solid:tag</FuseSvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <div className="flex flex-row">
                      <IconButton
                        variant="contained"
                        color="secondary"
                        disabled={_.isEmpty(dirtyFields) || !isValid}
                        onClick={handleSubmit(onSubmit)}
                      >
                        <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                      </IconButton>

                      {!hideRemove && (
                        <IconButton
                          className="flex flex-col"
                          onClick={handleDelete}
                        >
                          <FuseSvgIcon size={20}>
                            heroicons-solid:trash
                          </FuseSvgIcon>
                        </IconButton>
                      )}
                    </div>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>
      <Controller
        control={control}
        name="street"
        // render={({ field: { onChange, ...field } }) => (
        render={({ field }) => (
          <TextField
            className="mt-32"
            value={field.value || ""}
            {...field}
            label={dispatch(t("common.street"))}
            placeholder={dispatch(t("common.street"))}
            id="street"
            // onChange={(e) => {
            //   onChange(e.target.value);
            //   props.onChange({
            //     ...value,
            //     street: e.target.value,
            //   });
            // }}
            error={!!errors.street}
            helperText={errors?.street?.message}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FuseSvgIcon size={20}>heroicons-outline:home</FuseSvgIcon>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between">
          <Controller
            control={control}
            name="state"
            // render={({ field: { onChange, ...field } }) => (
            render={({ field }) => (
              <TextField
                className="mt-32 w-1/2 mr-2"
                {...field}
                value={field.value || ""}
                label={dispatch(t("common.status"))}
                placeholder={dispatch(t("common.status"))}
                id="state"
                // onChange={(e) => {
                //   onChange(e.target.value);
                //   props.onChange({
                //     ...value,
                //     state: e.target.value,
                //   });
                // }}
                error={!!errors.state}
                helperText={errors?.state?.message}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FuseSvgIcon size={20}>
                        heroicons-outline:home
                      </FuseSvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="city"
            // render={({ field: { onChange, ...field } }) => (
            render={({ field }) => (
              <TextField
                className="mt-32 ml-2 grow"
                {...field}
                value={field.value || ""}
                label={dispatch(t("common.city"))}
                placeholder={dispatch(t("common.city"))}
                id="city"
                // onChange={(e) => {
                //   onChange(e.target.value);
                //   props.onChange({
                //     ...value,
                //     city: e.target.value,
                //   });
                // }}
                error={!!errors.city}
                helperText={errors?.city?.message}
                variant="outlined"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FuseSvgIcon size={20}>
                        material-outline:location_city
                      </FuseSvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>

        <div className="flex flex-row justify-between">
          <Controller
            control={control}
            name="zip_code"
            // render={({ field: { onChange, ...field } }) => (
            render={({ field }) => (
              <TextField
                className="mt-32 w-1/2 mr-2"
                {...field}
                value={field.value || ""}
                label="Zip Code"
                placeholder="Zip Code"
                id="zip_code"
                error={!!errors.zip_code}
                helperText={errors?.zip_code?.message}
                variant="outlined"
                // onChange={(e) => {
                //   onChange(e.target.value);
                //   props.onChange({
                //     ...value,
                //     zip_code: e.target.value,
                //   });
                // }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FuseSvgIcon size={20}>
                        heroicons-outline:location-marker
                      </FuseSvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="country_code"
            render={({ field: { onChange, value, ...field } }) => (
              <Autocomplete
                className="mt-32 w-1/2 ml-2"
                id="country_code"
                options={countries}
                value={
                  value ? _.find(countries, { iso: value?.iso || value }) : null
                }
                onChange={(event, newValue) => {
                  // props.onChange({
                  //   ...value,
                  //   country_code: newValue,
                  // });
                  if (newValue != null && typeof newValue === "object")
                    return onChange(newValue.iso);

                  return onChange(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  value && option.iso == value?.iso
                }
                getOptionLabel={(option) => option.name}
                renderOption={(_props, option) => (
                  <li {..._props}>{option.name}</li>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={dispatch(t("common.country"))}
                    placeholder={dispatch(t("common.country"))}
                  />
                )}
              />
            )}
          />
        </div>
      </div>
    </form>
  );
};

export default AddressInput;
