import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "rsuite";
import { t } from "app/store/labels/globalLabels";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import _ from "@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { selectOpenWorkspace, setDrawerWorkspace } from "../store/drawerSlice";
import { fetchWorkspaces, selectWorkspaces } from "../store/workspacesSlice";
import Translate from "src/app/main/components/inputLanguageSelector/Translate";
import workspaceService from "../service/workspaceService";
import { selectSystemLanguages } from "app/store/i18nSlice";

const DrawerWorkspace = () => {
  const dispatch = useDispatch();
  const systemLang = useSelector(selectSystemLanguages);
  const open = useSelector(selectOpenWorkspace);
  const { workspaceInfo } = useSelector(selectWorkspaces);
  const [nameLang, setNameLang] = useState("");
  const [disabled, setDisabled] = useState(false);

  const schema = yup.object().shape({
    name_tag: yup.string().required(dispatch(t("common.input_required"))),
    is_active: yup.boolean(),
  });

  const { control, formState, handleSubmit, setError, setValue, reset } =
    useForm({
      mode: "onChange",
      defaultValues: {
        name_tag: "",
        is_active: false,
      },
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (workspaceInfo && workspaceInfo?.workspace_id) {
      setValue("name_tag", workspaceInfo?.name_tag || "", {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue("is_active", Boolean(workspaceInfo?.is_active) || false, {
        shouldDirty: false,
        shouldValidate: false,
      });
      setNameLang(workspaceInfo?.name_lang);
    }
  }, [workspaceInfo]);

  const handleClose = async () => {
    setDisabled(false);
    reset();
    setNameLang("");
    dispatch(setDrawerWorkspace(false));
  };

  const handleChangeName = async (e) => {
    setNameLang(e);
  };

  const onSubmit = async (data, e) => {
    setDisabled(true);
    e.preventDefault();
    try {
      const body = {
        name_tag: data.name_tag,
        is_active: Boolean(data.is_active) ? 1 : 0,
      };

      if (workspaceInfo?.workspace_id) {
        const res = await workspaceService.editWorkspace(
          workspaceInfo,
          body,
          nameLang,
          systemLang
        );
      } else {
        const res = await workspaceService.addWorkspace(
          body,
          nameLang,
          systemLang
        );
      }

      dispatch(fetchWorkspaces());
      handleClose();
    } catch (error) {}

    setDisabled(false);
  };

  return (
    <Drawer backdrop={true} open={open} onClose={handleClose}>
      <Drawer.Header>
        <Drawer.Title>
          {workspaceInfo?.workspace_id
            ? dispatch(t("common.edit"))
            : dispatch(t("common.add"))}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <form
          name="menuForm"
          noValidate
          className="flex flex-col justify-center w-full mt-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="name_tag"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label={dispatch(t("common.name"))}
                placeholder={dispatch(t("common.name"))}
                error={!!errors.name_tag}
                helperText={errors?.name_tag?.message}
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Translate
            id={"name_lang"}
            onChangeText={handleChangeName}
            lang={workspaceInfo?.workspace_id ? workspaceInfo?.name_lang : ""}
            placeholder={dispatch(t("common.title"))}
          />

          <Controller
            name="is_active"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                className="mb-8"
                control={
                  <Checkbox
                    checked={value}
                    onChange={(event) => onChange(event.target.checked)}
                    name={dispatch(t("common.active"))}
                    color="secondary"
                  />
                }
                label={dispatch(t("common.active"))}
                id="is_active"
              />
            )}
          />

          <Button
            variant="contained"
            color="secondary"
            className="w-full mt-16"
            aria-label="Sign in"
            disabled={_.isEmpty(dirtyFields) || !isValid || disabled}
            type="submit"
            size="large"
          >
            {dispatch(t("common.save"))}
          </Button>
        </form>
      </Drawer.Body>
    </Drawer>
  );
};

export default DrawerWorkspace;
