import { lazy } from "react";

const PlansApp = lazy(() => import("./PlansApp"));

const PlansConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/access_plans/plans",
      element: <PlansApp />,
    },
  ],
};

export default PlansConfig;
