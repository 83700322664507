import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { action } from "mobx";
import axiosConfig from "src/app/auth/services/axios/axiosConfig";

export const fetchCronjobsList = createAsyncThunk(
  "cronjobs/gantt/fetchCronjobsList",
  async (_, { dispatch, getState }) => {
    try {
      const cronjobsRes = await axiosConfig.get("/cronjobs");
      const fileRes = await axiosConfig.get("/cronjobs/system");

      if (cronjobsRes.status === "error" || fileRes.status === "error")
        throw new Error();

      return { cronjobs: cronjobsRes.result, files: fileRes.result };
    } catch (error) {
      throw new Error(error);
    }
  }
);

const cronjobsSlice = createSlice({
  name: "cronjobs/gantt",
  initialState: {
    cronjobs: [],
    files: [],
    status: "pending",
  },

  reducers: {
    setCronjobs: (state, action) => {
      state.cronjobs = action.payload;
    },

    setFiles: (state, action) => {
      state.files = action.payload;
    },

    addCronjobs: (state, action) => {
      state.cronjobs.push(action.payload);
    },

    removeCronjobs: (state, action) => {
      const index = action.payload;
      state.cronjobs.splice(index, 1);
    },

    updateCronjobs: (state, action) => {
      const { index, value } = action.payload;
      state.cronjobs[index] = value;
    },
    updateCronjobsState: (state, action) => {
      const { index, value } = action.payload;
      state.cronjobs[index] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCronjobsList.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchCronjobsList.fulfilled, (state, action) => {
        state.cronjobs = action.payload.cronjobs;
        state.files = action.payload.files;

        state.status = "success";
      })
      .addCase(fetchCronjobsList.rejected, (state, action) => {
        state.cronjobs = [];
        state.files = [];
        state.status = "rejected";
      });
  },
});

export const {
  setCronjobs,
  setFiles,
  addCronjobs,
  removeCronjobs,
  updateCronjobs,
  updateCronjobsState,
} = cronjobsSlice.actions;

export const selectCronjobs = ({ cronjobs }) => cronjobs.gantt.cronjobs;
export const selectFiles = ({ cronjobs }) => cronjobs.gantt.files;
export const selectStatus = ({ cronjobs }) => cronjobs.gantt.status;

export default cronjobsSlice.reducer;
